import {
    FETCH_SLIDER_IMAGE_REQUEST,
    FETCH_SLIDER_IMAGE_SUCCESS,
    FETCH_SLIDER_IMAGE_FAIL,
    CHANGE_ALTERNATIVE_TEXT,
    DELETE_SLIDER_IMAGE,
    DELETE_ALL_IMAGES,
} from "../actionTypes";
import { SidebarData } from "../../components/HomePageSlider/SliderImagesData";

export const getSliderImages = () => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_SLIDER_IMAGE_REQUEST,
        });
        const images = SidebarData;
        dispatch({
            type: FETCH_SLIDER_IMAGE_SUCCESS,
            payload: images,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SLIDER_IMAGE_FAIL,
            payload: error,
        });
    }
};

export const deleteSliderImage = (image) => (dispatch, getState) => {
    const images = getState().SliderImagesReducer.images.filter(
        (x) => x.id !== image.id
    );
    // console.log(images);
    dispatch({
        type: DELETE_SLIDER_IMAGE,
        payload: images,
    });
};

export const deleteAll = (data) => (dispatch, getState) => {
    const images = getState().SliderImagesReducer.images;
    let arrayId = [];
    data.forEach((d) => {
        if (d.select) {
            arrayId.push(d.id);
        }
    });
    let arr = images.filter((val) => !arrayId.includes(val.id));
    // console.log(arr);

    dispatch({
        type: DELETE_ALL_IMAGES,
        payload: arr,
    });
};

export const changeAlternativeText =
    (id, alternativeText) => (dispatch, getState) => {
        let images = getState().SliderImagesReducer.images;

        for (let i = 0; i < images.length; i++) {
            if (images[i].id === id) {
                images[i].alternativeText = alternativeText;
            }
        }
        dispatch({
            type: CHANGE_ALTERNATIVE_TEXT,
            images: images,
        });
    };
