import React, { useState, useEffect, useCallback } from "react";
import PageHeading from "../pages/PageHeading";
import axios from "axios";
import { notify } from "../../utility";
import ProductCard from "./productCard";

const ProductsDashboard = (props) => {
  let [products, setProducts] = useState([]);
  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [category, setCategory] = useState("clothes");
  let [electronicsDevice, setElectronicsDevice] = useState("mobiles");

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;

  const fetchClothes = useCallback(() => {
    let config = {
      method: "get",
      url: "http://localhost:5000/products",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.products;
        setProducts(
          data.map((d) => {
            return {
              select: false,
              id: d.id,
              title: d.title,
              image: d.image,
              description: d.description,
              price: d.price,
              category: d.category,
              count: d.count,
              rating: d.rating,
              status: d.status,
              images: d.images,
              attribute: d.attributes,
            };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [access_token]);

  const fetchMobiles = useCallback(() => {
    let config = {
      method: "get",
      url: "http://localhost:5000/products/getMobiles",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.mobiles;
        setProducts(
          data.map((d) => {
            return {
              select: false,
              id: d.id,
              title: d.title,
              image: d.image,
              description: d.description,
              price: d.price,
              count: d.count,
              rating: d.rating,
              status: d.status,
              images: d.images,
            };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [access_token]);

  const fetchLaptops = useCallback(() => {
    let config = {
      method: "get",
      url: "http://localhost:5000/products/getLaptops",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.laptops;
        setProducts(
          data.map((d) => {
            return {
              select: false,
              id: d.id,
              title: d.title,
              image: d.image,
              description: d.description,
              price: d.price,
              count: d.count,
              rating: d.rating,
              status: d.status,
              images: d.images,
            };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [access_token]);

  const fetchProducts = useCallback(() => {
    if (category === "clothes") {
      fetchClothes();
    }
    if (category === "electronics" && electronicsDevice === "mobiles") {
      fetchMobiles();
    }
    if (category === "electronics" && electronicsDevice === "laptop") {
      fetchLaptops();
    }
  }, [category, electronicsDevice, fetchClothes, fetchMobiles, fetchLaptops]);

  useEffect(() => {
    fetchProducts();
  }, [category, electronicsDevice, fetchProducts]);

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      let imagesArr = [];
      if (category === "clothes") {
        products.forEach((d) => {
          if (d.select) {
            arrayId.push(d.id);
            let a = d.image.split("/");
            imagesArr.push({
              folder: "productImages",
              image: a[a.length - 1],
            });
          }
        });
        products.forEach((d) => {
          if (d.select) {
            if (d.images.length > 0) {
              d.images.map((i) => {
                // console.log("i", i);
                // let a = i.split("/");
                imagesArr.push({
                  folder: "productImages",
                  image: i.imageName,
                });
                return null;
              });
            }
          }
        });
        axios
          .delete("http://localhost:5000/products/deleteProduct", {
            data: {
              product_ids: arrayId,
            },
          })
          .then((response) => {
            notify(response.data.message);
            setSelectAllCheckbox(false);
            setActiveInactive(false);
            setPageSearch(true);
            fetchProducts();
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      if (category === "electronics" && electronicsDevice === "mobiles") {
        products.forEach((d) => {
          if (d.select) {
            arrayId.push(d.id);
            let a = d.image.split("/");
            imagesArr.push({
              folder: "mobileImages",
              image: a[a.length - 1],
            });
          }
        });
        products.forEach((d) => {
          if (d.select) {
            if (d.images.length > 0) {
              d.images.map((i) => {
                // let a = i.split("/");
                imagesArr.push({
                  folder: "mobileImages",
                  // image: a[a.length - 1],
                  image: i.imageName,
                });
                return null;
              });
            }
          }
        });
        axios
          .delete("http://localhost:5000/products/deleteMobiles", {
            data: {
              product_ids: arrayId,
            },
          })
          .then((response) => {
            notify(response.data.message);
            setSelectAllCheckbox(false);
            setActiveInactive(false);
            setPageSearch(true);
            fetchProducts();
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      if (category === "electronics" && electronicsDevice === "laptop") {
        products.forEach((d) => {
          if (d.select) {
            arrayId.push(d.id);
            let a = d.image.split("/");
            imagesArr.push({
              folder: "laptopImages",
              image: a[a.length - 1],
            });
          }
        });
        products.forEach((d) => {
          if (d.select) {
            if (d.images.length > 0) {
              // console.log(d.images);
              d.images.map((i) => {
                // let a = i.split("/");
                imagesArr.push({
                  folder: "laptopImages",
                  // image: a[a.length - 1],
                  image: i.imageName,
                });
                return null;
              });
            }
          }
        });
        axios
          .delete("http://localhost:5000/products/deleteLaptops", {
            data: {
              product_ids: arrayId,
            },
          })
          .then((response) => {
            notify(response.data.message);
            setSelectAllCheckbox(false);
            setActiveInactive(false);
            setPageSearch(true);
            fetchProducts();
          })
          .catch((error) => {
            console.log("error", error);
          });
      }

      if (imagesArr.length > 0) {
        axios
          .delete("http://localhost:5000/uploads/multipleDelete", {
            data: {
              imagesArr: imagesArr,
            },
          })
          .then((response) => {
            // console.log("response delete images", response);
            setSelectAllCheckbox(false);
            setActiveInactive(false);
            setPageSearch(true);
            fetchProducts();
          })
          .catch((error) => {
            console.log("response error", error);
          });
      }
    }
  };
  const handleActive = () => {
    let arrayId = [];
    products.forEach((d) => {
      if (d.select) {
        arrayId.push(d.id);
      }
    });
    const data = {
      status: 1,
      product_ids: arrayId,
    };
    if (category === "clothes") {
      axios
        .put("http://localhost:5000/products/activeInactiveProducts", data)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchProducts();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (category === "electronics" && electronicsDevice === "mobiles") {
      axios
        .put("http://localhost:5000/products/activeInactiveMobiles", data)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchProducts();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (category === "electronics" && electronicsDevice === "laptop") {
      axios
        .put("http://localhost:5000/products/activeInactiveLaptops", data)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchProducts();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const handleInActive = () => {
    let arrayId = [];
    products.forEach((d) => {
      if (d.select) {
        arrayId.push(d.id);
      }
    });
    const data = {
      status: 0,
      product_ids: arrayId,
    };
    if (category === "clothes") {
      axios
        .put("http://localhost:5000/products/activeInactiveProducts", data)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchProducts();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (category === "electronics" && electronicsDevice === "mobiles") {
      axios
        .put("http://localhost:5000/products/activeInactiveMobiles", data)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchProducts();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (category === "electronics" && electronicsDevice === "laptop") {
      axios
        .put("http://localhost:5000/products/activeInactiveLaptops", data)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchProducts();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const handleSearch = () => {
    let searchData = products.filter((d) => {
      if (
        d.title.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ) {
        // console.log(d);
        return d;
      }
      return null;
    });
    // console.log(searchData);
    if (searchData.length === 0) {
      setProducts(products);
    } else {
      setProducts(searchData);
    }
  };
  const handleReset = () => {
    setSearch("");
    fetchProducts();
  };
  const handleClose = () => {
    setSearch("");
    fetchProducts();
  };
  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setProducts(
      products.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };
  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setProducts(
      products.map((d) => {
        if (id === d.id) {
          d.select = checked;
        }

        return d;
      })
    );

    const result = products.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${props.Sidebar
          ? ""
          : ""
        }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Products"}
          searchLabel={"Title"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/products/addProducts"
          addBtn={true}
        />
        <div className="w-full  dark:bg-gray-700 py-2 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10">
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack dark:text-white">
                Category
              </label>
              <select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                className="border h-10 rounded px-2 text-sm font-medium"
              >
                <option value="clothes">Clothes</option>
                <option value="electronics">Electronics</option>
              </select>
            </div>
            {category === "electronics" && (
              <div className="flex flex-col ">
                <label className="font-medium mb-1 dark:text-white flex items-center">
                  Electronics Devices
                </label>
                <select
                  value={electronicsDevice}
                  onChange={(e) => {
                    setElectronicsDevice(e.target.value);
                  }}
                  className="border h-10 rounded px-2 text-sm font-medium"
                >
                  <option value="mobiles">Mobiles</option>
                  <option value="laptop">Laptop</option>
                </select>
              </div>
            )}
          </div>
        </div>
        <ProductCard
          products={products}
          selectAllCheckbox={selectAllCheckbox}
          setSelectAllCheckbox={setSelectAllCheckbox}
          selectSingle={selectSingle}
          selectAll={selectAll}
          category={category}
          electronicsDevice={electronicsDevice}
        />
      </div>
    </div>
  );
};

export default ProductsDashboard;
