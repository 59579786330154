import React, { useState, useRef } from "react";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";

const ContactTable = ({
  contacts,
  contactPerPage,
  totalContacts,
  paginate,
  currentPage,
  setNumberOfContact,
  sortNames,
  sortByName,
  setSortByName,
  sortEmails,
  sortByEmail,
  setSortByEmail,
  sortPhones,
  sortByPhone,
  setSortByPhone,
  sortSubmitted,
  sortBySubmitted,
  setSortBySubmitted,
}) => {
  const tableRef = useRef(null);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalContacts / contactPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortNameArrows, setSortNameArrows] = useState(false);
  let [sortEmailArrow, setSortEmailArrow] = useState(true);
  let [sortPhoneArrow, setSortPhoneArrow] = useState(true);
  let [sortSubmittedArrow, setSortSubmittedArrow] = useState(true);

  return (
    <div>
      {contacts.length !== 0 ? (
        <div className="py-4 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5">
          {/* <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn bg-blue-500 rounded download-table-xls-button btn btn-success mb-3"
            table="table-to-xls"
            filename="Contact inquiries"
            sheet="Contact inquiries"
            buttonText="Export to Excel"
          /> */}
          <DownloadTableExcel
            filename="Contact inquiries"
            sheet="Contact inquiries"            
            currentTableRef={tableRef.current}
          ><span className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-xs">
            Export to excel
            </span></DownloadTableExcel>
        </div>
      ) : null}
      <div className="overflow-x-auto scrollbar-hide py-2 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5">
        <table
          className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b"
          id="table-to-xls"
          ref={tableRef}
        >
          <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
            <tr>
              <th
                onClick={() => {
                  sortNames(sortByName);
                  setSortByName(!sortByName);
                  setSortNameArrows(false);
                  setSortEmailArrow(true);
                  setSortPhoneArrow(true);
                  setSortSubmittedArrow(true);
                }}
                scope="col"
                className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
              >
                <span className="inline-block mr-2 ">Name</span>
                {sortNameArrows && (
                  <>
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortNameArrows && (
                  <>
                    {sortByName === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                onClick={() => {
                  sortEmails(sortByEmail);
                  setSortByEmail(!sortByEmail);
                  setSortNameArrows(true);
                  setSortEmailArrow(false);
                  setSortPhoneArrow(true);
                  setSortSubmittedArrow(true);
                }}
                scope="col"
                className="cursor-pointer  px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
              >
                <span className="inline-block mr-2 ">Email</span>
                {sortEmailArrow && (
                  <>
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortEmailArrow && (
                  <>
                    {sortByEmail === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                scope="col"
                className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
              >
                <span className="inline-block mr-2 ">Message</span>
              </th>
              <th
                onClick={() => {
                  sortPhones(sortByPhone);
                  setSortByPhone(!sortByPhone);
                  setSortNameArrows(true);
                  setSortEmailArrow(true);
                  setSortPhoneArrow(false);
                  setSortSubmittedArrow(true);
                }}
                scope="col"
                className="cursor-pointer  px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
              >
                <span className="inline-block mr-2 ">Phone</span>
                {sortPhoneArrow && (
                  <>
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortPhoneArrow && (
                  <>
                    {sortByPhone === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                onClick={() => {
                  sortSubmitted(sortBySubmitted);
                  setSortBySubmitted(!sortBySubmitted);
                  setSortNameArrows(true);
                  setSortEmailArrow(true);
                  setSortPhoneArrow(true);
                  setSortSubmittedArrow(false);
                }}
                scope="col"
                className="cursor-pointer  px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
              >
                <span className="inline-block mr-2 ">Submitted On</span>
                {sortSubmittedArrow && (
                  <>
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortSubmittedArrow && (
                  <>
                    {sortBySubmitted === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-darkblack">
            {contacts &&
              contacts.map((contact) => (
                <tr
                  key={contact.id}
                  className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
                >
                  <td className="px-6 py-4  text-sm capitalize text-gray-600 dark:text-textinblack">
                    <span className=" pb-2 inline-block whitespace-nowrap">
                      {contact.firstName} {contact.lastName}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600 dark:text-textinblack">
                    <span className=" pb-2 inline-block">{contact.email}</span>
                  </td>
                  <td className="px-6 py-4 text-sm capitalize text-gray-600 dark:text-textinblack">
                    <span className=" pb-2 inline-block">
                      {contact.message}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm capitalize text-gray-600 dark:text-textinblack ">
                    <span className="pb-2 inline-block">{contact.phone}</span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600 dark:text-textinblack">
                    <span className=" pb-2 inline-block whitespace-nowrap">
                      {/* {console.log(contact.createdDate)} */}
                      {moment(contact.createdDate).format("MM/DD/YYYY hh:mm a")}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
          <div className="bg-gray-200 px-5 py-3 w-full border-t">
            <label
              onClick={() => {
                sortNames(sortByName);
                setSortByName(!sortByName);
                setSortNameArrows(false);
                setSortEmailArrow(true);
                setSortPhoneArrow(true);
                setSortSubmittedArrow(true);
              }}
              className="px-5 py-3 text-sm font-medium leading-normal"
            >
              Names
              {!sortNameArrows && (
                <>
                  {sortByName === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </label>
          </div>
          {contacts &&
            contacts.map((contact) => (
              <div className="tab w-full border" key={contact.id}>
                <input
                  className="absolute opacity-0"
                  id={contact.id}
                  type="checkbox"
                  name="tabs"
                />
                <label
                  className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                  for={contact.id}
                >
                  <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">
                    {contact.firstName}
                  </span>
                </label>
                <div className="tab-content border-t overflow-hidden w-full">
                  <div className="p-4">
                    <div className="flex py-1">
                      <h1 className="dark:text-white font-semibold">Name:</h1>
                      <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                        {contact.fName}
                      </h1>
                    </div>
                    <div className="flex py-1">
                      <h1 className="dark:text-white font-semibold">Email:</h1>
                      <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                        {contact.email}
                      </h1>
                    </div>
                    <div className="flex py-1">
                      <h1 className="dark:text-white font-semibold">
                        Message:
                      </h1>
                      <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                        {contact.message}
                      </h1>
                    </div>
                    <div className="flex py-1">
                      <h1 className="dark:text-white font-semibold">
                        Submitted on:
                      </h1>
                      <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                        {moment(contact.submittedOn).format(
                          "MM/DD/YYYY hh:mm a"
                        )}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* pagination */}
        <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
        <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                  currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
                } liTags`}
                onClick={() => paginate(number)}
              >
                {number}
              </li>
            ))}
          </ul>
          <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
            <span className="pr-2 dark:text-textinblack">Show</span>
            <input
              type="number"
              min={1}
              value={contactPerPage}
              className="px-1 py-1 w-24 border border-black"
              onChange={(e) => setNumberOfContact(e.target.value)}
            />
            <span className="pl-2 dark:text-textinblack">Entries</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactTable;
