import axios from "axios";
import { addOrUpdateAccessTokenToIDBStore } from "indexeddb-util";
import React, { useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import {
  RemoveLoginData,
  StoreLoginData,
} from "./../../Redux/actions/LoginActions";

const Login = ({ setLogin }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const userInfo = useSelector((state) => state.LoginReducer.userInfo);
  // const userIsLoggedIn = useSelector((LoginReducer) => LoginReducer.isLoading);

  const [email, setEmail] = useState(
    localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : ""
  );
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [Loading, setLoading] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [APIError, setAPIError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    let emailError = "";
    let passwordError = "";

    if (email.trim() === "") {
      emailError = "The email field is required.";
    }
    if (password.trim() === "") {
      passwordError = "The password field is required.";
    }
    if (emailError !== "" || passwordError !== "") {
      setEmailError(emailError);
      setPasswordError(passwordError);
      setLoading(false);
      return;
    }
    if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i.test(email)) {
      setEmailError("Invalid email address");
      setLoading(false);
      return;
    }

    const path = API + "/users/auth/login";

    let data = {
      username: email,
      password: password,
    };
    let config = {
      credentials: "include",
      method: "post",
      url: path,
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Methods": "*",
      },
      data: data,
      crossDomain: true,
    };
    await axios(config)
      .then(async (response) => {
        let userInfo = await JSON.stringify(response.data);
        localStorage.setItem("user", userInfo);
        localStorage.setItem("isAdmin", "true");
        let userData = response.data;
        if (userData && userData.userType.toLowerCase() === "admin") {
          // Auth.authenticate();

          // console.log("in login");
          await dispatch(StoreLoginData(userData));
          const newItem = {
            accessToken: userData.accessToken,
            user: { ...userData },
          };
          addOrUpdateAccessTokenToIDBStore({
          dbName: "loginStore",
          storeName: "loginStore",
          keyPath: "accessToken",
          version: 2,
          newItem,
          });
          setLogin(true);
          setLoading(false);
          navigate("/admin/dashboard");
        } else {
          let access_token = userData.accessToken;
          const config = {
            method: "put",
            url: API + "/users/auth/logout",
            headers: {
              Authorization: `${access_token}`,
            },
          };
          axios(config)
            .then(function (response) {
              dispatch(RemoveLoginData());
              localStorage.removeItem("user");
              setEmailError("You are not admin user");
              setLoading(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("error", error);
        if (error) {
          let respo_error = error?.response?.data;
          let error_messages = Object.values(respo_error?.messagesMap);
          setAPIError(
            error_messages[0] || "Something went wrong! Contact Super Admin"
          );
          // Object.entries(eorror_data.messagesMap).forEach(([key, value]) => {
          //   setAPIError(
          //     value || "Set your password form the link sent to your email"
          //   );
          // });
        }
      });

    if (rememberMe) {
      localStorage.setItem("userEmail", email);
    } else {
      localStorage.removeItem("userEmail");
    }
  };

  const handleKeyDown = (event) => {
    // console.log("I am here")
    if (event.key === "Enter") {
      // console.log("Enter pressed here");
      handleLogin(event);
    }
  };

  return (
    <div className="w-full flex justify-center my-5" onKeyDown={(e) => handleKeyDown(e)}>
      {Loading === true ? (
        <Loader
          type="spinner-circle"
          bgColor={"black"}
          // title={"spinner-circle"}
          // color="black"
          size={100}
        />
      ) : (
        <div className="max-w-[452px] 2xl:w-4/12 xl:w-4/12 lg:w-6/12 md:w-2/4 sm:w-4/5 w-11/12 shadow-2xl xxl:p-[70px] xl:p-[60px] lg:p-[50px] md:p-[40px] p-[30px] rounded-lg border border-[#D4DAEC] bg-white">
          <div className="w-full">
            <img className="object-contain mx-auto w-10/12" alt="" src={process.env.PUBLIC_URL + "/images/header-logo.png"} />
          </div>
          <div className="my-5 text-center">
            <h1 className="2xl:text-3xl xl:text-2xl text-xl font-normal">Welcome To Blattnertech</h1>
            <p className="mb-0">Sign in by entering the information below</p>
          </div>
          <div className="w-full flex flex-col">
            <label className="font-normal text-sm flex item-center tracking-wider">
              Email <CgAsterisk className="inline-block text-red-500" />
            </label>
            <input
              type="email"
              value={email}
              placeholder="Enter your email address"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
                setAPIError("");
              }}
              onBlur={() => {
                if (email.trim() === "") {
                  setEmailError("The email field is required.");
                }
              }}
              className={`${
                emailError && "border-red-500"
              } py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500 px-2`}
            />
            {emailError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {emailError}
              </span>
            )}
          </div>
          <div className="w-full flex flex-col my-4">
            <label className="font-normal text-sm flex item-center tracking-wider">
              Password <CgAsterisk className="inline-block text-red-500" />
            </label>
            <input
              type="password"
              placeholder="Enter your password"
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError("");
                setAPIError("");
              }}
              onBlur={() => {
                if (password.trim() === "") {
                  setPasswordError("The password field is required.");
                }
              }}
              className={`${
                passwordError && "border-red-500"
              } py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500 px-2`}
            />
            {passwordError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {passwordError}
              </span>
            )}
          </div>
          <div className="w-full flex items-center">
            <input
              id="rememberMe"
              type="checkbox"
              checked={rememberMe}
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
              className="mr-2"
            />
            <label
              htmlFor="rememberMe"
              className="text-sm tracking-wider cursor-pointer"
            >
              Remember Me
            </label>
          </div>
          {APIError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {APIError}
            </span>
          )}

          <div className="w-full flex items-center justify-center my-4">
            <button
              onClick={(e) => {
                setLoading(true);
                handleLogin(e);
              }}
              
              type="submit"
              className="w-full h-10 bg-blue-500 hover:bg-blue-600 text-sm text-white rounded shadow-lg tracking-wider"
            >
              Login
            </button>
          </div>
          <div className="w-full flex items-center justify-center">
            <button
              onClick={() => {
                localStorage.setItem("forgotPasswordAdmin", true);
                navigate("/bakend/forgetPassword", {
                  state: {
                    origin: "admin",
                  },
                });
                // navigate("/bakend/forgetPassword");
              }}
              className=" text-xs hover:underline tracking-wider outline-none"
            >
              Forgot Your Password?
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
