import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDropzone } from "react-dropzone";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import PageHeading from "../pages/PageHeading";

const AddTestimonials = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [ID, setID] = useState("");
  const [AuthorName, setAuthorName] = useState("");
  const [AuthorNameError, setAuthorNameError] = useState("");
  const [URL, setURL] = useState("");
  const [URLError, setURLError] = useState("");
  const [DisplayOrder, setDisplayOrder] = useState(0);
  const [Status, setStatus] = useState(true);
  const [pageContent, setPageContent] = useState("");
  const [pageContentError, setPageContentError] = useState("");
  const [ImageAltText, setImageAltText] = useState("");
  const [AllowImage, setAllowImage] = useState("Yes");
  const [imageUrl, setImageUrl] = useState("");
  const [allDisplayOrder, setAllDisplayOrder] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;
  const [imageErrors, setImageErrors] = useState("");

  const getSiteConfigData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.siteConfig;
        let filteredData = data.filter(
          (option) => option.name === "field_testimonial_title_display"
        );
        if (filteredData.length !== 0) {
          setAllowImage(filteredData[0].val);
        } else setAllowImage("Yes");
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/testimonials/view");
        }
        console.log(error);
      });
  };

  const nextDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/testimonials/nextdisplayorder",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const order = response.data;
        setDisplayOrder(order);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin");
        }
        console.log(error);
      });
  }, [access_token]);

  const fetchAllDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/testimonials/alldisplayorders",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const orders = response.data;
        setAllDisplayOrder(orders);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin");
        }
        console.log(error);
      });
  }, [access_token]);

  const getTestimonialsData = (id) => {
    let config = {
      method: "get",
      url: API + "/testimonials/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data;
        setID(data.id);
        setAuthorName(data.clientName);
        setURL(data.url);
        setImageUrl(data.image);
        setDisplayOrder(data.displayOrder);
        setStatus(data.status);
        setPageContent(data.description);
        setImageAltText(data.imageAlt);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/testimonials/view");
        }
        console.log(error);
      });
  };

  const handleCancelBtn = () => {
    setAuthorName("");
    setAuthorNameError("");
    setURL("");
    setURLError("");
    setDisplayOrder(0);
    setStatus("Active");
    setPageContent("");
    setPageContentError("");
    navigate("/admin/testimonials/view");
  };

  const isValidForm = () => {
    let isValid = true;
    let authorError = "";
    let urlError = "";
    let pageContentError = "";
    if (AuthorName.trim() === "") {
      isValid = false;
      authorError = "Author/Client name required";
      setAuthorNameError(authorError);
    }
    if (
      URL &&
      !(
        URL.toLowerCase().includes("http://") ||
        URL.toLowerCase().includes("https://")
      )
    ) {
      urlError = "The url format is invalid";
      isValid = false;
      setURLError(urlError);
    }

    if (
      URL &&
      !(
        URL.toLocaleLowerCase().startsWith("http://") ||
        URL.toLocaleLowerCase().startsWith("https://")
      )
    ) {
      urlError = "The url format is invalid";
      isValid = false;
      setURLError(urlError);
    }

    if (pageContent.trim() === "") {
      isValid = false;
      pageContentError = "Description required";
      setPageContentError(pageContentError);
    }
    if (authorError !== "" || urlError !== "" || pageContentError !== "")
      isValid = false;

    if (isValid) {
      setPageContentError("");
      setURLError("");
      setAuthorNameError("");
    }

    return isValid;
  };

  const SaveEdit = (saveclick) => {
    if (isValidForm()) {
      let id = ID;
      if (id) {
        let dataBody = {
          id: ID,
          clientName: AuthorName,
          url: URL,
          image: imageUrl,
          imageAlt: ImageAltText,
          description: pageContent,
          displayOrder: parseInt(DisplayOrder),
          status: Status,
        };

        let config = {
          method: "put",
          url: API + "/testimonials/",
          data: dataBody,
          headers: {
            "Content-type": "application/json",
            Authorization: `${access_token}`,
          },
        };

        axios(config)
          .then((response) => {
            if (saveclick) {
              notify("Testimonial updated successfully");
              navigate("/admin/testimonials/view");
            } else {
              notify("Testimonial updated successfully");
              let data = response.data;
              setID(data.id);
              setAuthorName(data.clientName);
              setURL(data.url);
              setDisplayOrder(data.displayOrder);
              setStatus(data.status);
              setPageContent(data.description);
              setImageAltText(data.imageAlt);
              setAuthorNameError("");
              setURLError("");
              setPageContentError("");
            }
          })
          .catch((error) => {
            console.log(error);
            if (
              error.status === "401" &&
              error.message === "JWT token is expired"
            ) {
              navigate("/admin/testimonials/view");
            }
          });
      } else {
        let dataBody = {
          clientName: AuthorName,
          url: URL,
          image: imageUrl,
          imageAlt: ImageAltText,
          description: pageContent,
          displayOrder: parseInt(DisplayOrder),
          status: Status,
        };
        let config = {
          method: "post",
          url: API + "/testimonials/",
          headers: {
            Authorization: `${access_token}`,
          },
          data: dataBody,
        };

        axios(config)
          .then((response) => {
            if (saveclick) {
              notify("Testimonials created successfully");
              navigate("/admin/testimonials/view");
            } else {
              notify("Testimonials created successfully");
              let data = response.data;
              setID(data.id);
              setAuthorName(data.clientName);
              setURL(data.url);
              setDisplayOrder(data.displayOrder);
              setStatus(data.status);
              setPageContent(data.description);
              setImageAltText(data.imageAlt);
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };

  const handleSave = () => {
    SaveEdit(true);
  };

  const handleSaveAndContinue = () => {
    SaveEdit(false);
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setImageErrors(fileRejections[0].errors[0].message);
      }

      if (acceptedFiles.length > 0) {
        setImageErrors("");
        acceptedFiles.map((image) => {
          let data = new FormData();
          data.append("file", image);
          const config = {
            method: "post",
            url: API + "/testimonials/image/upload/",
            headers: {
              Authorization: `${access_token}`,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setImageErrors("");
              setImageUrl(response.data);
            })
            .catch((error) => {
              console.log(error);
              if (
                error.status === "401" &&
                error.message === "JWT token is expired"
              ) {
                navigate("/admin/testimonials/view");
              }
            });
          return null;
        });
      }
    },
    [access_token]
  );

  const fetchData = async () => {
    await getSiteConfigData();
    if (params.id) {
      await getTestimonialsData(params.id);
      await fetchAllDisplayOrder();
    } else {
      await nextDisplayOrder();
    }
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 10000000,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  return (
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading={params.id ? "Edit Testimonials" : "Add Testimonials"}
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        handleSave={handleSave}
        saveAndContinue={true}
        handleSaveAndContinue={handleSaveAndContinue}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">
              Author/Client Name
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={AuthorName}
              onChange={(e) => {
                setAuthorNameError("");
                setAuthorName(e.target.value);
              }}
              type="text"
              className={`${
                AuthorNameError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter Author/Client Name"
            />
            {AuthorNameError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {AuthorNameError}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">
              URL
            </label>
            <input
              value={URL}
              onChange={(e) => {
                setURLError("");
                setURL(e.target.value);
              }}
              type="text"
              className={`${
                URLError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter URL"
            />
            <span className="text-sm my-1 tracking-wider text-black dark:text-textinblack">
              Url must contain <b>http://</b> or <b>https://</b>
            </span>
            {URLError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {URLError}
              </span>
            )}
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Status</label>
            <select
              value={Status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Display Order</label>
            <select
              value={DisplayOrder}
              onChange={(e) => {
                setDisplayOrder(e.target.value);
              }}
              disabled={ID ? false : true}
              name="displayOrder"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              {!ID && (
                <option key={DisplayOrder} value={DisplayOrder}>
                  {DisplayOrder}
                </option>
              )}
              {ID &&
                allDisplayOrder.map((order) => (
                  <option key={order} value={order}>
                    {order}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {AllowImage === "Yes" ? (
          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="1" className="font-medium mb-1">Image</label>
              <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
                {imageUrl && (
                  <img
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(imageUrl)}`
                    }
                    alt="img"
                    className="w-full h-4/5  object-contain"
                  />
                )}
                {imageUrl && (
                  <button
                    onClick={() => {
                      setImageUrl("");
                      setImageErrors("");
                    }}
                    className="absolute z-20 top-1 right-1 btn bg-gray-900 text-white"
                  >
                    Remove
                  </button>
                )}
                {!imageUrl && (
                  <div>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                      <em>(Only *.jpeg and *.png images will be accepted)</em>
                    </div>
                  </div>
                )}
              </div>
              <div className="text-xs mt-1">
                Max size 5(MB) and Recommended Size: 1900PX x 1080PX (Allowed
                only jpg, jpeg, png and gif images)
              </div>
              {imageErrors && (
                <span className="text-xs text-red-500 my-1 tracking-wider">
                  {imageErrors}
                </span>
              )}
            </div>
            <div className="flex flex-col">
              <label htmlFor="1" className="font-medium mb-1">Alternate Text</label>
              <input
                value={ImageAltText}
                onChange={(e) => {
                  setImageAltText(e.target.value);
                }}
                type="text"
                placeholder="Enter Alternate Text"
                className={`border h-10 rounded-lg px-2 text-sm font-medium `}
              />
            </div>
          </div>
        ) : null}
        <div className="w-full mt-5  grid lg:grid-cols-1 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">
              Description
              <CgAsterisk className="inline text-red-500" />
            </label>

            {ID ? (
              <>
                {pageContent ? (
                  <Editor
                    value={pageContent}
                    init={{
                      height: 300,
                      forced_root_block: "",
                      statusbar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newValue, editor) => {
                      setPageContentError("");
                      if (editor.getContent() === "") {
                        setPageContent(" ");
                      } else {
                        setPageContent(editor.getContent());
                      }
                    }}
                  />
                ) : null}
              </>
            ) : (
              <Editor
                value={pageContent}
                init={{
                  height: 300,
                  forced_root_block: "",
                  statusbar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newValue, editor) => {
                  setPageContentError("");
                  setPageContent(editor.getContent());
                }}
              />
            )}
            {pageContentError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {pageContentError}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTestimonials;
