import React, { useEffect, useState } from "react";
import PageHeading from "../pages/PageHeading";
import axios from "axios";
import { CgAsterisk } from "react-icons/cg";
import ReactPhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreFooterData } from "../../Redux/actions/CompanyAction";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { RefreshToken } from "./../../services/RefreshToken";

const Setting = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");
  const titleNamesData = [
    "app_name",
    "records_per_page",
    "footer_title_for_admin",
    "site_title",
    "tagline",
    "copyright_text",
    "meta_description_for_front",
    "ios_app_download_link",
    "android_app_download_link",
    "company_name",
    "company_address_line1",
    "company_address_line2",
    "company_city",
    "company_state",
    "company_country",
    "company_zipcode",
    "phone",
    "company_email",
    "googlemap_embedded",
    "from_name",
    "from_email",
    "admin_email",
    "password_strength",
    "login_attempt",
    "minimum_password_length",
    "user_block_time",
    "facebook_link",
    "twitter_link",
    "linkedin_link",
    "GOOGLE_RECAPTCHA_KEY",
    "GOOGLE_RECAPTCHA_SECRET",
    "robots_meta_tag",
    "google_analytics_code",
    "api_access_token_lifetime",
    "api_refresh_token_lifetime",
    "signup_charges",
  ];

  const getData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const siteConfigData = response.data.siteConfig;
        let formData = UserFormData;
        for (let index = 0; index < titleNamesData.length; index++) {
          const element = titleNamesData[index];
          let filteredData = siteConfigData.filter(
            (option) => option.name === element
          );
          formData[element] = filteredData[0].val;
        }
        setUserFormData({ ...UserFormData, formData });
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
        }
        console.log(error);
      });
  };

  useEffect(() => {
    if (access_token) {
      getData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, [navigate]);

  const [UserFormData, setUserFormData] = useState({
    app_name: "",
    records_per_page: "",
    footer_title_for_admin: "",

    adminError: false,
    appNameError: "",
    noOfRecordsError: "",
    footerTitleError: "",

    site_title: "",
    tagline: "",
    copyright_text: "",
    meta_description_for_front: "",

    FrontendError: false,
    siteTitleError: "",
    siteTagLineError: "",
    siteCopyrightTextError: "",

    ios_app_download_link: "",
    android_app_download_link: "",

    company_name: "",
    company_address_line1: "",
    company_address_line2: "",
    company_city: "",
    company_state: "",
    company_country: "",
    company_zipcode: null,
    phone: "",
    company_email: "",
    googlemap_embedded: (
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3220.8028446848048!2d-86.78386438477024!3d36.171351980082946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886466f60caf6ae7%3A0xd4db82ec4192b543!2sDevDigital!5e0!3m2!1sen!2sin!4v1574155490019!5m2!1sen!2sin"
        width="600"
        height="310"
        allowFullScreen=""
      ></iframe>
    ),

    companyDetailsError: false,
    companyNameError: "",
    addressLine1Error: "",
    addressLine2Error: "",
    cityError: "",
    stateError: "",
    countryError: "",
    ZipCodeError: "",

    from_name: "",
    from_email: "",
    admin_email: "",

    emailSettingError: false,
    fromNameError: "",
    fromEmailError: "",
    adminEmailError: "",

    password_strength: "",
    login_attempt: null,
    minimum_password_length: null,
    user_block_time: null,

    passwordSettingError: false,
    passwordStrengthError: "",
    noOfAttemptError: null,
    minPasswordLengthError: null,
    userBlockTimeError: null,

    facebook_link: "",
    twitter_link: "",
    linkedin_link: "",

    GOOGLE_RECAPTCHA_KEY: "",
    GOOGLE_RECAPTCHA_SECRET: "",

    seoSettingError: false,
    robots_meta_tag: "",
    google_analytics_code: "",
    googleAnalyticsCodeError: null,

    api_access_token_lifetime: null,
    api_refresh_token_lifetime: null,

    apiSettingError: false,
    apiAccessTokenError: null,
    apiRefreshTokenError: null,

    signupchargesError: false,
    signUpError: "",
    signup_charges: "",
  });

 

  const createSetting = () => {
    let adminError = false;
    let appNameError = "";
    let noOfRecordsError = "";
    let footerTitleError = "";

    let FrontendError = false;
    let siteTitleError = "";
    let siteTagLineError = "";
    let siteCopyrightTextError = "";

    let companyDetailsError = false;
    let companyNameError = "";
    let addressLine1Error = "";
    let cityError = "";
    let stateError = "";
    let countryError = "";
    let ZipCodeError = "";

    let emailSettingError = false;
    let fromNameError = "";
    let fromEmailError = "";
    let adminEmailError = "";

    let passwordSettingError = false;
    let noOfAttemptError = "";
    let minPasswordLengthError = "";
    let userBlockTimeError = "";

    let seoSettingError = false;
    let googleAnalyticsCodeError = "";

    let apiSettingError = false;
    let apiAccessTokenError = "";
    let apiRefreshTokenError = "";

    if (UserFormData.app_name.trim() === "") {
      appNameError = "Enter app name";
      adminError = true;
    }
    if (UserFormData.records_per_page === "") {
      adminError = true;
      noOfRecordsError = "Select No Record";
    }
    if (UserFormData.footer_title_for_admin.trim() === "") {
      adminError = true;
      footerTitleError = "Enter Footer Title";
    }
    if (UserFormData.site_title.trim() === "") {
      FrontendError = true;
      siteTitleError = "Enter Site Title";
    }
    if (UserFormData.tagline.trim() === "") {
      FrontendError = true;
      siteTagLineError = "Enter Site Tag line";
    }
    if (UserFormData.copyright_text.trim() === "") {
      FrontendError = true;
      siteCopyrightTextError = "Enter Site copyright text";
    }
    if (UserFormData.company_name.trim() === "") {
      companyDetailsError = true;
      companyNameError = "Enter company copyright name";
    }
    if (UserFormData.company_address_line1.trim() === "") {
      companyDetailsError = true;
      addressLine1Error = "Enter address line 1";
    }
    
    if (UserFormData.company_city.trim() === "") {
      companyDetailsError = true;
      cityError = "Enter city name";
    }
    if (UserFormData.company_state.trim() === "") {
      companyDetailsError = true;
      stateError = "Enter state name";
    }
    if (UserFormData.company_country.trim() === "") {
      companyDetailsError = true;
      countryError = "Enter country name";
    }
    if (UserFormData.company_zipcode === null) {
      companyDetailsError = true;
      ZipCodeError = "Enter ZipCode";
    }
    if (UserFormData.from_name.trim() === "") {
      emailSettingError = true;
      fromNameError = "Enter name";
    }
    if (UserFormData.from_email.trim() === "") {
      emailSettingError = true;
      fromEmailError = "Enter email";
    }
    if (UserFormData.admin_email.trim() === "") {
      emailSettingError = true;
      adminEmailError = "Enter admin email";
    }
    
    if (UserFormData.login_attempt === null) {
      passwordSettingError = true;
      noOfAttemptError = "Enter no of attempt";
    }
    if (UserFormData.minimum_password_length === null) {
      passwordSettingError = true;
      minPasswordLengthError = "Enter password length";
    }
    if (UserFormData.user_block_time === null) {
      passwordSettingError = true;
      userBlockTimeError = "Enter user block time";
    }
    if (UserFormData.google_analytics_code === "") {
      seoSettingError = true;
      googleAnalyticsCodeError = "Enter google analytics code";
    }
    if (UserFormData.api_access_token_lifetime === null) {
      apiSettingError = true;
      apiAccessTokenError = "Enter access token lifetime time";
    }
    if (UserFormData.api_refresh_token_lifetime === null) {
      apiSettingError = true;
      apiRefreshTokenError = "Enter refresh token lifetime time";
    }

    if (
      adminError === true ||
      FrontendError === true ||
      companyDetailsError === true ||
      emailSettingError === true ||
      passwordSettingError === true ||
      seoSettingError === true ||
      apiSettingError === true
    ) {
      setUserFormData({
        adminError: adminError,
        appNameError: appNameError,
        noOfRecordsError: noOfRecordsError,
        footerTitleError: footerTitleError,

        FrontendError: FrontendError,
        siteTitleError: siteTitleError,
        siteTagLineError: siteTagLineError,
        siteCopyrightTextError: siteCopyrightTextError,

        companyDetailsError: companyDetailsError,
        companyNameError: companyNameError,
        addressLine1Error: addressLine1Error,
        cityError: cityError,
        stateError: stateError,
        countryError: countryError,
        ZipCodeError: ZipCodeError,

        emailSettingError: emailSettingError,
        fromNameError: fromNameError,
        fromEmailError: fromEmailError,
        adminEmailError: adminEmailError,

        passwordSettingError: passwordSettingError,
        noOfAttemptError: noOfAttemptError,
        minPasswordLengthError: minPasswordLengthError,
        userBlockTimeError: userBlockTimeError,

        seoSettingError: seoSettingError,
        googleAnalyticsCodeError: googleAnalyticsCodeError,

        apiSettingError: apiSettingError,
        apiAccessTokenError: apiAccessTokenError,
        apiRefreshTokenError: apiRefreshTokenError,
      });
      return false;
    } else {
      submitFormData();
    }
  };

  const submitFormData = () => {
    var data = [
      {
        id: "04d015d0-a6a0-4cb0-92e9-355a02225c21",
        name: "app_name",
        val: UserFormData.app_name,
        status: true,
        type: "string",
      },
      {
        id: "d217bf6f-16fc-4773-8f38-a1b70dcd6c3c",
        name: "records_per_page",
        val: UserFormData.records_per_page,
        status: true,
        type: "int",
      },
      {
        id: "44c4a4b7-9ba7-4b34-abd2-421b564f0884",
        name: "footer_title_for_admin",
        val: UserFormData.footer_title_for_admin,
        status: true,
        type: "string",
      },
      {
        id: "dde2f637-feda-4fd0-a532-47ae9c17bdd4",
        name: "site_title",
        val: UserFormData.site_title,
        status: true,
        type: "string",
      },
      {
        id: "4c3b41a9-6937-452c-af29-d50b5affc01d",
        name: "tagline",
        val: UserFormData.tagline,
        status: true,
        type: "string",
      },
      {
        id: "7e74a8a8-4a76-4416-8f97-1ccc9dc758f0",
        name: "copyright_text",
        val: UserFormData.copyright_text,
        status: true,
        type: "string",
      },
      {
        id: "478d90fc-1649-47a7-b3d3-0684083bdf89",
        name: "meta_description_for_front",
        val: UserFormData.meta_description_for_front,
        status: true,
        type: "string",
      },
      {
        id: "bc70eb3b-c141-46c6-af78-f9cf5b3b2c47",
        name: "ios_app_download_link",
        val: UserFormData.ios_app_download_link,
        status: true,
        type: "string",
      },
      {
        id: "a06f5ccb-6eab-4c1f-a7ec-f64fa6726fd0",
        name: "android_app_download_link",
        val: UserFormData.android_app_download_link,
        status: true,
        type: "string",
      },
      {
        id: "2eeb02c7-40cc-4784-986e-2214cd2cd41a",
        name: "company_name",
        val: UserFormData.company_name,
        status: true,
        type: "string",
      },
      {
        id: "7857943d-d872-476a-a1e3-3f95b3e82f1b",
        name: "company_address_line1",
        val: UserFormData.company_address_line1,
        status: true,
        type: "string",
      },
      {
        id: "4b4b68b2-0e43-47a2-abd3-4e4b6d608f02",
        name: "company_address_line2",
        val: UserFormData.company_address_line2,
        status: true,
        type: "string",
      },
      {
        id: "f3fe43ea-4b7f-4ea6-8706-026b6c2313d5",
        name: "company_city",
        val: UserFormData.company_city,
        status: true,
        type: "string",
      },
      {
        id: "9fe5299a-9a1b-4fb4-815c-55ee4daa0cd3",
        name: "company_state",
        val: UserFormData.company_state,
        status: true,
        type: "string",
      },
      {
        id: "e3df9d8f-01f3-4eb1-b614-83dfd843ef11",
        name: "company_country",
        val: UserFormData.company_country,
        status: true,
        type: "string",
      },
      {
        id: "8aa2303f-94c3-4b55-8dc9-6f707e90db0a",
        name: "company_zipcode",
        val: UserFormData.company_zipcode,
        status: true,
        type: "string",
      },
      {
        id: "bffbce1e-30bf-4cb2-8664-35f8f9bd8013",
        name: "phone",
        val: UserFormData.phone,
        status: true,
        type: "string",
      },
      {
        id: "495a22e7-f458-4951-8848-e79142686b5b",
        name: "company_email",
        val: UserFormData.company_email,
        status: true,
        type: "email",
      },
      {
        id: "778537c7-35dc-4536-b567-372fc0cceb7a",
        name: "googlemap_embedded",
        val: UserFormData.googlemap_embedded,        
        status: true,
        type: "string",
      },
      {
        id: "63e82627-3abc-434b-8ca1-a64d627d3368",
        name: "from_name",
        val: UserFormData.from_name,
        status: true,
        type: "string",
      },
      {
        id: "b9b305b8-be2c-4f7c-a185-4f157e432a01",
        name: "from_email",
        val: UserFormData.from_email,
        status: true,
        type: "email",
      },
      {
        id: "dd4bd536-8300-4fe8-bf03-f31f5b3d5090",
        name: "admin_email",
        val: UserFormData.admin_email,
        status: true,
        type: "email",
      },
      {
        id: "4b6d5c9c-110c-462f-86d5-c2a5db84a78d",
        name: "password_strength",
        val: UserFormData.password_strength,
        status: true,
        type: "string",
      },
      {
        id: "fa386199-7b03-4ee7-8459-c0e0ee236f97",
        name: "login_attempt",
        val: UserFormData.login_attempt,
        status: true,
        type: "int",
      },
      {
        id: "1b4f3367-9d4d-4163-b2cc-7a7ce33e4878",
        name: "minimum_password_length",
        val: UserFormData.minimum_password_length,
        status: true,
        type: "int",
      },
      {
        id: "b11d01ed-ec91-4f1d-8afa-23d99654ed1a",
        name: "user_block_time",
        val: UserFormData.user_block_time,
        status: true,
        type: "int",
      },
      {
        id: "3ffdcb78-225f-454b-9595-9d2204cec1e6",
        name: "facebook_link",
        val: UserFormData.facebook_link,
        status: true,
        type: "string",
      },
      {
        id: "90ba9c7c-da11-4959-a89a-ab9a7bde9e9e",
        name: "twitter_link",
        val: UserFormData.twitter_link,
        status: true,
        type: "string",
      },
      {
        id: "7bd75c9b-8973-4602-848c-3933323922cc",
        name: "linkedin_link",
        val: UserFormData.linkedin_link,
        status: true,
        type: "string",
      },
      {
        id: "7e27be75-e848-4fd8-b28c-684b4462bbc7",
        name: "GOOGLE_RECAPTCHA_KEY",
        val: UserFormData.GOOGLE_RECAPTCHA_KEY,
        status: true,
        type: "string",
      },
      {
        id: "886bb862-61f1-4926-ba2c-4829c685aa60",
        name: "GOOGLE_RECAPTCHA_SECRET",
        val: UserFormData.GOOGLE_RECAPTCHA_SECRET,
        status: true,
        type: "string",
      },
      {
        id: "60864387-e422-4161-9c2e-f53e145fe473",
        name: "robots_meta_tag",
        val: UserFormData.robots_meta_tag,
        status: true,
        type: "string",
      },
      {
        id: "8a0781ee-6051-41b0-bb73-30683fc71f1f",
        name: "google_analytics_code",
        val: UserFormData.google_analytics_code,
        status: true,
        type: "string",
      },
      {
        id: "29f29707-94fc-4942-abc9-7226b56bbeef",
        name: "api_access_token_lifetime",
        val: UserFormData.api_access_token_lifetime,
        status: true,
        type: "int",
      },
      {
        id: "433e97ea-5661-47a5-8391-94fdefab9aad",
        name: "api_refresh_token_lifetime",
        val: UserFormData.api_refresh_token_lifetime,
        status: true,
        type: "int",
      },
      {
        id: "15c3b11e-f182-4bdf-aa1b-a625597fdeed",
        name: "signup_charges",
        val: UserFormData.signup_charges,
        status: true,
        type: "String",
      },
    ];

    let configData = {
      method: "put",
      url: API + "/siteConfigs/all",
      data: data,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(configData)
      .then((response) => {
        notify("Settings updated successfully");
      })
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/blog/setting");
        }
      });

    dispatch(StoreFooterData(UserFormData.footer_title_for_admin));
  };

  return (
    
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading="Settings"
          showSaveOptionsBtn={true}
          save={true}
          createUser={true}
          handleSave={createSetting}
        />
        <div className="w-full lg:w-8/12 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 my-4 relative ">
          <div className="shadow-md w-full">
            <div className="tab w-full border-t">
            
              <input
                className="absolute opacity-0"
                id="tab-multi-one"
                type="checkbox"
                checked={UserFormData.adminError}
                onChange={() =>
                  setUserFormData({
                    ...UserFormData,
                    adminError: !UserFormData.adminError,
                  })
                }
                name="tabs"
              />

              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-one">
                Admin Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col" data-tip="Enter App Name">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    App Name <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    placeholder="CMS Admin"
                    value={UserFormData.app_name}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        appNameError: "",
                        app_name: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.appNameError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  />
                  {UserFormData.appNameError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.appNameError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col" data-tip="Select Number of Records per page">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    No of Records per page{" "}
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <select
                    value={UserFormData.records_per_page}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        noOfRecordsError: "",
                        records_per_page: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.noOfRecordsError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  >
                    <option>Select No Records</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                    <option value="300">300</option>
                    <option value="350">350</option>
                    <option value="400">400</option>
                    <option value="450">450</option>
                    <option value="500">500</option>
                    <option value="all">All</option>
                  </select>
                  {UserFormData.noOfRecordsError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.noOfRecordsError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col" data-tip="Enter Footer Title for Admin">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Footer Title for Admin{" "}
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    value={UserFormData.footer_title_for_admin}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        footerTitleError: "",
                        footer_title_for_admin: e.target.value,
                      });
                    }}
                    type="text"
                    className={`${
                      UserFormData.footerTitleError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  />
                  {UserFormData.footerTitleError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.footerTitleError}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t" >
              <input
                className="absolute opacity-0"
                id="tab-multi-two"
                type="checkbox"
                checked={UserFormData.FrontendError}
                onChange={() =>
                  setUserFormData({
                    ...UserFormData,
                    FrontendError: !UserFormData.FrontendError,
                  })
                }
                name="tabs"
              />

              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-two"
              >
                Front End Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal  lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col" data-tip="Enter Site Title">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Site Title <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.site_title}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        siteTitleError: "",
                        site_title: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.siteTitleError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Site title"
                  />
                  {UserFormData.siteTitleError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.siteTitleError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter Tagline">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Tag Line
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.tagline}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        siteTagLineError: "",
                        tagline: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.siteTagLineError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Site Tagline"
                  />
                  {UserFormData.siteTagLineError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.siteTagLineError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter Copyright Text">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Copyright Text
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.copyright_text}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        siteCopyrightTextError: "",
                        copyright_text: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.siteCopyrightTextError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Site Copyright Text"
                  />
                  {UserFormData.siteCopyrightTextError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.siteCopyrightTextError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col lg:col-span-3"  data-tip="Enter meta description">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Meta Description
                  </label>
                  <textarea
                    type="text"
                    value={UserFormData.meta_description_for_front}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        meta_description_for_front: e.target.value,
                      });
                    }}
                    className="border h-20 rounded px-2 text-sm font-medium "
                    placeholder="Site Copyright Text"
                  />
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-three"
                type="checkbox"
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-three"
              >
                App Download Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Enter iOS App download link here">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    iOS App Download Link
                  </label>
                  <input
                    type="text"
                    value={UserFormData.ios_app_download_link}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        ios_app_download_link: e.target.value,
                      });
                    }}
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="iOS App Download Link"
                  />
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter Android App Download Link here">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Android App Download Link
                  </label>
                  <input
                    type="text"
                    value={UserFormData.android_app_download_link}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        android_app_download_link: e.target.value,
                      });
                    }}
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Android App Download Link"
                  />
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-four"
                type="checkbox"
                checked={UserFormData.companyDetailsError}
                onChange={() =>
                  setUserFormData({
                    ...UserFormData,
                    companyDetailsError: !UserFormData.companyDetailsError,
                  })
                }
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-four"
              >
                Company Details
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Enter Company Name">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Company Name
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.company_name}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        companyNameError: "",
                        company_name: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.companyNameError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder=" Company Name"
                  />
                  {UserFormData.companyNameError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.companyNameError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Company Address - Line 1">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Address Line 1{" "}
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    value={UserFormData.company_address_line1}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        addressLine1Error: "",
                        company_address_line1: e.target.value,
                      });
                    }}
                    type="text"
                    className={`${
                      UserFormData.addressLine1Error
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Address Line One"
                  />
                  {UserFormData.addressLine1Error && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.addressLine1Error}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Company Address - Line 2">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Address Line 2{" "}
                  </label>
                  <input
                    value={UserFormData.company_address_line2}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        addressLine2Error: "",
                        company_address_line2: e.target.value,
                      });
                    }}
                    type="text"
                    className={`${
                      UserFormData.addressLine2Error
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Address Line two"
                  />
                  {UserFormData.addressLine2Error && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.addressLine2Error}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter City">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    City
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.company_city}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        cityError: "",
                        company_city: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.cityError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="City"
                  />
                  {UserFormData.cityError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.cityError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter State">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    State
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.company_state}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        stateError: "",
                        company_state: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.stateError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="State"
                  />
                  {UserFormData.stateError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.stateError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter Country">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Country
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.company_country}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        countryError: "",
                        company_country: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.countryError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Country"
                  />
                  {UserFormData.countryError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.countryError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter ZipCode">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    ZipCode
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="number"
                    value={UserFormData.company_zipcode}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        ZipCodeError: "",
                        company_zipcode: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.ZipCodeError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="ZipCode"
                  />
                  {UserFormData.ZipCodeError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.ZipCodeError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter Phone Number">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Phone
                  </label>
                  <ReactPhoneInput
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      height: "2.5rem",
                      fontSize: "13px",
                      paddingLeft: "48px",
                      borderRadius: "5px",
                      fontWeight: "600",
                      border: "1px solid lightgray",
                    }}
                    value={UserFormData.phone}
                    onChange={(phone) => {
                      setUserFormData({ ...UserFormData, phone });
                    }}
                  />
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter email address">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Email
                  </label>
                  <input
                    value={UserFormData.company_email}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        company_email: e.target.value,
                      });
                    }}
                    type="email"
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Email"
                  />
                </div>
                <div className="p-3 flex flex-col lg:col-span-3"  data-tip="Embedded HTML code for Google Map">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Google map embedded html code
                  </label>
                  <textarea
                    value={UserFormData.googlemap_embedded}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        googlemap_embedded: e.target.value,
                      });
                    }}
                    className="border h-20 max-h-20 rounded px-2 text-sm font-medium "
                  />
                  <div>
                    <h1 className="text-xs mt-2 mb-2">
                      (Tips: How to get embedded code from Google maps)
                    </h1>
                    <ul className="text-xs">
                      <li className="mb-2">
                        Step 1: Open https://www.google.com/maps and search
                        "company name"
                      </li>
                      <li className="mb-2">
                        Step 2: Once the business listing is opened, click on
                        the "share" button
                      </li>
                      <li>Step 3: Click on "embedded map" then COPY Html</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-six"
                type="checkbox"
                checked={UserFormData.emailSettingError}
                onChange={() =>
                  setUserFormData({
                    ...UserFormData,
                    emailSettingError: !UserFormData.emailSettingError,
                  })
                }
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-six"
              >
                Email Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Email Settings from Name">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    From Name
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={UserFormData.from_name}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        fromNameError: "",
                        from_name: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.fromNameError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="From name"
                  />
                  {UserFormData.fromNameError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.fromNameError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Email Settings from Email">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    From Email
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="email"
                    placeholder="From Email"
                    value={UserFormData.from_email}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        fromEmailError: "",
                        from_email: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.fromEmailError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  />
                  {UserFormData.fromEmailError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.fromEmailError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Email Settings from Admin Email">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Admin Email
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="email"
                    placeholder="Admin Email"
                    value={UserFormData.admin_email}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        adminEmailError: "",
                        admin_email: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.adminEmailError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  />
                  {UserFormData.adminEmailError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.adminEmailError}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-seven"
                type="checkbox"
                checked={UserFormData.passwordSettingError}
                onChange={() =>
                  setUserFormData({
                    ...UserFormData,
                    passwordSettingError: !UserFormData.passwordSettingError,
                  })
                }
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-seven"
              >
                Password Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Select Password Strength">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Password Strength
                  </label>
                  <select
                    value={UserFormData.password_strength}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        passwordStrengthError: "",
                        password_strength: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.passwordStrengthError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  >
                    <option>Select Strength</option>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="strong">Strong</option>
                  </select>
                  {UserFormData.passwordStrengthError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.passwordStrengthError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Number of failed Attempts to block the User while login">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    No of attempt to block user while login
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="number"
                    value={UserFormData.login_attempt}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        noOfAttemptError: "",
                        login_attempt: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.noOfAttemptError
                        ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                    placeholder="No of attempt to block user while login "
                  />
                  {UserFormData.noOfAttemptError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.noOfAttemptError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter the number of characters in the password">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Minimum Password Length
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    placeholder="Enter Minimum Password Length"
                    type="number"
                    value={UserFormData.minimum_password_length}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        minPasswordLengthError: "",
                        minimum_password_length: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.minPasswordLengthError
                        ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  />
                  {UserFormData.minPasswordLengthError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.minPasswordLengthError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter the user block time">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    User Block Time
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    placeholder="User Block Time"
                    type="number"
                    value={UserFormData.user_block_time}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        userBlockTimeError: "",
                        user_block_time: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.userBlockTimeError
                        ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  />
                  {UserFormData.userBlockTimeError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.userBlockTimeError}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-eight"
                type="checkbox"
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-eight"
              >
                Social Networking Links
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Link for facebook">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Facebook
                  </label>
                  <input
                    type="text"
                    value={UserFormData.facebook_link}
                    onChange={(e) =>
                      setUserFormData({
                        ...UserFormData,
                        facebook_link: e.target.value,
                      })
                    }
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Enter Facebook Link"
                  />
                </div>
                <div className="p-3 flex flex-col"  data-tip="Link for Twitter">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Twitter
                  </label>
                  <input
                    type="text"
                    value={UserFormData.twitter_link}
                    onChange={(e) =>
                      setUserFormData({
                        ...UserFormData,
                        twitter_link: e.target.value,
                      })
                    }
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Enter Twitter Link"
                  />
                </div>
                <div className="p-3 flex flex-col"  data-tip="Link for LinkedIn">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    LinkedIn
                  </label>
                  <input
                    type="text"
                    value={UserFormData.linkedin_link}
                    onChange={(e) =>
                      setUserFormData({
                        ...UserFormData,
                        linkedin_link: e.target.value,
                      })
                    }
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Enter LinkedIn Link"
                  />
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-nine"
                type="checkbox"
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-nine"
              >
                Google Captcha
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Enter the Google Recaptcha Site key">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Google Recaptcha Site Key
                  </label>
                  <input
                    type="text"
                    value={UserFormData.GOOGLE_RECAPTCHA_KEY}
                    onChange={(e) =>
                      setUserFormData({
                        ...UserFormData,
                        GOOGLE_RECAPTCHA_KEY: e.target.value,
                      })
                    }
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Google Recaptcha Site Key"
                  />
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter the Google Recaptcha Secret key">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Google Recaptcha Secret Key
                  </label>
                  <input
                    type="text"
                    value={UserFormData.GOOGLE_RECAPTCHA_SECRET}
                    onChange={(e) =>
                      setUserFormData({
                        ...UserFormData,
                        GOOGLE_RECAPTCHA_SECRET: e.target.value,
                      })
                    }
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Google Recaptcha Secert Key"
                  />
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-ten"
                type="checkbox"
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-ten"
              >
                SEO Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Enter Robots Meta tag">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Robots Meta Tag
                  </label>
                  <input
                    type="text"
                    value={UserFormData.robots_meta_tag}
                    onChange={(e) =>
                      setUserFormData({
                        ...UserFormData,
                        robots_meta_tag: e.target.value,
                      })
                    }
                    className="border h-10 rounded-lg px-2 text-sm font-medium "
                    placeholder="Robots Meta Tag"
                  />
                </div>
                <div className="p-3 flex flex-col lg:col-span-3"  data-tip="Enter Google Analytics Code">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Google Analytics Code
                  </label>
                  <textarea
                    value={UserFormData.google_analytics_code}
                    onChange={(e) =>
                      setUserFormData({
                        ...UserFormData,
                        google_analytics_code: e.target.value,
                      })
                    }
                    className={`${
                      UserFormData.userBlockTimeError
                        ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  />
                  {UserFormData.googleAnalyticsCodeError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.googleAnalyticsCodeError}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-eleven"
                type="checkbox"
                checked={UserFormData.apiSettingError}
                onChange={() =>
                  setUserFormData({
                    ...UserFormData,
                    apiSettingError: !UserFormData.apiSettingError,
                  })
                }
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-eleven"
              >
                API Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Enter API access token lifetime">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    API Access Token Lifetime{" "}
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="number"
                    value={UserFormData.api_access_token_lifetime}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        apiAccessTokenError: "",
                        api_access_token_lifetime: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.apiAccessTokenError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Robots Meta Tag"
                  />
                  {UserFormData.apiAccessTokenError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.apiAccessTokenError}
                    </span>
                  )}
                </div>
                <div className="p-3 flex flex-col"  data-tip="Enter API Refresh Token lifetime">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    API Refresh Token Lifetime{" "}
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="number"
                    value={UserFormData.api_refresh_token_lifetime}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        apiRefreshTokenError: "",
                        api_refresh_token_lifetime: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.apiRefreshTokenError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                    placeholder="Robots Meta Tag"
                  />
                  {UserFormData.apiRefreshTokenError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.apiRefreshTokenError}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="tab w-full overflow-hidden border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-twelve"
                type="checkbox"
                checked={UserFormData.signupchargesError}
                onChange={() =>
                  setUserFormData({
                    ...UserFormData,
                    signupchargesError: !UserFormData.signupchargesError,
                  })
                }
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor="tab-multi-twelve"
              >
                eCommerce Settings
              </label>
              <div className="tab-content overflow-hidden  border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full">
                <div className="p-3 flex flex-col"  data-tip="Select Signup Charges">
                  <label htmlFor="1" className="text-sm mb-2 flex items-center text-black dark:text-textinblack">
                    Signup Charges
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <select
                    value={UserFormData.signup_charges}
                    onChange={(e) => {
                      setUserFormData({
                        ...UserFormData,
                        signUpError: "",
                        signup_charges: e.target.value,
                      });
                    }}
                    className={`${
                      UserFormData.signupchargesError
                        ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  >
                    <option value="Enabled">Enabled</option>
                    <option value="Disabled">Disabled</option>
                  </select>
                  {UserFormData.signupchargesError && (
                    <span className="text-red-500 text-xs">
                      {UserFormData.signUpError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default Setting;