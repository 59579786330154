import React, { useCallback, useEffect, useState } from "react";
import ContactHeading from "./ContactHeading";
import ContactTable from "./contactTable";
import axios from "axios";
// import ReactExport from "react-data-export";
import { API } from "../../services/API";
import { RefreshToken } from "./../../services/RefreshToken";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../login/handleLogOut";
import { notify, NoRecord } from "../../utility";
import Loader from "react-js-loader";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContactsView = (props) => {
  const [contacts, setContacts] = useState([]);
  let navigate = useNavigate();
  let [currentPage, setCurrentPage] = useState(1);
  let [contactPerPage, setContactPerPage] = useState(5);
  let [totalContacts, setTotalContacts] = useState(0);
  let [search, setSearch] = useState("");
  let [pageSearch] = useState(true);

  let [sortByName, setSortByName] = useState(true);
  let [sortByEmail, setSortByEmail] = useState(true);
  let [sortByPhone, setSortByPhone] = useState(true);
  let [sortBySubmitted, setSortBySubmitted] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");


  const fetchContacts = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/contacts/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.contacts;
        if (data !== undefined && data !== null && data !== "") {
          const sorted = data.sort((a, b) => {
            const isReverse = sortByName === true ? 1 : -1;
            return isReverse * a.firstName.localeCompare(b.firstName);
          });
          getperPageData(data);

          setContacts(sorted);
          setTotalContacts(response.data.totalItems);
        } else {
          setContacts([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        console.log(error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
      });
  }, [access_token, sortByName]);

  const getperPageData = (contactData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setContactPerPage(
          contactData.length < parseInt(data.val)
            ? contactData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (access_token) {
      fetchContacts();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastContact = currentPage * contactPerPage;
  const indexOfFirstContact = indexOfLastContact - contactPerPage;
  const currentContacts = contacts.slice(
    indexOfFirstContact,
    indexOfLastContact
  );

  const paginate = (number) => {
    if(number>0){
      setCurrentPage(number);
    }
  };

  const setNumberOfContact = (number) => {
    if(number>0){
      if(number>contacts.length){
        setContactPerPage(parseInt(contacts.length))
      }
      setContactPerPage(parseInt(number));
    }
  };

  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url: API + "/contacts/?keywords=" + search,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.contacts;
        if (
          data !== undefined &&
          data !== null &&
          data !== "" &&
          data.length !== 0
        ) {
          const sorted = data.sort((a, b) => {
            const isReverse = sortByName === true ? 1 : -1;
            return isReverse * a.firstName.localeCompare(b.firstName);
          });
          setContactPerPage(data.length < 10 ? data.length : 10);
          setContacts(sorted);
          setTotalContacts(response.data.totalItems);
        } else {
          setContacts([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    fetchContacts();
  };

  const handleClose = () => {
    setSearch("");
    fetchContacts();
  };

  const sortNames = (sortByName) => {
    const sorted = contacts.sort((a, b) => {
      const isReverse = sortByName === true ? 1 : -1;
      return isReverse * a.firstName.trim().localeCompare(b.firstName.trim());
    });
    setContacts(sorted);
  };

  const sortEmails = (sortByEmail) => {
    const sorted = contacts.sort((a, b) => {
      const isReverse = sortByEmail === true ? 1 : -1;
      return isReverse * a.email.trim().localeCompare(b.email.trim());
    });
    setContacts(sorted);
  };

  const sortPhones = (sortByPhone) => {
    const sorted = contacts.sort((a, b) => {
      return sortByPhone ? a.phone - b.phone : b.phone - a.phone;
    });
    setContacts(sorted);
  };

  const sortSubmitted = (sortBySubmitted) => {
    const sorted = contacts.sort((a, b) => {
      let dateA = new Date(a.createdDate);
      let dateB = new Date(b.createdDate);
      return sortBySubmitted ? dateA - dateB : dateB - dateA;
    });
    setContacts(sorted);
  };

  return (
    <div
    className={`content-container relative overflow-y-auto scrollbar-hide ${props.Sidebar
      ? ""
      : ""
    }`}
    >
      <div className="flex-col items-center w-full">
        <ContactHeading
          pageHeading={"Contact Inquiries"}
          searchLabel={"Name"}
          pageSearch={pageSearch}
          deleteBtn={false}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentContacts.length === 0 ? (
          <NoRecord />
        ) : (
          <ContactTable
            contacts={currentContacts}
            contactPerPage={contactPerPage}
            totalContacts={totalContacts}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfContact={setNumberOfContact}
            sortNames={sortNames}
            sortByName={sortByName}
            setSortByName={setSortByName}
            sortEmails={sortEmails}
            sortByEmail={sortByEmail}
            setSortByEmail={setSortByEmail}
            sortPhones={sortPhones}
            sortByPhone={sortByPhone}
            setSortByPhone={setSortByPhone}
            sortSubmitted={sortSubmitted}
            sortBySubmitted={sortBySubmitted}
            setSortBySubmitted={setSortBySubmitted}
          />
        )}
      </div>
    </div>
  );
};

export default ContactsView;
