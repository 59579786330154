import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

const Options = (props) => {
    const [options, setOptions] = useState([]);
    const fetchOptions = useCallback(() => {
        axios
            .get("http://localhost:5000/chatbot")
            .then((response) => {
                const data = response.data;
                const a = [];
                // console.log(data);
                data.map((d) => {
                    a.push({
                        text: d.options,
                        handler: props.actionProvider.handleQuiz,
                        id: d.id,
                    });
                    return null;
                });
                setOptions(a);
            })
            .catch((err) => {});
    }, [props.actionProvider.handleQuiz]);

    useEffect(() => {
        fetchOptions();
    }, []);

    const buttonsMarkup =
        options &&
        options.map((option) => (
            <button
                key={option.id}
                onClick={() => {
                    option.handler(option.text);
                }}
                className="m-2 rounded-full border px-3 py-2 border-blue-400 text-center"
            >
                {option.text}
            </button>
        ));

    return <div className="flex flex-wrap">{options && buttonsMarkup}</div>;
};

export default Options;
