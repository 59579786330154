import React, { useState, useEffect, useCallback } from "react";
import PageHeading from "../pages/PageHeading";
import { CgAsterisk } from "react-icons/cg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notify } from "../../utility";

const AddChatBotQueAns = (props) => {
  let navigate = useNavigate();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [option, setOption] = useState("1");
  const [status, setStatus] = useState("1");

  const [questionError, setQuestionError] = useState("");
  const [answerError, setAnswerError] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;

  const handleAddEdit = () => {
    let questionError = "";
    let answerError = "";

    if (question.trim() === "") {
      questionError = "Enter Question";
    }
    if (answer.trim() === "") {
      answerError = "Enter Answer";
    }
    if (questionError !== "" || answerError !== "") {
      setQuestionError(questionError);
      setAnswerError(answerError);
      return;
    }

    if (props.match.params.id) {
      let editQuestion = {
        question: question,
        answer: answer,
        status: status,
        option: option,
        id: props.match.params.id,
      };
      let config = {
        method: "put",
        url: "http://localhost:5000/chatbot/editQuestion",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: editQuestion,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/chatbot");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      let AddQuestion = {
        question: question,
        answer: answer,
        option: option,
        status: status,
      };
      let config = {
        method: "post",
        url: "http://localhost:5000/chatbot",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: AddQuestion,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/chatbot");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const fetchQuestion = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: "http://localhost:5000/chatbot/" + id,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data.Faqs;
          setQuestion(data.question);
          setAnswer(data.answer);
          setOption(data.option);
          setStatus(data.status);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    [access_token]
  );

  const handleCancelBtn = () => {
    navigate(-1);
  };

  useEffect(() => {
    const id = props.match.params.id;
    if (id) {
      fetchQuestion(id);
    }
  }, [props.match.params.id]);

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading={props.match.params.id ? "Edit Question" : "Add Question"}
          showSaveOptionsBtn={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          save={true}
          saveAndContinue={true}
          handleSave={handleAddEdit}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Question
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={question}
                onChange={(e) => {
                  setQuestionError("");
                  setQuestion(e.target.value);
                }}
                type="text"
                className={`${
                  questionError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter title"
              />
              {questionError && (
                <span className="text-red-500 text-xs">{questionError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="font-medium mb-1">Option</label>
              <select
                className="border  h-10 rounded px-2 text-sm font-medium"
                value={option}
                onChange={(e) => {
                  setOption(e.target.value);
                }}
              >
                <option value="1">Javascript</option>
                <option value="2">Python</option>
                <option value="3">Java</option>
              </select>
            </div>
          </div>
          <div className="w-full my-5">
            <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              Answer
              <CgAsterisk className="inline text-red-500" />
            </label>
            <div className="w-full py-5">
              <textarea
                className="w-full h-56 border rounded p-2"
                value={answer}
                onChange={(e) => {
                  setAnswer(e.target.value);
                  setAnswerError("");
                }}
              />
              {answerError && (
                <span className="text-red-500 text-xs">{answerError}</span>
              )}
            </div>
          </div>
          <div className="w-full mt-2  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">Status</label>
              <select
                className="border  h-10 rounded px-2 text-sm font-medium"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};
export default AddChatBotQueAns;
