import React, { useState, useEffect, useCallback } from "react";
import Dropzone from "react-dropzone";
import axios from "axios";
import { FaFileUpload } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import moment from "moment";
import { notify } from "../../../utility";

const ClientDashboard = () => {
  const [requestForDocs, setRequestForDocs] = useState([]);
  const [selectedUploadList, setSelectedUploadList] = useState([]);
  const [agent, setAgent] = useState({});
  let userData = JSON.parse(localStorage.getItem("user"));

  const fetchDocRequests = useCallback(() => {
    const id = userData.id;
    const bodyParameters = {
      id: id,
    };
    axios
      .post("http://localhost:5000/doc/getClientsDocumentReq", bodyParameters)
      .then((res) => {
        const data = res.data.doc.map((d) => {
          let requestStatus = "";
          if (d.currentprogress !== d.totalprogress) {
            requestStatus = moment(d.dueDate).isBefore(moment())
              ? "OVERDUE"
              : moment(d.requestDueDate).isAfter(moment())
              ? "INPROGRESS"
              : "UNOPENED";
          } else {
            requestStatus = "READYFORREVIEW";
          }
          const a = d;
          a.requestStatus = requestStatus;
          return a;
        });
        setRequestForDocs(data);
      })
      .catch((err) => console.log(err));
  }, [userData.id]);

  const onImageDrop = (acceptedFiles, id, requestedtableid) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((image) => {
        let data = new FormData();
        data.append("folder", "document");
        data.append("image", image);
        const config = {
          method: "post",
          url: "http://localhost:5000/uploads",

          data: data,
        };

        axios(config)
          .then(function (response) {
            const d = {
              id: id,
              requestedtableid: requestedtableid,
              documentname: response.data.imageName,
            };
            const conf = {
              method: "put",
              url: "http://localhost:5000/doc/uploadDoc",

              data: d,
            };
            axios(conf)
              .then((res) => {
                notify(res.data.message);
                fetchDocRequests();
              })
              .catch((err) => {
                console.log("error", err);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
        return null;
      });
    }
  };

  const handleRadioChange = (doc) => {
    setAgent(doc);
    const bodyParameters = {
      id: doc.id,
    };
    axios
      .post("http://localhost:5000/doc/getSelectAgentDocReq", bodyParameters)
      .then((res) => {
        setSelectedUploadList(res.data.docList);
        // console.log(res.data.docList);
      })
      .catch((err) => console.log(err));
  };

  const removeDoc = (id, requestedtableid, documentname) => {
    let arr = documentname.split("/");
    let docName = arr[arr.length - 1];
    const conf = {
      method: "delete",
      url: "http://localhost:5000/uploads",
      data: {
        folder: "document",
        image: docName,
      },
    };
    axios(conf)
      .then(function (response) {
        const bodyParameters = {
          id: id,
          requestedtableid: requestedtableid,
        };
        const config = {
          method: "delete",
          url: "http://localhost:5000/doc/deleteDoc",
          data: bodyParameters,
        };
        axios(config)
          .then((response) => {
            notify(response.data.message);
            fetchDocRequests();
            handleRadioChange(agent);
          })
          .catch((err) => console.log(err));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDocRequests();
  }, []);
  return (
    <div className="w-full mt-14 px-10 min-h-screen pt-8 mb-14 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 pb-10 gap-4 dark:bg-gray-700 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
      <div className="flex w-full flex-col mt-2 ">
        {requestForDocs &&
          requestForDocs.map((doc) => (
            <>
              <input
                id={doc.id}
                className="hidden"
                type="radio"
                name="docs"
                onChange={(e) => handleRadioChange(doc)}
              />
              <label
                for={doc.id}
                key={doc.id}
                className="border p-4 m-2 shadow-sm grid grid-cols-3 gap-2 cursor-pointer dark:bg-white rounded"
              >
                <div className="col-span-2">
                  <h1 className="font-semibold  capitalize">
                    <span className="font-semibold mr-2 capitalize">
                      Agent Name:
                    </span>
                    {doc.agent_name}
                  </h1>
                  <div className="w-full flex items-center ">
                    <h1 className="font-semibold mr-2">Requested documents:</h1>
                    <h1 className="font-semibold mr-2">
                      {doc.currentprogress} /{doc.totalprogress}
                    </h1>
                    <h1
                      className=" border rounded-full overflow-hidden h-4 bg-gray-300 flex items-center"
                      style={{ width: "250px" }}
                    >
                      <span
                        className="bg-blue-400 rounded-full h-4 inline-block "
                        style={{
                          width:
                            (250 / doc.totalprogress) * doc.currentprogress +
                            "px",
                        }}
                      ></span>
                    </h1>
                    {doc.totalprogress === doc.currentprogress && (
                      <AiFillCheckCircle className="text-yellow-500 text-2xl" />
                    )}
                  </div>
                  <div className="flex">
                    <h1 className="font-semibold">Date:</h1>
                    <h1 className="font-semibold pr-1 text-yellow-500">
                      {moment(doc.date).format("DD/MM/YYYY")}
                    </h1>
                  </div>
                  <div className="flex">
                    <h1 className="font-semibold">Due Date:</h1>
                    <h1 className="font-semibold pr-1 text-yellow-500">
                      {moment(doc.duedate).format("DD/MM/YYYY")}
                    </h1>
                  </div>
                </div>
                <div className="">
                  <h1 className="text-xs lg:text-sm font-semibold text-center my-1">
                    Status
                  </h1>
                  <h1
                    className={`text-xs lg:text-sm font-semibold border-2 text-center p-1 ${
                      doc.requestStatus === "OVERDUE"
                        ? "border-red-500 text-red-500"
                        : "" || doc.requestStatus === "READYFORREVIEW"
                        ? "border-green-500 text-green-500"
                        : "" || doc.requestStatus === "INPROGRESS"
                        ? "border-yellow-500 text-yellow-500"
                        : "" || doc.requestStatus === "UNOPENED"
                        ? "border-black text-black"
                        : ""
                    }`}
                  >
                    {doc.requestStatus}
                  </h1>
                </div>
              </label>
            </>
          ))}
      </div>
      <div className="w-full flex flex-col border mt-4  p-4 ">
        {selectedUploadList.length > 0 && (
          <h1 className="w-full text-center text-xl font-medium capitalize dark:text-white">
            {agent.agent_name}
          </h1>
        )}
        {selectedUploadList.length > 0 &&
          selectedUploadList.map((i) => (
            <div
              key={i.id}
              className={`${
                i.status === 0
                  ? "border border-gray-500"
                  : i.review === "wrong"
                  ? "border border-red-500"
                  : "border border-green-500"
              } flex flex-col border-4  p-2 my-8 mx-2 shadow-md dark:bg-white rounded`}
            >
              <h1 className="text-lg font-medium capitalize">
                {i.documenttype}
              </h1>
              {i.status === 0 ? (
                <div className="w-full flex items-center justify-center">
                  <Dropzone
                    // accept="image/*"
                    onDrop={(acceptedFiles) => {
                      onImageDrop(acceptedFiles, i.id, i.requestedtableid);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="w-1/2 h-30 border p-2  border-black border-dashed flex flex-col items-center justify-center"
                      >
                        <input {...getInputProps()} />
                        <FaFileUpload
                          className="w-full text-gray-400 flex items-center justify-center "
                          size={30}
                        />
                        <p className="p-2">
                          Drag 'n' drop {i.documenttype} here, or click to
                          select {i.documenttype}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
              ) : (
                <div className="w-full flex items-center relative justify-center">
                  <div className="w-full h-40 imgHover  flex items-center justify-center border p-4">
                    <img
                      alt="gg"
                      src={i.documentname}
                      className="w-full h-full object-contain"
                    />
                    <button
                      onClick={() => {
                        removeDoc(i.id, i.requestedtableid, i.documentname);
                      }}
                      className="absolute  imgHoverDisplay z-20 top-1 right-1 btn bg-gray-900 text-white"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
              {i.status === 1 && i.note && (
                <div className="w-full my-2 flex items-center relative justify-start">
                  <h1 className="font-semibold capitalize">
                    Note: <span className="text-yellow-500">{i.note}</span>
                  </h1>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClientDashboard;
