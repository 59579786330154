import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { API } from "../../../services/API";
import "./slider.css";

const ImageSlider = () => {
  let navigate = useNavigate();
  const [sliderData, setSliderData] = useState([]);
  const [allowSlider, setAllowSlider] = useState("yes");
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const fetchSliderImages = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/slider/front/?size=-1",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.sliders;
        let filteredData = data.filter((option) => option.status === true);
        setSliderData(
          filteredData.map((im) => {
            return {
              select: false,
              id: im.id,
              title: im.title,
              status: im.status,
              overlayText: im.overlayText,
              imageAlt: im.imageAlt,
              image: im.image,
              // alternativeText: im.alternativeText,
              activeSlider: im.status,
              displayOrder: im.displayOrder,
            };
          })
        );
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          console.log(error);
        }
        console.log(error);
      });
  }, []);

  const showSlider = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/field_slider_title_display",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data;
        const image_show = data.val;
        setAllowSlider(image_show);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/");
        }
        console.log(error);
      });
  };

  const DisplaySlider = () => {
    return (
      <AutoplaySlider play={true} interval={3000}>
        {sliderData.map((data, i) => {
          return (
            <div key={i} className="w-full h-full">
              <img
                className="object-cover object-center w-full h-full min-w-full min-h-full"
                alt="img"
                src={
                  API +
                  "/files/download/?fileName=" +
                  `${encodeURIComponent(data.image)}`
                }
              />
              {allowSlider === "yes" && (
                <div>
                  <div className="text-lg font-medium text-yellow-500 text-center">
                    {data.title}
                  </div>
                  <div className="text-sm font-small text-yellow-500 text-center">
                    {data.overlayText}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </AutoplaySlider>
    );
  };

  useEffect(() => {
    fetchSliderImages();
    showSlider();
  }, []);

  return (
    // <div className="bg-white dark:bg-gray-700 lg:mt-1">

    sliderData.length > 0 ? (
      <DisplaySlider />
    ) : (
      <div className="w-full h-full mt-48">
        <img
          className="object-cover object-center w-full h-full min-w-full min-h-full"
          alt="img"
          src={process.env.PUBLIC_URL + "/images/header-logo.png"}
        />
      </div>
    )

    // </div>
  );
};

export default ImageSlider;
