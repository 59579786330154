import axios from "axios";
import { API } from "./API";

export function RefreshToken(prevToken, setAccess_token, setUserInfo) {
  let userCred;
  let config = {
    method: "get",
    url: API + "/users/auth/refreshtoken",
    headers: {
      isRefreshToken: true,
      Authorization: prevToken,
    },
  };
  axios(config)
    .then(function (response) {
      localStorage.removeItem("user");
      let userData = JSON.stringify(response.data);
      localStorage.setItem("user", userData);
      userCred = JSON.parse(localStorage.getItem("user"));
      setUserInfo && setUserInfo(userCred);
      setAccess_token && setAccess_token(userCred.accessToken);
    })
    .catch(function (error) {
      console.log("error during token refresh", error);
    });
  return userCred;
}
