import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineViewComfyAlt } from "react-icons/md";
import { MdSettingsApplications } from "react-icons/md";

const SubMenu = ({ item, hideSidebarFromMenu, Sidebar }) => {
  const navigate = useNavigate();
  const [subnav, setSubnav] = useState(false);
  const showSubNav = () => {
    setSubnav(!subnav);
  };
  return (
    <>
      {/* {console.log("sidebar", item.path, item.path === "")} */}
      <div
        // to={!item.subNav ? item.path : item.subNav}
        onClick={() => {
          if ((Sidebar && item.subNav) || item.subNav) {
            showSubNav();
          } else {
            navigate(item.path);
          }
        }}
        className={`w-52-5 flex items-center flex-shrink-0 overflow-hidden dark:text-textinblack hover:text-white dark:hover:text-white hover:bg-bluecolor box-border h-10 rounded my-0.5 cursor-pointer ${
          item.path === window.location.pathname || window.location.pathname.includes(item.path)
            ? "bg-bluecolor text-white dark:text-white"
            : "text-blackbtn"
        }`}
      >
        <div className="flex items-center justify-start flex-nowrap w-full border-box">
          <span className="w-10 border-box flex items-center justify-center">
            {" "}
            {item.icon}{" "}
          </span>
          <span className="text-sm capitalize ml-1">{item.title}</span>
        </div>
        <div className="dark:text-textinblack text-black pr-3">
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </div>
      <div className="w-52-5 bg-blue-200 rounded">
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <div
                key={index}
                // to={item.path}
                onClick={() => {
                  navigate(item.path);
                }}
                className={`flex dark:text-textinblack items-center hover:text-white justify-between dark:hover:text-white hover:bg-bluecolor cursor-pointer w-52-5 h-10 rounded my-0.5 ${
                  item.path === window.location.pathname || window.location.pathname.includes(item.path)
                    ? "bg-bluecolor text-white dark:text-white"
                    : "text-blackbtn"
                }`}
              >
                <div className="w-full flex items-center justify-start">
                  <span className="w-10 flex items-center justify-center">
                    {item.title === "View All" ? (
                      <MdOutlineViewComfyAlt />
                    ) : item.title === "Settings" ? (
                      <MdSettingsApplications />
                    ) : (
                      <span>{item.icon}</span>
                    )}
                  </span>
                  <span className="text-sm capitalize ml-1">{item.title}</span>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default SubMenu;
