import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { hoverAnimation } from "../../helper/helper";
import { API } from "../../services/API";
import { Modal } from "react-responsive-modal";

const GalleryCards = ({
  galleries,
  setGalleries,
  setSelectAllCheckbox,
  selectAllCheckbox,
  selectSingle,
  selectAll,
  handleDelete,
}) => {
  let navigate = useNavigate();
  const [viewImg, setViewImg] = useState("");
  const [isImgHover, setIsImgHover] = useState(false);
  const [ImagePopup, setImagePopup] = useState(false);

  useEffect(() => {}, []);

  const onCloseModal = () => {
    setImagePopup(false);
    setViewImg(null);
  };

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark">
      <div className="mb-5">
        <input
          type="checkbox"
          checked={selectAllCheckbox}
          onChange={(e) => {
            setSelectAllCheckbox(!selectAllCheckbox);
            selectAll(e);
          }}
          id="selectAll"
          className="cursor-pointer"
        />
        <label
          for="selectAll"
          className="text-black dark:text-textinblack ml-2 text-lg"
        >
          Select/Unselect All
        </label>
      </div>
      <div className="grid grid-cols-1 gap-4  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
        {galleries.map((gallery) => (
          <div
            key={gallery.id}
            className={`border-2 w-full overflow-hidden flex flex-col  rounded   ${
              gallery.status === true ? "border-green-500" : "border-red-500"
            }`}
          >
            <div
              className="w-full relative imgHover h-40 bg-white"
              onMouseEnter={() => setIsImgHover(gallery.id)}
              onMouseLeave={() => setIsImgHover(null)}
            >
              <img
                className="w-full h-full object-contain p-2"
                src={
                  API +
                  "/files/download/?fileName=" +
                  `${encodeURIComponent(gallery.image)}`
                }
                alt={gallery.imageAlt}
              />
              <div
                className={
                  isImgHover && isImgHover === gallery.id
                    ? "visible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                    : "invisible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                }
              >
                <button
                  data-tooltip-id={`EyeToolTip${gallery.id}`}
                  data-tooltip-content="Preview"
                  data-tooltip-place="top"
                  onClick={() => {
                    setViewImg(gallery);
                    setImagePopup(true);
                  }}
                  className={
                    "w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center" +
                    hoverAnimation
                  }
                >
                  <AiOutlineEye />
                  <Tooltip id={`EyeToolTip${gallery.id}`} />
                </button>
                <button
                  data-tooltip-id={`PencilToolTip${gallery.id}`}
                  data-tooltip-content="Edit"
                  data-tooltip-place="top"
                  onClick={() => {
                    navigate(`/admin/galleries/editGallery/${gallery.id}`, {
                      state: {
                        details: gallery,
                      },
                    });
                  }}
                  className={
                    "w-8 h-8 bg-green-500 rounded-full flex items-center justify-center" +
                    hoverAnimation
                  }
                >
                  <BsPencil />
                  <Tooltip id={`PencilToolTip${gallery.id}`} />
                </button>
                <button
                  data-tooltip-id={`DeleteToolTip${gallery.id}`}
                  data-tooltip-content="Delete"
                  data-tooltip-place="top"
                  onClick={() => {
                    setGalleries(
                      galleries.map((d) => {
                        if (gallery.id === d.id) {
                          d.select = true;
                        }
                        return d;
                      })
                    );
                    handleDelete();
                  }}
                  className={
                    "w-8 h-8 bg-black rounded-full flex items-center justify-center text-lg" +
                    hoverAnimation
                  }
                >
                  x
                  <Tooltip id={`DeleteToolTip${gallery.id}`} />
                </button>
              </div>
            </div>

            <div className="flex justify-between items-center mt-2 p-2">
              <div className="w-1/5 ">
                <input
                  type="checkbox"
                  checked={gallery.select}
                  onChange={(e) => selectSingle(e, gallery.id)}
                  className="cursor-pointer"
                />
              </div>
              <h1 className="dark:text-white w-4/5 flex justify-end">
                <span className="overflow-ellipsis">{gallery.title}</span>
              </h1>
            </div>
          </div>
        ))}
      </div>

      <Modal open={ImagePopup} onClose={onCloseModal} center>
        <div>
          <img
            className="w-full"
            src={
              viewImg !== null &&
              API +
                "/files/download/?fileName=" +
                `${encodeURIComponent(viewImg.image)}`
            }
            alt={viewImg !== null && viewImg.imageAlt}
          />
          <div className="px-6 py-4">
            <div className="font-bold text-black text-xl mb-2">
              {viewImg !== null && viewImg.title}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GalleryCards;
