import React from "react";
import { NavLink } from "react-router-dom";
import { FaHome, FaBlogger } from "react-icons/fa";
import { RiShoppingBag3Line, RiContactsBook2Fill } from "react-icons/ri";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import useDarkMode from "../../../useDarkMode";

const MobileIconMenu = () => {
  let navigate = useNavigate();
  const [colorTheme, setTheme] = useDarkMode();

  return (
    <div className="flex w-full sm:hidden md:hidden lg:hidden xl:hidden fixed bottom-0 z-10 dark:bg-gray-800 bg-white h-14 justify-evenly items-center shadow-xl">
      
      <NavLink to="/" activeclassname="text-blue-400 dark:text-blue-400">
        <FaHome size="20" />
      </NavLink>
      <NavLink to="/blogs" activeclassname="text-blue-400 dark:text-blue-400">
        <FaBlogger size="20" />
      </NavLink>
      {/* <NavLink
        to="/products"
        activeclassname="text-blue-400 dark:text-blue-400"
      >
        <RiShoppingBag3Line size="20" />
      </NavLink> */}
      
      <a href="#contact">
        <RiContactsBook2Fill
          size="20"
          onClick={() => {
            navigate("/");
          }}
        />
      </a>
      <NavLink to="javascript:void(0)"
         activeclassname="text-blue-400 dark:text-blue-400"
         onClick={(e) => {
          e.preventDefault();
          setTheme(colorTheme);
        }}
         className={`border-1 border-black p-1 order-1 cursor-pointer rounded-full xl:order-2 xl:relative xl:top-0 xl:right-0 lg:order-2 lg:relative lg:top-0 lg:right-0 ${
          colorTheme === "light" ? "bg-white" : "bg-gray-800"
        }`}
        >
          <HiOutlineLightBulb
             className={`${
              colorTheme === "light" ? "text-black" : "text-white"
            } `}
          />
        </NavLink>

    </div>
  );
};

export default MobileIconMenu;
