import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import Options from "../Options/Options";
import Quiz from "../Quiz/Quiz";

const config = {
    botName: "LearningBot",
    initialMessages: [
        createChatBotMessage(`Hello. What do you want to learn`, {
            widget: "options",
        }),
    ],
    state: {
        questions: [],
    },
    widgets: [
        {
            widgetName: "options",
            widgetFunc: (props) => <Options {...props} />,
        },
        {
            widgetName: "javascript",
            widgetFunc: (props) => <Quiz {...props} option={1} />,
            mapStateToProps: ["questions"],
        },
        {
            widgetName: "python",
            widgetFunc: (props) => <Quiz {...props} option={2} />,
            mapStateToProps: ["questions"],
        },
        {
            widgetName: "java",
            widgetFunc: (props) => <Quiz {...props} option={3} />,
            mapStateToProps: ["questions"],
        },
    ],
};

export default config;
