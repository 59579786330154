import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { API } from "../../../services/API";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const PagesDescription = (props) => {
  const params = useParams();
  let navigate = useNavigate();
  const [Page, setPage] = useState("");
  const [PageId, setPageId] = useState("");

  const fetchPageData = (id) => {
    let PageConfig = {
      method: "get",
      url: API + "/pages/front/" + id,
    };
    axios(PageConfig)
      .then(function (response) {
        // console.log("response pages", response);
        setPage(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const id = params.id;
    if (id) {
      fetchPageData(id);
    }
  }, [params.id]);

  return (
    <>
    <div className="w-full mt-20">
      {Page && (
        <>
          {Page.description && Page.banner && (
            // <img alt="image" src={ API + "/files/download/?fileName=" + `${encodeURIComponent(Page.description)}` } className="object-cover object-center w-full h-full min-w-full min-h-full" />
            <img alt="image" src={ API + "/files/download/?fileName=" + `${encodeURIComponent(Page.description)}` } className="w-full aspect-[16/9]" />
          )}
        </>
      )}
    </div>
    <div className="w-full mb-14 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 bg-white dark:bg-gray-700 2xl:p-10 xl:p-10 lg:p-10 text-black dark:text-white">
      {Page && (
        <div className="main-content mx-3 lg:mx-auto">
          <h1 className="w-full mb-2 pt-2 font-semibold text-3xl capitalize">{Page.title}</h1>
          <div className="w-full font-medium capitalize mb-8">
            <div dangerouslySetInnerHTML={{ __html: Page.bannerContent }} />
          </div>
        </div>
      )}
    </div>
    </>
  );
};
export default PagesDescription;
