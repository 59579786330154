import React, { useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
import axios from "axios";
import { API } from "../../../services/API";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";

import { notify } from "../../../utility";

export default function VerifyAccountComponent(props) {
  const {
    invalidMessage,
    userId: verifiedUserId,
    email,
    userType,
    // isSetPassword,
  } = props;
  // console.log("props in verify component", props);
  let navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [NewUser, setNewUser] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordLength, setNewPasswordLength] = useState(0);
  const [newPasswordStrength, setNewPasswordStrength] = useState("strong");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  let EasyregEx = /^[A-Za-z0-9!"#.@_~$%^*:|-]*$/;
  let MediumregEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[!\\"#.@_~$%^*:|-]$/;
  let StrongregEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!\\"#.@_`~$%^*:,;|-])$/;

  // const isForgotPasswordAdmin = localStorage.getItem("forgotPasswordAdmin");
  const backToLoginPath = userType === "Admin" ? "/admin" : "/frontendLogin";

  const handleVerify = (e) => {
    let newPasswordError = "";
    let confirmPasswordError = "";
    let regEX = "";

    if (newPasswordStrength === "easy") regEX = EasyregEx;
    else if (newPasswordStrength === "medium") regEX = MediumregEx;
    else regEX = StrongregEx;

    if (newPassword.trim() === "") {
      newPasswordError = "Enter new password before save";
    }
    if (confirmPassword.trim() === "") {
      confirmPasswordError = "Enter Confirm password before save";
    }
    if (newPasswordError !== "" || confirmPasswordError !== "") {
      setNewPasswordError(newPasswordError);
      setConfirmPasswordError(confirmPasswordError);
      return;
    }
    if (newPassword.length >= newPasswordLength) {
      setNewPassword(newPassword);
    } else {
      setNewPasswordError(
        "Please check following condition to set new password"
      );
      return;
    }
    if (newPassword === confirmPassword) {
      setConfirmPassword(confirmPassword);
    } else {
      setConfirmPasswordError("new password and confirm password is not same");
      return;
    }

    const reset_data = {
      // userId: userId,
      userId: verifiedUserId,
      newPassword: newPassword,
    };
    // console.log("------On going api call data--------", reset_data);
    let config = {
      method: "put",
      url: API + "/users/resetPassword",
      headers: {
        "Content-type": "application/json",
      },
      data: reset_data,
    };

    axios(config)
      .then(function (response) {
        // const data = response.data;
        notify("User new password set successfully");
        window.location.href = backToLoginPath;
        // if (isForgotPasswordAdmin === "true") {
        //   window.location.href = "/admin";
        // } else {
        //   window.location.href = "/frontendLogin";
        // }
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          notify("Something went wrong");
          navigate("/setPassword/token/" + userId + "/" + userEmail);
          // handleLogout(history);
        } else if (error.message === "Request failed with status code 400") {
          notify("Please provide valid password");
          navigate("/setPassword/token/" + userId + "/" + userEmail);
          setNewPasswordError(
            "Please check following condition to set new password"
          );
          // handleLogout(history);
        } else {
          navigate("/setPassword/token/" + userId + "/" + userEmail);
        }
      });
    return false;
  };
  const getUser = (email) => {
    let config = {
      method: "get",
      url: API + "/users/front/email/" + email,
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("------------get User Response-----------", response);
        let response_user = response.data;
        if (response_user) {
        } else {
          navigate("/admin");
        }
      })
      .catch((error) => {
        let eorror_data = error.response.data;
        if (eorror_data.code === 404) {
          notify("User not found");
          navigate("/admin");
        }
      });
  };

  const getPasswordData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/minimum_password_length",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        setNewPasswordLength(parseInt(data.val));
      })
      .catch((error) => {
        console.log("error", error);
      });

    let Dataconfig = {
      method: "get",
      url: API + "/siteConfigs/front/name/password_strength",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(Dataconfig)
      .then((response) => {
        const data = response.data;
        setNewPasswordStrength(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    // if (params.token) {
    //   setUserId(params.token);
    // }
    // if (params.email) {
    //   setUserEmail(params.email);
    // }
    let pathname = window.location.pathname;
    // console.log("pathname", pathname);
    if (pathname.includes("/setPassword/")) {
      // console.log("new user", pathname.includes("/setPassword/"));
      setNewUser(true);
    } else if (pathname.includes("/resetPassword")) {
      // console.log("not new user", pathname.includes("/resetPassword"));
      setNewUser(false);
    }
  }, []);

  // useEffect(() => {
  //   if (params.token) {
  //     setUserId(params.token);
  //   }
  // }, [params.token]);

  useEffect(() => {
    if (email) {
      setUserEmail(email);
      getUser(email);
      getPasswordData();
    }
  }, [email]);

  return (
    <div className="w-full flex items-center justify-center dark:bg-gray-600 mt-20 mb-12">
      <div className="lg:w-1/3 sm:w-3/5 w-9/12 shadow-2xl p-5 rounded-lg dark:bg-gray-500">
        {invalidMessage && (
          <div className="w-full flex items-center justify-center text-red-600 dark:bg-gray-600 mt-20 mb-12">
            {invalidMessage}
          </div>
        )}
        <div className="w--full flex items-center justify-center my-4">
          <h1 className="text-3xl font-normal text-black dark:text-white">
            {NewUser ? "Account Activation" : "Reset Password"}
          </h1>
        </div>
        <div className="w-full flex flex-col">
          <label className="font-normal text-sm flex item-center tracking-wider text-black dark:text-white">
            Email
            <CgAsterisk className="inline-block text-red-500 " />
          </label>
          <input
            type="text"
            value={userEmail}
            disabled={true}
            placeholder="Email"
            className={`py-2 border-b rounded  px-2 dark:bg-gray-200 border-gray-300 focus:outline-none focus:border-blue-500`}
          />
        </div>
        <div className="w-full flex flex-col">
          <label className="font-normal text-sm flex item-center tracking-wider text-black dark:text-white">
            Password
            <CgAsterisk className="inline-block text-red-500 " />
          </label>
          <input
            type="password"
            value={newPassword}
            placeholder="Enter your password"
            disabled={invalidMessage}
            onChange={(e) => {
              setNewPasswordError("");
              setNewPassword(e.target.value);
            }}
            onBlur={() => {
              if (newPassword.trim() === "") {
                setNewPasswordError("New  password is required");
              }
            }}
            className={`${
              newPasswordError && "border-red-500"
            } py-2 border-b rounded  px-2 dark:bg-gray-200 border-gray-300 focus:outline-none focus:border-blue-500`}
          />
          {newPasswordError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {newPasswordError}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col mt-2">
          <p className="text-xs">
            Your password must be at least {newPasswordLength} characters long
            and your password should contain.
          </p>
          {newPasswordStrength === "strong" ? (
            <ol className="list-decimal text-xs pl-3">
              <li>At least one upper case letter</li>
              <li>At least one lower case letter</li>
              <li>At least one digit.</li>
              <li>
                At least one special character. Only (! \" # . @ _ ` ~ $ % ^ * :
                , ; | -) special characters are allowed in the password.
              </li>
            </ol>
          ) : newPasswordStrength === "medium" ? (
            <ol className="list-decimal text-xs pl-3">
              <li>At least one upper case letter</li>
              <li>At least one lower case letter</li>
              <li>At least one digit.</li>
              <li>
                Only (! \" # . @ _ ` ~ $ % ^ * : , ; | -) special characters are
                allowed in the password.
              </li>
            </ol>
          ) : (
            <li>
              Only (! \" # . @ _ ` ~ $ % ^ * : , ; | -) special characters are
              allowed in the password.
            </li>
          )}
        </div>
        <div className="w-full flex flex-col mt-2">
          <label className="font-normal text-sm flex item-center tracking-wider text-black dark:text-white">
            Confirm Password
            <CgAsterisk className="inline-block text-red-500 " />
          </label>
          <input
            type="password"
            value={confirmPassword}
            disabled={invalidMessage}
            placeholder="Enter your confirm password"
            onChange={(e) => {
              setConfirmPasswordError("");
              setConfirmPassword(e.target.value);
            }}
            onBlur={() => {
              if (confirmPassword.trim() === "") {
                setConfirmPasswordError(
                  "The Confirm-password field is required."
                );
              }
            }}
            className={`${
              confirmPasswordError && "border-red-500"
            } py-2 border-b rounded  px-2 dark:bg-gray-200 border-gray-300 focus:outline-none focus:border-blue-500`}
          />
          {confirmPasswordError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {confirmPasswordError}
            </span>
          )}
        </div>

        <div className="w-full flex items-center justify-center my-4">
          <button
            onClick={(e) => handleVerify(e)}
            type="submit"
            className={
              invalidMessage
                ? "w-full h-10 bg-gray-300 text-white rounded shadow-lg tracking-wider "
                : "w-full h-10 bg-blue-500 hover:bg-blue-600 text-sm text-white rounded shadow-lg tracking-wider dark:bg-gray-800 dark:hover:bg-gray-700"
            }
            disabled={invalidMessage}
          >
            {NewUser ? "Set Password" : "Reset Password"}
          </button>
        </div>
        <div className="w-full flex items-center justify-center">
          <Link to={{ pathname: backToLoginPath }} target="_blank">
            <button className=" text-xs hover:underline tracking-wider outline-none">
              Back to the Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
