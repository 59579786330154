import React, { useState, useEffect } from "react";
import axios from "axios";
import FlashCard from "./FlashCard";

const Quiz = (props) => {
    let [questions, setQuestions] = useState([]);
    let [questionIndex, setQuestionIndex] = useState(0);

    useEffect(() => {
        const config = {
            method: "post",
            url: "http://localhost:5000/chatbot/getQuestionAnswer",
            data: {
                option: props.option,
            },
        };
        axios(config)
            .then((response) => {
                const qusAns = response.data;
                const a = [];
                qusAns.map((qa) => {
                    a.push({
                        question: qa.question,
                        answer: qa.answer,
                        id: qa.id,
                    });
                    return null;
                });
                setQuestions(a);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, [props.option]);

    const incrementIndex = () => setQuestionIndex((prev) => (prev += 1));
    const currentQuestion = questions[questionIndex];

    if (!currentQuestion) {
        return <p>Quiz over.</p>;
    }

    return (
        <FlashCard
            question={currentQuestion.question}
            answer={currentQuestion.answer}
            incrementIndex={incrementIndex}
        />
    );
};

export default Quiz;
