import React from "react";
// import { MdOutlineForum } from "react-icons/md";
// import { MdOutlineDashboard } from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";
import * as CiIcons from "react-icons/ci";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BoxIcons from "react-icons/bi";
import * as BootstrapIcon from "react-icons/bs";
import * as MDIcons from "react-icons/md";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
    // icon: <AiIcons.AiFillDashboard />,
    icon: <MDIcons.MdOutlineDashboard />,
  },
  {
    title: "Users",
    path: "/admin/users",
    dashboardPath : "/admin/users",
    // icon: <AiIcons.AiOutlineUser />,
    icon: <MDIcons.MdOutlineAccountBox />,
  },
  {
    title: "Pages",
    path: "/admin/pages",
    dashboardPath : "/admin/pages",
    // icon: <IoIcons.IoIosPaper />,
    icon: <MDIcons.MdOutlineDescription />,
  },
  // {
  //     title: "SiteConfig",
  //     path: "/admin/siteconfigs",
  //     icon: <AiIcons.AiOutlineApi />,
  // },
  {
    title: "Categories",
    path: "/admin/categories",
    dashboardPath : "/admin/categories",
    // icon: <AiIcons.AiFillAppstore />,
    icon: <MDIcons.MdOutlineWindow />
  },
  {
    title: "Testimonials",
    path: "/admin/testimonials",
    // icon: <BoxIcons.BiMessageCheck />,
    dashboardPath : "/admin/testimonials/view",
    icon: <MDIcons.MdOutlineComment />,
    iconClosed: <MDIcons.MdExpandMore className="dark:text-white" />,
    iconOpened: <MDIcons.MdExpandLess className="dark:text-white" />,
    subNav: [
      {
        title: "View All",
        path: "/admin/testimonials/view",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Settings",
        path: "/admin/testimonials/setting",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Blogs",
    path: "/admin/blog",
    // icon: <FaIcons.FaBlogger />,
    dashboardPath : "/admin/blog/view",
    icon: <MDIcons.MdOutlineSpeakerNotes />,
    iconClosed: <MDIcons.MdExpandMore className="dark:text-white" />,
    iconOpened: <MDIcons.MdExpandLess className="dark:text-white" />,
    subNav: [
      {
        title: "View All",
        path: "/admin/blog/view",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Settings",
        path: "/admin/blog/setting",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "FAQs",
    path: "/admin/faqs",
    // icon: <FaIcons.FaQuestionCircle />,
    dashboardPath : "/admin/faqs",
    icon: <MDIcons.MdOutlineForum />,
  },
  {
    title: "Home Page Sliders",
    path: "/admin/sliders",
    // icon: <FaIcons.FaSlidersH />,
    dashboardPath : "/admin/sliders/view",
    icon: <MDIcons.MdOutlineImage />,
    iconClosed: <MDIcons.MdExpandMore className="dark:text-white" />,
    iconOpened: <MDIcons.MdExpandLess className="dark:text-white" />,
    subNav: [
      {
        title: "View All",
        path: "/admin/sliders/view",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Settings",
        path: "/admin/sliders/setting",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Galleries",
    path: "/admin/galleries",
    dashboardPath : "/admin/galleries",
    // icon: <BootstrapIcon.BsFillImageFill />,
    icon: <MDIcons.MdFilter />,
  },
  // {
  //     title: "Products",
  //     path: "/admin/products",
  //     icon: <FaIcons.FaProductHunt />,
  // },
  {
    title: "Our Teams",
    path: "/admin/teams",
    // icon: <BoxIcons.BiMessageCheck />,
    dashboardPath : "/admin/teams/view",
    icon: <MDIcons.MdOutlineGroup />,
    iconClosed: <MDIcons.MdExpandMore className="dark:text-white" />,
    iconOpened: <MDIcons.MdExpandLess className="dark:text-white" />,
    subNav: [
      {
        title: "View All",
        path: "/admin/teams/view",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Settings",
        path: "/admin/teams/setting",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Plan features",
    path: "/admin/planFeatures",
    dashboardPath : "/admin/planFeatures",
    icon: <CiIcons.CiMemoPad />,
  },
  {
    title: "Subscription Plans",
    path: "/admin/subscriptions",
    dashboardPath : "/admin/subscriptions",
    icon: <FiIcons.FiDollarSign />,
  },
  {
    title: "Contact Us",
    path: "/admin/contact",
    // icon: <RiIcons.RiContactsBookLine />,
    dashboardPath : "/admin/contact/view",
    icon: <MDIcons.MdOutlineContacts />,
    iconClosed: <MDIcons.MdExpandMore className="dark:text-white" />,
    iconOpened: <MDIcons.MdExpandLess className="dark:text-white" />,
    subNav: [
      {
        title: "View All",
        path: "/admin/contact/view",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Settings",
        path: "/admin/contact/setting",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Events",
    path: "/admin/events",
    dashboardPath : "/admin/events",
    // icon: <BoxIcons.BiCalendarStar />,
    icon: <MDIcons.MdOutlineEvent />,
  },
  {
    title: "Settings",
    path: "/admin/setting",
    dashboardPath : "/admin/setting",
    icon: <AiIcons.AiOutlineSetting />,
  },
  {
    title: "IP Tracker",
    path: "/admin/IpTracker",
    dashboardPath : "/admin/IpTracker",
    icon: <BoxIcons.BiCurrentLocation />,
  },

  // {
  //     title: "Chatbot",
  //     path: "/admin/chatbot",
  //     icon: <AiIcons.AiFillRobot />,
  // },
];
