/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import {
  RemoveLoginData,
  updateLastActive,
} from "./Redux/actions/LoginActions";
import { fetchEvents } from "./Redux/actions/eventsAction";
import { getSliderImages } from "./Redux/actions/fetchSliderImagesAction";
// import { RefreshInterceptor } from "react-axios-interceptor";

//import Auth from "./components/login/Auth";
import Dashboard from "./components/Dashboard/Dashboard";
import AddEditFaqs from "./components/FAQS/addEditFaqs";
import Faqs from "./components/FAQS/faqs";
import Footer from "./components/Footer/Footer";
import SliderEdit from "./components/HomePageSlider/SliderEdit.js";
import SliderSetting from "./components/HomePageSlider/SliderSetting";
import SliderView from "./components/HomePageSlider/SliderView";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import TeamSetting from "./components/Teams/TeamSetting";
import Teams from "./components/Teams/Teams";
import AddEditeTeams from "./components/Teams/addEditeTeams";
import AddTestimonials from "./components/Testimonials/AddTestimonials";
import TestimonialsView from "./components/Testimonials/TestimonialsView";
import AddBlog from "./components/blogs/AddBlog";
import BlogsSetting from "./components/blogs/BlogsSetting";
import BlogsViewAll from "./components/blogs/BlogsViewAll";
import BlogComments from "./components/blogs/Comment";
import EditComment from "./components/blogs/EditComment";
import AddCategories from "./components/categories/AddCategories";
import Categories from "./components/categories/Categories";
import AddChatBotQueAns from "./components/chatbot/AddChatBotQueAns";
import ViewChatbotQusAns from "./components/chatbot/ViewChatBotQusAns";
import ContactSetting from "./components/contact/contactSetting";
import ContactsView from "./components/contact/contactView";
import AddEvent from "./components/events/AddEvent";
import Event from "./components/events/Event";
import AddEditGallery from "./components/galleries/addEditGallery";
import Galleries from "./components/galleries/galleries";
import ForgetPassword from "./components/login/ForgetPassword";
import Login from "./components/login/Login";
import AddPage from "./components/pages/AddPage";
import Page from "./components/pages/Page";
import AddProducts from "./components/products/addProducts";
import ProductsDashboard from "./components/products/products";
import ChangePassword from "./components/profile/ChangePassword";
import Profile from "./components/profile/Profile";
import Setting from "./components/setting/Setting";
import MobileMenuDashboard from "./components/sidebar/MobileMenuDashboard";
import Navbar from "./components/sidebar/Navbar";
import SidebarComp from "./components/sidebar/Sidebar";
import Users from "./components/users/Users.js";
import CreateUser from "./components/users/createUser";

// front end single page application
import AddSiteConfig from "./components/SiteConfig/AddSiteConfig";
import SiteConfig from "./components/SiteConfig/SiteConfig";
import Home from "./components/frontend-spa/Home/Home";
import MobileIconMenu from "./components/frontend-spa/Navbar/mobileIcon";
import FrontSpaNavbar from "./components/frontend-spa/Navbar/navbar";
import FrontEndBlogListing from "./components/frontend-spa/blogs/FrontEndBlogListing";
import BlogDescription from "./components/frontend-spa/blogs/blogDescription";
import Cart from "./components/frontend-spa/cart/cart";
import ClientDashboard from "./components/frontend-spa/clientDashboard/clientDashboard";
import FrontendFaqs from "./components/frontend-spa/faqs/faqs";
import FrontSpaFooter from "./components/frontend-spa/footer/Footer";
import FrontEndSignUp from "./components/frontend-spa/frontend-login-signup/FrontEndSignUp";
import FrontendLogin from "./components/frontend-spa/frontend-login-signup/FrontendLogin";
import FrontendGalleries from "./components/frontend-spa/galleries/FrontendGalleries";
import GalleryDescription from "./components/frontend-spa/galleries/galleryDescription";
import DocumentView from "./components/frontend-spa/partnerDashboard/DocumentView";
import PartnerDashboard from "./components/frontend-spa/partnerDashboard/PartnerDashboard";
import RequestForDocs from "./components/frontend-spa/partnerDashboard/RequestForDocs";
import Products from "./components/frontend-spa/products/Products";
import ProductsDescription from "./components/frontend-spa/products/ProductsDescription";
import VerifyAccount from "./components/frontend-spa/userVerification/VerifyAccount";
//import SiteConAdd from "./components/SiteConfig/SiteConAdd";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { AiOutlineMessage } from "react-icons/ai";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import IpTracker from "./components/IpTracker/IpTracker";
import PlanFeatureAdd from "./components/PlanFeatures/PlanFeatureAdd";
import PlanFeatureView from "./components/PlanFeatures/PlanFeatureView";
import SubscriptionEdit from "./components/SubscriptionPlan/SubscriptionEdit";
import SubscriptionView from "./components/SubscriptionPlan/SubscriptionView";
import TestimonialSetting from "./components/Testimonials/TestimonialSetting";
import FrontDashboard from "./components/frontend-spa/Dashboard/FrontDashboard";
import PaymentFail from "./components/frontend-spa/StripePayment/PaymentFail.js";
import PaymentSuccess from "./components/frontend-spa/StripePayment/PaymentSuccess.js";
import PlanDetails from "./components/frontend-spa/StripePayment/PlanDetails.js";
import ActionProvider from "./components/frontend-spa/chatbot/ActionProvider";
import MessageParser from "./components/frontend-spa/chatbot/MessageParser";
import config from "./components/frontend-spa/chatbot/config";
import VerfiyEmail from "./components/frontend-spa/emailVerification";
import PagesDescription from "./components/frontend-spa/pages/PagesDescription";
import SurveyPage from "./components/frontend-spa/survey";
import { handleLogout } from "./components/login/handleLogOut";
import AdminRoute from "./route/AdminRoute";

// const PrivateRoute = ({ element: element, Sidebar, ...rest }) => {
//   // console.log("Auth.getAuth()", Auth.getAuth());
//   return (
//     <Route
//
//       {...rest}
//       render={(props) =>
//         Auth.getAuth() ? (
//           <element {...props} Sidebar={Sidebar} />
//         ) : (
//           <>
//             {console.log("signing out user")}
//             <Redirect
//               to={{
//                 pathname: "/admin",
//               }}
//             />
//           </>
//         )
//       }
//     />
//   );
// };

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};
// Make sure to call `loadStripe` outside of a element’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51MEnlwSEU6bXvMjgk5rhBA3KmKdrJLlUyTQ8CAd4t7cvIyAvGFJXU4jgYP1JpATiWY5BCqJkPtsvGCRu94yepzeb00rsPEQstq"
);

const Layout = ({ children }) => {
  const [showChatbot, setChatBot] = useState(false);
  return (
    <div className="w-full">
      <FrontSpaNavbar />
      <div className="w-full min-h-screen">{children}</div>
      <div id="chatbot-home" className="text-center fixed right-1 bottom-14 z-20 bg-gray-100 dark:bg-gray-600 flex items-center justify-center">
        {showChatbot === false && (
          <button
            className="bg-yellow-500 p-3 lg:p-4 border rounded text-white"
            onClick={() => {
              setChatBot(true);
            }}
          >
            <AiOutlineMessage />
          </button>
        )}

        {showChatbot === true && (
          <div className="border rounded  border-yellow-500 bg-white p-2">
            <div className="w-full flex items-center justify-end ">
              <button
                className="bg-yellow-500 p-3 border rounded text-black"
                onClick={() => {
                  setChatBot(false);
                }}
              >
                X
              </button>
            </div>
            <Chatbot
              config={config}
              actionProvider={ActionProvider}
              messageParser={MessageParser}
            />
          </div>
        )}
      </div>
      <FrontSpaFooter />
      <MobileIconMenu />
    </div>
  );
};

function App() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const viewImage = process.env.PUBLIC_URL + "/images/NoInternet.jpg";
  const timeout = 3000;
  const userIsLoggedIn = useSelector((state) => state.LoginReducer.isLoading);
  const userlastActive = useSelector((state) => state.LoginReducer.lastActive);
  // const userlastActive = useSelector(
  //   (state) => state.LoginReducer?.lastLoginAt
  // );
  // useSelector((state) =>
  //   state.LoginReducer?.lastLoginAt && state.LoginReducer?.lastLoginAt !== "-"
  //     ? state.LoginReducer?.lastLoginAt
  //     : state.LoginReducer.lastActive
  // );

  const [Sidebar, setSidebar] = useState(true);
  const [login, setLogin] = useState(false);
  // const [Frontlogin, setFrontLogin] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     console.log("useEffect hit..");
  //     const onSuccessfulFetchCallback = async (info) => {
  //       if (info) {
  //         console.log("info present..", info);
  //         localStorage.setItem("userData", JSON.stringify(info));
  //         await dispatch(StoreLoginData(info));
  //       } else {
  //         localStorage.removeItem("userData");
  //         await dispatch(RemoveLoginData());
  //       }
  //     };

  //     await getKeyInfoFromIDBStore({
  //       dbName: "loginStore",
  //       storeName: "loginStore",
  //       key: "userData",
  //       keyPath: "accessToken",
  //       onSuccessfulFetchCallback: onSuccessfulFetchCallback,
  //     });
  //   })();
  // }, []);

  const onPrompt = () => {
    // Fire a Modal Prompt
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    let result = null;
    // Do something when a user triggers a watched event

    //convert current time to timestamp
    let date1 = new Date(moment());
    let timeNowTimestamp = date1.getTime();

    if (userlastActive && userlastActive !== null) {
      //convert last active time to timestamp
      const [dateRelated, timeRelated] = userlastActive?.split(" ");
      const [day, month, year] = dateRelated?.split("-");
      const [hours, minutes, seconds] = timeRelated?.split(":");
      const date2 = new Date(
        +year,
        month - 1,
        +day,
        +hours,
        +minutes,
        +seconds
      );
      let userLastActiveTimestamp = date2.getTime();
      var diff = (timeNowTimestamp - userLastActiveTimestamp) / (1000 * 60);
      let minDiff = diff % 60;
      result = Math.abs(Math.round(minDiff));
      // console.log("result", result, userIsLoggedIn);
    }

    if (userlastActive !== null) {
      if (result == null || result == NaN || result <= 30) {
        dispatch(updateLastActive(moment().format("DD-MM-yyyy hh:mm:ss")));
      } else if (result > 30) {
        // console.log("loggingout");
        // commented handleLogout(navigate) as causing issue with refresh token, implemented refreshInterceptor 13-07-2023
        handleLogout(navigate);
      }
    }
  };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 20,
    promptTimeout: 0,
    events: [
      "keydown",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      // "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const showSidebar = () => {
    setSidebar(!Sidebar);
  };

  const hideSidebarFromMenu = (sidebar) => {
    setSidebar(!Sidebar);
  };

  useEffect(() => {
    // console.log("window.location.pathname", window.location.pathname);

    let userCred = JSON.parse(localStorage.getItem("user"));
    let frontUserCred = JSON.parse(localStorage.getItem("userData"));
    const pathname = window.location.pathname;
    if (
      (pathname === "/admin" ||
        pathname === "/" ||
        pathname === "/admin/login" ||
        pathname === "/galleries" ||
        pathname === "/blogs" ||
        pathname === "/faqs" ||
        pathname.startsWith("/galleries/view/") ||
        pathname.startsWith("/pages")) &&
      !frontUserCred
    ) {
      setLogin(false);
      // setFrontLogin(false);
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
      dispatch(RemoveLoginData());
    }
    if (userCred !== undefined && userCred !== null && userCred !== "") {
      setLogin(true);
    }
    // if (
    //   frontUserCred !== undefined &&
    //   frontUserCred !== null &&
    //   frontUserCred !== ""
    // ) {
    //   setFrontLogin(true);
    // }

    dispatch(getSliderImages());
    dispatch(fetchEvents());
  }, [userIsLoggedIn]);
  const isAdmin =
    window.location.pathname.startsWith("/admin") ||
    window.location.pathname == "/profile";
  return (
    <>
      {login && <Navbar showSidebar={showSidebar} setLogin={setLogin} />}
      {/* {Frontlogin && (
        <Navbar showSidebar={showSidebar} setFrontLogin={setFrontLogin} />
      )} */}
      <div className="display-none h-0 w-0">
        <img src={viewImage} alt="No Internet connection. Please try later!" />
      </div>
      <div className="flex box-border grow">
        {login && (
          <SidebarComp
            hideSidebarFromMenu={hideSidebarFromMenu}
            Sidebar={Sidebar}
          />
        )}
        <div
          className={`bg-bgtheme dark:bg-bgdarkblack overflow-y-auto scrollbar-hide transform duration-500 transition-all transition-duration:200ms delay-100 w-full ms-0 ${
            login
              ? isAdmin && Sidebar
                ? "sm:ms-56"
                : "sm:ms-12"
              : "flex items-center"
          } `}
        >
          <Routes>
            {/*  <Route path="/admin" element={<Login setLogin={setLogin} />} /> */}
            <Route
              path="/admin"
              element={
                <AdminRoute
                  setLogin={setLogin}
                  path="/admin"
                  setSidebar={setSidebar}
                >
                  <Login />
                </AdminRoute>
              }
            />
            <Route
              path="/galleries/view/:id"
              element={
                // !login && (
                <Layout>
                  <GalleryDescription />
                </Layout>
                // )
              }
            />
            <Route
              path="/pages/:id"
              element={
                // !login && (
                <Layout>
                  <PagesDescription />
                </Layout>
                // )
              }
            />
            <Route
              path="/faqs"
              element={
                // !login && (
                <Layout>
                  <FrontendFaqs />
                </Layout>
                // )
              }
            />
            <Route
              path="/blog/:id"
              element={
                // !login && (
                <Layout>
                  <BlogDescription />
                </Layout>
                // )
              }
            />
            <Route
              path="/blogs"
              element={
                // !login && (
                <Layout>
                  <FrontEndBlogListing />
                </Layout>
                // )
              }
            />
            <Route
              path="/galleries"
              element={
                // !login && (
                <Layout>
                  <FrontendGalleries />
                </Layout>
                // )
              }
            />
            <Route
              path="/"
              element={
                // !login && (
                <Layout>
                  <Home />
                </Layout>
                // )
              }
            />
            <Route path="/bakend/forgetPassword" element={<ForgetPassword />} />
            <Route
              path="/front/dashboard"
              element={
                <Layout>
                  <FrontDashboard />
                </Layout>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <AdminRoute
                  setLogin={setLogin}
                  path="/admin/dashboard"
                  setSidebar={setSidebar}
                >
                  <Dashboard Sidebar={Sidebar} />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <AdminRoute
                  setLogin={setLogin}
                  path="/admin/users"
                  setSidebar={setSidebar}
                >
                  <Users Sidebar={Sidebar} />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/users/create"
              element={
                <AdminRoute
                  setLogin={setLogin}
                  path="/admin/users/create"
                  setSidebar={setSidebar}
                >
                  <CreateUser Sidebar={Sidebar} />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/users/create/:id"
              element={<CreateUser Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/pages/addPage"
              element={<AddPage Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/pages/addPage/:id"
              element={<AddPage Sidebar={Sidebar} />}
            />
            <Route path="/admin/pages" element={<Page Sidebar={Sidebar} />} />
            <Route
              path="/admin/categories"
              element={<Categories Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/categories/addCategory"
              element={<AddCategories Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/categories/addCategory/:id"
              element={<AddCategories Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/testimonials/view"
              element={<TestimonialsView Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/testimonials/view/add/:id"
              element={<AddTestimonials Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/testimonials/view/add"
              element={<AddTestimonials Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/testimonials/setting"
              element={<TestimonialSetting Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/blog/view"
              element={<BlogsViewAll Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/blog/view/addBlog"
              element={<AddBlog Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/blog/view/editBlog/:id"
              element={<AddBlog Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/blog/view/comment/:id"
              element={<BlogComments Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/blog/view/editComment/:id"
              element={<EditComment Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/blog/setting"
              element={<BlogsSetting Sidebar={Sidebar} />}
            />
            <Route path="/admin/faqs" element={<Faqs Sidebar={Sidebar} />} />
            <Route
              path="/admin/faqs/addFaq"
              element={<AddEditFaqs Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/faqs/edit/:id"
              element={<AddEditFaqs Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/setting"
              element={<Setting Sidebar={Sidebar} />}
            />
            {/* <Route path="/setting" element={<Setting Sidebar={Sidebar} />} /> */}
            <Route
              path="/admin/galleries/addGallery"
              element={<AddEditGallery Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/galleries/editGallery/:id"
              element={<AddEditGallery Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/galleries"
              element={<Galleries Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/products"
              element={<ProductsDashboard Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/products/addProducts"
              element={<AddProducts Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/products/editProduct/:id/:category"
              element={<AddProducts Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/products/editProduct/:id/:category/:subcategory"
              element={<AddProducts Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/contact/view"
              element={<ContactsView Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/contact/setting"
              element={<ContactSetting Sidebar={Sidebar} />}
            />
            <Route path="/admin/events" element={<Event Sidebar={Sidebar} />} />
            <Route
              path="/admin/events/addEvent"
              element={<AddEvent Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/events/editEvent/:id"
              element={<AddEvent Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/sliders/setting"
              element={<SliderSetting Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/sliders/view/edit"
              element={<SliderEdit Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/sliders/view"
              element={<SliderView Sidebar={Sidebar} />}
            />
            <Route path="/profile" element={<Profile Sidebar={Sidebar} />} />
            <Route
              path="/admin/subscriptions"
              element={<SubscriptionView Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/subscriptions/edit/:id"
              element={<SubscriptionEdit Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/planFeatures"
              element={<PlanFeatureView Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/planFeatures/add"
              element={<PlanFeatureAdd Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/planFeatures/edit/:id"
              element={<PlanFeatureAdd Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/changePassword"
              element={<ChangePassword Sidebar={Sidebar} />}
            />
            {/* <Route
                path="/changePassword"
                element={<ChangePassword Sidebar={Sidebar} />}
              /> */}
            <Route
              path="/admin/chatbot"
              element={<ViewChatbotQusAns Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/chatbot/addQuestion"
              element={<AddChatBotQueAns Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/chatbot/editQuestion/:id"
              element={<AddChatBotQueAns Sidebar={Sidebar} />}
            />
            <Route path="/admin/teams/view" element={<Teams Sidebar={Sidebar} />} />
            <Route
              path="/admin/teams/view/addTeam"
              element={<AddEditeTeams Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/teams/setting"
              element={<TeamSetting Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/teams/view/addTeam/:id"
              element={<AddEditeTeams Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/siteconfigs/addSiteConfig/:id"
              element={<AddSiteConfig Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/siteconfigs/addSiteConfig"
              element={<AddSiteConfig Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/siteconfigs"
              element={<SiteConfig Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/IpTracker"
              element={<IpTracker Sidebar={Sidebar} />}
            />
            <Route
              path="/admin/*"
              element={<PageNotFound Sidebar={Sidebar} />}
            />
            {/* FONT END SINGLE PAGE APPLICATION */}
            <Route
              path="/products"
              element={
                <Layout>
                  <Products />
                </Layout>
              }
            />
            <Route
              path="/product/:id/:category/:subCategory"
              element={
                <Layout>
                  <ProductsDescription />
                </Layout>
              }
            />
            <Route
              path="/faqs"
              element={
                <Layout>
                  <FrontendFaqs />
                </Layout>
              }
            />
            <Route
              path="/cart"
              element={
                <Layout>
                  <Cart />
                </Layout>
              }
            />
            {/* <Route
                path="/checkout"
                element={
                  <Layout>
                    <Checkout />
                  </Layout>
                }
              />
              <Route
                path="/payment"
                element={
                  <Layout>
                    <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                      <Payment />
                    </Elements>
                  </Layout>
                }
              /> */}

            <Route
              path="/paymentSuccess/sessionId/:id"
              element={
                <Layout>
                  <PaymentSuccess />
                </Layout>
              }
            />

            <Route
              path="/paymentFailure"
              element={
                <Layout>
                  <PaymentFail />
                </Layout>
              }
            />

            <Route
              path="/subscriptionPlans"
              element={
                <Layout>
                  <PlanDetails />
                </Layout>
              }
            />

            <Route
              path="/frontendLogin"
              element={
                <Layout>
                  <FrontendLogin />
                </Layout>
              }
            />
            <Route
              path="/survey"
              element={
                <Layout>
                  <SurveyPage />
                </Layout>
              }
            />
            <Route
              path="/frontEndSignUp"
              element={
                <Layout>
                  <FrontEndSignUp />
                </Layout>
              }
            />
            <Route
              path="/clientDashboard"
              element={
                <Layout>
                  <ClientDashboard />
                </Layout>
              }
            />
            <Route
              path="/partnerDashboard"
              element={
                <Layout>
                  <PartnerDashboard />
                </Layout>
              }
            />
            <Route
              path="/requestForDocs"
              element={
                <Layout>
                  <RequestForDocs />
                </Layout>
              }
            />
            <Route
              path="/documentView/:id/:name"
              element={
                <Layout>
                  <DocumentView />
                </Layout>
              }
            />
            <Route
              path="/setPassword/token/:token"
              element={<VerifyAccount setLogin={setLogin} />}
            />
            <Route
              path="/resetPassword/token/:token"
              element={<VerifyAccount setLogin={setLogin} />}
            />
            <Route path="/verfiyEmail/token/:id" element={<VerfiyEmail />} />
          </Routes>
        </div>
      </div>
      <ToastContainer />
      {login && <Footer />}
      {login && (
        <MobileMenuDashboard hideSidebarFromMenu={hideSidebarFromMenu} />
      )}
    </>
  );
}

export default App;
