export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";

export const FETCH_SLIDER_IMAGE_REQUEST = "FETCH_SLIDER_IMAGE_REQUEST";
export const FETCH_SLIDER_IMAGE_SUCCESS = "FETCH_SLIDER_IMAGE_SUCCESS";
export const FETCH_SLIDER_IMAGE_FAIL = "FETCH_SLIDER_IMAGE_FAIL";
export const CHANGE_ALTERNATIVE_TEXT = "CHANGE_ALTERNATIVE_TEXT";
export const DELETE_SLIDER_IMAGE = "DELETE_SLIDER_IMAGE";
export const DELETE_ALL_IMAGES = "DELETE_ALL_IMAGES";

export const FETCH_EVENT_REQUEST = "FETCH_EVENT_REQUEST";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_FAIL = "FETCH_EVENT_FAIL";
export const DELETE_EVENTS = "DELETE_ALL_EVENTS";
export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const ACTIVE_EVENT = "ACTIVE_EVENT";
export const INACTIVE_EVENT = "INACTIVE_EVENT";

// categories
export const FETCH_CATEGORY_REQUEST = "FETCH_CATEGORY_REQUEST";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAIL = "FETCH_CATEGORY_FAIL";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ACTIVE_CATEGORY = "ACTIVE_CATEGORY";
export const INACTIVE_CATEGORY = "INACTIVE_CATEGORY";

// blogs
export const FETCH_BLOGS_REQUEST = "FETCH_BLOGS_REQUEST";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOGS_FAIL = "FETCH_BLOGS_FAIL";
export const DELETE_BLOGS = "DELETE_BLOGS";
export const ACTIVE_BLOGS = "ACTIVE_BLOGS";
export const INACTIVE_BLOGS = "INACTIVE_BLOGS";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CART_PRODUCT_COUNT_INCREASE = "CART_PRODUCT_COUNT_INCREASE";
export const CART_PRODUCT_COUNT_DECREASE = "CART_PRODUCT_COUNT_DECREASE";
export const EMPTY_CART = "EMPTY_CART";

//Login
export const STORE_LOGIN_DATA = "STORE_LOGIN_DATA";
export const REMOVE_LOGIN_DATA = "REMOVE_LOGIN_DATA";
export const UPDATE_LOGIN_DATA = "UPDATE_LOGIN_DATA";
export const UPDATE_LAST_ACTIVE = "UPDATE_LAST_ACTIVE";

//user signup
export const STORE_SIGNUP_DETAILS = "STORE_SIGNUP_DETAILS";
export const REMOVE_SIGNUP_DETAILS = "REMOVE_SIGNUP_DETAILS";
export const STORE_SUBSCRIPTION_PLAN = "STORE_SUBSCRIPTION_PLAN";

//Company
export const FOOTER_MESSAGE = "FOOTER_MESSAGE";
