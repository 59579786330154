import React, { useState } from "react";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

const EventsTable = ({
  events,
  eventsPerPage,
  totalEvent,
  paginate,
  currentPage,
  setNumberOfEvent,
  selectAll,
  selectSingle,
  sortTitle,
  sortByTitle,
  setSortByTitle,
  StartDateSort,
  sortStartDate,
  setSortStartDate,
  EndDateSort,
  sortEndDate,
  setSortEndDate,
  SortTime,
  sortByTime,
  setSortByTime,
  sortRecurrence,
  sortByRecurrence,
  setSortByRecurrence,
  sortModified,
  sortByModified,
  setSortByModified,
  selectAllCheckbox,
  setSelectAllCheckbox,
  handleActive,
  handleInActive,
  allEvents,
  setEvents,
}) => {
  const pageNumbers = [];
  let navigate = useNavigate();
  for (let i = 1; i <= Math.ceil(totalEvent / eventsPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortTitleArrows, setSortTitleArrows] = useState(false);
  let [sortStartDateArrow, setSortStartDateArrow] = useState(true);
  let [sortEndDateArrow, setSortEndDateArrow] = useState(true);
  let [sortTimeArrow, setSortTimeArrow] = useState(true);
  let [sortRecurrenceArrow, setSortRecurrenceArrow] = useState(true);
  let [sortModifiedOnArrow, setModifiedOnArrow] = useState(true);

  const selectRecord = (record) => {
    let data = allEvents.map((item) => {
      if (item.id === record.id) {
        item.select = true;
      }
      return item;
    });
    setEvents(data);
    if (record.status) {
      handleInActive();
    } else {
      handleActive();
    }
  };

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark">
      <table className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b">
        <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
          <tr>
            <th scope="col" className="border-l-6 bg-gray-400"></th>

            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <input
                checked={selectAllCheckbox}
                onChange={(e) => {
                  setSelectAllCheckbox(!selectAllCheckbox);
                  selectAll(e);
                }}
                type="checkbox"
                className="cursor-pointer"
              />
            </th>
            <th
              onClick={() => {
                sortTitle(sortByTitle);
                setSortByTitle(!sortByTitle);
                setSortTitleArrows(false);
                setSortStartDateArrow(true);
                setSortEndDateArrow(true);
                setSortTimeArrow(true);
                setSortRecurrenceArrow(true);
                setModifiedOnArrow(true);
              }}
              scope="col"
              className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2">Title</span>
              {sortTitleArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortTitleArrows && (
                <>
                  {sortByTitle === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                StartDateSort(sortStartDate);
                setSortStartDate(!sortStartDate);
                setSortTitleArrows(true);
                setSortStartDateArrow(false);
                setSortEndDateArrow(true);
                setSortTimeArrow(true);
                setSortRecurrenceArrow(true);
                setModifiedOnArrow(true);
              }}
              scope="col"
              className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Start Date
              {sortStartDateArrow && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortStartDateArrow && (
                <>
                  {sortStartDate === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                EndDateSort(sortEndDate);
                setSortEndDate(!sortEndDate);
                setSortTitleArrows(true);
                setSortStartDateArrow(true);
                setSortEndDateArrow(false);
                setSortTimeArrow(true);
                setSortRecurrenceArrow(true);
                setModifiedOnArrow(true);
              }}
              scope="col"
              className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase whitespace-nowrap "
            >
              End Date{" "}
              {sortEndDateArrow && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortEndDateArrow && (
                <>
                  {sortEndDate === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}{" "}
                </>
              )}
            </th>
            <th
              onClick={() => {
                SortTime(sortByTime);
                setSortByTime(!sortByTime);
                setSortTitleArrows(true);
                setSortStartDateArrow(true);
                setSortEndDateArrow(true);
                setSortTimeArrow(false);
                setSortRecurrenceArrow(true);
                setModifiedOnArrow(true);
              }}
              scope="col"
              className=" px-6 py-3 text-left text-xs cursor-pointer font-medium uppercase whitespace-nowrap"
            >
              Event Time{" "}
              {sortTimeArrow && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortTimeArrow && (
                <>
                  {sortByTime === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}{" "}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortRecurrence(sortByRecurrence);
                setSortByRecurrence(!sortByRecurrence);
                setSortTitleArrows(true);
                setSortStartDateArrow(true);
                setSortEndDateArrow(true);
                setSortTimeArrow(true);
                setSortRecurrenceArrow(false);
                setModifiedOnArrow(true);
              }}
              scope="col"
              className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Recurrence{" "}
              {sortRecurrenceArrow && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortRecurrenceArrow && (
                <>
                  {sortByRecurrence === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortModified(sortByModified);
                setSortByModified(!sortByModified);
                setSortTitleArrows(true);
                setSortStartDateArrow(true);
                setSortEndDateArrow(true);
                setSortTimeArrow(true);
                setSortRecurrenceArrow(true);
                setModifiedOnArrow(false);
              }}
              scope="col"
              className="cursor-pointer  px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Modification On{" "}
              {sortModifiedOnArrow && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortModifiedOnArrow && (
                <>
                  {sortByModified === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              {/* <FiEdit2 size={17} /> */}Edit
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-darkblack">
          {events &&
            events.map((event) => (
              <tr
                key={event.id}
                className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
              >
                <td
                  className={`${
                    event.status
                      ? "border-left-green-6 bg-green-500 cursor-pointer"
                      : "border-left-red-6 bg-red-500 cursor-pointer"
                  }`}
                  onClick={(e) => {
                    selectRecord(event);
                  }}
                ></td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-black dark:text-textinblack ">
                  <input
                    checked={event.select}
                    onChange={(e) => selectSingle(e, event.id)}
                    type="checkbox"
                    className="cursor-pointer"
                  />
                </td>
                <td className="px-6 py-4 cursor-pointer  text-sm capitalize text-black dark:text-textinblack">
                  <span
                    onClick={() => {
                      navigate("/admin/events/editEvent/" + event.id);
                    }}
                    className="hover:underline pb-2 inline-block hover:text-blue-500 hover:font-semibold"
                  >
                    {event.title}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                  {event.startDate}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                  {event.endDate}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                  {event.eventTime}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm capitalize  text-black dark:text-textinblack">
                  {event.recurrence}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                  {event.modifiedOn}
                </td>
                <td className="flex py-4 place-content-center">
                  <h1
                    onClick={() => {
                      navigate("/admin/events/editEvent/" + event.id);
                    }}
                    className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                  >
                    <FiEdit2 size={17} />
                  </h1>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <input
            className="ml-3"
            checked={selectAllCheckbox}
            onChange={(e) => {
              setSelectAllCheckbox(!selectAllCheckbox);
              selectAll(e);
            }}
            type="checkbox"
          />
          <label
            onClick={() => {
              sortTitle(sortByTitle);
              setSortByTitle(!sortByTitle);
              setSortTitleArrows(false);
              setSortStartDateArrow(true);
              setSortEndDateArrow(true);
              setSortTimeArrow(true);
              setSortRecurrenceArrow(true);
              setModifiedOnArrow(true);
            }}
            className="px-5 py-3 text-sm font-medium leading-normal"
          >
            Events{" "}
            {!sortTitleArrows && (
              <>
                {sortByTitle === true ? (
                  <RiArrowUpFill className="inline-block ml-2" />
                ) : (
                  <RiArrowDownFill className="inline-block ml-2" />
                )}
              </>
            )}
          </label>
        </div>
        {events &&
          events.map((event) => (
            <div
              key={event.id}
              className={`tab w-full border-t ${
                event.status ? "border-left-green-8 " : "border-left-red-8"
              }`}
            >
              <input
                className="absolute opacity-0"
                id={event.id}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                for={event.id}
              >
                <input
                  checked={event.select}
                  onChange={(e) => selectSingle(e, event.id)}
                  type="checkbox"
                />
                <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">{event.title}</span>
              </label>
              <div className="tab-content border-t overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">Title:</h1>
                    <h1 className="text-white text-left pl-2">
                      {event.title}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">
                      Start date:
                    </h1>
                    <h1 className="text-white text-left pl-2">
                      {event.startDate}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">End date:</h1>
                    <h1 className="text-white text-left pl-2">
                      {event.endDate}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">
                      Event time:
                    </h1>
                    <h1 className="text-white text-left pl-2">
                      {event.eventTime}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">
                      Event time:
                    </h1>
                    <h1 className="text-white text-left pl-2">
                      {event.eventTime}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">
                      Recurrence:
                    </h1>
                    <h1 className="text-white text-left pl-2">
                      {event.recurrence}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">
                      Modified on:
                    </h1>
                    <h1 className="text-white text-left pl-2">
                      {event.modifiedOn}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-white font-semibold">Edit:</h1>
                    <h1 className="text-white pl-2">
                      <h1
                        onClick={() => {
                          navigate("/admin/events/editEvent/" + event.id);
                        }}
                        className="cursor-pointer underlinetext-bluecolor hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        <FiEdit2 size={17} />
                      </h1>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* pagination */}
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
      <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
              } liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 text-black dark:text-textinblack">Show</span>
          <input
            type="number"
            min={1}
            value={eventsPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfEvent(e.target.value)}
          />
          <span className="pl-2 text-black dark:text-textinblack">Entries</span>
        </div>
      </div>
    </div>
  );
};
export default EventsTable;
