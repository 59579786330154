import React from "react";

const FrontDashboard = () => {
  return (
    <div className="w-full flex items-center justify-center dark:bg-gray-600 mt-20 mb-12">
      Front end user dashboard
    </div>
  );
};

export default FrontDashboard;
