import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaFileDownload } from "react-icons/fa";
import { notify } from "../../../utility";
import { saveAs } from "file-saver";

const DocumentView = () => {
    const params = useParams();
    const id = params.id;
    const name = params.name;
    const [docList, setDocList] = useState([]);
    const [docPopUp, setDocPopUp] = useState(false);
    const [docLink, setDocLink] = useState("");
    const [note, setNote] = useState("");

    const fetchSelectClientDocs = (id) => {
        const bodyParameters = {
            id: id,
        };
        axios
            .post(
                "http://localhost:5000/doc/getSelectAgentDocReq",
                bodyParameters
            )
            .then((res) => {
                // console.log("res.data.docList", res.data.docList);
                setDocList(res.data.docList);
            })
            .catch((err) => console.log(err));
    };

    const downloadImage = (url) => {
        saveAs(url, "image.jpg"); // Put your image url here.
    };

    const handleReviewChange = (review, id) => {
        const data = {
            id: id,
            review: review,
        };
        const config = {
            method: "put",
            url: "http://localhost:5000/doc/setReview",
            data: data,
        };
        axios(config)
            .then((response) => {
                notify(response.data.message);
            })
            .catch((err) => console.log(err));
    };

    const handleSendNote = (id) => {
        const data = {
            id: id,
            note: note,
        };
        const config = {
            method: "put",
            url: "http://localhost:5000/doc/setNote",
            data: data,
        };
        axios(config)
            .then((response) => {
                notify(response.data.message);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (id) {
            fetchSelectClientDocs(id);
        }
    }, [id]);

    return (
        <div className="w-full mt-14 px-2 sm:px-4 md:px-6 lg:px-10 min-h-screen xl:px-10  pt-8 mb-14 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 pb-10 gap-4 dark:bg-gray-700">
            <div className="flex flex-col">
                {docList.length > 0 && (
                    <h1 className="text-xs lg:text-lg xl:text-lg font-semibold capitalize dark:text-white">
                        Client Name :
                        <span className="text-yellow-500">{name}</span>
                    </h1>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                    {docList &&
                        docList.map((doc) => (
                            <div
                                key={doc.id}
                                className="border shadow-sm mt-3 p-2 rounded bg-gray-200 dark:bg-white"
                            >
                                <h1 className="mt-2 text-xs lg:text-sm xl:text-sm font-semibold capitalize ">
                                    Document Name :
                                    <span className="text-yellow-500">
                                        {doc.documenttype}
                                    </span>
                                </h1>
                                <div className="flex my-4 justify-between items-center">
                                    <h1 className="text-xs lg:text-sm xl:text-sm font-semibold capitalize ">
                                        Status :
                                        <span className="text-yellow-500">
                                            {doc.status === 0
                                                ? "Not uploaded"
                                                : "Uploaded"}
                                        </span>
                                    </h1>
                                    {doc.status === 1 && (
                                        <FaFileDownload
                                            className="text-yellow-500 text-2xl cursor-pointer"
                                            onClick={() => {
                                                downloadImage(doc.documentname);
                                            }}
                                        />
                                    )}
                                </div>
                                {doc.status === 0 ? (
                                    <div className="mt-2 w-full overflow-hidden h-40 flex items-center justify-center">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/image.png"
                                            }
                                            alt="dd"
                                            className="w-full h-full object-contain"
                                        />
                                    </div>
                                ) : (
                                    <div className="mt-2 w-full overflow-hidden h-40 flex items-center justify-center">
                                        <img
                                            src={doc.documentname}
                                            alt="ff"
                                            onClick={() => {
                                                setDocPopUp(true);
                                                setDocLink(doc.documentname);
                                            }}
                                            className="w-full h-full object-contain cursor-pointer"
                                        />
                                    </div>
                                )}
                                {doc.status === 1 && (
                                    <div className="my-3 w-full overflow-hidden flex items-center justify-between">
                                        <label className="text-xs w-3/12 lg:text-sm xl:text-sm font-semibold capitalize ">
                                            Review
                                        </label>
                                        <select
                                            onChange={(e) => {
                                                handleReviewChange(
                                                    e.target.value,
                                                    doc.id
                                                );
                                            }}
                                            className="border w-9/12 rounded px-4 py-2 text-xs lg:text-sm xl:text-sm font-semibold capitalize"
                                        >
                                            <option>Select</option>
                                            <option value="done">Done</option>
                                            <option value="wrong">wrong</option>
                                        </select>
                                    </div>
                                )}
                                {doc.status === 1 && (
                                    <div className="my-3 w-full  overflow-hidden flex items-center justify-between">
                                        <label className="text-xs w-3/12 lg:text-sm xl:text-sm font-semibold capitalize ">
                                            Note
                                        </label>
                                        <textarea
                                            onChange={(e) => {
                                                setNote(e.target.value);
                                            }}
                                            className="w-9/12 p-2 max-h-40 rounded"
                                        ></textarea>
                                    </div>
                                )}
                                {doc.status === 1 && (
                                    <div className="my-3 w-full  overflow-hidden flex items-center justify-end">
                                        <button
                                            onClick={() => {
                                                handleSendNote(doc.id);
                                            }}
                                            className="btn bg-yellow-500"
                                        >
                                            Send note
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
            {docPopUp && (
                <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
                    <div className="w-1/2 h-1/2  bg-white rounded  flex flex-col mt-10">
                        <div className="w-full h-10 bg-white flex item-center justify-end">
                            <h1
                                onClick={() => {
                                    setDocPopUp(false);
                                    setDocLink("");
                                }}
                                className="w-10 h-10 flex items-center justify-center cursor-pointer bg-black border-2 text-white"
                            >
                                x
                            </h1>
                        </div>
                        <img
                            src={docLink}
                            alt="dd"
                            className="border-2 w-full h-full bg-white object-contain"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DocumentView;
