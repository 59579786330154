import moment from "moment";
import { FOOTER_MESSAGE } from "./../actionTypes";

export const StoreFooterData = (payload) => {
  let data = {
    message: payload,
  };
  return {
    type: FOOTER_MESSAGE,
    payload: data,
  };
};
