import React, { useState, useEffect, useCallback } from "react";
import PageHeading from "../pages/PageHeading";
import CommentTable from "./CommentTable";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { notify, NoRecord } from "../../utility";
import moment from "moment";
import { API } from "../../services/API";
import { handleLogout } from "../login/handleLogOut";
import { RefreshToken } from "./../../services/RefreshToken";
import Loader from "react-js-loader";

const BlogComments = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [comments, setComment] = useState([]);
  // const [historyComments, setHistoryComments] = useState([]);
  const [AllComments, setAllComments] = useState([]);
  const [search, setSearch] = useState("");
  const [pageSearch, setPageSearch] = useState(true);
  const [activeInactive, setActiveInactive] = useState(false);
  let [status, setStatus] = useState();
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [currentPage, setCurrentPage] = useState(1);
  let [commentPerPage, setCommentPerPage] = useState(5);

  let [sortByComment, setSortByComment] = useState(true);
  let [sortByName, setSortByName] = useState(false);
  let [createdBy, setCreatedBy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  let id = params.id;

  const fetchComments = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/blogs/comments/blog/" + id + "/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log("comments response", response.data.blogsComments);
        let comments = response.data.blogsComments;
        getperPageData(comments);
        let data = comments.map((comment) => {
          return {
            select: false,
            blogCommentId: comment.blogCommentId,
            blogId: comment.blogId,
            comment: comment.comments,
            name: comment.createdBy,
            createdOn: comment.createdAt
              ? moment(comment.createdAt).format("DD-MM-YYYY hh:mm a")
              : null,
            status: comment.status,
          };
        });
        setComment(data);
        setAllComments(data);
        setLoading(false);
      })
      .catch(async (error) => {
        console.log("error in getcomment", error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
      });
  }, [id, access_token, navigate]);

  useEffect(() => {
    if (access_token) {
      fetchComments();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const getperPageData = (commentsData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        setCommentPerPage(
          commentsData.length < parseInt(data.val)
            ? commentsData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const indexOfLastComment = currentPage * commentPerPage;
  const indexOfFirstComment = indexOfLastComment - commentPerPage;
  const currentComments = comments.slice(
    indexOfFirstComment,
    indexOfLastComment
  );

  const paginate = (number) => {
    setCurrentPage(number);
  };
  const setNumberOfEvent = (number) => {
    setCommentPerPage(parseInt(number));
  };
  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setComment(
      comments.map((d) => {
        d.select = checked;
        return d;
      })
    );

    // console.log(comments);
  };
  const selectSingle = (e, blogCommentId) => {
    let checked = e.target.checked;
    setComment(
      comments.map((comment) => {
        if (blogCommentId === comment.blogCommentId) {
          comment.select = checked;
          // console.log(comment);
        }

        return comment;
      })
    );

    // console.log(comments);

    const result = comments.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      comments.forEach((d) => {
        if (d.select) {
          arrayId.push(d.blogCommentId);
        }
      });
      let config = {
        method: "delete",
        url: API + "/blogs/comments/",
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          // console.log("response", response);
          notify(response.data.message);
          setActiveInactive(!activeInactive);
          setPageSearch(!pageSearch);
          setSelectAllCheckbox(false);
          fetchComments();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };
  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let activeID = [];
      comments.forEach((active) => {
        if (active.select) {
          activeID.push(active.blogCommentId);
        }
      });

      let config = {
        method: "put",
        url: API + "/blogs/comments/status/" + true,
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      // console.log("active config", config);

      axios(config)
        .then((response) => {
          notify("Comments activated successfully!");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchComments();
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };
  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let activeID = [];
      comments.forEach((active) => {
        if (active.select) {
          activeID.push(active.blogCommentId);
        }
      });

      let config = {
        method: "put",
        url: API + "/blogs/comments/status/" + false,
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      // console.log("inactive config", config);

      axios(config)
        .then((response) => {
          notify("Comments inactivated successfully!");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchComments();
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };
  const handleSearch = () => {
    // console.log("comments", comments);
    let searchData = comments.filter((d) => {
      if (
        d.comment.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        d.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ) {
        // console.log(d);
        return d;
      }
      return null;
    });
    // console.log("searchData", searchData);
    if (searchData.length === 0) {
      setComment([]);
    } else {
      setComment(searchData);
    }
  };
  const handleReset = () => {
    setSearch("");
    // fetchComments();
    setComment(AllComments);
  };
  const handleClose = () => {
    setSearch("");
    // fetchComments();
    setComment(AllComments);
  };
  const sortComment = (sortByComment) => {
    const sorted = comments.sort((a, b) => {
      const isReverse = sortByComment === true ? 1 : -1;
      return isReverse * a.comment.localeCompare(b.comment);
    });
    setComment(sorted);
  };
  const sortName = (sortByName) => {
    const sorted = comments.sort((a, b) => {
      // console.log("name", a.name);
      const isReverse = sortByName === true ? 1 : -1;
      return isReverse * a.name.localeCompare(b.name);
    });
    setComment(sorted);
  };
  const sortCreated = (createdBy) => {
    const sorted = comments.sort((a, b) => {
      let dateA = new Date(a.createdOn);
      let dateB = new Date(b.createdOn);
      return createdBy ? dateA - dateB : dateB - dateA;
    });
    setComment(sorted);
  };

  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Comments"}
          searchLabel={"Name"}
          pageSearch={pageSearch}
          backBtn={true}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          setStatus={setStatus}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          addBtn={false}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentComments.length === 0 ? (
          <NoRecord />
        ) : (
          <CommentTable
            comments={currentComments}
            commentPerPage={commentPerPage}
            totalComments={comments.length}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfEvent={setNumberOfEvent}
            selectAll={selectAll}
            selectSingle={selectSingle}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            sortComment={sortComment}
            sortByComment={sortByComment}
            setSortByComment={setSortByComment}
            sortName={sortName}
            sortByName={sortByName}
            setSortByName={setSortByName}
            sortCreated={sortCreated}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
          />
        )}
      </div>
    </div>
  );
};

export default BlogComments;
