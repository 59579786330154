import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../services/API";
import { AiFillLinkedin } from "react-icons/ai";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";

const FrontSpaFooter = () => {
  const [FooterMessage, setFooterMessage] = useState(
    "Thank you for contacting us!"
  );
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [LinkedIn, setLinkedIn] = useState("");

  let date = new Date();
  useEffect(() => {
    getFooterData();
    getSocialLinksData();
  }, []);

  const getFooterData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/copyright_text",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setFooterMessage(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getSocialLinksData = () => {
    let facebookconfig = {
      method: "get",
      url: API + "/siteConfigs/front/name/facebook_link",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(facebookconfig)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setFacebook(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
    let twitterconfig = {
      method: "get",
      url: API + "/siteConfigs/front/name/twitter_link",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(twitterconfig)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setTwitter(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
    let linkedinconfig = {
      method: "get",
      url: API + "/siteConfigs/front/name/linkedin_link",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(linkedinconfig)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setLinkedIn(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="w-full flex flex-col lg:h-10 lg:flex-row items-center justify-between text-footertext dark:text-white bg-footer dark:bg-darkblack py-4 px-3 mb-12 sm:mb-0">
      <div>
        <h1 className="text-sm font-normal text-center lg:text-left">
          © 2019 - {date.getFullYear()} {FooterMessage}
        </h1>
      </div>
      <div className="text-xs my-0 sm:my-4">
        {" "}
        <li className="w-full my-4 md:w-auto md:my-0 lg:w-auto lg:my-0 border-b flex justify-center  md:border-none  lg:border-none xl:border-none">
          <a
            href={Facebook}
            rel="noopener noreferrer"
            target="_blank"
            className="px-2"
          >
            <FaFacebookSquare size="27" color="#4267B2" />
          </a>
          <a
            href={Twitter}
            rel="noopener noreferrer"
            target="_blank"
            className="px-2"
          >
            <FaSquareXTwitter size="27" />
          </a>
          <a
            href={LinkedIn}
            rel="noopener noreferrer"
            target="_blank"
            className="px-2"
          >
            <AiFillLinkedin size="27" color="#0e76a8" />
          </a>
        </li>
      </div>
      <div className="mt:2 lg:mt-0 text-center lg:text-right">
        <span className="text-xs">Powered By </span>
        <a className="text-sm cursor-pointer" href="https://www.devdigital.com/" target="_new">
          DevDigital : Nashville Software Development
        </a>
      </div>
    </div>
  );
};

export default FrontSpaFooter;
