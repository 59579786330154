import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import { RefreshToken } from "./../../services/RefreshToken";
import PageHeading from "./PageHeading";
import PageTable from "./PageTable";

const Page = (props) => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  let [pages, setPages] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [postPerPage, setPostPerPage] = useState(5);

  let [sortByTitle, setSortByTitleDesc] = useState(false);
  let [sortBySlug, setSortBySlugDesc] = useState(true);
  let [sortByModified, setSortByModified] = useState(true);

  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  let [status, setStatus] = useState("");

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  const fetchPages = () => {
    setLoading(true);
    let config = {
      method: "get",
      url: API + "/pages/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.pages;
        // console.log("response.data", response);
        if (data !== null) {
          // const sorted = data.sort((a, b) => {
          //   const isReverse = true ? 1 : -1;
          //   return isReverse * a.title.localeCompare(b.title);
          // });
          getperPageData(data);
          setPages(
            data.map((page) => {
              return {
                select: false,
                id: page.id,
                title: page.title,
                status: page.status,
                slug: page.slug,
                banner: page.description,
                bannerAlt: page.bannerAlt,
                createdBy: page.createdBy,
                createdDate: page.createdDate
                  ? moment(page.createdDate).format("MM/DD/YYYY h:mm A")
                  : null,
                deletedBy: page.deletedBy,
                pageContent: page.bannerContent,
                deletedDate: page.deletedDate
                  ? moment(page.deletedDate).format("MM/DD/YYYY h:mm A")
                  : null,
                metaTitle: page.metaTitle,
                metaDescription: page.metaDesc,
                updatedBy: page.updatedBy,
                updatedDate: page.updatedDate
                  ? moment(page.updatedDate).format("MM/DD/YYYY h:mm A")
                  : "-",
                modules: page.modules,
              };
            })
          );
        } else {
          setPages([]);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
        console.log(error);
      });
  };

  const getperPageData = (pagesData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setPostPerPage(
          pagesData.length < parseInt(data.val)
            ? pagesData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (access_token) {
      fetchPages();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, [navigate]);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = pages.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (number) => {
    if (number > 0) {
      setCurrentPage(number);
    }
  };
  const setNumberOfPost = (number) => {
    if (number > 0) {
      if (number > pages.length) {
        setPostPerPage(parseInt(pages.length));
      }
      setPostPerPage(parseInt(number));
    }
  };

  const sortTitle = (sortByTitle) => {
    const sorted = pages.sort((a, b) => {
      const isReverse = sortByTitle === true ? 1 : -1;
      return isReverse * a.title.trim().localeCompare(b.title.trim());
    });
    setPages(sorted);
  };
  const sortSlug = (sortBySlug) => {
    const sorted = pages.sort((a, b) => {
      const isReverse = sortBySlug === true ? 1 : -1;
      return isReverse * a.slug.trim().localeCompare(b.title.trim());
    });
    setPages(sorted);
  };

  const sortModified = (sortByModified) => {
    const sorted = pages.sort((a, b) => {
      let dateA = new Date(a.updatedDate);
      let dateB = new Date(b.updatedDate);
      return sortByModified ? dateA - dateB : dateB - dateA;
    });
    setPages(sorted);
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setPages(
      pages.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setPages(
      pages.map((data) => {
        if (id === data.id) {
          data.select = checked;
        }

        return data;
      })
    );

    const result = pages.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };

  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",

      url:
        API +
        `/pages/?size=-1${
          search && status
            ? `&status=${status}&keywords=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&keywords=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.pages;
        if (data !== null && data !== undefined) {
          const sorted = data.sort((a, b) => {
            const isReverse = true ? 1 : -1;
            return isReverse * a.title.localeCompare(b.title);
          });
          setPostPerPage(data.length < 10 ? data.length : 10);
          setPages(
            sorted.map((page) => {
              return {
                select: false,
                id: page.id,
                title: page.title,
                status: page.status,
                slug: page.slug,
                banner: page.description,
                bannerAlt: page.bannerAlt,
                createdBy: page.createdBy,
                createdDate: page.createdDate
                  ? moment(page.createdDate).format("MM/DD/YYYY h:mm A")
                  : null,
                deletedBy: page.deletedBy,
                pageContent: page.bannerContent,
                deletedDate: page.deletedDate
                  ? moment(page.deletedDate).format("MM/DD/YYYY h:mm A")
                  : null,
                metaTitle: page.metaTitle,
                metaDescription: page.metaDesc,
                updatedBy: page.updatedBy,
                updatedDate: page.updatedDate
                  ? moment(page.updatedDate).format("MM/DD/YYYY h:mm A")
                  : null,
                modules: page.modules,
              };
            })
          );
        } else {
          setPages([]);
        }
      })
      .catch(function (error) {
        let eorror_data = error.response;
        if (eorror_data && eorror_data.status === 204) {
          setPages([]);
        }
        if (
          eorror_data &&
          eorror_data.status === 401 &&
          (eorror_data.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        //console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    fetchPages();
  };

  const handleClose = () => {
    setSearch("");
    fetchPages();
  };

  const handleActive = () => {
    let a = window.confirm("Are you sure you want to Active this");
    if (a) {
      let arrayId = [];
      pages.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/pages/" + true,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Pages activated successfully");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchPages();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };
  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to In-Active this");
    if (a) {
      let arrayId = [];
      pages.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/pages/" + false,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Pages deactivated successfully");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchPages();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      pages.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "delete",
        url: API + "/pages/",
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Pages deleted successfully");
          setActiveInactive(false);
          setPageSearch(!pageSearch);
          setSelectAllCheckbox(false);
          fetchPages();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Pages"}
          searchLabel={"Title"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          search={search}
          setSearch={setSearch}
          setStatus={setStatus}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/pages/addPage"
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentPosts.length === 0 ? (
          <NoRecord />
        ) : (
          <PageTable
            pages={currentPosts}
            postPerPage={postPerPage}
            totalPosts={pages.length}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfPost={setNumberOfPost}
            sortTitle={sortTitle}
            sortByTitle={sortByTitle}
            setSortByTitleDesc={setSortByTitleDesc}
            sortSlug={sortSlug}
            sortBySlug={sortBySlug}
            setSortBySlugDesc={setSortBySlugDesc}
            sortModified={sortModified}
            sortByModified={sortByModified}
            setSortByModified={setSortByModified}
            selectAll={selectAll}
            selectSingle={selectSingle}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allPages={pages}
            setPages={setPages}
          />
        )}
      </div>
    </div>
  );
};

export default Page;
