import {
    FETCH_BLOGS_REQUEST,
    FETCH_BLOGS_SUCCESS,
    FETCH_BLOGS_FAIL,
    DELETE_BLOGS,
    ACTIVE_BLOGS,
    INACTIVE_BLOGS,
} from "../actionTypes";

export const BlogsReducer = (
    state = {
        blogs: [],
        loading: false,
        error: null,
    },
    action
) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_BLOGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_BLOGS_SUCCESS:
            return {
                ...state,
                blogs: payload,
                loading: false,
            };
        case FETCH_BLOGS_FAIL:
            return {
                ...state,
                blogs: [],
                error: payload,
                loading: false,
            };
        case DELETE_BLOGS:
            return {
                ...state,
                blogs: payload,
            };
        case ACTIVE_BLOGS:
            return {
                ...state,
                blogs: payload,
            };
        case INACTIVE_BLOGS:
            return {
                ...state,
                blogs: payload,
            };
        default:
            return state;
    }
};
