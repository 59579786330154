import React, { useState, useEffect, useCallback } from "react";
import PageHeading from "../pages/PageHeading";
import { CgAsterisk } from "react-icons/cg";
import { AiOutlineEye } from "react-icons/ai";
import Dropzone from "react-dropzone";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { reject } from "lodash";
import { notify } from "../../utility";
import { hoverAnimation } from "../../helper/helper";

function UploadMultipleImages({
  acceptedFiles,
  getRootProps,
  getInputProps,
  open,
  fileRejections,
  handleCancel,
  uploadSingle,
  multipleImagesUpload,
}) {
  fileRejections.map((file) => alert("file size must be less  than 10 mb"));
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li
      key={file.path}
      className="border-b pb-2 border-gray-800 flex w-full items-center justify-between my-4 px-1"
    >
      <img
        className="w-14 h-14"
        alt={file.path}
        // src={URL.createObjectURL(file)}
        src={file.webkitRelativePath}
      />
      <h1 className="text-sm px-2">{file.path}</h1>
      <h1>{file.size} bytes</h1>
      <div className="flex space-x-1">
        <button
          className="btn bg-blue-400 text-sm"
          onClick={() => uploadSingle(file)}
        >
          Upload
        </button>
        <button
          className="btn bg-black text-sm"
          onClick={() => handleCancel(file)}
        >
          Cancel
        </button>
      </div>
    </li>
  ));
  useEffect(() => {}, []);
  return (
    <section className="container">
      <div className="w-full flex space-x-3">
        <button
          type="button"
          className="btn bg-green-600 text-xs"
          onClick={open}
        >
          Add Files
        </button>
        <button
          type="button"
          className="btn bg-blue-400 text-xs"
          onClick={() => multipleImagesUpload()}
        >
          Start Upload
        </button>
      </div>
      <div class="w-full mt-2">
        <span className="text-sm text-black dark:text-textinblack">
          Max size 10(MB) and Recommended Size: 1900PX x 1080PX (Allowed only
          jpg, jpeg, png and gif images)
        </span>
      </div>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="w-full border-dashed border-2 border-black bg-gray-200 h-40 flex items-center justify-center cursor-pointer my-4 rounded"
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here</p>
      </div>

      <ul className="w-full">{acceptedFileItems}</ul>
    </section>
  );
}

const AddProducts = (props) => {
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("clothes");
  const [clothes, setClothes] = useState("mens");
  const [electronicsDevice, setElectronicsDevice] = useState("mobiles");
  const [count, setCount] = useState("");
  const [rating, setRating] = useState("");
  const [color, setColors] = useState("");
  const [qty, setQty] = useState("");
  const [size, setSize] = useState("");
  const [attributes, setAttributes] = useState([]);

  const [imageName, setImageName] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [myFiles, setMyFiles] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [imgPopUp, setImgPopUp] = useState(false);
  const [colorPopup, setColorPopup] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);

  const [company, setCompany] = useState("");
  const [series, setSeries] = useState("");
  const [displaySize, setDisplaySize] = useState("");
  const [displayType, setDisplayType] = useState("");
  const [storage, setStorage] = useState("");
  const [ram, setRam] = useState("");
  const [processor, setProcessor] = useState("");

  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [seriesError, setSeriesError] = useState("");
  const [displaySizeError, setDisplaySizeError] = useState("");
  const [ramError, setRamError] = useState("");
  const [processorError, setProcessorError] = useState("");
  const [displayTypeError, setDisplayTypeError] = useState("");
  const [storageError, setStorageError] = useState("");
  const [clothesError, setClothesError] = useState("");
  const [countError, setCountError] = useState("");
  const [colorError, setColorError] = useState("");
  const [qtyError, setQtyError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const [attributesError, setAttributesError] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;

  const onImageDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((image) => {
        let data = new FormData();
        if (category === "electronics" && electronicsDevice === "mobiles") {
          data.append("folder", "mobileImages");
        } else if (
          category === "electronics" &&
          electronicsDevice === "laptop"
        ) {
          data.append("folder", "laptopImages");
        } else {
          data.append("folder", "productImages");
        }

        data.append("image", image);
        const config = {
          method: "post",
          url: "http://localhost:5000/uploads",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            notify(response.data.message);
            setImage(response.data.imageUrl);
            setImageName(response.data.imageName);
            setImageError("");
          })
          .catch(function (error) {
            console.log(error);
          });
        return null;
      });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...myFiles, ...acceptedFiles];
      const filesWithId = files.map((item, index) => {
        return {
          id: index + 1,
          lastModified: item.lastModified,
          lastModifiedDate: item.lastModifiedDate,
          name: item.name,
          size: item.size,
          type: item.type,
          webkitRelativePath: !item.id
            ? URL.createObjectURL(item)
            : item.webkitRelativePath,
        };
      });
      setMyFiles(filesWithId);
    },
    [myFiles]
  );

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: "image/*",
      noKeyboard: true,
      noClick: true,
      maxSize: 10485760,
      onDrop,
    });

  const handleCancel = (file) => {
    const newFiles = [...myFiles];
    setMyFiles(reject(newFiles, { id: file.id }));
  };

  const uploadSingle = (file) => {
    acceptedFiles.map((f) => {
      if (f.name === file.name) {
        let data = new FormData();
        if (category === "electronics" && electronicsDevice === "mobiles") {
          data.append("folder", "mobileImages");
        } else if (
          category === "electronics" &&
          electronicsDevice === "laptop"
        ) {
          data.append("folder", "laptopImages");
        } else {
          data.append("folder", "productImages");
        }
        data.append("image", f);
        const config = {
          method: "post",
          url: "http://localhost:5000/uploads",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            if (response.status === 200) {
              const d = [...uploadedData];
              d.push({
                imageName: response.data.imageName,
                imageUrl: response.data.imageUrl,
              });

              setUploadedData(d);
              notify(response.data.message);
              handleCancel(file);
              if (props.match.params.id && response.status === 200) {
                const insertImages = [];
                insertImages.push({
                  imageName: response.data.imageName,
                  gallery_id: props.match.params.id,
                });
                let config = {
                  method: "post",
                  url: "http://localhost:5000/gallery/insertImageLink",
                  headers: {
                    Authorization: `Bearer ${access_token}`,
                  },
                  data: {
                    insertImages: insertImages,
                  },
                };
                axios(config)
                  .then((res) => {
                    // console.log("res", res);
                  })
                  .catch((err) => {
                    console.log("err", err);
                  });
              }
            }
          })
          .catch(function (error) {
            console.log("error", error);
          });
      }
      return null;
    });
  };

  const multipleImagesUpload = () => {
    let data = new FormData();
    if (category === "electronics" && electronicsDevice === "mobiles") {
      data.append("folder", "mobileImages");
    } else if (category === "electronics" && electronicsDevice === "laptop") {
      data.append("folder", "laptopImages");
    } else {
      data.append("folder", "productImages");
    }
    let arr = [];
    acceptedFiles.map((file) => {
      myFiles.map((f) => {
        if (file.name === f.name) {
          arr.push(file);
        }
        return null;
      });
      return null;
    });
    for (let i = 0; i < arr.length; i++) {
      data.append("image", arr[i]);
    }
    const config = {
      method: "post",
      url: "http://localhost:5000/uploads/multipleUpload",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          const images = response.data.images;
          const d = uploadedData;
          images.map((image) => {
            d.push({
              imageName: image.imageName,
              imageUrl: image.imageUrl,
            });
            return null;
          });
          setUploadedData(d);
          notify(response.data.message);
          setMyFiles([]);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const remove = (imageUrl) => {
    let arr = imageUrl.split("/");
    let image = arr[arr.length - 1];
    const folder = () => {
      if (category === "electronics" && electronicsDevice === "mobiles") {
        return "mobileImages";
      } else if (category === "electronics" && electronicsDevice === "laptop") {
        return "laptopImages";
      } else {
        return "productImages";
      }
    };
    const data = {
      image: image,
      folder: folder(),
    };

    const config = {
      method: "delete",
      url: "http://localhost:5000/uploads",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        let data;
        if (props.match.params.id) {
          if (props.match.params.id && category === "clothes") {
            data = {
              table: "products",
              id: props.match.params.id,
            };
          }
          if (
            props.match.params.id &&
            category === "electronics" &&
            electronicsDevice === "mobiles"
          ) {
            data = {
              table: "mobiles",
              id: props.match.params.id,
            };
          }
          if (
            props.match.params.id &&
            category === "electronics" &&
            electronicsDevice === "laptop"
          ) {
            data = {
              table: "laptop",
              id: props.match.params.id,
            };
          }
          const con = {
            method: "put",
            url: "http://localhost:5000/products/deleteImage",
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
            data: data,
          };
          axios(con)
            .then((res) => {
              notify(res.data.message);
              setImage("");
              setImageName("");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          notify(response.data.message);
          setImage("");
          setImageName("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeImg = (imageUrl) => {
    let arr = imageUrl.split("/");
    let image = arr[arr.length - 1];
    const folder = () => {
      if (category === "electronics" && electronicsDevice === "mobiles") {
        return "mobileImages";
      } else if (category === "electronics" && electronicsDevice === "laptop") {
        return "laptopImages";
      } else {
        return "productImages";
      }
    };
    const data = {
      image: image,
      folder: folder(),
    };

    const config = {
      method: "delete",
      url: "http://localhost:5000/uploads",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const data = uploadedData.filter((d) => d.imageUrl !== imageUrl);
        setUploadedData(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadedImage = (imageUrl, imageId, product_id) => {
    let arr = imageUrl.split("/");
    let image = arr[arr.length - 1];
    let x;

    if (category === "electronics" && electronicsDevice === "mobiles") {
      x = {
        image: image,
        folder: "mobileImages",
      };
    } else if (category === "electronics" && electronicsDevice === "laptop") {
      x = {
        image: image,
        folder: "laptopImages",
      };
    } else {
      x = {
        image: image,
        folder: "productImages",
      };
    }
    // console.log("data", x);
    const config = {
      method: "delete",
      url: "http://localhost:5000/uploads",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: x,
    };
    axios(config)
      .then(function (response) {
        const data = uploadedData.filter((d) => d.imageUrl !== imageUrl);
        setUploadedData(data);
        let d;
        if (props.match.params.id && category === "clothes") {
          d = {
            table: "productimages",
            columnName: "product_id",
            imageId: imageId,
            product_id: product_id,
          };
        }
        if (
          props.match.params.id &&
          category === "electronics" &&
          electronicsDevice === "mobiles"
        ) {
          d = {
            table: "mobileimages",
            columnName: "mobile_id",
            imageId: imageId,
            product_id: product_id,
          };
        }
        if (
          props.match.params.id &&
          category === "electronics" &&
          electronicsDevice === "laptop"
        ) {
          d = {
            table: "laptopimages",
            columnName: "laptop_id",
            imageId: imageId,
            product_id: product_id,
          };
        }
        const con = {
          method: "delete",
          url: "http://localhost:5000/products/deleteProductImage",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          data: d,
        };
        axios(con)
          .then((res) => {
            notify(res.data.message);
          })
          .catch((err) => {
            notify(err);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addEditClothes = () => {
    let titleError = "";
    let imageError = "";
    let priceError = "";
    let clothesError = "";
    let countError = "";
    let attributesError = "";

    if (title.trim() === "") {
      titleError = "Enter title";
    }
    if (image.trim() === "") {
      imageError = "Upload image";
    }
    if (price.length === 0) {
      priceError = "Enter price";
    }
    if (clothes.trim() === "") {
      clothesError = "Select category";
    }
    if (count.length === 0) {
      countError = "Enter quantity";
    }
    if (attributes.length === 0) {
      attributesError = "Add Color,Size and Quantity";
    }
    if (
      titleError !== "" ||
      imageError !== "" ||
      priceError !== "" ||
      clothesError !== "" ||
      countError !== "" ||
      attributesError !== ""
    ) {
      setTitleError(titleError);
      setImageError(imageError);
      setPriceError(priceError);
      setClothesError(clothesError);
      setCountError(countError);
      setAttributesError(attributesError);
      return;
    }

    if (props.match.params.id) {
      const img = image.split("/");
      let editProduct = {
        title: title,
        image: img[img.length - 1],
        price: price,
        category: clothes,
        count: count,
        description: pageContent,
        rating: rating,
        id: props.match.params.id,
      };
      let config = {
        method: "put",
        url: "http://localhost:5000/products/editProduct",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: editProduct,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/products");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      let a = [];
      uploadedData.map((d) => {
        a.push(d.imageName);
        return null;
      });
      let product = {
        title: title,
        image: imageName,
        price: price,
        category: clothes,
        count: count,
        description: pageContent,
        rating: rating,
        images: a,
        attributes: attributes,
      };
      let config = {
        method: "post",
        url: "http://localhost:5000/products/addProduct",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: product,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/products");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const addEditMobile = () => {
    let titleError = "";
    let imageError = "";
    let priceError = "";
    let countError = "";
    let colorError = "";
    let companyError = "";
    let seriesError = "";
    let displaySizeError = "";
    let ramError = "";
    let processorError = "";
    let displayTypeError = "";
    let storageError = "";

    if (title.trim() === "") {
      titleError = "Enter title";
    }
    if (image.trim() === "") {
      imageError = "Upload image";
    }
    if (price.length === 0) {
      priceError = "Enter price";
    }
    if (color.trim() === "") {
      colorError = "Select color";
    }
    if (count.length === 0) {
      countError = "Enter quantity";
    }
    if (company === "") {
      companyError = "Select company";
    }
    if (series === "") {
      seriesError = "Enter series name";
    }
    if (displaySize === "") {
      displaySizeError = "Enter display size";
    }
    if (displayType === "") {
      displayTypeError = "Select display type";
    }
    if (ram === "") {
      ramError = "Select ram size";
    }
    if (processor === "") {
      processorError = "Enter processor name";
    }
    if (storage === "") {
      storageError = "Select storage";
    }

    if (
      titleError !== "" ||
      imageError !== "" ||
      priceError !== "" ||
      colorError !== "" ||
      countError !== "" ||
      companyError !== "" ||
      seriesError !== "" ||
      displaySizeError !== "" ||
      ramError !== "" ||
      processorError !== "" ||
      displayTypeError !== "" ||
      storageError !== ""
    ) {
      setTitleError(titleError);
      setImageError(imageError);
      setPriceError(priceError);
      setColorError(colorError);
      setCountError(countError);
      setCompanyError(companyError);
      setSeriesError(seriesError);
      setDisplaySizeError(displaySizeError);
      setRamError(ramError);
      setProcessorError(processorError);
      setStorageError(storageError);
      setDisplayTypeError(displayTypeError);
      return;
    }
    if (props.match.params.id) {
      let editMobile = {
        electronicsDevice: "mobile",
        company: company,
        color: color,
        title: title,
        count: count,
        rating: rating,
        price: price,
        mobileSeries: series,
        displaySize: displaySize,
        ram: ram,
        processor: processor,
        displayType: displayType,
        storage: storage,
        description: pageContent,
        image: image,
        id: props.match.params.id,
      };
      let config = {
        method: "put",
        url: "http://localhost:5000/products/editMobile",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: editMobile,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/products");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      let a = [];
      uploadedData.map((d) => {
        a.push(d.imageName);
        return null;
      });
      let addMobile = {
        electronicsDevice: "mobile",
        company: company,
        color: color,
        title: title,
        count: count,
        rating: rating,
        price: price,
        mobileSeries: series,
        displaySize: displaySize,
        ram: ram,
        processor: processor,
        displayType: displayType,
        storage: storage,
        description: pageContent,
        image: image,
        images: a,
      };

      let config = {
        method: "post",
        url: "http://localhost:5000/products/addMobile",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: addMobile,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/products");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const addEditLaptop = () => {
    let titleError = "";
    let imageError = "";
    let priceError = "";
    let countError = "";
    let colorError = "";
    let companyError = "";
    let seriesError = "";
    let displaySizeError = "";
    let ramError = "";
    let processorError = "";
    let displayTypeError = "";
    let storageError = "";

    if (title.trim() === "") {
      titleError = "Enter title";
    }
    if (image.trim() === "") {
      imageError = "Upload image";
    }
    if (price.length === 0) {
      priceError = "Enter price";
    }
    if (color.trim() === "") {
      colorError = "Select color";
    }
    if (count.length === 0) {
      countError = "Enter quantity";
    }
    if (company === "") {
      companyError = "Select company";
    }
    if (series === "") {
      seriesError = "Enter series name";
    }
    if (displaySize === "") {
      displaySizeError = "Enter display size";
    }
    if (displayType === "") {
      displayTypeError = "Select display type";
    }
    if (ram === "") {
      ramError = "Select ram size";
    }
    if (processor === "") {
      processorError = "Enter processor name";
    }
    if (storage === "") {
      storageError = "Select storage";
    }

    if (
      titleError !== "" ||
      imageError !== "" ||
      priceError !== "" ||
      colorError !== "" ||
      countError !== "" ||
      companyError !== "" ||
      seriesError !== "" ||
      displaySizeError !== "" ||
      ramError !== "" ||
      processorError !== "" ||
      displayTypeError !== "" ||
      storageError !== ""
    ) {
      setTitleError(titleError);
      setImageError(imageError);
      setPriceError(priceError);
      setColorError(colorError);
      setCountError(countError);
      setCompanyError(companyError);
      setSeriesError(seriesError);
      setDisplaySizeError(displaySizeError);
      setRamError(ramError);
      setProcessorError(processorError);
      setStorageError(storageError);
      setDisplayTypeError(displayTypeError);
      return;
    }
    if (props.match.params.id) {
      let editLaptop = {
        electronicsDevice: "laptop",
        company: company,
        color: color,
        title: title,
        count: count,
        rating: rating,
        price: price,
        laptopSeries: series,
        displaySize: displaySize,
        ram: ram,
        processor: processor,
        displayType: displayType,
        storage: storage,
        description: pageContent,
        image: image,
        id: props.match.params.id,
      };
      let config = {
        method: "put",
        url: "http://localhost:5000/products/editLaptop",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: editLaptop,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/products");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      let a = [];
      uploadedData.map((d) => {
        a.push(d.imageName);
        return null;
      });
      let addLaptop = {
        electronicsDevice: "mobile",
        company: company,
        color: color,
        title: title,
        count: count,
        rating: rating,
        price: price,
        laptopSeries: series,
        displaySize: displaySize,
        ram: ram,
        processor: processor,
        displayType: displayType,
        storage: storage,
        description: pageContent,
        image: image,
        images: a,
      };

      let config = {
        method: "post",
        url: "http://localhost:5000/products/addLaptop",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: addLaptop,
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/admin/products");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const handleAddEdit = () => {
    if (category === "clothes") {
      addEditClothes();
    }
    if (category === "electronics" && electronicsDevice === "mobiles") {
      addEditMobile();
    }

    if (category === "electronics" && electronicsDevice === "laptop") {
      addEditLaptop();
    }
  };

  const reset = () => {
    setTitle("");
    setImage("");
    setPrice("");
    setClothes("");
    setCount("");
    setRating("");
    setColors("");
    setQty("");
    setSize("");
    setAttributes("");
    setCompany("");
    setSeries("");
    setDisplaySize("");
    setDisplayType("");
    setStorage("");
    setRam("");
    setProcessor("");
  };

  const fetchProduct = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: "http://localhost:5000/products/" + id,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data.product;
          // console.log("response", data);
          setTitle(data[0].title);
          setImage(data[0].image);
          setImageName(data[0].imageName);
          setPrice(data[0].price);
          setRating(data[0].rating);
          setCount(data[0].count);
          setClothes(data[0].category);
          setAttributes(data[0].attribute);
          setPageContent(data[0].description);
          setUploadedData(data[0].images);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    [access_token]
  );

  const fetchMobile = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: "http://localhost:5000/products/mobiles/" + id,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data.mobile;
          setTitle(data[0].title);
          setImage(data[0].image);
          setImageName(data[0].imageName);
          setPrice(data[0].price);
          setRating(data[0].rating);
          setCount(data[0].count);
          setClothes(data[0].category);
          setPageContent(data[0].description);
          setUploadedData(data[0].images);
          setCompany(data[0].company);
          setSeries(data[0].mobileSeries);
          setDisplaySize(data[0].displaySize);
          setDisplayType(data[0].displayType);
          setStorage(data[0].storage);
          setRam(data[0].ram);
          setProcessor(data[0].processor);
          setColors(data[0].color);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    [access_token]
  );

  const fetchLaptop = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: "http://localhost:5000/products/laptops/" + id,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data.laptop;
          console.log("data", data);
          setTitle(data[0].title);
          setImage(data[0].image);
          setImageName(data[0].imageName);
          setPrice(data[0].price);
          setRating(data[0].rating);
          setCount(data[0].count);
          setClothes(data[0].category);
          setPageContent(data[0].description);
          setUploadedData(data[0].images);
          setCompany(data[0].company);
          setSeries(data[0].laptopSeries);
          setDisplaySize(data[0].displaySize);
          setDisplayType(data[0].displayType);
          setStorage(data[0].storage);
          setRam(data[0].ram);
          setProcessor(data[0].processor);
          setColors(data[0].color);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    [access_token]
  );

  const handleCancelBtn = () => {
    if (props.match.params.id === undefined) {
      if (image !== "") {
        remove(image);
      }
      if (uploadedData.length > 0) {
        const imagesArr = [];
        uploadedData.map((d) => {
          if (category === "clothes") {
            imagesArr.push({
              folder: "productImages",
              image: d.imageName,
            });
          }
          if (category === "electronics" && electronicsDevice === "mobiles") {
            imagesArr.push({
              folder: "mobileImages",
              image: d.imageName,
            });
          }

          if (category === "electronics" && electronicsDevice === "laptop") {
            imagesArr.push({
              folder: "laptopImages",
              image: d.imageName,
            });
          }
          return null;
        });

        axios
          .delete("http://localhost:5000/uploads/multipleDelete", {
            data: {
              imagesArr: imagesArr,
            },
          })
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            console.log("response error", error);
          });
      }

      if (image === "" && uploadedData.length === 0) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const handleAddColorSizeQty = () => {
    let colorError = "";
    let qtyError = "";
    let sizeError = "";

    if (color === "") {
      colorError = "Select color";
    }

    if (qty === "") {
      qtyError = "Enter Quantity";
    }
    if (size === "") {
      sizeError = "Select size";
    }

    if (colorError !== "" || qtyError !== "" || sizeError !== "") {
      setColorError(colorError);
      setQtyError(qtyError);
      setSizeError(sizeError);
      return;
    }

    let a = {
      color: color,
      qty: qty,
      size: size,
    };

    let att = [...attributes];
    att.push(a);
    setAttributes(att);
    setAttributesError("");
    setColorPopup(false);
    setColors("");
    setColors("");
    setQty("");
  };

  useEffect(() => {
    const id = props.match.params.id;
    const category = props.match.params.category;
    const electronicsDevice = props.match.params.subcategory;
    if (id && category === "clothes") {
      fetchProduct(id);
      setCategory(category);
    }
    if (id && category === "electronics" && electronicsDevice === "mobiles") {
      setCategory(category);
      setElectronicsDevice(electronicsDevice);
      fetchMobile(id);
    }
    if (id && category === "electronics" && electronicsDevice === "laptop") {
      setCategory(category);
      setElectronicsDevice(electronicsDevice);
      fetchLaptop(id);
    }
  }, [
    fetchLaptop,
    fetchMobile,
    fetchProduct,
    props.match.params.category,
    props.match.params.id,
    props.match.params.subcategory,
  ]);

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <>
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading="Add Product"
          showSaveOptionsBtn={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          save={true}
          saveAndContinue={true}
          handleSave={handleAddEdit}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                Category
                <CgAsterisk className="inline text-red-500" />
              </label>
              <select
                value={category}
                disabled={props.match.params.category ? true : false}
                onChange={(e) => {
                  setCategory(e.target.value);
                  reset();
                }}
                className="border h-10 rounded px-2 text-sm font-medium"
              >
                <option value="clothes">Clothes</option>
                <option value="electronics">Electronics</option>
              </select>
            </div>
            {category === "clothes" && (
              <div className="flex flex-col">
                <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                  Clothes
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <select
                  value={clothes}
                  onChange={(e) => {
                    setClothes(e.target.value);
                    setClothesError("");
                  }}
                  className={`${
                    clothesError ? "border-red-500 border" : "border"
                  } h-10 rounded px-2 text-sm font-medium `}
                >
                  <option value="men">Mens</option>
                  <option value="women">Women</option>
                  <option value="kids">kids</option>
                </select>
                {clothesError && (
                  <span className="text-red-500 text-xs">{clothesError}</span>
                )}
              </div>
            )}
            {category === "electronics" && (
              <div className="flex flex-col">
                <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                  Electronics Devices
                </label>
                <select
                  value={electronicsDevice}
                  disabled={
                    props.match.params.category &&
                    props.match.params.subcategory
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setElectronicsDevice(e.target.value);
                  }}
                  className="border h-10 rounded px-2 text-sm font-medium"
                >
                  <option value="mobiles">Mobiles</option>
                  <option value="laptop">Laptop</option>
                </select>
              </div>
            )}
          </div>
          {category === "electronics" && (
            <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                  Company
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <select
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                    setCompanyError("");
                  }}
                  className={`${
                    companyError ? "border-red-500 border" : "border"
                  } h-10 rounded px-2 text-sm font-medium `}
                >
                  <option>Select company</option>
                  <option value="apple">Apple</option>
                  <option value="samsung">Samsung</option>
                  <option value="realme">Realme</option>
                  <option value="xiaomi">Xiaomi</option>
                  <option value="oppo">Oppo</option>
                  <option value="vivo">Vivo</option>
                </select>

                {companyError && (
                  <span className="text-red-500 text-xs">{companyError}</span>
                )}
              </div>
              <div className="flex flex-col">
                <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                  Color
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <select
                  value={color}
                  onChange={(e) => {
                    setColors(e.target.value);
                    setColorError("");
                  }}
                  className={`${
                    colorError ? "border-red-500 border" : "border"
                  } h-10 rounded px-2 text-sm font-medium `}
                >
                  <option>Select color</option>
                  <option value="red">Red</option>
                  <option value="black">Black</option>
                  <option value="blue">Blue</option>
                  <option value="white">White</option>
                  <option value="green">Green</option>
                  <option value="yellow">Yellow</option>
                  <option value="purple">Purple</option>
                  <option value="pink">Pink</option>
                  <option value="gray">Gray</option>
                </select>

                {colorError && (
                  <span className="text-red-500 text-xs">{colorError}</span>
                )}
              </div>
            </div>
          )}

          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Title
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={title}
                onChange={(e) => {
                  setTitleError("");
                  setTitle(e.target.value);
                }}
                type="text"
                className={`${
                  titleError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter title"
              />
              {titleError && (
                <span className="text-red-500 text-xs">{titleError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Count
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={count}
                onChange={(e) => {
                  setCount(e.target.value);
                }}
                type="number"
                className={`${
                  countError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
              />
              {countError && (
                <span className="text-red-500 text-xs">{countError}</span>
              )}
            </div>
          </div>

          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">Rating</label>
              <input
                value={rating}
                onChange={(e) => {
                  setRating(e.target.value);
                }}
                type="number"
                className="border h-10 rounded-lg px-2 text-sm font-medium "
              />
            </div>

            <div className="flex flex-col">
              <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                Price <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={price}
                type="number"
                onChange={(e) => {
                  setPrice(e.target.value);
                  setPriceError("");
                }}
                className={`${
                  priceError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
              />
              {priceError && (
                <span className="text-red-500 text-xs">{priceError}</span>
              )}
            </div>
          </div>
          {category === "electronics" &&
            (electronicsDevice === "mobiles" ||
              electronicsDevice === "laptop") && (
              <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="font-medium mb-1">
                    Series
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    value={series}
                    onChange={(e) => {
                      setSeries(e.target.value);
                      setSeriesError("");
                    }}
                    type="text"
                    className={`${
                      seriesError ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  />
                  {seriesError && (
                    <span className="text-red-500 text-xs">{seriesError}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="font-medium mb-1">
                    Display size
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    value={displaySize}
                    onChange={(e) => {
                      setDisplaySize(e.target.value);
                      setDisplaySizeError("");
                    }}
                    type="text"
                    className={`${
                      displaySizeError ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  />
                  {displaySizeError && (
                    <span className="text-red-500 text-xs">
                      {displaySizeError}
                    </span>
                  )}
                </div>
              </div>
            )}
          {category === "electronics" &&
            (electronicsDevice === "mobiles" ||
              electronicsDevice === "laptop") && (
              <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="font-medium mb-1">
                    RAM
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <select
                    value={ram}
                    onChange={(e) => {
                      setRam(e.target.value);
                      setRamError("");
                    }}
                    className={`${
                      ramError ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  >
                    <option>Select Ram</option>
                    <option value="2">2 GB</option>
                    <option value="4">4 GB</option>
                    <option value="6">6 GB</option>
                    <option value="8">8 GB</option>
                    <option value="16">16 GB</option>
                  </select>
                  {ramError && (
                    <span className="text-red-500 text-xs">{ramError}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="font-medium mb-1">
                    processor
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={processor}
                    onChange={(e) => {
                      setProcessor(e.target.value);
                      setProcessorError("");
                    }}
                    className={`${
                      processorError ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  />
                  {processorError && (
                    <span className="text-red-500 text-xs">
                      {processorError}
                    </span>
                  )}
                </div>
              </div>
            )}

          {category === "electronics" &&
            (electronicsDevice === "mobiles" ||
              electronicsDevice === "laptop") && (
              <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="font-medium mb-1">
                    Display Type
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <select
                    value={displayType}
                    onChange={(e) => {
                      setDisplayType(e.target.value);
                      setDisplayTypeError("");
                    }}
                    className={`${
                      displayTypeError ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  >
                    <option>Select display</option>
                    <option value="amoled">Amoled</option>
                    <option value="tft lcd">TFT LCD</option>
                    <option value="ips-lcd">IPS-LCD</option>
                    <option value="oled">OLED</option>
                    <option value="super amoled">Super AMOLED</option>
                  </select>
                  {displayTypeError && (
                    <span className="text-red-500 text-xs">
                      {displayTypeError}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="font-medium mb-1">
                    Storage
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <select
                    value={storage}
                    onChange={(e) => {
                      setStorage(e.target.value);
                      setStorageError("");
                    }}
                    className={`${
                      storageError ? "border-red-500 border" : "border"
                    } h-10 rounded px-2 text-sm font-medium `}
                  >
                    <option>Select storage</option>
                    <option value="16">16 GB</option>
                    <option value="32">32 GB</option>
                    <option value="64">64 GB</option>
                    <option value="128">128 GB</option>
                    <option value="256">256 GB</option>
                    <option value="512">512 GB</option>
                    <option value="1">1 TB</option>
                  </select>
                  {storageError && (
                    <span className="text-red-500 text-xs">{storageError}</span>
                  )}
                </div>
              </div>
            )}

          <div className="w-full my-5">
            <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              Description
            </label>

            <div className="w-full py-5">
              <textarea
                className="w-full h-56 border rounded"
                value={pageContent}
                onChange={(e) => {
                  setPageContent(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="w-full flex flex-col">
            <div className="w-full">
              {category === "clothes" && (
                <button
                  onClick={() => setColorPopup(true)}
                  className="btn bg-yellow-500 text-white hover:bg-yellow-600"
                >
                  Add Color & Qty
                </button>
              )}
              {attributesError && (
                <span className="text-red-500 text-xs">{attributesError}</span>
              )}
            </div>
          </div>
          <div className="w-full my-5">
            {attributes.length > 0 &&
              attributes.map((att, index) => (
                <div className="w-full flex items-center justify-between px-4 py-2 bg-gray-200 my-2">
                  <div className="flex">
                    <h1 className="text-sm font-semibold pr-2">
                      Color : {att.color}
                    </h1>
                    <h1 className="text-sm font-semibold pr-2">
                      Size: {att.size}
                    </h1>
                    <h1 className="text-sm font-semibold pr-2">
                      Quantity: {att.qty}
                    </h1>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => {
                        if (props.match.params.id) {
                        } else {
                          let att = [...attributes];
                          att.splice(index, 1);
                          setAttributes(att);
                        }
                      }}
                      className="btn btn bg-black text-white w-6 h-6 flex items-center justify-center"
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="w-full mt-5">
            <div>
              <label className="font-medium mb-1">Image</label>
              <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
                {image && (
                  <img
                    src={image}
                    alt="no img"
                    className="w-full h-4/5  object-contain"
                  />
                )}
                {image && (
                  <button
                    onClick={() => {
                      remove(image);
                    }}
                    className="absolute z-20 top-1 right-1 btn bg-gray-900 text-white"
                  >
                    Remove
                  </button>
                )}
                {!image && (
                  <Dropzone
                    accept="image/*"
                    onDrop={(acceptedFiles) => {
                      onImageDrop(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="w-full h-full  flex items-center justify-center"
                      >
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>
            <div className="text-xs mt-1">
              Max size 5(MB) and Recommended Size: 1900PX x 1080PX (Allowed only
              jpg, jpeg, png and gif images)
            </div>
            {imageError && (
              <span className="text-red-500 text-xs">{imageError}</span>
            )}
          </div>

          <div className="w-full my-5">
            <UploadMultipleImages
              imageSize={5}
              acceptedFiles={myFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              fileRejections={fileRejections}
              handleCancel={handleCancel}
              uploadSingle={uploadSingle}
              multipleImagesUpload={multipleImagesUpload}
            />
          </div>
          <div className="w-full my-5 grid grid-cols-1 gap-4  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
            {uploadedData.length > 0 &&
              uploadedData.map((d) => (
                <div className="w-full relative imgHover border border-gray-700 flex items-center justify-center">
                  <img src={d.imageUrl} alt="imge" className="w-full" />
                  <div className="imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full hidden items-center justify-center">
                    <button
                      onClick={() => {
                        setImgPopUp(true);
                        setImgLink(d.imageUrl);
                      }}
                      className={
                        "w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center" +
                        hoverAnimation
                      }
                    >
                      <AiOutlineEye />
                    </button>
                    <button
                      onClick={() => {
                        let a = window.confirm(
                          "Are you sure you want to delete this"
                        );
                        if (a) {
                          if (props.match.params.id) {
                            uploadedImage(d.imageUrl, d.imageId, d.product_id);
                          } else {
                            removeImg(d.imageUrl);
                          }
                        }
                      }}
                      className="w-8 h-8 bg-black rounded-full flex items-center justify-center text-lg"
                    >
                      x
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {colorPopup && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
          <div className="w-1/2 bg-white rounded  flex  mt-10 p-8 items-start justify-between space-x-2 flex-wrap">
            <div className="flex flex-col w-5/12">
              <h1 className=" mb-1 flex items-center text-yellow-500 font-bold text-lg">
                Add color size and there quantity
              </h1>
            </div>
            <div className="flex flex-col w-5/12 items-end">
              <button
                onClick={() => {
                  setColorPopup(false);
                  setColors("");
                  setColors("");
                  setQty("");
                }}
                className="btn bg-black text-white w-6 h-6 flex items-center justify-center"
              >
                x
              </button>
            </div>
            <div className="flex flex-col w-5/12">
              <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                Color <CgAsterisk className="inline text-red-500" />
              </label>
              <select
                onChange={(e) => {
                  setColorError("");
                  setColors(e.target.value);
                }}
                className={`${
                  colorError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
              >
                <option>Select color</option>
                <option value="1">Red</option>
                <option value="2">Black</option>
                <option value="3">Blue</option>
                <option value="4">White</option>
                <option value="5">Green</option>
                <option value="6">Yellow</option>
                <option value="7">Purple</option>
                <option value="8">Pink</option>
                <option value="9">Gray</option>
              </select>
              {colorError && (
                <span className="text-red-500 text-xs">{colorError}</span>
              )}
            </div>
            <div className="flex flex-col w-5/12">
              <label className="font-medium mb-1">
                Quantity <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={qty}
                onChange={(e) => {
                  setQtyError("");
                  setQty(e.target.value);
                }}
                type="number"
                className={`${
                  qtyError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
              />
              {qtyError && (
                <span className="text-red-500 text-xs">{qtyError}</span>
              )}
            </div>
            <div className="flex flex-col w-5/12">
              <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                Size <CgAsterisk className="inline text-red-500" />
              </label>
              <select
                onChange={(e) => {
                  setSizeError("");
                  setSize(e.target.value);
                }}
                className={`${
                  sizeError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
              >
                <option>Select Size</option>
                <option value="1">M</option>
                <option value="2">L</option>
                <option value="3">XL</option>
                <option value="4">2XL</option>
                <option value="5">3XL</option>
              </select>
              {sizeError && (
                <span className="text-red-500 text-xs">{sizeError}</span>
              )}
            </div>
            <div className="flex justify-end w-full mt-2">
              <button
                onClick={() => handleAddColorSizeQty()}
                className="btn bg-yellow-500 text-white "
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      {imgPopUp && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
          <div className="w-1/2 h-1/2  bg-white rounded  flex flex-col mt-10">
            <div className="w-full h-10 flex item-center justify-end">
              <h1
                onClick={() => {
                  setImgPopUp(false);
                  setImgLink("");
                }}
                className="w-10 h-10 flex items-center justify-center cursor-pointer bg-black border-2 text-white"
              >
                x
              </h1>
            </div>
            <img src={imgLink} alt="imag" className="border-2 w-full h-full" />
          </div>
        </div>
      )}
      </>
    // </div>
  );
};
export default AddProducts;
