import moment from "moment";
import {
  STORE_LOGIN_DATA,
  REMOVE_LOGIN_DATA,
  UPDATE_LOGIN_DATA,
  UPDATE_LAST_ACTIVE,
} from "./../actionTypes";

export const StoreLoginData = (payload) => {
    let data = {
    userInfo: payload,
    lastActive: moment().format("DD-MM-yyyy hh:mm:ss"),
  };
  return {
    type: STORE_LOGIN_DATA,
    payload: data,
  };
};

export const updateLastActive = (payload) => {
  let data = {
    lastActive: payload,
  };
  return {
    type: UPDATE_LAST_ACTIVE,
    payload: data,
  };
};

export const UpdateLoginData = (payload) => {
  return {
    type: UPDATE_LOGIN_DATA,
    payload: payload,
  };
};

export const RemoveLoginData = () => {
  let data = {
    userInfo: null,
  };
  return {
    type: REMOVE_LOGIN_DATA,
    payload: data,
  };
};
