import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../services/API";

const Faqs = () => {
  // let [faqsPerPage, setFaqsPerPage] = useState(5);
  const [faqs, setFaqs] = useState([]);
  const [id, setId] = useState("");

  const fetchFaqs = () => {
    let config = {
      method: "get",
      url: API + "/faqs/front/",
    };
    axios(config)
      .then((response) => {
        const faqs = response.data;
        let filtered = faqs.filter((option) => option.status === true);
        setFaqs(filtered);
        setId(filtered[0].id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  // const indexOfLastQuestion = currentPage * faqsPerPage;
  // const indexOfFirstQuestion = indexOfLastQuestion - faqsPerPage;
  // const currentFaqs = faqs.slice(indexOfFirstQuestion, indexOfLastQuestion);

  // const pageNumbers = [];
  // // console.log("faqs.length", faqs.length);
  // for (let i = 1; i <= Math.ceil(faqs.length / faqsPerPage); i++) {
  //   pageNumbers.push(i);
  // }

  // const paginate = (number) => {
  //   setCurrentPage(number);
  // };

  // const setNumberOfFaqs = (number) => {
  //   if(number>0){
  //     if(number>faqs.length){
  //       setFaqsPerPage(parseInt(faqs.length))
  //     }
  //     setFaqsPerPage(parseInt(number));
  //   }
  // };

  return (
    <div className="w-full pt-24">
      <h1 className="w-full text-center mb-4 text-2xl capitalize font-medium text-black dark:text-white">
        FAQs
      </h1>
      {faqs &&
        faqs.length > 0 &&
        faqs.map((faq) => (
          <div className="main-content mx-3 lg:mx-auto">
            {faq.status === true && (
              <div className="w-full">
                <input
                  className="absolute opacity-0"
                  id={faq.id}
                  type="checkbox"
                  onChange={(e) => {
                    setId(e.target.id);
                    if (id === faq.id) {
                      setId("");
                    } else {
                      setId(faq.id);
                    }
                  }}
                />
                <label
                  className="flex justify-between items-center px-5 py-5 font-medium leading-normal cursor-pointer bg-darkblack text-white dark:bg-gray-700 dark:text-white border-b"
                  for={faq.id}
                >
                  <h1 className="capitalize">{faq.question}</h1>
                  <h1 className="border rounded-full h-6 w-6 flex items-center justify-center bg-gray-800 text-white">
                    {id === faq.id ? "-" : "+"}
                  </h1>
                </label>
                <div
                  className={`${
                    id === faq.id ? "flex" : " hidden"
                  }   border-l-4 border-blue-400 dark:border-red-500 w-full px-5 py-4 font-medium bg-white dark:bg-darkblack text-black dark:text-white`}
                >
                  {/* {faq.answer} */}
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </div>
              </div>
            )}
          </div>
        ))}

      {/* Pagination */}
      {/* <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-between mt-5 mb-3">
        <ul className="flex items-center justify-start cursor-pointer space-x-2">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`p-3 text-sm ${
                currentPage === number ? "bg-darkblack dark:bg-bluecolor" : "bg-bluecolor dark:bg-darkblack"
              }  text-white liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 dark:text-white">Show</span>
          <input
            type="number"
            value={faqsPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfFaqs(e.target.value)}
          />
          <span className="pl-2 dark:text-white">Entries</span>
        </div>
      </div> */}
    </div>
  );
};

export default Faqs;
