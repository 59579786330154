import React, { useState, useEffect } from "react";
import PageHeading from "../pages/PageHeading";
import { CgAsterisk } from "react-icons/cg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notify } from "../../utility";
import { API } from "../../services/API";
import { handleLogout } from "../login/handleLogOut";
import { RefreshToken } from "./../../services/RefreshToken";

const ContactSetting = (props) => {
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [titleRecord, setTitleRecord] = useState("");
  const [message, setMessage] = useState("");
  const [messageRecord, setMessageRecord] = useState("");
  const [email, setEmail] = useState("");
  const [emailRecord, setEmailRecord] = useState("");
  const [acknowledgment, setAcknowledgment] = useState("");
  const [acknowledgmentRecord, setAcknowledgmentRecord] = useState("");
  const [contactSettingError, setContactSettingError] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  useEffect(() => {
    if (access_token) {
      getData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const getData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.siteConfig;
        // console.log("response.data", response.data);

        let filteredTitle = data.filter(
          (option) => option.name === "contact_title"
        );
        setTitleRecord(filteredTitle[0]);
        setTitle(filteredTitle[0].val);

        let filteredMessage = data.filter(
          (option) => option.name === "contact_sub_title"
        );
        // console.log("filteredMessage", filteredMessage);
        setMessageRecord(filteredMessage[0]);
        setMessage(filteredMessage[0].val);

        let filteredEmail = data.filter((option) => option.name === "email");
        // console.log("filteredEmail", filteredEmail);
        setEmailRecord(filteredEmail[0]);
        setEmail(filteredEmail[0].val);

        let filteredAcknowledgement = data.filter(
          (option) => option.name === "user_contact_acknowledge"
        );
        // console.log("filteredAcknowledgement", filteredAcknowledgement);
        setAcknowledgmentRecord(filteredAcknowledgement[0]);
        setAcknowledgment(filteredAcknowledgement[0].val);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
        console.log(error);
      });
  };

  const contactSetting = () => {
    let titleError = "";
    let messageError = "";
    let emailError = "";
    let contactSettingError = false;

    if (title.trim() === "") {
      titleError = "Enter title";
      contactSettingError = true;
    }
    if (message.trim() === "") {
      messageError = "Enter message";
      contactSettingError = true;
    }
    if (email.trim() === "") {
      emailError = "Enter email";
      contactSettingError = true;
    }

    if (contactSettingError === true) {
      setTitleError(titleError);
      setMessageError(messageError);
      setEmailError(emailError);
      setContactSettingError(contactSettingError);
      return;
    }

    let dataBody = [
      {
        id: titleRecord.id,
        val: title,
        status: true,
        type: "string",
        name: titleRecord.name,
      },
      {
        id: messageRecord.id,
        val: message,
        status: true,
        type: "string",
        name: messageRecord.name,
      },
      {
        id: emailRecord.id,
        val: email,
        status: true,
        type: "string",
        name: emailRecord.name,
      },
      {
        id: acknowledgmentRecord.id,
        val: acknowledgment,
        status: true,
        type: "string",
        name: acknowledgmentRecord.name,
      },
    ];
    // console.log("dataBody", dataBody);

    dataBody.map((item) => {
      let config = {
        method: "put",
        url: API + "/siteConfigs/",
        data: item,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      //   console.log("config", config);
      axios(config)
        .then(function (response) {})
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    });
    notify("Contact settings updated successfully");
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading="Settings"
        showSaveOptionsBtn={true}
        save={true}
        createUser={true}
        handleSave={contactSetting}
      />
      <div className="w-full lg:w-8/12 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 my-4 relative ">
        <div className="shadow-md w-full">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0"
              id="tab-one"
              type="checkbox"
              checked={contactSettingError}
              onChange={() => setContactSettingError(!contactSettingError)}
              name="tabs"
            />
            <label
              className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
              for="tab-one"
            >
              Contact settings
            </label>
            <div className="tab-content overflow-hidden border-l-4 border-blue-400 dark:border-red-500 leading-normal lg:grid lg:grid-cols-3 w-full text-black dark:text-textinblack">
              <div className="p-3 flex flex-col col-span-3">
                <label className="text-sm mb-2 flex items-center">
                  Title
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <textarea
                  type="number"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleError("");
                  }}
                  className={`${
                    titleError ? "border-red-500 border" : "border"
                  } border h-20 rounded px-2 text-sm font-medium `}
                ></textarea>
                {titleError && (
                  <span className="text-red-500 text-xs">{titleError}</span>
                )}
              </div>
              <div className="p-3 flex flex-col col-span-3">
                <label className="text-sm mb-2 flex items-center">
                  Thank You Message
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <textarea
                  type="number"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageError("");
                  }}
                  className={`${
                    messageError ? "border-red-500 border" : "border"
                  } border h-20 rounded px-2 text-sm font-medium `}
                ></textarea>
                {messageError && (
                  <span className="text-red-500 text-xs">{messageError}</span>
                )}
              </div>
              <div className="p-3 flex flex-col">
                <label className="text-sm mb-2 flex items-center">
                  Contact email
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  className={`${
                    emailError ? "border-red-500 border" : "border"
                  } border h-10 rounded-lg px-2 text-sm font-medium `}
                />
                {emailError && (
                  <span className="text-red-500 text-xs">{emailError}</span>
                )}
              </div>
              <div className="p-3 flex flex-col">
                <label className="text-sm mb-2 flex items-center">
                  Sent acknowledgment to the user?
                </label>
                <select
                  className="border h-10 rounded px-2 text-sm font-medium"
                  value={acknowledgment}
                  onChange={(e) => {
                    // console.log("e.target.value", e.target.value);
                    setAcknowledgment(e.target.value);
                  }}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default ContactSetting;
