import React, { useState, useEffect } from "react";
import { CgAsterisk } from "react-icons/cg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { notify } from "../../../utility";
import { API } from "../../../services/API";
import {
  TextField,
  cleanseData
} from "utility-reactlibrary";

const Contact = () => {
  const [fName, setFName] = useState("");
  const [fnameError, setFNameError] = useState("");

  const [lName, setLName] = useState("");

  const [phone, setPhone] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");

  const [MobileNumber, setMobileNumber] = useState({ phone: "" });

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const [verified, setVerified] = useState(false);
  const [verifiedError, setVerifiedError] = useState("");

  const [AcknowledgementMessage, setAcknowledgementMessage] = useState(
    "Thank you for contacting us!"
  );

  const [ReceivedAddressData, setReceivedAddressData] = useState(false);

  const AddressTitles = [
    "company_name",
    "company_address_line1",
    "company_address_line2",
    "company_city",
    "company_state",
    "company_country",
    "company_zipcode",
    "phone",
    "googlemap_embedded",
  ];

  const [UserFormData, setUserFormData] = useState({
    company_name: "",
    company_address_line1: "",
    company_address_line2: "",
    company_city: "",
    company_state: "",
    company_country: "",
    company_zipcode: "",
    phone: "",
    googlemap_embedded: "",
  });

  let captcha;

  useEffect(() => {
    getContactSettingsData();
    getAddressDetails();
  }, []);

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };

  const getContactSettingsData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/contact_sub_title",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        setAcknowledgementMessage(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const normalizeInput = (value, previousValue) => {
    if (!value) return value; 
    
    const currentValue = value.target.value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 
  
    if (!previousValue || value.length > previousValue.length) {
  
    
      if (cvLength < 4) return currentValue; 
  
      if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`; 
  
      setPhoneNumber(currentValue);
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 9)}`; 
    }
  };

  const getAddressDetails = async () => {
    let formData = UserFormData;
    for (let index = 0; index < AddressTitles.length; index++) {
      const element = AddressTitles[index];
      let config = {
        method: "get",
        url: API + "/siteConfigs/front/name/" + element,
        headers: {
          "Content-type": "application/json",
        },
      };
      await axios(config)
        .then((response) => {
          const data = response.data;
          formData[element] = data.val;
        })
        .catch((error) => {
          console.log("error", error);
        });
      }
      setUserFormData(formData);
      setReceivedAddressData(true);
    };
    
    const resetCaptcha = () => {
    captcha.reset();
  };

  const handleRecaptcha = (value) => {
    setVerified(true);
    setVerifiedError("");
  };

  const handleSubmit = (e) => {
    let fnameError = "";
    let lnameError = "";
    let emailError = "";
    let messageError = "";
    let verifiedError = "";

    if (fName.trim() === "") {
      fnameError = "Enter First Name";
    }
    if (lName.trim() === "") {
      lnameError = "Enter Last Name";
    }
    if (email.trim() === "") {
      emailError = "Enter Email";
    }
    if (message.trim() === "") {
      messageError = "Type Your Message";
    }
    if (!verified) {
      verifiedError = "Please Enter Captcha";
    }
    if (
      fnameError !== "" ||
      lnameError !== "" ||
      emailError !== "" ||
      messageError !== "" ||
      verifiedError !== ""
    ) {
      setFNameError(fnameError);
      setEmailError(emailError);
      setMessageError(messageError);
      setVerifiedError(verifiedError);
      return;
    }

    let userMessage = {
      firstName: fName,
      lastName: lName,
      phone: cleanseData(MobileNumber.phone),
      email: email,
      message: message,
      status: true,
      page: "home",
    };


    let config = {
      method: "post",
      url: API + "/contacts/front/",
      data: userMessage,
    };
    axios(config)
      .then(function (response) {
        const data = response.data;
        notify(AcknowledgementMessage);
        setFName("");
        setLName("");
        setPhone(null);
        setPhoneNumber(null);
        setEmail("");
        setMessage("");
        setVerified(false);
        resetCaptcha();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div
        id="contact"
        className=""
      >
        <div className="bg-contact py-14">
        <div className="main-content">
          <div className="lg:w-2/2 md:w-3/4 w-5/6 lg:p-10 md:p-10 shadow-lg p-4 mx-auto bg-white dark:bg-darkblack text-black dark:text-white">
            <div className="text-center justify-center">
              <h1 className="text-xl md:text-2xl lg:text-3xl text-center mb-3">
                Leave a request and our manager will contact you.
              </h1>
            </div>
            <div className="w-full flex flex-col mb-5">
              <label className="flex items-center text-sm tracking-wide">
                First Name <CgAsterisk className="text-red-500" />
              </label>
              <input
                type="text"
                name="fName"
                value={fName}
                onChange={(e) => {
                  setFName(e.target.value);
                  setFNameError("");
                }}
                className={`${
                  fnameError && "border-red-500 border"
                } border-2 h-10 my-1 px-2`}
              />
              {fnameError && (
                <span className="text-xs tracking-wider text-red-500">
                  {fnameError}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col mb-5">
              <label htmlFor="1" className="flex items-center text-sm tracking-wide">
                Last Name
              </label>
              <input
                value={lName}
                type="text"
                name="lName"
                onChange={(e) => {
                  setLName(e.target.value);
                }}
                className="border-2 h-10 my-1 px-2"
              />
            </div>
            <div className="w-full flex flex-col mb-5">
              <label htmlFor="1" className="flex items-center text-sm tracking-wide">
                Phone
              </label>
              {/* <input
                type="text"
                name="phone"
                placeholder="xxx-xxx-xxxx"
                value={phone}
                className="border-2 h-10 my-1 px-2"
                onChange={(phone) => {
                  setPhone(prevState=> 
                     normalizeInput(phone, prevState)
                )}}
              /> */}
              <TextField
              placeholder="(999)999-9999"
              defaultValue={MobileNumber.phone}
              fieldKey="phone"
              type="tel"
              data={MobileNumber}
              setData={setMobileNumber}
              className="border-2 h-10 my-1 px-2"
              />
            </div>
            <div className="w-full flex flex-col mb-5">
              <label className="flex items-center text-sm tracking-wide">
                Email <CgAsterisk className="text-red-500" />
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                className={`${
                  emailError && "border-red-500 border"
                } border-2 h-10 my-1 px-2`}
              />
              {emailError && (
                <span className="text-xs tracking-wider text-red-500">
                  {emailError}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col mb-5">
              <label className="flex items-center text-sm tracking-wide">
                Message <CgAsterisk className="text-red-500" />
              </label>
              <textarea
                name="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessageError("");
                }}
                className={`${
                  messageError && "border-red-500 border"
                } border-2 max-h-40 my-1 px-2`}
              />
              {messageError && (
                <span className="text-xs tracking-wider text-red-500">
                  {messageError}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col mb-5 overflow-hidden">
              <ReCAPTCHA
                ref={(r) => setCaptchaRef(r)}
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={handleRecaptcha}
              />
              {verifiedError && (
                <span className="text-xs tracking-wider text-red-500">
                  {verifiedError}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col mb-5">
              <button
                onClick={(e) => handleSubmit(e)}
                className="w-1/3 bg-bluecolor hover:bg-bgdarkblack py-3 rounded text-white"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        </div>
        <div className="bg-footerbg text-white">
          <div className="main-content pt-2 pb-14 px-3">
            {ReceivedAddressData && (
              <div className="flex items-center w-full lg:justify-between mt-6 lg:mt-14 flex-col lg:flex-row">
                <div className="mb-5 lg:mb-0 w-full p-2 sm:p-2 md:p-2 sm:w-full md:w-full lg:w-auto xl:w-auto">
                  <div className="mb-2">
                    <a
                      href="tel:9999999999"
                      className=""
                    > 
                      {UserFormData.phone !== "" && UserFormData.phone}
                    </a>
                  </div>
                  <ul className="text-sm">
                    <li>
                      {UserFormData.company_name !== "" &&
                        UserFormData.company_name}
                    </li>
                    <li>
                      {" "}
                      {UserFormData.company_address_line1 !== "" &&
                        UserFormData.company_address_line1}
                    </li>
                    <li>
                      {" "}
                      {UserFormData.company_address_line2 !== "" &&
                        UserFormData.company_address_line2}
                      ,{" "}
                      {UserFormData.company_zipcode !== "" &&
                        UserFormData.company_zipcode}
                    </li>
                    <li>
                      {" "}
                      {UserFormData.company_city !== "" &&
                        UserFormData.company_city}
                      ,{" "}
                      {UserFormData.company_state !== "" &&
                        UserFormData.company_state}
                      ,{" "}
                      {UserFormData.company_country !== "" &&
                        UserFormData.company_country}
                    </li>
                  </ul>
                </div>
                <div className="overflow-hidden w-full lg:w-1/2 flex">
                  {UserFormData.googlemap_embedded !== "" && (
                    <iframe
                      src={UserFormData.googlemap_embedded}
                      className="w-full"
                      height="310"
                      style={{ border: "0" }}
                      allowFullScreen=""
                      title="map"
                    ></iframe>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
