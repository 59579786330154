import React from "react";
import { SidebarData } from "../sidebar/SidebarData";
import { Link } from "react-router-dom";
import PageHeading from "../pages/PageHeading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notify = (msg) => {
  toast.success(msg, {
    position: "top-center",
  });
};

const Dashboard = (props) => {
  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <PageHeading pageHeading="Dashboard" pageSearch={false} />
        <div className="w-full  p-5">
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full">
            {SidebarData.map((item, index) => {
              return (
                <Link
                  to={item.dashboardPath}
                  className={`bg-white dark:bg-darkblack border-bordertheme border dark:border-0 w-full h-36 md:h-40 flex flex-col items-center sm:items-start rounded-lg p-2 hover:bg-bluecolor dark:hover:bg-bluecolor text-black dark:text-textinblack hover:text-white dark:hover:text-white ${
                    index === 0 ? "hidden" : "flex"
                  }`}
                  key={index}
                >
                  <div className="text-5xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl">
                    {item.icon}
                  </div>
                  <p className="text-xl sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium mt-auto">
                    {item.title}
                  </p>
                </Link>
              );
            })}
          </div>
        </div>
        <ToastContainer />
      </div>
    // </div>
  );
};

export default Dashboard;
