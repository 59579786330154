import {
  STORE_SIGNUP_DETAILS,
  REMOVE_SIGNUP_DETAILS,
  STORE_SUBSCRIPTION_PLAN,
} from "./../actionTypes";

export const UserSignupReducer = (
  state = {
    userDetails: {},
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_SIGNUP_DETAILS:
      // console.log("STORE_SIGNUP_DETAILS", payload);
      return { ...state, userDetails: payload.userDetails };
    case STORE_SUBSCRIPTION_PLAN:
      // console.log("STORE_SUBSCRIPTION_PLAN", payload);
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          subscriptionPlanId: payload.subscriptionPlanId,
        },
      };
    case REMOVE_SIGNUP_DETAILS:
      // console.log("REMOVE_SIGNUP_DETAILS");
      return {
        ...state,
        userDetails: {},
      };

    default:
      return state;
  }
};
