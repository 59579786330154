import React, { useEffect, useRef } from "react";
import OutsideClick from "./ComponentClose";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";

const SidebarComp = (props) => {
  const boxRef = useRef(null);
  const boxOutsideClick = OutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && props.Sidebar) {
      props.hideSidebarFromMenu();
    }
  }, [boxOutsideClick]);

  return (
    <div
      ref={boxRef}
      className={`slide-navigation bg-lightblue dark:bg-darkblack ${
        props.Sidebar
          ? ""
          : "-left-96 sm:-left-0 transition transform duration-500"
      } px-1 overflow-x-hidden content-container hover:w-56 hover:sm:w-56 md:hover:w-56 lg:hover:w-56 xl:hover:w-56 box-border overflow-y-auto fixed z-10 sm:fixed md:fixed lg:fixed xl:fixed top-16 bottom-0 pb-8 flex justify-start flex-col transition-all transition-duration:200ms delay-100 ${
        props.Sidebar ? "w-56" : "w-12"
      } `}
    >
      {" "}
      {SidebarData.map((item, index) => {
        return (
          <SubMenu
            item={item}
            key={index}
            hideSidebarFromMenu={props.hideSidebarFromMenu}
            Sidebar={props.Sidebar}
          />
        );
      })}{" "}
    </div>
  );
};

export default SidebarComp;
