import React, { useCallback, useEffect, useState } from "react";
import PageHeading from "../pages/PageHeading";
import FaqsTable from "./QueAnsTable";
import axios from "axios";
import { notify } from "../../utility";

const ViewChatbotQusAns = (props) => {
  const [questions, setQuestions] = useState([]);

  let [currentPage, setCurrentPage] = useState(1);
  let [questionPerPage, setQuestionsPerPage] = useState(10);

  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);

  let [sortByQuestion, setSortByQuestion] = useState(true);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;

  const fetchQuestion = useCallback(() => {
    let config = {
      method: "get",
      url: "http://localhost:5000/chatbot/getAllQuestionAnswer",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.question;
        const sorted = data.sort((a, b) => {
          const isReverse = sortByQuestion === true ? 1 : -1;
          return isReverse * a.question.localeCompare(b.question);
        });
        setQuestionsPerPage(data.length < 10 ? data.length : 10);
        setQuestions(
          sorted.map((qus) => {
            return {
              select: false,
              id: qus.id,
              question: qus.question,
              answer: qus.answer,
              status: qus.status,
              option: qus.option,
            };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [access_token, sortByQuestion]);

  useEffect(() => {
    fetchQuestion();
  }, []);

  const indexOfLastQuestion = currentPage * questionPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionPerPage;
  const currentFaqs = questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const paginate = (number) => {
    if(number>0){
      setCurrentPage(number);
    }
  };
  const setNumberOfQuestions = (number) => {
    if(number>0){
      if(number>questions.length){
        setQuestionsPerPage(parseInt(questions.length));
      }
      setQuestionsPerPage(parseInt(number));
    }
  };
  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setQuestions(
      questions.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };
  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setQuestions(
      questions.map((qus) => {
        if (id === qus.id) {
          qus.select = checked;
        }
        return qus;
      })
    );

    const result = questions.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  const handleSearch = () => {
    let searchData = questions.filter((d) => {
      if (d.question.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
        return d;
      }
      return null;
    });
    if (searchData.length === 0) {
      setQuestions(questions);
    } else {
      setQuestions(searchData);
      setCurrentPage(1);
    }
  };
  const handleReset = () => {
    setSearch("");
    fetchQuestion();
  };
  const handleClose = () => {
    setSearch("");
    fetchQuestion();
  };
  const sortQuestions = (sortByQuestion) => {
    const sorted = questions.sort((a, b) => {
      const isReverse = sortByQuestion === true ? 1 : -1;
      return isReverse * a.question.trim().localeCompare(b.question.trim());
    });
    setQuestions(sorted);
  };
  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      questions.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      axios
        .delete("http://localhost:5000/chatbot/deleteQuestions", {
          data: {
            question_ids: arrayId,
          },
        })
        .then((response) => {
          notify(response.data.message);
          setActiveInactive(!activeInactive);
          setPageSearch(!pageSearch);
          setSelectAllCheckbox(false);
          fetchQuestion();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const handleActive = () => {
    let arrayId = [];
    questions.forEach((d) => {
      if (d.select) {
        arrayId.push(d.id);
      }
    });
    const data = {
      status: 1,
      question_ids: arrayId,
    };
    axios
      .put("http://localhost:5000/chatbot/activeInactiveQuestions", data)
      .then((response) => {
        notify(response.data.message);
        setSelectAllCheckbox(false);
        setActiveInactive(false);
        setPageSearch(true);
        fetchQuestion();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleInActive = () => {
    let arrayId = [];
    questions.forEach((d) => {
      if (d.select) {
        arrayId.push(d.id);
      }
    });
    const data = {
      status: 0,
      question_ids: arrayId,
    };
    axios
      .put("http://localhost:5000/chatbot/activeInactiveQuestions", data)
      .then((response) => {
        notify(response.data.message);
        setSelectAllCheckbox(false);
        setActiveInactive(false);
        setPageSearch(true);
        fetchQuestion();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <div
    className={`content-container relative overflow-y-auto scrollbar-hide ${props.Sidebar
      ? ""
      : ""
    }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Questions"}
          searchLabel={"Title"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/chatbot/addQuestion"
          addBtn={true}
        />
        <FaqsTable
          questions={currentFaqs}
          questionPerPage={questionPerPage}
          totalFaqs={questions.length}
          paginate={paginate}
          currentPage={currentPage}
          setNumberOfQuestions={setNumberOfQuestions}
          selectAll={selectAll}
          selectSingle={selectSingle}
          sortQuestions={sortQuestions}
          sortByQuestion={sortByQuestion}
          setSortByQuestion={setSortByQuestion}
          selectAllCheckbox={selectAllCheckbox}
          setSelectAllCheckbox={setSelectAllCheckbox}
        />
      </div>
    </div>
  );
};

export default ViewChatbotQusAns;
