import {
    FETCH_CATEGORY_REQUEST,
    FETCH_CATEGORY_SUCCESS,
    FETCH_CATEGORY_FAIL,
    DELETE_CATEGORY,
    ACTIVE_CATEGORY,
    INACTIVE_CATEGORY,
} from "../actionTypes";

export const CategoryReducer = (
    state = {
        categories: [],
        loading: false,
        error: null,
    },
    action
) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: payload,
                loading: false,
            };
        case FETCH_CATEGORY_FAIL:
            return {
                ...state,
                categories: [],
                error: payload,
                loading: false,
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                category: payload,
            };
        case ACTIVE_CATEGORY:
            return {
                ...state,
                category: payload,
            };
        case INACTIVE_CATEGORY:
            return {
                ...state,
                category: payload,
            };
        default:
            return state;
    }
};
