import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CART_PRODUCT_COUNT_INCREASE,
    CART_PRODUCT_COUNT_DECREASE,
    EMPTY_CART,
} from "../actionTypes";
import { notify } from "../../utility";

export const addToCart = (product) => (dispatch, getState) => {
    // debugger;
    const cartItems = getState().CartReducer.cartItems;

    // let alreadyExists = false;
    // if (cartItems.length > 0) {
    //     cartItems.forEach((x) => {
    //         if (x.id === product.id) {
    //             alreadyExists = true;
    //             x.count++;
    //         }
    //     });
    //     if (!alreadyExists) {
    //         cartItems.push({
    //             ...product,
    //             count: 1,
    //         });
    //     }
    // } else {
    //     cartItems.push({
    //         ...product,
    //         count: 1,
    //     });
    // }

    let alreadyExists = false;
    if (cartItems.length > 0) {
        cartItems.forEach((x) => {
            if (x.id === product.id) {
                alreadyExists = true;
                notify("Already in cart");
            }
        });
        if (!alreadyExists) {
            cartItems.push({
                ...product,
                count: 1,
            });
            notify("Product added in cart");
        }
    } else {
        cartItems.push({
            ...product,
            count: 1,
        });
        notify("Product added in cart");
    }
    dispatch({
        type: ADD_TO_CART,
        payload: cartItems,
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    // console.log("add", cartItems);
};

export const removeFromCart = (product) => (dispatch, getState) => {
    const cartItems = getState().CartReducer.cartItems.filter(
        (x) => x.id !== product.id
    );

    dispatch({
        type: REMOVE_FROM_CART,
        payload: cartItems,
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    // console.log("remove", cartItems);
};

export const increaseProductCount = (product) => (dispatch, getState) => {
    let cartItems = getState().CartReducer.cartItems;
    cartItems.forEach((x) => {
        if (x.id === product.id) {
            x.count = x.count + 1;
        }
    });
    dispatch({
        type: CART_PRODUCT_COUNT_INCREASE,
        payload: cartItems,
    });
    // console.log("increase", cartItems);
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const decreaseProductCount = (product) => (dispatch, getState) => {
    const cartItems = getState().CartReducer.cartItems;
    cartItems.forEach((x) => {
        if (x.id === product.id) {
            x.count = x.count - 1;
        }
    });
    dispatch({
        type: CART_PRODUCT_COUNT_DECREASE,
        payload: cartItems,
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    // console.log("decrease", cartItems);
};

export const emptyCart = () => (dispatch) => {
    const cartItems = [];
    dispatch({
        type: EMPTY_CART,
        payload: cartItems,
    });
    localStorage.removeItem("cartItems");
    // console.log("empty cart", cartItems);
};
