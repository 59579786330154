import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../../../utility";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const RequestForDocs = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const [docArr, setDocArr] = useState([]);
  const client = location.state.client;
  const partnerid = location.state.partnerid;
  const [dueDate, setDueDate] = useState(null);
  const [dueDateError, setDueDateError] = useState("");
  const documentList = [
    {
      id: 1,
      docType: "Aadhar Card",
    },
    {
      id: 2,
      docType: "Pan card",
    },
    {
      id: 3,
      docType: "Driving License",
    },
  ];

  const sendDocRequest = () => {
    if (dueDate === null) {
      setDueDateError("Due Date must be grater than todays date");
      return;
    }
    if (moment(dueDate).isSameOrBefore(moment())) {
      setDueDateError("Due Date must be grater than todays date");
      return;
    }

    const data = {
      partnerid: partnerid,
      clientid: client.id,
      totalprogress: docArr.length,
      docArr: docArr,
      dueDate: dueDate,
    };
    let config = {
      method: "post",
      url: "http://localhost:5000/doc/sendDocRequest",
      data: data,
    };
    if (docArr.length === 0) {
      notify("Please select document");
    } else {
      axios(config)
        .then((response) => {
          notify(response.data.message);
          navigate("/PartnerDashboard");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  return (
    <div className="w-full mt-14 px-10  pt-8 mb-14 min-h-screen  sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0  gap-4 dark:bg-gray-700 grid grid-cols-2">
      <div className="flex rounded col-span-2  sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1 w-full flex-col mt-2 border p-4 dark:bg-white overflow-hidden">
        <h1 className="text-xl lg:text-2xl xl:text-2xl font-semibold text-yellow-500 mt-2">
          Send Document Request
        </h1>
        <h1 className="font-semibold w-full capitalize text-sm my-2">
          Name: {client.name}
        </h1>
        <div className="flex flex-col justify-start items-start w-full overflow-hidden">
          <label className="font-semibold mr-2 capitalize text-sm">
            Select document For
          </label>
          <select className="border w-full p-2 font-semibold capitalize">
            <option>Select</option>
            <option>home docs</option>
            <option>car loan docs</option>
            <option>account opening docs docs</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
            Publish Date
          </label>
          <DatePicker
            selected={dueDate}
            // dateFormat="dd-MM-yyyy"
            placeholderText="Due Date"
            isClearable
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={150}
            onChange={(date) => {
              if (moment(date).isSameOrBefore(moment())) {
                setDueDateError("Due Date must be grater than todays date");
                setDueDate(date);
                return;
              } else {
                setDueDate(date);
                setDueDateError("");
              }
            }}
            className={`${
              dueDateError ? "border-red-500 border" : "border"
            } border h-10 rounded-lg px-2 text-sm font-medium w-full`}
          />
          {dueDateError && (
            <span className="text-red-500 text-xs">{dueDateError}</span>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col border rounded mt-2 text-sm font-semibold  p-4 col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1 dark:bg-white">
        <h1 className="my-2 fonts-semibold text-xl">Select Documents</h1>
        {documentList &&
          documentList.map((doc) => (
            <div className="my-2">
              <input
                type="checkbox"
                id={doc.id}
                value={doc.docType}
                onChange={(e) => {
                  let arr = docArr;
                  if (e.target.checked) {
                    arr.push(doc.docType);
                    setDocArr(arr);
                  } else {
                    if (arr.includes(doc.docType)) {
                      var index = arr.indexOf(doc.docType);
                      arr.splice(index, 1);
                      setDocArr(arr);
                    }
                  }
                }}
              />
              <label for={doc.id}> {doc.docType}</label>
            </div>
          ))}
      </div>
      <div className="col-span-2 w-full flex justify-end">
        <button
          className="border h-10 bg-yellow-500 hover:bg-yellow-400 text-white p-2 text-sm font-medium"
          type="button"
          onClick={() => sendDocRequest()}
        >
          Send Request
        </button>
      </div>
    </div>
  );
};

export default RequestForDocs;
