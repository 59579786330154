import React, { useState } from "react";
import { RiArrowDownFill, RiArrowUpFill, RiH2 } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import moment from "moment";

const BlogsTable = ({
  blogs,
  blogsPerPage,
  totalBlogs,
  paginate,
  currentPage,
  setNumberOfEvent,
  selectAll,
  selectSingle,
  selectAllCheckbox,
  setSelectAllCheckbox,
  sortTitle,
  sortByTitle,
  setSortByTitle,
  sortAuthor,
  sortByAuthor,
  setSortByAuthor,
  sortPublish,
  sortByPublish,
  setSortByPublish,
  sortModified,
  sortByModified,
  setSortByModified,
  sortBlogCategory,
  sortByBlogCategory,
  setSortByBlogCategory,
  handleActive,
  handleInActive,
  allBlogs,
  setBlogs,
}) => {
  const pageNumbers = [];
  let navigate = useNavigate();
  for (let i = 1; i <= Math.ceil(totalBlogs / blogsPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortTitleArrows, setSortTitleArrows] = useState(false);
  let [sortAuthorArrows, setSortAuthorArrows] = useState(true);
  let [sortPublishArrows, setSortPublishArrows] = useState(true);
  let [sortModifiedArrows, setSortModifiedArrows] = useState(true);
  let [sortBlogCategoryArrows, setSortBlogCategoryArrows] = useState(true);

  const selectRecord = (record) => {
    let data = allBlogs.map((item) => {
      if (item.blogId === record.blogId) {
        item.select = true;
      }
      return item;
    });
    setBlogs(data);
    if (record.active) {
      handleInActive();
    } else {
      handleActive();
    }
  };

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark">
      {blogs.length > 0 ? (
        <table className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b">
          <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
            <tr>
              <th scope="col" className="border-l-6 bg-gray-400"></th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                <input
                  checked={selectAllCheckbox}
                  onChange={(e) => {
                    setSelectAllCheckbox(!selectAllCheckbox);
                    selectAll(e);
                  }}
                  type="checkbox"
                  className="cursor-pointer"
                />
              </th>
              <th
                onClick={() => {
                  sortTitle(sortByTitle);
                  setSortByTitle(!sortByTitle);
                  setSortTitleArrows(false);
                  setSortAuthorArrows(true);
                  setSortPublishArrows(true);
                  setSortModifiedArrows(true);
                  setSortBlogCategoryArrows(true);
                }}
                scope="col"
                className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                <span className="inline-block mr-2">Title</span>
                {sortTitleArrows && (
                  <>
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortTitleArrows && (
                  <>
                    {sortByTitle === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                onClick={() => {
                  sortAuthor(sortByAuthor);
                  setSortByAuthor(!sortByAuthor);
                  setSortAuthorArrows(false);
                  setSortTitleArrows(true);
                  setSortPublishArrows(true);
                  setSortModifiedArrows(true);
                  setSortBlogCategoryArrows(true);
                }}
                scope="col"
                className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase tracking-wider"
              >
                Author
                {sortAuthorArrows && (
                  <>
                    {" "}
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortAuthorArrows && (
                  <>
                    {sortByAuthor === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                onClick={() => {
                  sortPublish(sortByPublish);
                  setSortByPublish(!sortByPublish);
                  setSortPublishArrows(false);
                  setSortTitleArrows(true);
                  setSortAuthorArrows(true);
                  setSortModifiedArrows(true);
                  setSortBlogCategoryArrows(true);
                }}
                scope="col"
                className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase tracking-wider"
              >
                Publish Date
                {sortPublishArrows && (
                  <>
                    {" "}
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortPublishArrows && (
                  <>
                    {sortByPublish === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                onClick={() => {
                  sortModified(sortByModified);
                  setSortByModified(!sortByModified);
                  setSortModifiedArrows(false);
                  setSortPublishArrows(true);
                  setSortTitleArrows(true);
                  setSortAuthorArrows(true);
                  setSortBlogCategoryArrows(true);
                }}
                scope="col"
                className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase tracking-wider"
              >
                {" "}
                Modified On
                {sortModifiedArrows && (
                  <>
                    {" "}
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortModifiedArrows && (
                  <>
                    {sortByModified === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                onClick={() => {
                  sortBlogCategory(sortByBlogCategory);
                  setSortByBlogCategory(!sortByBlogCategory);
                  setSortBlogCategoryArrows(false);
                  setSortModifiedArrows(true);
                  setSortPublishArrows(true);
                  setSortTitleArrows(true);
                  setSortAuthorArrows(true);
                }}
                scope="col"
                className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase tracking-wider"
              >
                Blog Category
                {sortBlogCategoryArrows && (
                  <>
                    {" "}
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!sortBlogCategoryArrows && (
                  <>
                    {sortByBlogCategory === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </th>
              <th
                scope="col"
                className=" px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Comment
              </th>
              <th
                scope="col"
                className=" px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Edit
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-darkblack">
            {blogs &&
              blogs.map((blog) => (
                <>
                  <tr
                    key={blog.blogId}
                    className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
                  >
                    <td
                      className={`${
                        blog.active
                          ? "border-left-green-6 bg-green-500 cursor-pointer"
                          : "border-left-red-6 bg-red-500 cursor-pointer"
                      }`}
                      onClick={(e) => {
                        selectRecord(blog);
                      }}
                    ></td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black dark:text-textinblack">
                      <input
                        checked={blog.select}
                        onChange={(e) => selectSingle(e, blog.blogId)}
                        type="checkbox"
                        className="cursor-pointer"
                      />
                    </td>
                    <td className="px-6 py-4 cursor-pointer  text-sm capitalize text-black dark:text-textinblack">
                      <span
                        onClick={() => {
                          navigate("/admin/blog/view/editBlog/" + blog.blogId);
                        }}
                        className="hover:underline pb-2 inline-block hover:text-blue-500 hover:font-semibold"
                      >
                        {blog.blogTitle}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                      {blog.authorFirstName} {blog.authorLastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                      {moment(blog.publishDate).format("MM/DD/YYYY HH:mm")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                      {blog.updatedDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                      {blog.categoryObj}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                      <span
                        onClick={() => {
                          if (blog.numberOfComments > 0) {
                            navigate("/admin/blog/view/comment/" + blog.blogId);
                          }
                        }}
                        className={
                          blog.numberOfComments > 0
                            ? "text-blue-400 underline pb-1 cursor-pointer"
                            : "pb-1"
                        }
                      >
                        Comment [{blog.numberOfComments}]
                      </span>
                    </td>
                    <td className="flex py-4 place-content-center">
                      <button
                        onClick={() => {
                          navigate("/admin/blog/view/editBlog/" + blog.blogId);
                        }}
                        className="text-bluecolor hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        <FiEdit2 size={17} />
                      </button>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="py-2 bg-gray-200 text-center text-black dark:text-textinblack ">
          <h2>No Record found</h2>
        </div>
      )}

      <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <input
            className="ml-3"
            onChange={(e) => {
              setSelectAllCheckbox(!selectAllCheckbox);
              selectAll(e);
            }}
            type="checkbox"
          />
          <label
            onClick={() => {
              sortTitle(sortByTitle);
              setSortByTitle(!sortByTitle);
              setSortTitleArrows(false);
              setSortAuthorArrows(true);
              setSortPublishArrows(true);
              setSortModifiedArrows(true);
              setSortBlogCategoryArrows(true);
            }}
            scope="col"
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
          >
            <span className="inline-block mr-2 ">Title</span>
            {sortTitleArrows && (
              <>
                <RiArrowUpFill className="inline-block ml-1" />
                <RiArrowDownFill className="inline-block ml-1" />
              </>
            )}
            {!sortTitleArrows && (
              <>
                {sortByTitle === true ? (
                  <RiArrowUpFill className="inline-block ml-2" />
                ) : (
                  <RiArrowDownFill className="inline-block ml-2" />
                )}
              </>
            )}
          </label>
        </div>

        {blogs &&
          blogs.map((blog) => (
            <div
              key={blog.blogId}
              className={`tab w-full border-t ${
                blog.active ? "border-left-green-8 " : "border-left-red-8"
              }`}
            >
              <input
                className="absolute opacity-0"
                id={blog.blogId}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor={blog.blogId}
              >
                <input
                  checked={blog.select}
                  onChange={(e) => selectSingle(e, blog.blogId)}
                  type="checkbox"
                />
                <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">{blog.blogTitle}</span>
              </label>
              <div className="tab-content border-t   overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Author:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {blog.authorFirstName} {blog.authorLastName}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">
                      Publish Date:
                    </h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {moment(blog.publishDate).format("MM/DD/YYYY HH:mm ")}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">
                      Modified on:
                    </h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {blog.updatedDate}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Category:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {blog.categoryObj}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Comments:</h1>
                    <h1
                      onClick={() => {
                        if (blog.numberOfComments > 0) {
                          navigate("/admin/blog/view/comment/" + blog.blogId);
                        }
                      }}
                      className={
                        blog.numberOfComments > 0
                          ? "text-blue-400 underline pb-1 cursor-pointer text-left pl-2"
                          : "text-left pl-2"
                      }
                    >
                      Comment [{blog.numberOfComments}]
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Edit:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      <h1
                        onClick={() => {
                          navigate("/admin/blog/view/editBlog/" + blog.blogId);
                        }}
                        className="cursor-pointer underlinetext-bluecolor hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        <FiEdit2 size={17} />
                      </h1>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
      <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
              } liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 text-black dark:text-textinblack">Show</span>
          <input
            type="number"
            min={1}
            value={blogsPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfEvent(e.target.value)}
          />
          <span className="pl-2 text-black dark:text-textinblack">Entries</span>
        </div>
      </div>
    </div>
  );
};
export default BlogsTable;
