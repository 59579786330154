import React, { useState } from "react";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const PageTable = ({
  siteconfigs,
  siteConfigPerPage,
  totalSiteConfigs,
  paginate,
  currentPage,
  setNumberOfSiteConfig,
  sortByName,
  setSortByNameDesc,
  sortName,
  sortVal,
  sortByVal,
  setSortByValDesc,
  selectAll,
  selectSingle,
  sortStatus,
  sortByStatus,
  setSortByStatus,
  selectAllCheckbox,
  setSelectAllCheckbox,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalSiteConfigs / siteConfigPerPage); i++) {
    pageNumbers.push(i);
  }
  let navigate = useNavigate();
  let [sortNameArrows, setSortNameArrows] = useState(false);
  let [sortValArrows, setSortValArrows] = useState(true);
  let [sortSatusOnArrow, setStatusOnArrow] = useState(true);

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
      <table className="min-w-full hidden sm:table md:table lg:table xl:table  divide-y divide-gray-200 border">
        <thead className="bg-gray-50 dark:bg-gray-300 dark:text-textinblack">
          <tr className="border-l-8 border-gray-400">
            {/* <th
                            scope="col"
                            className="dark:text-gray-700 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            <input
                                checked={selectAllCheckbox}
                                onChange={(e) => {
                                    selectAll(e);
                                    setSelectAllCheckbox(!selectAllCheckbox);
                                }}
                                type="checkbox"
                            />
                        </th> */}

            <th
              onClick={() => {
                sortName(sortByName);
                setSortByNameDesc(!sortByName);
                setSortNameArrows(false);
                setSortValArrows(true);
                setStatusOnArrow(true);
              }}
              scope="col"
              className="dark:text-gray-700 cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <span className="inline-block mr-2">Name</span>

              {sortNameArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortNameArrows && (
                <>
                  {sortByName === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortVal(sortByVal);
                setSortByValDesc(!sortByVal);
                setSortValArrows(false);
                setSortNameArrows(true);
                setStatusOnArrow(true);
              }}
              scope="col"
              className="dark:text-gray-700  px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Value
              {sortValArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortValArrows && (
                <>
                  {sortByVal === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>

            <th
              scope="col"
              onClick={() => {
                sortStatus(sortByStatus);
                setSortByStatus(!sortByStatus);
                setSortNameArrows(true);
                //setSortSlugArrows(true);
                //setModifiedOnArrow(false);
              }}
              className="dark:text-gray-700  px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
              {sortSatusOnArrow && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortSatusOnArrow && (
                <>
                  {sortByStatus === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>

            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-darkblack">
          {siteconfigs &&
            siteconfigs.map((siteconfig) => (
              <tr
                key={siteconfig.id}
                className={`${
                  siteconfig.status
                    ? "border-left-green-8 "
                    : "border-left-red-8"
                } hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-500 dark:border-b-gray-700 border-b`}
              >
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-textinblack">
                                    <input
                                        checked={siteconfig.select}
                                        onChange={(e) =>
                                            selectSingle(e, siteconfig.id)
                                        }
                                        type="checkbox"
                                    />
                                </td> */}
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-textinblack">
                    {siteconfig.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-textinblack">
                    {siteconfig.val}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-textinblack">
                    {siteconfig.status === true ? "Active" : "InActive"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <h1
                    onClick={() => {
                      navigate(
                        "/admin/siteconfigs/addSiteConfig/" + siteconfig.id
                      );
                    }}
                    className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                  >
                    Edit
                  </h1>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <input
            className="ml-3"
            onChange={(e) => selectAll(e)}
            type="checkbox"
          />
          <label
            onClick={() => {
              sortName(sortByName);
              setSortByNameDesc(!sortByName);
            }}
            className="px-5 py-3 text-sm font-medium leading-normal"
          >
            Title{" "}
            {sortByName === true ? (
              <RiArrowUpFill className="inline-block" />
            ) : (
              <RiArrowDownFill className="inline-block" />
            )}
          </label>
        </div>
        {siteconfigs &&
          siteconfigs.map((siteconfig) => (
            <div
              key={siteconfig.name + siteconfig.id}
              className={`tab w-full border-t ${
                siteconfig.status === true
                  ? "border-left-green-8 "
                  : "border-left-red-8"
              }`}
            >
              <input
                className="absolute opacity-0"
                id={siteconfig.id}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor={siteconfig.id}
              >
                <input
                  checked={siteconfig.select}
                  onChange={(e) => selectSingle(e, siteconfig.id)}
                  type="checkbox"
                />
                <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">{siteconfig.name}</span>
              </label>
              <div className="tab-content border-t   overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Name:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {siteconfig.name}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Val:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {siteconfig.val}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Val:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {siteconfig.satus}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Edit:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      <p
                        onClick={() => {
                          navigate("/admin/pages/addPage/" + siteconfig.id);
                        }}
                        className="text-indigo-600 underline hover:text-indigo-900 dark:text-blue-400 dark:hover:text-blue-500"
                      >
                        Edit
                      </p>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* pagination */}
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
        <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
              } liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 dark:text-textinblack">Show</span>
          <input
            type="number"
            min={1}
            value={siteConfigPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfSiteConfig(e.target.value)}
          />
          <span className="pl-2 dark:text-textinblack">Entries</span>
        </div>
      </div>
    </div>
  );
};

export default PageTable;
