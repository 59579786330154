import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CART_PRODUCT_COUNT_INCREASE,
    CART_PRODUCT_COUNT_DECREASE,
    EMPTY_CART,
} from "../actionTypes";

const initialState = {
    cartItems:
        localStorage.getItem("cartItems") === null
            ? []
            : JSON.parse(localStorage.getItem("cartItems")),
};

export const CartReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ADD_TO_CART:
            return {
                ...state,
                cartItems: payload,
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                cartItems: payload,
            };
        case CART_PRODUCT_COUNT_INCREASE:
            return {
                ...state,
                cartItems: payload,
            };
        case CART_PRODUCT_COUNT_DECREASE:
            return {
                ...state,
                cartItems: payload,
            };
        case EMPTY_CART:
            return {
                ...state,
                cartItems: payload,
            };
        default:
            return state;
    }
};
