import React, { useEffect, useState } from "react";
// import { BlogsData } from "../../blogs/BlogsData";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../../services/API";
import BlogsCategories from "./blogsCategories";
import BlogsPage from "./blogsPage";
import BlogsSearch from "./blogsSearch";

const FrontEndBlogListing = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const searchValue = location.search;
  const [BlogsData, setBlogsData] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let [blogPerPage, setBlogPerPage] = useState(3);
  let [categories, setCategories] = useState();
  let [activeCategory, setActiveCategory] = useState("all");
  const [showLoader, setLoader] = useState(false);
  let [search, setSearch] = useState("");
  let [searchIcon, setSearchIcon] = useState(true);

  const fetchBlogs = async () => {
    setLoader(true);
    let config = {
      method: "get",
      url: API + "/blogs/front/?page=0&size=-1&sort=blogTitle, asc",
    };
    await axios(config)
      .then((response) => {
        // console.log("response blogs", response.data.blogs);
        let allblogs = response.data.blogs;
        let blogs = allblogs.filter((option) => option.active === true);
        const sorted = blogs.sort((a, b) => {
          let dateA = new Date(a.publishDate);
          let dateB = new Date(b.publishDate);
          return dateB - dateA;
        });
        // console.log("sorted", sorted);
        setBlogsData(sorted);
        setBlogs(sorted);
        setSearch("");
        setSearchIcon(true);
        getCategories(sorted);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getCategories = async (data) => {
    let config = {
      method: "get",
      url: API + "/blogs/categories/front/",
    };
    await axios(config)
      .then((response) => {
        // console.log("response blogs", response.data.categorys);
        let data = response.data.categorys;
        let arr = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let obj = {
            id: element.categoryId,
            val: element.title,
            image: element.image,
            description: element.description,
          };
          arr.push(obj);
        }
        setCategories(arr);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getBlogPerPageSettings = async () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/field_blog_per_page_display",
      headers: {
        "Content-type": "application/json",
      },
    };
    await axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setBlogPerPage(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const setActiveCategoryFunc = (key) => {
    setActiveCategory(key);
  };

  const categoriesFilter = (item) => {
    // console.log("BlogsData in category filter", BlogsData);
    let blogsFilter = BlogsData.filter((val) => {
      if (val.blogCategory === item.id) {
        return val;
      }
      return null;
    });
    setBlogs(blogsFilter);
    setActiveCategory(item);
    setCurrentPage(blogsFilter);
    setCurrentPage(1);
    // currentBlogs = blogsFilter;
    setSearch("");
    setSearchIcon(true);
    navigate("/blogs");
  };

  const setAllBlog = () => {
    const sorted = BlogsData.sort((a, b) => {
      let dateA = new Date(a.publishDate);
      let dateB = new Date(b.publishDate);
      return dateB - dateA;
    });
    setBlogs(sorted);
    setActiveCategory("all");
    setCurrentPage(1);
    setSearch("");
    setSearchIcon(true);
    navigate("/blogs");
  };

  const handleSearch = () => {
    if (search !== "") {
      let searchData = BlogsData.filter((d) =>
        d.blogTitle.toLowerCase().includes(search.toLowerCase())
      );
      // console.log("searchData", searchData);
      if (searchData.length === 0) {
        setSearchIcon(false);
        setBlogs([]);
        setCurrentPage(1);
      } else {
        setBlogs(searchData);
        setSearchIcon(false);
        setCurrentPage(1);
      }
    }
  };

  const handleClose = () => {
    setSearch("");
    if (BlogsData) {
      const sorted = BlogsData.sort((a, b) => {
        let dateA = new Date(a.publishDate);
        let dateB = new Date(b.publishDate);
        return dateB - dateA;
      });
      setBlogs(sorted);
      setSearchIcon(!searchIcon);
      setCurrentPage(1);
      setSearch("");
      setActiveCategory("all");
      navigate("/blogs");
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchBlogs();
    getBlogPerPageSettings();
    setDisplayData();
  }, [searchValue]);

  const setDisplayData = () => {
    const searchData = new URLSearchParams(searchValue).get("search");
    if (searchData !== null) {
      setSearch(searchData);
    }
    // console.log("location.state", location.state);

    if (location.state) {
      if (location.state.category === "all") {
        setAllBlog();
      } else {
        if (BlogsData.length !== 0) categoriesFilter(location.state.category);
      }
    }
  };

  useEffect(() => {
    setDisplayData();
  }, [BlogsData]);

  const indexOfLastBlog = currentPage * blogPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);
  const paginate = (number) => {
    setCurrentPage(number);
  };

  return (
    <div className="main-content">
      <div className="w-full mb-6 h-full md:flex md:items-start lg:flex lg:items-start justify-center mx-auto flex-wrap">
        <h1 className="w-full text-center text-2xl capitalize font-medium text-black dark:text-white mt-24">
          {activeCategory === "all" ? "All Blogs" : `${activeCategory.val} Blogs`}
        </h1>
      </div>
      <div className="card bg-dark text-white mb-3 rounded-0 border-0">
        <img src="https://laravelcms.devdigdev.com/storage/category/fit-setting-71f874935d9a0c17bf0bcb2dad91469114d4d62d57d1632e31b7c4b1a0bf4e68abf2c9bd3da7dd70b6731f7e618eef5ccff4c17cdfe4c5488a73b785cc4eace6.jpg" class="ratio ratio-21x9 rounded-0 border-0" alt="Test1" />
        <div className="card-img-overlay d-flex flex-column justify-content-center w-100 text-center">
          <h1>QA Cat 1-Testing1234567890</h1>
        </div>
      </div>
      <div className="w-full mb-14 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 h-full md:flex md:items-start lg:flex lg:items-start justify-center mx-auto flex-wrap">
        {activeCategory === "all" ? null : (
          <>
            <div className="w-full blog-page-banner">
              <img alt="image" src={ API + "/files/download/?fileName=" + `${encodeURIComponent(activeCategory.image)}` } className="object-cover object-center w-full h-full min-w-full min-h-full"/>
            </div>
            <div className="w-full flex my-6">
              <div className="main-content mx-auto">
                {activeCategory.description}
              </div>
            </div>
          </>
        )}
        <div className="w-full px-3 md:w-4/6 lg:w-4/6">
          {/* {showLoader && (
            <Loader
              type="box-up"
              bgColor={"#0075fd"}
              // title={"Loading"}
              color={"#0075fd"}
              size={100}
            />
          )} */}
          {/* {console.log("currentBlogs,blogs", currentBlogs, blogs)} */}
          {currentBlogs.length === 0 ? (
            <div className="w-full h-screen">
              <h1 className="w-full text-4xl">No blog found</h1>
            </div>
          ) : (
            <BlogsPage
              blogs={currentBlogs}
              blogPerPage={blogPerPage}
              totalBlogs={blogs.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
        <div className="w-full md:w-2/6 lg:w-2/6 flex fle-col px-3 flex-wrap">
          {activeCategory === "all" ? (
            <BlogsSearch
              search={search}
              setSearch={setSearch}
              searchIcon={searchIcon}
              handleSearch={handleSearch}
              handleClose={handleClose}
            />
          ) : null}
          <BlogsCategories
            setAllBlog={setAllBlog}
            categories={categories}
            activeCategory={activeCategory}
            setActiveCategoryFunc={setActiveCategoryFunc}
            categoriesFilter={categoriesFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default FrontEndBlogListing;
