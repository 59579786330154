import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";

const SubscriptionEdit = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [ID, setID] = useState("");
  const [PlanName, setPlanName] = useState("");
  const [PlanNameError, setPlanNameError] = useState("");
  const [Charge, setCharge] = useState("");
  const [ChargeError, setChargeError] = useState("");
  const [DisplayOrder, setDisplayOrder] = useState(0);
  const [Status, setStatus] = useState(true);
  const [Description, setDescription] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [Period, setPeriod] = useState("");
  const [Type, setType] = useState("");
  const [PlanFeaturesDTO, setPlanFeaturesDTO] = useState([]);
  const [PlanFeaturesDTOError, setPlanFeaturesDTOError] = useState("");
  const [PlanFeatures, setPlanFeatures] = useState([]);
  const [allDisplayOrder, setAllDisplayOrder] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;

  const nextDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/users/subscriptionplan/nextdisplayorder",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const order = response.data;
        setDisplayOrder(order);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin");
          handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token]);

  const fetchAllDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/users/subscriptionplan/alldisplayorders",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const orders = response.data;
        setAllDisplayOrder(orders);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin");
          handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token]);

  const getPlanFeaturesData = () => {
    let config = {
      method: "get",
      url: API + "/users/planfeature/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(async (response) => {
        let plans = response.data.planFeatures;
        if (plans !== undefined && plans !== "" && plans !== null) {
          await setPlanFeatures(
            plans.map((data) => {
              return {
                select: false,
                title: data.title,
                createdBy: data.createdBy,
                updatedAt: data.updatedAt,
                createdAt: data.createdAt,
                displayOrder: data.displayOrder,
                id: data.id,
                status: data.status,
              };
            })
          );
        } else {
          setPlanFeatures([]);
        }
      })
      .catch(async (error) => {
        console.log("error", error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/subscriptions");
        }
      });
  };

  const getSubscriptionData = (id) => {
    let config = {
      method: "get",
      url: API + "/users/subscriptionplan/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data;
        setID(data.id);
        setPlanName(data.planName);
        setCharge(data.charge);
        setPeriod(data.period);
        setDisplayOrder(data.displayOrder);
        setStatus(data.status);
        setDescription(data.description);
        setType(data.type);
        setType(data.type);
        setPlanFeaturesDTO(data.planFeaturesDTO);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/subscriptions");
        }
        console.log(error);
      });
  };

  const SelectedDataSorting = async () => {
    let newObj = PlanFeatures;
    for (let index = 0; index < newObj.length; index++) {
      const element = newObj[index];
      let filtered = PlanFeaturesDTO.filter(
        (option) => option.id === element.id
      );
      if (filtered.length !== 0) {
        element.select = true;
      }
    }
    setPlanFeatures([...newObj]);
  };

  const handleCancelBtn = () => {
    navigate("/admin/subscriptions");
  };

  const SaveEdit = (saveclick) => {
    let PlanNameError = "";
    let ChargeError = "";
    let DescriptionError = "";
    let PlanFeaturesDTOError = "";

    if (PlanName.trim() === "") {
      PlanNameError = "Plan name required";
      setPlanNameError(PlanNameError);
    }
    if (Charge === "") {
      ChargeError = "charges are required";
      setChargeError(ChargeError);
    }
    if (Description.trim() === "") {
      DescriptionError = "Description is required";
      setDescriptionError(DescriptionError);
    }

    let filtered = PlanFeatures.filter((option) => option.select === true);
    if (filtered.length === 0) {
      PlanFeaturesDTOError = "Please select atleast one plan feature";
      setPlanFeaturesDTOError(PlanFeaturesDTOError);
    }

    if (
      PlanNameError !== "" ||
      ChargeError !== "" ||
      DescriptionError !== "" ||
      PlanFeaturesDTOError !== ""
    ) {
      return;
    }

    let data = [];
    filtered.map((item) => {
      let obj = {
        createdAt: item.createdAt,
        createdBy: item.createdBy,
        displayOrder: item.displayOrder,
        id: item.id,
        status: item.status,
        title: item.title,
      };
      data.push(obj);
    });

    let id = ID;
    if (id) {
      let dataBody = {
        id: ID,
        planName: PlanName,
        charge: Charge,
        period: Period,
        type: Type,
        description: Description,
        planFeaturesDTO: data,
        displayOrder: parseInt(DisplayOrder),
        status: Status,
      };

      let config = {
        method: "put",
        url: API + "/users/subscriptionplan/",
        data: dataBody,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Subscription updated successfully");
            navigate("/admin/subscriptions");
          } else {
            notify("Subscription updated successfully");
            let data = response.data;
            setID(data.id);
            setPlanName(data.planName);
            setCharge(data.charge);
            setPeriod(data.period);
            setDisplayOrder(data.displayOrder);
            setStatus(data.status);
            setDescription(data.description);
            setType(data.type);
            setPlanFeaturesDTO(data.planFeaturesDTO);
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/subscriptions");
          }
        });
    }
  };

  const handleSave = () => {
    SaveEdit(true);
  };

  const handleSaveAndContinue = () => {
    SaveEdit(false);
  };

  const toggleSelectedOption = async (e, item) => {
    let newObj = PlanFeatures;
    newObj.forEach((option) => {
      if (option.id === item.id) {
        option.select = e.target.checked;
      }
    });
    await setPlanFeatures([...newObj]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await getPlanFeaturesData();
    await fetchAllDisplayOrder();
    if (params.id) {
      await getSubscriptionData(params.id);
    }
  };

  useEffect(() => {
    SelectedDataSorting();
  }, [PlanFeaturesDTO]);

  return (
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading="Edit Subscription"
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        handleSave={handleSave}
        saveAndContinue={true}
        handleSaveAndContinue={handleSaveAndContinue}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Plan Name
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={PlanName}
              onChange={(e) => {
                setPlanNameError("");
                setPlanName(e.target.value);
              }}
              type="text"
              className={`${
                PlanNameError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter Plan Name"
            />
            {PlanNameError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {PlanNameError}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Charge
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={Charge}
              onChange={(e) => {
                setChargeError("");
                setCharge(e.target.value);
              }}
              type="text"
              className={`${
                ChargeError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter URL"
            />
            {ChargeError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {ChargeError}
              </span>
            )}
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Status</label>
            <select
              value={Status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Display Order</label>
            <select
              value={DisplayOrder}
              onChange={(e) => {
                setDisplayOrder(e.target.value);
              }}
              disabled={ID ? false : true}
              name="displayOrder"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              {!ID && (
                <option key={DisplayOrder} value={DisplayOrder}>
                  {DisplayOrder}
                </option>
              )}
              {ID &&
                allDisplayOrder.map((order) => (
                  <option key={order} value={order}>
                    {order}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Period</label>
            <select
              value={Period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              disabled
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option value="1">1</option>
              <option value="12">12</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Type</label>
            <select
              value={Type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              disabled
              name="Type"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option value="Recurring">Recurring</option>
              <option value="One Time">One Time</option>
            </select>
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-1 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">
              Description
              <CgAsterisk className="inline text-red-500" />
            </label>

            {Description ? (
              <Editor
                value={Description}
                init={{
                  height: 300,
                  forced_root_block: "",
                  statusbar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newValue, editor) => {
                  setDescriptionError("");
                  if (editor.getContent() === "") {
                    setDescription(" ");
                  } else {
                    setDescription(editor.getContent());
                  }
                }}
              />
            ) : null}

            {DescriptionError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {DescriptionError}
              </span>
            )}
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-1 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">
              Subscription Plan Features
              <CgAsterisk className="inline text-red-500" />
            </label>
            {PlanFeatures.length !== 0 &&
              PlanFeatures.map((item) => {
                return (
                  <div>
                    <input
                      checked={item.select === true ? true : false}
                      onChange={(e) => toggleSelectedOption(e, item)}
                      type="checkbox"
                      className="cursor-pointer"
                    />
                    {item.title}
                  </div>
                );
              })}

            {PlanFeaturesDTOError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {PlanFeaturesDTOError}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionEdit;
