import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import TimeKeeper from "react-timekeeper";
import { TextField } from "utility-reactlibrary";
import isURL from "validator/lib/isURL";
import PageHeading from "../../components/pages/PageHeading";
import { API } from "../../services/API";
import { notify } from "../../utility";

const AddEvent = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [ShowTime, setShowTime] = useState(false);
  const [Id, setId] = useState(null);
  const [Title, setTitle] = useState("");
  const [Recurrence, setRecurrence] = useState("DAILY");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [EventTime, setEventTime] = useState("00:00pm");
  const [Status, setStatus] = useState(true);
  const [URL, setURL] = useState(null);
  const [Description, setDescription] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Country, setCountry] = useState("");
  const [TitleError, setTitleError] = useState("");
  const [StartDateError, setStartDateError] = useState("");
  const [EndDateError, setEndDateError] = useState("");
  const [EventTimeError, setEventTimeError] = useState("");
  const [URLError, setURLError] = useState("");
  const [zipInfo, setZipInfo] = useState({ zipCode: "" });

  const fetchEvent = (id) => {
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let access_token = userInfo.accessToken;
    let config = {
      method: "get",
      url: API + "/events/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setId(data.id);
        setTitle(data.title);
        setRecurrence(data.recurrence);
        setStartDate(moment(data.startDate).toDate());
        setEndDate(moment(data.endDate).toDate());
        setEventTime(moment(data.eventTime, "HH:mm").format("hh:mm A"));
        setStatus(data.status);
        setURL(data.url);
        setDescription(data.description);
        setAddress1(data.address1);
        setAddress2(data.address2);
        setCity(data.city);
        setState(data.state);
        setZipInfo(data.zipcode ? data.zipcode : "" );
        setCountry(data.country);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
          ) {
            navigate("/admin/events");
        }
        console.log("error", error);
      });
  };

  useEffect(() => {
    let id = params.id;
    if (id) {
      fetchEvent(id);
    }
  }, [params.id]);

  const validateUrl = (value) => {
    if (isURL(value)) {
      setURL(value);
      setURLError("");
    } else {
      setURL(null);
      setURLError("URL must contain http:// or https://");
    }
  };

  const handleCancelBtn = () => {
    navigate(-1);
  };

  const isValidForm = () => {
    let isValid = true;
    let titleError = "";
    let startDateError = "";
    let endDateError = "";
    let eventTimeError = "";
    let urlError = "";
    let sDate = new Date(StartDate);
    let eDate = new Date(EndDate);
    if (Title.trim() === "") {
      titleError = "Enter Event title";
      isValid = false;
    }
    if (StartDate === "") {
      startDateError = "Enter Event start date";
      isValid = false;
    }
    if (sDate > eDate) {
      startDateError = "End Date is greater than or equal to the Start Date";
      isValid = false;
    }
    if (EndDate === "") {
      endDateError = "Enter Event end date";
      isValid = false;
    }
    if (EventTime === "00:00pm") {
      eventTimeError = "Select Event time";
      isValid = false;
    }
    if (
      URL &&
      !(
        URL.toLowerCase().startsWith("http://") ||
        URL.toLowerCase().startsWith("https://")
      )
    ) {
      urlError = "The url format is invalid";
      isValid = false;
    }
    if (
      titleError !== "" ||
      startDateError !== "" ||
      endDateError !== "" ||
      eventTimeError !== "" ||
      urlError !== ""
    ) {
      setTitleError(titleError);
      setStartDateError(startDateError);
      setEndDateError(endDateError);
      setEventTimeError(eventTimeError);
      setURLError(urlError);

      isValid = false;
    }

    if (isValid) {
      setTitleError("");
      setStartDateError("");
      setEndDateError("");
      setEventTimeError("");
      setURLError("");
    }

    return isValid;
  };

  const SaveEdit = (saveclick) => {
    let event = {
      id: Id,
      title: Title,
      recurrence: Recurrence,
      startDate: moment(StartDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
      endDate: moment(EndDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
      eventTime: moment(EventTime, "hh:mm A").format("HH:mm a"),
      status: Status,
      url: URL,
      description: Description,
      address1: Address1,
      address2: Address2,
      city: City,
      state: State,
      zipcode: zipInfo.zipCode,
      country: Country,
    };
    
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let access_token = userInfo.accessToken;
    const isValid = isValidForm();

    if (isValid) {
      if (Id) {
        let config = {
          method: "put",
          url: API + "/events/",
          data: event,
          headers: {
            "Content-type": "application/json",
            Authorization: `${access_token}`,
          },
        };
        axios(config)
          .then((response) => {
            if (saveclick) {
              notify("Event updated successfully");
              navigate("/admin/events");
            } else {
              notify("Event updated successfully");
              const data = response.data;
              setId(data.id);
              setTitle(data.title);
              setRecurrence(data.recurrence);
              setStartDate(moment(data.startDate).toDate());
              setEndDate(moment(data.endDate).toDate());
              setEventTime(moment(data.eventTime, "HH:mm").format("hh:mm A"));
              setStatus(data.status);
              setURL(data.url);
              setDescription(data.description);
              setAddress1(data.address1);
              setAddress2(data.address2);
              setCity(data.city);
              setState(data.state);
              setZipInfo({ zipCode: data.zipcode });
              setCountry(data.country);
            }
          })
          .catch((error) => {
            if (
              error.status === "401" &&
              error.message === "JWT token is expired"
            ) {
              navigate("/admin/events");
            }
            console.log(error);
          });
      } else {
        let event = {
          title: Title,
          recurrence: Recurrence,
          startDate: moment(StartDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
          endDate: moment(EndDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
          eventTime: moment(EventTime, "hh:mm A").format("HH:mm a"),
          status: Status,
          url: URL,
          description: Description,
          address1: Address1,
          address2: Address2,
          city: City,
          state: State,
          zipcode: zipInfo.zipCode,
          country: Country,
        };
        let config = {
          method: "post",
          url: API + "/events/",
          data: event,
          headers: {
            "Content-type": "application/json",
            Authorization: `${access_token}`,
          },
        };
        axios(config)
          .then((response) => {
            if (saveclick) {
              notify("Event added successfully");
              navigate("/admin/events");
            } else {
              notify("Event added successfully");
              const data = response.data;
              setId(data.id);
              setTitle(data.title);
              setRecurrence(data.recurrence);
              setStartDate(moment(data.startDate).toDate());
              setEndDate(moment(data.endDate).toDate());
              setEventTime(moment(data.eventTime, "HH:mm").format("hh:mm A"));
              setStatus(data.status);
              setURL(data.url);
              setDescription(data.description);
              setAddress1(data.address1);
              setAddress2(data.address2);
              setCity(data.city);
              setState(data.state);
              setZipInfo({ zipCode: data.zipcode });
              setCountry(data.country);
            }
          })
          .catch((error) => {
            if (
              error.status === "401" &&
              error.message === "JWT token is expired"
            ) {
              navigate("/admin/events");
            }
            console.log(error);
          });
      }
    }
  };

  const handleSave = () => {
    SaveEdit(true);
  };

  const handleSaveAndContinue = () => {
    SaveEdit(false);
  };

  return (
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading={Id ? "Edit Event" : "Add Event"}
          pageSearch={false}
          showSaveOptionsBtn={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          save={true}
          handleSave={handleSave}
          saveAndContinue={true}
          handleSaveAndContinue={handleSaveAndContinue}
        />
        <div className="w-full lg:w-8/12 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 flex items-center pt-4 pb-4">
          <div className="grid lg:grid-cols-2  gap-5 w-full">
            <div className="col-span-2 grid grid-cols-1 gap-5  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:w-full md:w-full lg:w-full xl:w-full">
              <div className="flex flex-col w-full">
                <label
                  htmlFor="title"
                  className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
                >
                  Title
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <input
                  type="text"
                  value={Title}
                  className={`${
                    TitleError ? "border-red-500 border" : "border"
                  } border h-10 rounded-lg px-2 text-sm font-medium `}
                  placeholder="Event Title"
                  onChange={(e) => {
                    setTitleError("");
                    setTitle(e.target.value);
                  }}
                  name="title"
                />
                {TitleError && (
                  <span className="text-red-500 text-xs">{TitleError}</span>
                )}
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="recurrence"
                  className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
                >
                  Recurrence
                </label>
                <select
                  value={Recurrence}
                  onChange={(e) => {
                    setRecurrence(e.target.value);
                  }}
                  name="recurrence"
                  className="border h-10 rounded px-2  text-sm font-medium"
                >
                  <option value="DAILY">Daily</option>
                  <option value="WEEKLY">Weekly</option>
                  <option value="MONTHLY">Monthly</option>
                  <option value="YEARLY">Yearly</option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-1  col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <div className="flex flex-col">
                <label
                  htmlFor="startDate"
                  className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
                >
                  Start Date
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <DatePicker
                  selected={StartDate}
                  placeholderText="Start Date"
                  isClearable
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={150}
                  onChange={(date) => {
                    setStartDateError("");
                    setStartDate(date);
                  }}
                  className={`${
                    StartDateError ? "border-red-500 border" : "border"
                  } border h-10 rounded-lg px-2 text-sm font-medium w-full `}
                />
                {StartDateError && (
                  <span className="text-red-500 text-xs">{StartDateError}</span>
                )}
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="endDate"
                  className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
                >
                  End Date
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <DatePicker
                  selected={EndDate}
                  placeholderText="End Date"
                  isClearable
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={150}
                  onChange={(date) => {
                    setEndDateError("");
                    setEndDate(date);
                  }}
                  className={`${
                    EndDateError ? "border-red-500 border" : "border"
                  } border h-10 rounded-lg px-2 text-sm font-medium w-full `}
                />
                {EndDateError && (
                  <span className="text-red-500 text-xs">{EndDateError}</span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-1 col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <div className="flex flex-col w-full overflow-hidden">
                <label
                  htmlFor="eventTime"
                  className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
                >
                  Event Time
                  <CgAsterisk className="inline text-red-500" />
                </label>
                {ShowTime && (
                  <div className="w-full overflow-hidden">
                    <TimeKeeper
                      time={EventTime}
                      onChange={(newTime) => setEventTime(newTime.formatted12)}
                      onDoneClick={() => {
                        setShowTime(false);
                      }}
                    />
                  </div>
                )}

                {!ShowTime && (
                  <input
                    value={EventTime}
                    className={`${
                      EventTimeError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium w-full `}
                    onClick={() => {
                      setEventTimeError("");
                      setShowTime(true);
                    }}
                    onChange={() => {}}
                  />
                )}
                {EventTimeError && (
                  <span className="text-red-500 text-xs">{EventTimeError}</span>
                )}
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="status" className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                  Status
                </label>
                <select
                  name="status"
                  value={Status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  className="border h-10 rounded px-2  text-sm font-medium"
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col w-full lg:col-span-1 sm:col-span-2">
              <label htmlFor="url" className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                URL
              </label>
              <input
                type="text"
                value={URL}
                className={`${
                  URLError ? "border-red-500 border" : "border"
                } border h-10 rounded-lg px-2 text-sm font-medium `}
                placeholder="Url"
                onChange={(e) => {
                  setURL(e.target.value);
                }}
                name="url"
              />
              <span className="text-sm my-1 tracking-wider text-black dark:text-textinblack">
                Url must contain <b>http://</b> or <b>https://</b>
              </span>
              {URLError && (
                <span className="text-red-500 text-xs">{URLError}</span>
              )}
            </div>
            <div className="flex flex-col w-full col-span-2">
              <label
                htmlFor="description"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Description
              </label>
              {Id ? (
                <>
                  {Description ? (
                    <Editor
                      value={Description}
                      init={{
                        height: 300,
                        forced_root_block: "",
                        statusbar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(newValue, editor) => {
                        if (editor.getContent() === "") {
                          setDescription(" ");
                        } else {
                          setDescription(editor.getContent());
                        }
                      }}
                    />
                  ) : null}
                </>
              ) : (
                <Editor
                  value={Description}
                  init={{
                    height: 300,
                    forced_root_block: "",
                    statusbar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onEditorChange={(newValue, editor) => {
                    setDescription(editor.getContent());
                  }}
                />
              )}
            </div>
            <div className="flex flex-col w-full col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <label
                htmlFor="address1"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Address 1
              </label>
              <input
                type="text"
                name="address1"
                value={Address1}
                placeholder="Enter Address 1"
                className="border h-10 rounded-lg px-2 text-sm font-medium "
                onChange={(e) => {
                  console.log("address 1", e.target.value)
                  setAddress1(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col w-full col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <label
                htmlFor="address2"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Address 2
              </label>
              <input
                type="text"
                name="address2"
                placeholder="Enter Address 2"
                value={Address2}
                className="border h-10 rounded-lg px-2 text-sm font-medium "
                onChange={(e) => {
                  setAddress2(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col w-full col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <label htmlFor="city" className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                City
              </label>
              <input
                type="text"
                name="city"
                placeholder="Enter City"
                value={City}
                className="border h-10 rounded-lg px-2 text-sm font-medium "
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col w-full col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <label htmlFor="state" className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
                State
              </label>
              <input
                type="text"
                name="state"
                placeholder="Enter State"
                value={State}
                className="border h-10 rounded-lg px-2 text-sm font-medium "
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col w-full col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <label
                htmlFor="zipCode"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Zipcode
              </label>
              <TextField
                key="zip"
                placeholder="Enter Zipcode"
                className="border h-10 rounded-lg px-2 text-sm font-medium "
                value={zipInfo.zipCode}
                defaultValue={zipInfo.zipCode}
                name="zipCode"
                type="zip"
                data={zipInfo}
                setData={setZipInfo}
                onChangeRetZip={(value) => {
                  if (value.indexOf("-") === -1) {
                    setZipInfo({ ...zipInfo, zipCode: value });
                  }
                }}
              />
            </div>
            <div className="flex flex-col w-full col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1">
              <label
                htmlFor="country"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Country
              </label>
              <input
                type="text"
                name="country"
                value={Country}
                placeholder="Enter Country"
                className="border h-10 rounded-lg px-2 text-sm font-medium "
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default AddEvent;
