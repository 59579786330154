import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CgAsterisk } from "react-icons/cg";
import { AiOutlineShareAlt, AiOutlineSearch } from "react-icons/ai";
import { RiArrowDropLeftFill, RiArrowDropRightFill } from "react-icons/ri";
import ReCAPTCHA from "react-google-recaptcha";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  WeiboShareButton,
} from "react-share";
import axios from "axios";
import { API } from "../../../services/API";
import { notify } from "../../../utility";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import BlogsCategories from "./blogsCategories";
import { InlineShareButtons } from "sharethis-reactjs";
import { StickyShareButtons } from "sharethis-reactjs";

let captcha;
const BlogDescription = (props) => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [blogData, setBlogData] = useState({});
  const [categories, setCategories] = useState([]);
  const [nextBlog, setNextBlog] = useState(null);
  const [previousBlog, setPreviousBlog] = useState(null);
  const [nextBlogName, setNextBlogName] = useState("");
  const [previousBlogName, setPreviousBlogName] = useState("");
  const [verified, setVerified] = useState(true);
  const [verifiedError, setVerifiedError] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [comment, setComment] = useState("");
  const [fNameError, setFNameError] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [commentError, setCommentError] = useState("");
  const [AllSocialIcon, setAllSocialIcon] = useState(false);
  const [activeCategory, setActiveCategory] = useState("all");
  const [search, setSearch] = useState("");
  let navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.scroll(0, 0);
    let id = params.id;
    setBlog(id);
  }, []);

  const setBlog = (id) => {
    let config = {
      method: "get",
      url: API + "/blogs/front/?page=0&size=-1",
    };
    axios(config)
      .then((response) => {
        let blogs = response.data.blogs;
        let selectedBlog = blogs.filter((option) => option.blogId === id);
        setAllBlogs(blogs);
        setBlogData(selectedBlog[0]);
        getCategories(blogs);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getCategories = () => {
    let config = {
      method: "get",
      url: API + "/blogs/categories/front/",
    };
    axios(config)
      .then((response) => {
        // console.log("response blogs", response.data.categorys);
        let data = response.data.categorys;
        let arr = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let obj = {
            id: element.categoryId,
            val: element.title,
            image: element.image,
            description: element.description,
          };
          arr.push(obj);
        }
        setCategories(arr);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleRecaptcha = (value) => {
    // console.log("captcha value", value);
    setVerified(true);
    setVerifiedError("");
  };

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };
  const postComment = () => {
    let fNameError = "";
    let lNameError = "";
    let commentError = "";
    let verifiedError = "";

    if (fName.trim() === "") {
      fNameError = "Enter first name";
    }
    if (lName.trim() === "") {
      lNameError = "Enter last name";
    }
    if (comment.trim() === "") {
      commentError = "Enter comment";
    }
    if (!verified) {
      verifiedError = "Please Enter Captcha";
    }
    if (
      fNameError !== "" ||
      lNameError !== "" ||
      commentError !== "" ||
      verifiedError !== ""
    ) {
      setFNameError(fNameError);
      setLNameError(lNameError);
      setCommentError(commentError);
      setVerifiedError(verifiedError);
      return;
    }
    let commentBody = {
      firstName: fName,
      lastName: lName,
      comments: comment,
      blogId: params.id,
    };

    // console.log("commentBody", commentBody);

    let config = {
      method: "post",
      url: API + "/blogs/comments/front/",
      data: commentBody,
    };

    axios(config)
      .then((response) => {
        // console.log("response comment", comment);
        resetCaptcha();
        notify("Comment added successfully");
        setFName("");
        setLName("");
        setComment("");
        setVerified(false);
        setFNameError("");
        setLNameError("");
        setCommentError("");
        setVerifiedError("");
        setBlog(params.id);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <h1 className="w-full text-center mb-4 text-2xl capitalize font-medium text-black dark:text-white mt-24">Blog</h1>
      <div className="main-content">
        <div className="w-full mb-14 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 h-full md:flex md:items-start lg:flex lg:items-start justify-center mx-auto flex-wrap">
          <div className="w-full px-3 md:w-4/6 lg:w-4/6 text-black dark:text-white">
            <div className="w-full mb-10">
              <h1 className="w-full line-clamp-1 text-2xl capitalize text-blue-400">
                {blogData.blogTitle}
              </h1>
              <p className="w-full mt-5 mb-5 text-xs font-medium text-gray-400">
                by{" "}
                <span className="px-1 font-bold text-gray-600 dark:text-white">
                  {blogData.authorFirstName} {blogData.authorLastName}
                </span>
                Posted On {moment(blogData.publishDate).format("y-MM-DD HH:mm a")}
              </p>
              <div className="w-full">
                <img
                  src={
                    API +
                    "/files/download/?fileName=" +
                    `${encodeURIComponent(blogData.blogImage)}`
                  }
                  alt={blogData.blogImageAlt}
                  className="w-full  object-contain"
                />
              </div>

              <p className="my-5">
                <div
                  dangerouslySetInnerHTML={{ __html: blogData.blogDescription }}
                />
              </p>
            </div>
            <div className="w-full my-4 px-6 py-3 flex space-x-2 justify-center">
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 20, // font size for the buttons
                  labels: "null", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    "linkedin",
                    "facebook",
                    "twitter",
                    "email",
                    "pinterest",
                    "sharethis",
                  ],
                  padding: 12, // padding within buttons (INTEGER)
                  radius: 4, // the corner radius on each button (INTEGER)
                  show_total: false,
                  size: 40, // the size of each button (INTEGER)
                  url:
                    "https://reactjscms.devdigdev.com" + window.location.pathname, // (defaults to current url)
                }}
              />
            </div>

            <div className="w-full my-4 px-6 py-3 flex justify-between">
              {previousBlog && previousBlog.blogId !== blogData.blogId && (
                <button
                  className="border-blue-400 border-2 rounded text-xs h-10 px-2 py-1 flex items-center dark:bg-white"
                  onClick={() => {
                    this.setBlog(previousBlog.blogId);
                    navigate({
                      pathname: `/blog/${previousBlog.blogId}`,
                    });
                  }}
                >
                  <RiArrowDropLeftFill size={30} className="text-blue-400" />
                  {previousBlogName}
                </button>
              )}
              {nextBlog && nextBlog.blogId !== blogData.blogId && (
                <button
                  className="border-blue-400 border-2 rounded text-xs  h-10 px-1 py-1 flex items-center overflow-hidden ml-auto dark:bg-white"
                  onClick={() => {
                    setBlog(nextBlog.blogId);
                    navigate({
                      pathname: `/blog/${nextBlog.blogId}`,
                    });
                  }}
                >
                  {nextBlogName}
                  <RiArrowDropRightFill size={30} className="text-blue-400" />
                </button>
              )}
            </div>
            <div className="w-full my-4 px-6 py-3 border rounded bg-white dark:bg-gray-700">
              <h1 className="w-full text-lg">Post Comment</h1>
              <div className="w-full flex justify-between mt-3 flex-col sm:flex-row lg:flex-row xl:flex-row">
                <div className="flex flex-col w-full sm:w-5/12 lg:w-5/12 xl:w-5/12">
                  <label className="text-sm">
                    First Name
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={fName}
                    onChange={(e) => {
                      setFName(e.target.value);
                      setFNameError("");
                    }}
                    className="w-full h-10 rounded mt-2 border-2"
                  />
                  {fNameError && (
                    <span className="text-red-500 text-xs">{fNameError}</span>
                  )}
                </div>
                <div className="flex flex-col w-full sm:w-5/12 lg:w-5/12 xl:w-5/12">
                  <label className="text-sm">
                    Last Name
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    value={lName}
                    onChange={(e) => {
                      setLName(e.target.value);
                      setLNameError("");
                    }}
                    type="text"
                    className="w-full h-10 rounded mt-2 border-2"
                  />
                  {lNameError && (
                    <span className="text-red-500 text-xs">{lNameError}</span>
                  )}
                </div>
              </div>
              <div className="w-full flex justify-between mt-3 flex-col">
                <label className="text-sm">
                  Comment
                  <CgAsterisk className="inline text-red-500" />
                </label>
                <textarea
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    setCommentError("");
                  }}
                  className="w-full border-2 h-20 rounded mt-2"
                ></textarea>
                {commentError && (
                  <span className="text-red-500 text-xs">{commentError}</span>
                )}
              </div>
              <div className="w-full mt-3 overflow-hidden">
                <ReCAPTCHA
                  ref={(r) => setCaptchaRef(r)}
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={handleRecaptcha}
                />
                {verifiedError && (
                  <span className="text-xs tracking-wider text-red-500">
                    {verifiedError}
                  </span>
                )}
              </div>
              <div className="w-full mt-3 ">
                <button
                  onClick={() => postComment()}
                  className="px-3 py-2 bg-blue-400 text-white text-sm rounded"
                >
                  Submit
                </button>
              </div>
            </div>

            {blogData.comments && blogData.comments.blogsComments.length > 0 && (
              <div className="w-full my-4 text-2xl dark:text-white">
                Read Comments
              </div>
            )}

            {blogData.comments &&
              blogData.comments.blogsComments.length > 0 &&
              blogData.comments.blogsComments.map((c) => (
                <>
                  {c.status && (
                    <div className="w-full my-4 py-3 px-2 flex flex-col border bg-white dark:bg-gray-700 rounded">
                      <div className="w-full flex justify-between">
                        <h1 className="dark:text-white capitalize">
                          {c.firstName} {c.lastName}
                          <span className="text-xs text-gray-400 pl-2">says</span>
                        </h1>
                        <h1 className="dark:text-white text-sm">
                          {moment(c.createdAt).format("MM-DD-YYYY hh:mm a")}
                        </h1>
                      </div>
                      <h1 className="dark:text-gray-300 mt-2">{c.comments}</h1>
                    </div>
                  )}
                </>
              ))}

            <div className="w-full my-4 py-3 flex justify-between">
              <button
                className="bg-gray-700 px-3 py-2 rounded text-sm text-white dark:bg-white dark:text-gray-700"
                onClick={() => {
                  navigate("/blogs");
                }}
              >
                Back to blog list
              </button>
            </div>
          </div>
          <div className="w-full md:w-2/6 lg:w-2/6 flex fle-col px-3 flex-wrap">
            <div className="w-full flex flex-col border mb-4 rounded-md dark:bg-gray-800 bg-white">
              <div className="w-full border-b h-10 flex items-center pl-3 bg-gray-50">
                <label>Search</label>
              </div>
              <div className="w-full flex h-20 pl-3  items-center">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  className="h-10 border w-3/4 rounded-tl-md rounded-bl-md px-3"
                />

                <button
                  onClick={() => {
                    // props.handleSearch();
                    if (search !== "") {
                      navigate("/blogs?search=" + search);
                    }
                  }}
                  className="h-10 w-10 cursor-pointer flex items-center justify-center border-r border-t border-b bg-gray-200 rounded-tr-md rounded-br-md"
                >
                  <AiOutlineSearch />
                </button>
              </div>
            </div>

            <div className="w-full flex flex-col border my-4 rounded-md dark:bg-gray-800 bg-white">
              <div className="w-full border-b h-10 flex items-center pl-3 bg-gray-50">
                <label>Categories</label>
              </div>
              <div className="w-full flex pl-3  items-center my-2">
                <ul className="">
                  {/* <li
                    onClick={() => {
                      navigate({
                        pathname: "/blogs",
                        state: {
                          category: "all",
                        },
                      });
                    }}
                    className="text-sm underline text-blue-400 capitalize mb-2 mt-2 cursor-pointer hover:text-blue-500"
                  >
                    All Blogs
                  </li> */}
                  {categories &&
                    categories.map((item, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            navigate({
                              pathname: "/blogs",
                              state: {
                                category: item,
                              },
                            });
                          }}
                          className="text-sm underline text-blue-400 capitalize mb-2 cursor-pointer hover:text-blue-500"
                        >
                          {item.val}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDescription;
