import React, { useState } from "react";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

const CategoriesTable = ({
  categories,
  categoryPerPage,
  totalCategories,
  paginate,
  currentPage,
  setNumberOfEvent,
  selectAll,
  selectSingle,
  sortTitle,
  sortByTitle,
  setSortByTitle,
  sortSlug,
  sortBySlug,
  setSortBySlug,
  selectAllCheckbox,
  setSelectAllCheckbox,
  handleActive,
  handleInActive,
  allCategories,
  setCategories,
}) => {
  const pageNumbers = [];
  let navigate = useNavigate();
  for (let i = 1; i <= Math.ceil(totalCategories / categoryPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortTitleArrows, setSortTitleArrows] = useState(false);
  let [sortSlugArrows, setSortSlugArrows] = useState(true);

  const selectRecord = (record) => {
    let data = allCategories.map((item) => {
      if (item.categoryId === record.categoryId) {
        item.select = true;
      }
      return item;
    });
    setCategories(data);
    if (record.active) {
      handleInActive();
    } else {
      handleActive();
    }
  };

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark">
      <table className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b">
        <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
          <tr>
            <th scope="col" className="border-l-6 bg-gray-400"></th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <input
                checked={selectAllCheckbox}
                onChange={(e) => {
                  setSelectAllCheckbox(!selectAllCheckbox);
                  selectAll(e);
                }}
                type="checkbox"
                className="cursor-pointer"
              />
            </th>
            <th
              onClick={() => {
                sortTitle(sortByTitle);
                setSortByTitle(!sortByTitle);
                setSortTitleArrows(false);
                setSortSlugArrows(true);
              }}
              scope="col"
              className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2">Title</span>
              {sortTitleArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortTitleArrows && (
                <>
                  {sortByTitle === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortSlug(sortBySlug);
                setSortBySlug(!sortBySlug);
                setSortSlugArrows(false);
                setSortTitleArrows(true);
              }}
              scope="col"
              className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Slug
              {sortSlugArrows && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortSlugArrows && (
                <>
                  {sortBySlug === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Edit
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-darkblack">
          {categories &&
            categories.map((category) => (
              <tr
                key={category.categoryId}
                className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
              >
                <td
                  className={`${
                    category.active === true
                      ? "border-left-green-6 bg-green-500 cursor-pointer"
                      : "border-left-red-6 bg-red-500 cursor-pointer"
                  }`}
                  onClick={(e) => {
                    selectRecord(category);
                  }}
                ></td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-black dark:text-textinblack">
                  <input
                    checked={category.select}
                    onChange={(e) => selectSingle(e, category.categoryId)}
                    type="checkbox"
                    className="cursor-pointer"
                  />
                </td>
                <td className="px-6 py-4 cursor-pointer  text-sm capitalize text-black dark:text-textinblack">
                  <span
                    onClick={() => {
                      navigate({
                        pathname: "/admin/categories/addCategory",
                        category: category,
                      });
                    }}
                    className="hover:underline pb-2 inline-block hover:text-blue-500 hover:font-semibold"
                  >
                    {category.title}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                  {category.slug}
                </td>
                <td className="flex py-4 place-content-center">
                  <button
                    onClick={() => {
                      navigate(
                        "/admin/categories/addCategory/" + category.categoryId
                      );
                    }}
                    className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                  >
                    <FiEdit2 size={17} />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="overflow-hidden block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <input
            className="ml-3"
            checked={selectAllCheckbox}
            onChange={(e) => {
              setSelectAllCheckbox(!selectAllCheckbox);
              selectAll(e);
            }}
            type="checkbox"
          />
          <label
            onClick={() => {
              sortTitle(sortByTitle);
              setSortByTitle(!sortByTitle);
              setSortTitleArrows(false);
              setSortSlugArrows(true);
            }}
            className="px-5 py-3 text-sm font-medium leading-normal"
          >
            Categories{" "}
            {sortTitleArrows && (
              <>
                <RiArrowUpFill className="inline-block ml-1" />
                <RiArrowDownFill className="inline-block ml-1" />
              </>
            )}
          </label>
        </div>
        {categories &&
          categories.map((category) => (
            <div
              key={category.categoryId}
              className={`tab w-full border-t ${
                category.active === true
                  ? "border-left-green-8 "
                  : "border-left-red-8"
              }`}
            >
              <input
                className="absolute opacity-0"
                id={category.categoryId}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                for={category.categoryId}
              >
                <input
                  checked={category.select}
                  onChange={(e) => selectSingle(e, category.categoryId)}
                  type="checkbox"
                />
                <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">{category.title}</span>
              </label>
              <div className="tab-content border-t   overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Title:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {category.title}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Slug</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {category.slug}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Edit:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      <h1
                        onClick={() => {
                          navigate(
                            "/admin/categories/addCategory" +
                              category.categoryId
                          );
                        }}
                        className="text-indigo-600 underline cursor-pointer hover:text-indigo-900 dark:text-blue-400 dark:hover:text-blue-500"
                      >
                        Edit
                      </h1>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* pagination */}
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
      <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
              } liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 text-black dark:text-textinblack">Show</span>
          <input
            type="number"
            min={1}
            value={categoryPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfEvent(e.target.value)}
          />
          <span className="pl-2 text-black dark:text-textinblack">Entries</span>
        </div>
      </div>
    </div>
  );
};
export default CategoriesTable;
