import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../Redux/actions/cartActions";

const Product = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [product, setProduct] = useState({});
  useEffect(() => {
    if (props.product) {
      setProduct(props.product);
    }
  }, [props]);
  return (
    <div
      className="bg-gray-300 rounded w-full overflow-hidden"
      key={product.id}
    >
      <div className="w-full h-24 sm:h-40 md:h-40 lg:h-40 xl:h-40 bg-white p-2">
        <img
          className="w-full h-full object-contain"
          src={product.image}
          alt={product.title}
        ></img>
      </div>
      <div className="w-full flex items-center justify-center p-2 sm:p-2 lg:p-2 xl:p-2">
        <h1 className="w-full line-clamp-2 sm:line-clamp-1 md:line-clamp-1 lg:line-clamp-1 xl:line-clamp-1 font-medium text-xs sm:text-sm  md:text-sm  lg:text-sm  xl:text-sm overflow-hidden">
          {product.title}
        </h1>
        <h1 className="font-medium text-yellow-500">${product.price}</h1>
      </div>
      <div className="w-full flex items-center justify-evenly sm:justify-end md:justify-end  lg:justify-end xl:justify-end space-x-2  px-0 py-2 sm:p-2 lg:p-2 xl:p-2">
        <button
          onClick={() => {
            dispatch(addToCart(product));
          }}
          className="px-1 sm:px-2 md:px-2 lg:px-2 xl:px-2 py-1  bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 border-2 border-yellow-500 text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm  font-normal sm:font-medium rounded"
        >
          Add To Cart
        </button>
        <button
          onClick={() =>
            navigate(
              `/product/${product.id}/${props.category}/${props.subCategory}`
            )
          }
          className="px-1 sm:px-2 md:px-2 lg:px-2 xl:px-2  py-1 bg-yellow-500 hover:bg-white hover:text-yellow-500  text-white border-2 border-transparent hover:border-yellow-500 text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm font-normal sm:font-medium rounded"
        >
          View more
        </button>
      </div>
    </div>
  );
};

export default Product;
