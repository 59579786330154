import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FETCH_BLOGS_REQUEST } from "../../Redux/actionTypes";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";
import BlogsTable from "./BlogsTable";

const BlogsViewAll = (props) => {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);

  const [search, setSearch] = useState("");
  const [pageSearch, setPageSearch] = useState(true);
  const [activeInactive, setActiveInactive] = useState(false);
  const [status, setStatus] = useState("");

  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [currentPage, setCurrentPage] = useState(1);
  let [blogsPerPage, setBlogsPerPage] = useState(10);

  let [sortByTitle, setSortByTitle] = useState(true);
  let [sortByAuthor, setSortByAuthor] = useState(false);
  let [sortByPublish, setSortByPublish] = useState(false);
  let [sortByModified, setSortByModified] = useState(false);
  let [sortByBlogCategory, setSortByBlogCategory] = useState(false);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");
  let userCred = JSON.parse(localStorage.getItem("user"));

  const fetchBlogs = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/blogs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${userCred?.accessToken}`,
      },
    };
    dispatch({
      type: FETCH_BLOGS_REQUEST,
    });
    axios(config)
      .then(function (response) {
        const data = response.data.blogs;
        if (data !== undefined || data !== null || data !== "") {
          getperPageData(data);
          setBlogs(
            data.map((blog) => {
              return {
                select: false,
                blogId: blog.blogId,
                blogTitle: blog.blogTitle,
                authorFirstName: blog.authorFirstName,
                authorLastName: blog.authorLastName,
                publishDate: blog.publishDate
                  ? // ? moment(blog.publishDate).format("DD-MM-YYYY HH:MM:SS")
                    blog.publishDate
                  : null,
                updatedDate: blog.updatedAt
                  ? moment(blog.updatedAt).format("MM/DD/YYYY HH:mm ")
                  : null,
                categoryObj: blog.category.title,
                blogCategory: blog.blogCategory,
                comments: blog.comments.blogsComments,
                active: blog.active,
                blogImage: blog.blogImage,
                blogImageAlt: blog.blogImageAlt,
                blogDescription: blog.blogDescription,
                blogMetaTitle: blog.blogMetaTitle,
                numberOfComments: blog.numberOfComments,
              };
            })
          );
        } else {
          setBlogs([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token, navigate]);

  useEffect(() => {
    if (access_token) {
      fetchBlogs();
    }
  }, [access_token]);

  // useEffect(() => {
  //   getperPageData();
  // }, [blogs]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const getperPageData = (blogsData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setBlogsPerPage(
          blogsData.length < parseInt(data.val)
            ? blogsData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (number) => {
    if (number > 0) {
      setCurrentPage(number);
    }
  };
  const setNumberOfEvent = (number) => {
    if (number > 0) {
      if (number > blogs.length) {
        setBlogsPerPage(parseInt(blogs.length));
      }
      setBlogsPerPage(parseInt(number));
    }
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setBlogs(
      blogs.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };
  const selectSingle = (e, blogId) => {
    let checked = e.target.checked;
    setBlogs(
      blogs.map((blog) => {
        if (blogId === blog.blogId) {
          blog.select = checked;
        }

        return blog;
      })
    );

    const result = blogs.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/blogs/search/?size=-1${
          search && status
            ? `&status=${status}&searchString=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&searchString=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.blogs;
        // console.log("data===>", data);
        if (
          data !== undefined &&
          data !== "" &&
          data !== null &&
          data.length !== 0
        ) {
          setBlogsPerPage(data.length < 10 ? data.length : 10);
          setBlogs(
            data.map((blog) => {
              return {
                select: false,
                blogId: blog.blogId,
                blogTitle: blog.blogTitle,
                authorFirstName: blog.authorFirstName,
                authorLastName: blog.authorLastName,
                publishDate: blog.publishDate
                  ? moment(blog.publishDate).format("MM/DD/YYYY HH:mm")
                  : null,
                updatedDate: blog.updatedAt
                  ? moment(blog.updatedAt).format("MM/DD/YYYY HH:mm")
                  : null,
                categoryObj: blog.category.title,
                blogCategory: blog.blogCategory,
                comments: blog.comments.blogsComments,
                active: blog.active,
                blogImage: blog.blogImage,
                blogImageAlt: blog.blogImageAlt,
                blogDescription: blog.blogDescription,
                blogMetaTitle: blog.blogMetaTitle,
              };
            })
          );
        } else {
          setBlogs([]);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        // let error_data = error.response.data;
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    fetchBlogs();
  };
  const handleClose = () => {
    setSearch("");
    fetchBlogs();
  };
  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      blogs.forEach((d) => {
        if (d.select) {
          arrayId.push(d.blogId);
        }
      });
      let config = {
        method: "delete",
        url: API + "/blogs/",
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Blogs deleted successfully");
          setActiveInactive(!activeInactive);
          setPageSearch(!pageSearch);
          setSelectAllCheckbox(false);
          fetchBlogs();
        })
        .catch((error) => {
          if (
            error.active === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };
  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let activeID = [];
      blogs.forEach((active) => {
        if (active.select) {
          activeID.push(active.blogId);
        }
      });
      let config = {
        method: "put",
        url: API + "/blogs/status/" + true,
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchBlogs();
          notify("Blogs activated successfully");
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };
  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let activeID = [];
      blogs.forEach((active) => {
        if (active.select) {
          activeID.push(active.blogId);
        }
      });
      let config = {
        method: "put",
        url: API + "/blogs/status/" + false,
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchBlogs();
          notify("Blogs deactivated successfully");
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };
  const sortTitle = (sortByTitle) => {
    const sorted = blogs.sort((a, b) => {
      const isReverse = sortByTitle === true ? 1 : -1;
      return isReverse * a.blogTitle.localeCompare(b.blogTitle);
    });
    setBlogs(sorted);
  };
  const sortAuthor = (sortByAuthor) => {
    const sorted = blogs.sort((a, b) => {
      const isReverse = sortByAuthor === true ? 1 : -1;
      return isReverse * a.authorFirstName.localeCompare(b.authorFirstName);
    });
    setBlogs(sorted);
  };
  const sortPublish = (sortByPublish) => {
    const sorted = blogs.sort((a, b) => {
      let dateA = new Date(a.publishDate);
      let dateB = new Date(b.publishDate);
      return sortByPublish ? dateA - dateB : dateB - dateA;
    });
    setBlogs(sorted);
  };
  const sortModified = (sortByModified) => {
    const sorted = blogs.sort((a, b) => {
      let dateA = new Date(a.updatedDate);
      let dateB = new Date(b.updatedDate);
      return sortByModified ? dateA - dateB : dateB - dateA;
    });
    setBlogs(sorted);
  };
  const sortBlogCategory = (sortByBlogCategory) => {
    const sorted = blogs.sort((a, b) => {
      const isReverse = sortByBlogCategory === true ? 1 : -1;
      return isReverse * a.blogCategory.localeCompare(b.blogCategory);
    });
    setBlogs(sorted);
  };
  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Blogs"}
          searchLabel={"Title"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          search={search}
          setSearch={setSearch}
          setStatus={setStatus}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/blog/view/addBlog"
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentBlogs.length === 0 ? (
          <NoRecord />
        ) : (
          <BlogsTable
            blogs={currentBlogs}
            blogsPerPage={blogsPerPage}
            totalBlogs={blogs.length}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfEvent={setNumberOfEvent}
            selectAll={selectAll}
            selectSingle={selectSingle}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            sortTitle={sortTitle}
            sortByTitle={sortByTitle}
            setSortByTitle={setSortByTitle}
            sortAuthor={sortAuthor}
            sortByAuthor={sortByAuthor}
            setSortByAuthor={setSortByAuthor}
            sortPublish={sortPublish}
            sortByPublish={sortByPublish}
            setSortByPublish={setSortByPublish}
            sortModified={sortModified}
            sortByModified={sortByModified}
            setSortByModified={setSortByModified}
            sortBlogCategory={sortBlogCategory}
            sortByBlogCategory={sortByBlogCategory}
            setSortByBlogCategory={setSortByBlogCategory}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allBlogs={blogs}
            setBlogs={setBlogs}
          />
        )}
      </div>
    </div>
  );
};

export default BlogsViewAll;
