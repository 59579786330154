import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { StoreFooterData } from "../../Redux/actions/CompanyAction";

const Footer = () => {
  let dispatch = useDispatch();
  let footerMessageState = useSelector((state) => state.CompanyReducer);
  // console.log("footerMessageState", footerMessageState);
  let date = new Date();

  const [FooterMessage, setFooterMessage] = useState(
    "Thank you for contacting us!"
  );

  useEffect(() => {
    getFooterData();
  }, []);

  const getFooterData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/footer_title_for_admin",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        setFooterMessage(data.val);
        dispatch(StoreFooterData(data.val));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="dark:bg-darkblack bg-lightblue border-t dark:border-0 z-10 w-full h-8 hidden sm:flex md:flex lg:flex xl:flex items-center justify-center">
      <h1 className="dark:text-textinblack text-black text-sm font-normal">
        © 2019 - {date.getFullYear()} {footerMessageState.message}
      </h1>
    </div>
  );
};

export default Footer;
