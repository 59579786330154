export const ytEmberUrl = (link) => {
  const ytLink = link.split("/");
  const watch = ytLink[3];
  const embedUrl = watch.split("=");

  return embedUrl[1];
};

export const hoverAnimation =
  " transition ease-in-out duration-300 hover:-translate-y-2 hover:shadow-xl hover:scale-125";
