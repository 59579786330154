import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { FaChevronDown, FaUserAlt } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import useDarkMode from "../../useDarkMode";
import { handleLogout } from "../login/handleLogOut";
import OutsideClick from "./ComponentClose";
import * as MDIcons from "react-icons/md";

const Navbar = (props) => {
  const [colorTheme, setTheme] = useDarkMode();
  const [showProfileMenu, setProfileMenu] = useState(false);
  const boxRef = useRef(null);
  const boxOutsideClick = OutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      setProfileMenu(false);
    }
  }, [boxOutsideClick]);
  let userInfo = useSelector((state) => state.LoginReducer.userInfo);

  let navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between items-center bg-white dark:bg-gray-800 h-16 w-full sticky top-0 z-20 shadow-lg">
        <div className="dark:text-textinblack text-2xl h-26 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto flex  items-center h-full">
          {/* {userInfo.userType.toLowerCase() == "admin" && ( */}
          <div className="h-full w-12 flex justify-center items-center">
            <FaIcons.FaBars onClick={props.showSidebar} className="cursor-pointer fill-black dark:fill-textinblack"/>
          </div>
          {/* )} */}
          <div className="pl-2 dark:text-textinblack text-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto font-semibold text-lg sm:text-lg md:text-lg lg:text-lg xl:text-lg">
            <img onClick={() => { window.open("https://reactjscms.devdigdev.com/"); }} className="object-contain w-64 cursor-pointer" src={process.env.PUBLIC_URL + "/images/header-logo.png"} alt=""/>
          </div>
        </div>
        <div className="flex items-center dark:text-textinblack pr-2 ">
          <div className="flex items-center relative ">
            <p className="pr-2 capitalize text-black dark:text-textinblack text-sm sm:text-base md:text-base lg:text-base xl:text-base hidden sm:flex md:flex lg:flex xl:flex">
              Welcome: {userInfo ? userInfo.firstName : null}{" "}
              {userInfo ? userInfo.lastName : null}
            </p>
            <div className="w-8 h-8 rounded-full dark:bg-white shadow flex items-center justify-center overflow-hidden mr-1">
              {userInfo && userInfo.profileImage ? (
                <img
                  className="w-full object-contain"
                  src={
                    API +
                    "/files/download/?fileName=" +
                    `${encodeURIComponent(userInfo.profileImage)}`
                  }
                  alt=""
                />
              ) : (
                <MDIcons.MdAccountCircle className="dark:text-gray-700 text-9xl" />
              )}
            </div>
            <FaChevronDown
              className="cursor-pointer group text-black dark:text-textinblack"
              onClick={() => setProfileMenu(!showProfileMenu)}
            />
            {showProfileMenu && (
              <div ref={boxRef} className=" flex-col items-start bg-white  shadow-lg w-48 -right-8 sm:w-full md:w-full lg:w-full xl:w-full justify-center absolute top-12 dark:bg-gray-700  text-sm ">
                <p
                  onClick={() => {
                    navigate("/profile");
                    setProfileMenu(!showProfileMenu);
                  }}
                  className="h-10 w-full flex items-center  p-3 cursor-pointer border-b-2 border-black dark:border-textinblack"
                >
                  My Profile
                </p>
                <p
                  onClick={() => {
                    navigate("/admin/changePassword");
                    setProfileMenu(!showProfileMenu);
                  }}
                  className="h-10 w-full flex items-center p-3 cursor-pointer border-b-2 border-black dark:border-textinblack"
                >
                  Change Password
                </p>
                <p
                  onClick={() => {
                    navigate("/admin/setting");
                    setProfileMenu(!showProfileMenu);
                  }}
                  className="h-10 w-full flex items-center p-3 cursor-pointer  border-b-2 border-black dark:border-textinblack"
                >
                  Settings
                </p>
                <p
                  onClick={() => {
                    handleLogout(navigate);
                    // if (userInfo.userType.toLowerCase() == "admin") {
                    props.setLogin(false);
                    // } else {
                    // props.setFrontLogin(false);
                    // }
                  }}
                  className="flex items-center h-10 w-full dark:bg-gray-400 dark:text-black bg-black text-white dark:font-bold p-3 cursor-pointer "
                >
                  <AiOutlineLogout /> <span className="pl-3">Logout</span>
                </p>
              </div>
            )}
          </div>
          <div
            onClick={() => setTheme(colorTheme)}
            className={`border-1 border-black p-2 ml-2 cursor-pointer rounded-full ${
              colorTheme === "light" ? "bg-white" : "bg-gray-800"
            }`}
          >
            <HiOutlineLightBulb
              className={`${
                colorTheme === "light" ? "text-black" : "text-white"
              } `}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
