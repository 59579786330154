import React, { useState, useEffect, useCallback } from "react";
import PageHeading from "../pages/PageHeading";
import { CgAsterisk } from "react-icons/cg";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { notify } from "../../utility";
import { API } from "../../services/API";
import { handleLogout } from "../login/handleLogOut";
import TinyMCE from "react-tinymce";
import { Editor } from "@tinymce/tinymce-react";

const AddEditFaqs = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [status, setStatus] = useState(true);
  const [DisplayOrder, setDisplayOrder] = useState(0);
  const [id, setId] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [answerError, setAnswerError] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;
  const [allDisplayOrder, setAllDisplayOrder] = useState([]);

  const SaveEdit = (saveclick) => {
    let questionError = "";
    let answerError = "";

    if (question.trim() === "") {
      questionError = "Enter Question";
    }
    if (answer.trim() === "") {
      answerError = "Enter Answer";
    }

    if (questionError !== "" || answerError !== "") {
      setQuestionError(questionError);
      setAnswerError(answerError);
      return;
    }

    if (id) {
      let editFaq = {
        id: id,
        question: question,
        displayOrder: DisplayOrder,
        answer: answer,
        status: status,
      };
      let config = {
        method: "put",
        url: API + "/faqs/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: editFaq,
      };
      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Question updated successfully");
            navigate("/admin/faqs");
          } else {
            let responseData = response.data;
            setId(responseData.id);
            setQuestion(responseData.question);
            setAnswer(responseData.answer);
            setDisplayOrder(responseData.displayOrder);
            setStatus(responseData.status);
            notify("Question updated successfully");
          }
        })
        .catch((error) => {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            navigate("/admin/faqs");
          }
          console.log("error", error);
        });
    } else {
      let faq = {
        question: question,
        answer: answer,
        status: status,
        displayOrder: DisplayOrder,
      };
      let config = {
        method: "post",
        url: API + "/faqs/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: faq,
      };
      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Question added successfully");
            navigate("/admin/faqs");
          } else {
            let responseData = response.data;
            setId(responseData.id);
            setQuestion(responseData.question);
            setDisplayOrder(responseData.displayOrder);
            setAnswer(responseData.answer);
            setStatus(responseData.status);
            notify("Question added successfully");
          }
        })
        .catch((error) => {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            navigate("/admin/faqs");
          }
        });
    }
  };

  const addEditHandle = () => {
    if (questionError !== "" || answerError !== "") {
      return;
    } else {
      SaveEdit(true);
    }
  };

  const saveAndContinueHandle = () => {
    if (questionError !== "" || answerError !== "") {
      return;
    } else {
      SaveEdit(false);
    }
  };

  const fetchFaq = (id) => {
    let config = {
      method: "get",
      url: API + "/faqs/" + id,
      headers: {
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        setId(data.id);
        setQuestion(data.question);
        setDisplayOrder(data.displayOrder);
        setAnswer(data.answer);
        setStatus(data.status);
      })
      .catch((error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin/faqs");
        }
        console.log("error", error);
      });
  };

  const nextDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/faqs/nextdisplayorder",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const order = response.data;
        setDisplayOrder(order);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin/faqs");
        }
        console.log(error);
      });
  }, [access_token]);

  const fetchAllDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/faqs/alldisplayorders",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const orders = response.data;
        setAllDisplayOrder(orders);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin/faqs");
        }
        console.log(error);
      });
  }, [access_token]);

  const handleCancelBtn = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  const fetchData = async () => {
    if (params.id) {
      await fetchAllDisplayOrder();
      await fetchFaq(params.id);
    } else {
      await nextDisplayOrder();
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading={params.id ? "Edit FAQs" : "Add FAQs"}
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        handleSave={addEditHandle}
        saveAndContinue={true}
        handleSaveAndContinue={saveAndContinueHandle}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">
              Question
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={question}
              onChange={(e) => {
                setQuestionError("");
                setQuestion(e.target.value);
              }}
              type="text"
              className={`${
                questionError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter title"
            />
            {questionError && (
              <span className="text-red-500 text-xs">{questionError}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Status</label>
            <select
              className="border  h-10 rounded px-2 text-sm font-medium"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Display Order</label>
            <select
              value={DisplayOrder}
              onChange={(e) => {
                setDisplayOrder(e.target.value);
              }}
              disabled={id ? false : true}
              name="displayOrder"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              {allDisplayOrder.map((order) => (
                <option key={order} value={order}>
                  {order}
                </option>
              ))}
              {!id && (
                <option key={DisplayOrder} value={DisplayOrder}>
                  {DisplayOrder}
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="w-full my-5">
          <label
            htmlFor="status"
            className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
          >
            Answer
            <CgAsterisk className="inline text-red-500" />
          </label>
          <div className="w-full py-5">
            {id ? (
              <>
                {answer ? (
                  <Editor
                    value={answer}
                    init={{
                      height: 300,
                      forced_root_block: "",
                      statusbar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newValue, editor) => {
                      setAnswerError("");
                      if (editor.getContent() === "") {
                        setAnswer(" ");
                      } else {
                        setAnswer(editor.getContent());
                      }
                    }}
                  />
                ) : null}
              </>
            ) : (
              <Editor
                value={answer}
                init={{
                  height: 300,
                  forced_root_block: "",
                  statusbar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newValue, editor) => {
                  setAnswerError("");
                  setAnswer(editor.getContent());
                }}
              />
            )}
            {answerError && (
              <span className="text-red-500 text-xs">{answerError}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddEditFaqs;
