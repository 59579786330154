import React, { useEffect, useState } from "react";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

const CommentTable = ({
  comments,
  commentPerPage,
  totalComments,
  paginate,
  currentPage,
  setNumberOfEvent,
  selectAll,
  selectSingle,
  selectAllCheckbox,
  setSelectAllCheckbox,

  sortComment,
  sortByComment,
  setSortByComment,
  sortName,
  sortByName,
  setSortByName,
  sortCreated,
  createdBy,
  setCreatedBy,
}) => {
  const pageNumbers = [];
  let navigate = useNavigate();
  for (let i = 1; i <= Math.ceil(totalComments / commentPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortTitleComment, setSortCommentArrows] = useState(false);
  let [sortNameArrows, setSortNameArrows] = useState(true);
  let [sortCreatedArrows, setSortCreatedArrows] = useState(true);

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark">
      <table className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b">
        <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
          <tr>
            <th scope="col" className="border-l-6 bg-gray-400"></th>

            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <input
                checked={selectAllCheckbox}
                onChange={(e) => {
                  setSelectAllCheckbox(!selectAllCheckbox);
                  selectAll(e);
                }}
                type="checkbox"
                className="cursor-pointer"
              />
            </th>
            <th
              onClick={() => {
                sortComment(sortByComment);
                setSortByComment(!sortByComment);
                setSortCommentArrows(false);
                setSortNameArrows(true);
                setSortCreatedArrows(true);
              }}
              scope="col"
              className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2">Comment</span>
              {sortTitleComment && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortTitleComment && (
                <>
                  {sortByComment === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortName(sortName);
                setSortByName(!sortByName);
                setSortNameArrows(false);
                setSortCommentArrows(true);
                setSortCreatedArrows(true);
              }}
              scope="col"
              className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Name
              {sortNameArrows && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortNameArrows && (
                <>
                  {sortByName === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortCreated(createdBy);
                setCreatedBy(!createdBy);
                setSortCreatedArrows(false);
                setSortNameArrows(true);
                setSortCommentArrows(true);
              }}
              scope="col"
              className=" px-6 py-3 cursor-pointer text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Created On
              {sortCreatedArrows && (
                <>
                  {" "}
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortCreatedArrows && (
                <>
                  {createdBy === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Edit
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-darkblack">
          {comments &&
            comments.map((comment) => (
              <tr
                key={comment.blogCommentId}
                className="dark:hover:bg-gray-100 hover:bg-gray-200  border-b-gray-500 border-b"
              >
                <td
                  className={`${
                    comment.status
                      ? "border-left-green-6 bg-green-500"
                      : "border-left-red-6 bg-red-500"
                  }`}
                ></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black dark:text-textinblack">
                  <input
                    checked={comment.select}
                    onChange={(e) => selectSingle(e, comment.blogCommentId)}
                    type="checkbox"
                    className="cursor-pointer"
                  />
                </td>
                <td className="px-6 py-4 cursor-pointer  text-sm capitalize text-black dark:text-textinblack">
                  <span
                    onClick={() => {
                      navigate(
                        "/admin/blog/view/editComment/" + comment.blogCommentId
                      );
                    }}
                    className="hover:underline pb-2 inline-block hover:text-blue-500 hover:font-semibold"
                  >
                    {comment.comment}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                  {comment.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm  text-black dark:text-textinblack">
                  {comment.createdOn}
                </td>
                <td className="flex py-4 place-content-center">
                  <button
                    onClick={() => {
                      navigate(
                        "/admin/blog/view/editComment/" + comment.blogCommentId
                      );
                    }}
                    className="text-bluecolor hover:text-indigo-900 dark:hover:text-blue-500"
                  >
                    <FiEdit2 size={17} />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r border-b dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <input
            className="ml-3"
            checked={selectAllCheckbox}
            onChange={(e) => {
              setSelectAllCheckbox(!selectAllCheckbox);
              selectAll(e);
            }}
            type="checkbox"
          />
          <label
            onClick={() => {
              sortComment(sortByComment);
              setSortByComment(!sortByComment);
              setSortCommentArrows(false);
              setSortNameArrows(true);
              setSortCreatedArrows(true);
            }}
            className="px-5 py-3 text-sm font-medium leading-normal"
          >
            Comments{" "}
            {!sortTitleComment && (
              <>
                {sortByComment === true ? (
                  <RiArrowUpFill className="inline-block ml-2" />
                ) : (
                  <RiArrowDownFill className="inline-block ml-2" />
                )}
              </>
            )}
          </label>
        </div>
        {comments &&
          comments.map((comment) => (
            <div
              key={comment.blogCommentId}
              className={`tab w-full border-t ${
                comment.status ? "border-left-green-8 " : "border-left-red-8"
              }`}
            >
              <input
                className="absolute opacity-0"
                id={comment.blogCommentId}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-blue-400 dark:bg-gray-700 text-black dark:text-textinblack"
                for={comment.blogCommentId}
              >
                <input
                  checked={comment.select}
                  onChange={(e) => selectSingle(e, comment.blogCommentId)}
                  type="checkbox"
                />
                <span className="px-5 block w-11/12">{comment.comment}</span>
              </label>
              <div className="tab-content border-t   overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="text-black dark:text-textinblack font-semibold">Comment:</h1>
                    <h1 className="text-black dark:text-textinblack text-left pl-2">
                      {comment.comment}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-black dark:text-textinblack font-semibold">
                      Comment by:
                    </h1>
                    <h1 className="text-black dark:text-textinblack text-left pl-2">
                      {comment.name}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-black dark:text-textinblack font-semibold">
                      Created on:
                    </h1>
                    <h1 className="text-black dark:text-textinblack text-left pl-2">
                      {comment.createdOn}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="text-black dark:text-textinblack font-semibold">Edit:</h1>
                    <h1 className="text-black dark:text-textinblack text-left pl-2">
                      <h1
                        onClick={() => {
                          navigate(
                            "/admin/blog/view/editComment/" + comment.blogCommentId
                          );
                        }}
                        className="cursor-pointer underlinetext-bluecolor hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        Edit
                      </h1>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* pagination */}
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-between mt-5 mb-3">
        <ul className="flex items-center justify-start cursor-pointer space-x-2">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`p-3 text-sm ${
                currentPage === number ? "bg-darkblack dark:bg-bluecolor" : "bg-bluecolor dark:bg-darkblack"
              }  text-white liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 text-black dark:text-textinblack">Show</span>
          <input
            type="number"
            min={1}
            value={commentPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfEvent(e.target.value)}
          />
          <span className="pl-2 text-black dark:text-textinblack">Entries</span>
        </div>
      </div>
    </div>
  );
};
export default CommentTable;
