import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";

const SliderSetting = (props) => {
  let navigate = useNavigate();
  const [TitleAllow, setTitleAllow] = useState("");
  const [RecordID, setRecordID] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  useEffect(() => {
    if (access_token) {
      getData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const getData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.siteConfig;
        let filteredData = data.filter(
          (option) => option.name === "field_slider_title_display"
        );
        setRecordID(filteredData[0].id);
        if (filteredData.length !== 0) setTitleAllow(filteredData[0].val);
        else setTitleAllow("No");
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
        console.log(error);
      });
  };

  const changeHandler = () => {
    let dataBody = {
      id: RecordID,
      val: TitleAllow,
      status: true,
      type: "string",
      name: "field_slider_title_display",
    };

    let config = {
      method: "put",
      url: API + "/siteConfigs/?size=-1",
      data: dataBody,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    // console.log("config", config);

    axios(config)
      .then((response) => {
        notify("Updated successfully");
      })
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          handleLogout(navigate);
        }
      });
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading="Settings"
          pageSearch={false}
          showSaveOptionsBtn={true}
          save={true}
          handleSave={changeHandler}
        />
        <div className="w-full lg:w-8/12 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 my-4 relative ">
          <div className="shadow-md w-full">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-one"
                type="checkbox"
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-blue-400 text-white dark:bg-gray-700 dark:text-white"
                for="tab-multi-one"
              >
                Slider Settings
              </label>
              <div className="tab-content overflow-hidden transition-all border-l-4 border-blue-400 dark:border-red-500 leading-normal lg:grid lg:grid-cols-3 w-full text-black dark:text-textinblack">
                <div className="p-3 flex flex-col">
                  <label className="text-sm mb-2 flex items-center">
                    Display slider title and overlay on Home Page
                  </label>
                  <select
                    value={TitleAllow}
                    onChange={(e) => {
                      setTitleAllow(e.target.value);
                    }}
                    className="border h-10 rounded px-2 text-sm font-medium"
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default SliderSetting;
