import axios from "axios";
import FormData from "form-data";
import { addOrUpdateNewInfoToIDBStore } from "indexeddb-util";
import React, { useEffect, useState, useCallback } from "react";
// import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { CgAsterisk } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateLoginData } from "../../Redux/actions/LoginActions";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";

const Profile = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [fname, setFname] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lname, setLname] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [profileImgError, setProfileImgError] = useState("");
  const [email, setEmail] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");
  const userCred = useSelector((state) => state.LoginReducer.userInfo);
// console.log("userCred",userCred)
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      setProfileImgError(fileRejections[0].errors[0].message);
    }
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((image) => {
        let data = new FormData();
        data.append("file", image);
        const config = {
          method: "post",
          url: API + "/users/profilePic",
          headers: {
            Authorization: `${access_token}`,
          },
          data: data,
        };
        console.log("config",config)
        axios(config)
          .then(async function (response) {
            notify("Image uploaded successfully");
            setProfileImg(response.data);
            setProfileImgError("");
            const newItem = { ...userInfo, profilePic: response.data };
            // await addOrUpdateNewInfoToIDBStore({
            //   dbName: "loginStore",
            //   storeName: "loginStore",
            //   keyPath: "accessToken",
            //   version: 2,
            //   newItem,
            //   key: userInfo.userType === "FRONT" ? "userData" : "user",
            // });
            // setImageName(response.data.imageName);
          })
          .catch(function (error) {
            if (
              (error.status = "401") &&
              (error.message = "JWT token is expired")
            ) {
              handleLogout(navigate);
            }
            console.log(error);
          });
        return null;
      });
    }
  }, [access_token]);

  useEffect(() => {
    if (access_token) {
      setFname(userInfo.firstName);
      setLname(userInfo.lastName);
      setEmail(userInfo.email);
      setProfileImg(userInfo.profileImage);
    }
  }, [
    // setProfileImg,
    userInfo.firstName,
    userInfo.lastName,
    userInfo.email,
    userInfo.profilePic,
    access_token,
  ]);

  useEffect(() => {
    if (userCred) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const handleEdit = () => {
    let fnameError = "";

    if (fname.trim() === "") {
      fnameError = "Enter First Name";
    }

    if (fnameError !== "") {
      setFnameError(fnameError);
      return;
    }
    let updateProfile = {
      method: "put",
      url: API + "/users/admin/",
      data: {
        userId: userInfo.userId,
        firstName: fname,
        lastName: lname,
        profilePic: profileImg,
        email: userInfo.email,
        userTypeId: userInfo.userTypeId,
      },
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(updateProfile)
      .then(function (response) {
        const data = response.data;
        dispatch(UpdateLoginData(data));
        localStorage.setItem("profilePic", JSON.stringify(data.profilePic));
        localStorage.setItem("userData", JSON.stringify(data));
        notify("Profile updated successfully");
        if (userInfo.userType.toLowerCase() === "admin") {
          navigate("/admin/dashboard");
        } else {
          // navigate("/front/dashboard");
          navigate("/");
        }
        // console.log("user data after update " + localStorage.getItem("userData"));
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log(error);
      });
  };

  const removeImg = () => {
    setProfileImg("");
    setProfileImgError("");
  };

  const handleCancelBtn = () => {
    if (userInfo.userType.toLowerCase() === "admin") {
      navigate("/admin/dashboard");
    } else {
      navigate("/");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 10000000,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading={"My Profile"}
        showSaveOptionsBtn={true}
        save={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        handleSave={handleEdit}
      />
      <div className="w-full lg:w-8/12 my-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 text-black dark:text-textinblack">
        <div className="grid  xl:grid-cols-2 gap-2 xl:h-32">
          <div className="flex flex-col xl:col-span-1 sm:col-span-2 col-span-2">
            <label
              for="fname"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              First Name
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              type="text"
              value={fname}
              className={`${
                fnameError ? "border-red-500 border" : "border"
              } border h-10 rounded-lg px-2 text-sm font-medium `}
              placeholder="First Name"
              onChange={(e) => {
                setFname(e.target.value);
                setFnameError("");
              }}
              name="fname"
            />
            {fnameError && (
              <span className="text-red-500 text-xs">{fnameError}</span>
            )}
          </div>
          <div className="flex flex-col xl:col-span-1 sm:col-span-2 col-span-2">
            <label
              for="lname"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Last Name
            </label>
            <input
              type="text"
              value={lname}
              className="border h-10 rounded px-2 text-sm font-medium"
              placeholder="Last Name"
              onChange={(e) => {
                setLname(e.target.value);
              }}
              name="lname"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label
              for="email"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Email
            </label>
            <input
              type="email"
              disabled
              value={email}
              className="border h-10 rounded-lg px-2 text-sm font-medium bg-gray"
              name="email"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
            Profile Image
          </label>
          <div className="w-full relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
            {profileImg && (
              <img
                src={API + "/files/download/?fileName=" + profileImg}
                // src={profileImg}
                alt="no img"
                className="w-full h-4/5  object-contain"
              />
            )}
            {profileImg && (
              <button
                onClick={() => {
                  removeImg(profileImg);
                }}
                className="absolute z-20 top-1 right-1 btn bg-gray-900 text-white"
              >
                Remove
              </button>
            )}
            {/* {console.log("fileRejectionItems", fileRejectionItems)} */}
            {!profileImg && (
              <div>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                  <em>(Only *.jpeg and *.png images will be accepted)</em>
                </div>
              </div>
              // <Dropzone
              //   accept="image/*"
              //   maxSize={10000000} //10MB
              //   onDrop={(acceptedFiles, fileRejections) => {
              //     onImageDrop(acceptedFiles, fileRejections);
              //   }}
              // >
              //   {({ getRootProps, getInputProps }) => (
              //     <div
              //       {...getRootProps()}
              //       className="w-full h-full  flex items-center justify-center"
              //     >
              //       <input {...getInputProps()} />
              //       <p>
              //         Drag 'n' drop some files here, or click to select files
              //       </p>
              //     </div>
              //   )}
              // </Dropzone>
            )}
          </div>
          <div className="text-xs mt-1">
            Max size 10(MB) and Recommended Size: 32PX x 32PX (Allowed only jpg,
            jpeg and png images)
          </div>
          {profileImgError && (
            <>
              <span className="text-red-500 text-xs">{profileImgError}</span>
            </>
          )}
          {/* {fileRejectionItems.length && (
            <span className="text-red-500 text-xs">
              {fileRejectionItems[0]?.key} file is rejected
            </span>
          )} */}
        </div>
      </div>
    </div>
    // </div>
  );
};
export default Profile;
