import React, { useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../services/API";
import { notify } from "../../utility";

const ForgetPassword = (props) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const isAdmin =
    localStorage.getItem("forgotPasswordAdmin") === "true" ? "admin" : "front";
  const origin = state?.origin || isAdmin;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const forgetPassword = () => {
    if (email.trim() === "") {
      setEmailError("Enter Your Email");
      return;
    }
    const config = {
      method: "put",
      url: API + "/users/forgotPassword/" + email,
    };
    axios(config)
      .then(function (response) {
        // console.log("response", response);
        if (response.data === true) {
          notify("We have e-mailed your password reset link!");
        }
      })
      .catch(function (error) {
        let errorMsg = error.response.data;
        if (errorMsg.code === 400)
          notify("We can't find a user with that e-mail address!");
      });
  };
  return (
    <div className="w-full flex justify-center my-5">
      <div className="max-w-[452px] 2xl:w-4/12 xl:w-4/12 lg:w-6/12 md:w-2/4 sm:w-4/5 w-11/12 shadow-2xl xxl:p-[70px] xl:p-[60px] lg:p-[50px] md:p-[40px] p-[30px] rounded-lg border border-[#D4DAEC] bg-white">
        <div className="w-full">
          <img className="object-contain mx-auto w-10/12" alt="" src={process.env.PUBLIC_URL + "/images/header-logo.png"} />
        </div>
        <div className="my-5 text-center">
          <h1 className="2xl:text-3xl xl:text-2xl text-xl font-normal">Forget Password</h1>
        </div>
        <div className="w-full flex flex-col">
          <label className="font-normal text-sm flex item-center tracking-wider">
            Email <CgAsterisk className="inline-block text-red-500" />
          </label>
          <input
            type="email"
            required
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            onBlur={() => {
              if (email.trim() === "") {
                setEmailError("The email field is required.");
              }
            }}
            className={`${
              emailError && "border-red-500"
            } py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500 px-2`}
          />
          {emailError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {emailError}
            </span>
          )}
        </div>

        <div className="w-full flex items-center justify-center my-4">
          <button
            onClick={() => forgetPassword()}
            type="submit"
            className="w-full h-10 bg-blue-500 hover:bg-blue-600 text-sm text-white rounded shadow-lg tracking-wider"
          >
            Send Password Reset Link
          </button>
        </div>
        <div className="w-full flex items-center justify-center">
          <button
            onClick={() => {
              if (origin === "admin") {
                navigate("/admin");
              } else {
                navigate("/frontendLogin");
              }
            }}
            className=" text-xs hover:underline tracking-wider"
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
