import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
// import logger from 'redux-logger';
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LoginReducer } from "./reducers/LoginReducer";
import { UserSignupReducer } from "./reducers/UserSignupReducer";
import { BlogsReducer } from "./reducers/blogsReducer";
import { CartReducer } from "./reducers/cartReducer";
import { CategoryReducer } from "./reducers/categoriesReducer";
import { CompanyReducer } from "./reducers/companyReducer";
import { EventsReducer } from "./reducers/eventsReducers";
import { SliderImagesReducer } from "./reducers/sliderImagesReducer";
import { UsersReducer } from "./reducers/usersReducers";

const rootReducer = combineReducers({
  LoginReducer,
  UsersReducer,
  SliderImagesReducer,
  EventsReducer,
  CategoryReducer,
  BlogsReducer,
  CartReducer,
  CompanyReducer,
  UserSignupReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["LoginReducer", "UserSignupReducer"], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);
//   const middleware = applyMiddleware(thunk);
// const store = createStore(pReducer, middleware);

const store = createStore(
  pReducer,
  {},
  composeWithDevTools(applyMiddleware(thunk))
  );

const persistor = persistStore(store);
export { persistor, store };

