import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";
import GalleryCards from "./galleryCards";

const Galleries = (props) => {
  let navigate = useNavigate();
  let [galleries, setGalleries] = useState([]);
  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [status, setStatus] = useState("");
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchGalleries = () => {
    let config = {
      method: "get",
      url: API + "/galleries/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.galleries;
        if (data !== undefined && data !== null && data !== "") {
          setGalleries(
            data.map((d) => {
              return {
                select: false,
                id: d.id,
                description: d.description,
                image: d.image,
                imageAlt: d.imageAlt,
                displayOrder: d.displayOrder,
                images: d.images,
                metaDescription: d.metaDescription,
                metaTitle: d.metaTitle,
                status: d.status,
                title: d.title,
              };
            })
          );
        } else {
          setGalleries([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        // console.log("error in galleries get API", error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
      });
  };

  useEffect(() => {
    if (access_token) {
      fetchGalleries();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      galleries.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let updateGallery = {
        method: "delete",
        url: API + "/galleries/",
        data: arrayId,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updateGallery", updateGallery);
      axios(updateGallery)
        .then((response) => {
          notify("Galleries deleted successfully");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchGalleries();
        })
        .catch(async (error) => {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            let userCred = await RefreshToken(
              access_token,
              setAccess_token,
              setUserInfo
            );
          } else {
            notify("Could not process your request!");
            // handleLogout(history);
          }
        });
    }
  };
  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let arrayId = [];
      galleries.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });

      let updateGallery = {
        method: "put",
        url: API + "/galleries/true",
        data: arrayId,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updateGallery", updateGallery);
      axios(updateGallery)
        .then((response) => {
          notify("Galleries activated successfully");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchGalleries();
        })
        .catch(async (error) => {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            let userCred = await RefreshToken(
              access_token,
              setAccess_token,
              setUserInfo
            );
          } else {
            notify("Could not process your request!");
            // handleLogout(history);
          }
        });
    }
  };
  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let arrayId = [];
      galleries.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let updateGallery = {
        method: "put",
        url: API + "/galleries/false",
        data: arrayId,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updateGallery", updateGallery);
      axios(updateGallery)
        .then((response) => {
          notify("Galleries inactivated successfully");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchGalleries();
        })
        .catch(async (error) => {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            let userCred = await RefreshToken(
              access_token,
              setAccess_token,
              setUserInfo
            );
          } else {
            notify("Could not process your request!");
            // handleLogout(history);
          }
        });
    }
  };
  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/galleries/?size=-1${
          search && status
            ? `&status=${status}&keywords=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&keywords=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.galleries;
        if (
          data !== undefined &&
          data !== null &&
          data !== "" &&
          data.length !== 0
        ) {
          setGalleries(
            data.map((d) => {
              return {
                select: false,
                id: d.id,
                description: d.description,
                image: d.image,
                imageAlt: d.imageAlt,
                displayOrder: d.displayOrder,
                images: d.images,
                metaDescription: d.metaDescription,
                metaTitle: d.metaTitle,
                status: d.status,
                title: d.title,
              };
            })
          );
        } else {
          setGalleries([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };
  const handleReset = () => {
    setSearch("");
    fetchGalleries();
  };
  const handleClose = () => {
    setSearch("");
    fetchGalleries();
  };
  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setGalleries(
      galleries.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };
  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setGalleries(
      galleries.map((d) => {
        if (id === d.id) {
          d.select = checked;
        }

        return d;
      })
    );

    const result = galleries.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Galleries"}
          searchLabel={"Title"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          setStatus={setStatus}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/galleries/addGallery"
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && galleries.length === 0 ? (
          <NoRecord />
        ) : (
          <GalleryCards
            galleries={galleries}
            setGalleries={setGalleries}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            selectSingle={selectSingle}
            selectAll={selectAll}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

export default Galleries;
