import React, { useState, useEffect } from "react";
import GalleriesCard from "./galleriesCard";
import axios from "axios";
import { API } from "../../../services/API";
import Loader from "react-js-loader";

const FrontendGalleries = () => {
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchGalleries = () => {
    let config = {
      method: "get",
      url: API + "/galleries/front/?size=-1",
    };
    axios(config)
      .then(function (response) {
        let galleries = response.data.galleries;
        // console.log("galleries", galleries);
        setGalleries(galleries.filter((option) => option.status === true));
        setLoading(false);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  useEffect(() => {
    fetchGalleries();
  }, []);
  return (
    <div className="main-content pt-24">
      {loading ? (
        <>
          <Loader type="spinner-circle" bgColor={"black"} size={100} />
        </>
      ) : (
        <>
          <h1 className="w-full text-center mb-4 text-2xl capitalize font-medium text-black dark:text-white">
            Galleries
          </h1>
          <div className="w-full p-4 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            {galleries.map((gallery) => (
              <GalleriesCard gallery={gallery} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FrontendGalleries;
