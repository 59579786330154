import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./Redux/store";
import "./output.css";
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


/**************************************** 
Uncomment the below code during ios build
****************************************/

// if (
//   navigator?.userAgent?.toLowerCase()?.search("iphone") > -1 ||
//   navigator?.userAgent?.toLowerCase()?.search("ipad") > -1 ||
//   navigator?.userAgent?.toLowerCase()?.search("ipod") > -1
//   ) {
//       var body = document.getElementById("ios_header_customcss");
//       var footer = document.getElementById("ios_footer_customcss");
//       body.style.display = "block";
//       footer.style.display = "block";
// } else if (navigator.userAgent.toLowerCase().search("android") > -1) {
//   console.log("android");
// } else {
//   console.log("desktop");
// }

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
