import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../services/API";
import VerifyAccountComponent from "./VerifyAccountComponent";
import Loader from "react-js-loader";
import { useParams, useNavigate, Link } from "react-router-dom";
import { deleteDB } from "indexeddb-util";

const VerifyAccountW = (Component) =>
  function Comp() {
    // const params = window.location.pathname.split("/");
    // const token = params[3];
    const params = useParams();
    const [invalidMessage, setInvalidTokenMessage] = useState("");
    const [verifyPasswordInfo, setVerifyPasswordInfo] = useState({
      userId: "",
      email: "",
      userType: "",
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const isForgotPasswordAdmin = localStorage.getItem("forgotPasswordAdmin");
      localStorage.clear();
      localStorage.setItem("forgotPasswordAdmin", isForgotPasswordAdmin);
      deleteDB({ dbName: "loginStore" });
      let config = {
        method: "get",
        url: API + "/users/front/validatePasswordToken",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Methods": "*",
        },
        params: {
          passwordToken: params.token,
        },
      };
      if (params.token) {
        axios(config)
          .then(function (response) {
            const data = response.data;
            // console.log("response data", data);
            setInvalidTokenMessage(null);
            setVerifyPasswordInfo(data);
            setIsLoading(false);
          })
          .catch((error) => {
            // console.log("error", error, error.response);
            if (
              error.response.data["passwordToken"] === "Invalid passwordToken"
            ) {
              setInvalidTokenMessage(
                "This reset password link has been expired already!"
              );
            } else {
              setInvalidTokenMessage(error.response.data["passwordToken"]);
            }
            setVerifyPasswordInfo({ userId: "", email: "", userType: "" });
            setIsLoading(false);
          });
      }
    }, [params.token]);

    return (
      <>
        {isLoading ? (
          <div className="w-full flex items-center justify-center h-screen">
            <Loader
              type="spinner-circle"
              bgColor={"gray"}
              // title={"spinner-circle"}
              // color="black"
              size={100}
            />
          </div>
        ) : (
          <Component
            invalidMessage={invalidMessage}
            userId={verifyPasswordInfo?.userId}
            email={verifyPasswordInfo?.email}
            userType={verifyPasswordInfo?.userType}
            // isSetPassword={isSetPassword}
          />
        )}
      </>
    );
  };

const VerifyAccount = VerifyAccountW(VerifyAccountComponent);

export default VerifyAccount;
