import React, { useState } from "react";
import {
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlineClose,
} from "react-icons/ai";
import { FiCircle } from "react-icons/fi";
import { RiRecordCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const StatusDropdownException = ["Subscriptions"];

const PageHeading = ({
  pageHeading,
  pageSearch,
  path,
  showSaveOptionsBtn,
  cancel,
  handleCancelBtn,
  save,
  searchLabel,
  handleSave,
  activeInactive,
  deleteBtn,
  handleDelete,
  search,
  setSearch,
  setStatus,
  handleSearch,
  handleReset,
  handleClose,
  handleActive,
  handleInActive,
  backBtn,
  saveAndContinue,
  handleSaveAndContinue,
  savedisable,
  addBtn,
}) => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  let navigate = useNavigate();

  const resetStatus = () => {
    const el = document.getElementById("statusSelect");
    el.value = "";
    setStatus("");
  };
  return (
    <div className="w-full px-4 sm:px-5 md:px-5 lg:px-5 xl:px-5 pt-5 flex justify-between items-start flex-col">
      <div className="w-full flex justify-between items-center flex-col sm:flex-row">
        <div className="flex items-center justify-start">
          <h1 className="text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl font-bold sm:font-bold text-black dark:text-white mb-3 sm:mb-0">
            {pageHeading}
          </h1>
        </div>
        {pageSearch && (
          <div className="flex items-center space-x-1">
            <button
              className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              onClick={() => setShowSearchBar(!showSearchBar)}
            >
              <AiOutlineSearch /> <span className="pl-1">Search</span>
            </button>
            {addBtn && (
              <button
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
                onClick={() => navigate(path)}
              >
                <AiOutlinePlus /> <span className="pl-1">Add</span>
              </button>
            )}

            {backBtn && (
              <button
                onClick={() => navigate(-1)}
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                <span className="pl-1">Back</span>
              </button>
            )}
          </div>
        )}
        {activeInactive && (
          <div className="flex items-center space-x-1">
            <button
              onClick={() => handleActive()}
              className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
            >
              <RiRecordCircleLine />
              <span className="pl-1 font-normal">Active</span>
            </button>
            <button
              onClick={() => handleInActive()}
              className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
            >
              <FiCircle size={12} />
              <span className="pl-1">Inactive</span>
            </button>
            {deleteBtn && (
              <button
                onClick={() => handleDelete()}
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                <AiOutlineDelete />
                <span className="pl-1">Delete</span>
              </button>
            )}
          </div>
        )}

        {showSaveOptionsBtn && (
          <div className="flex items-center space-x-1">
            {cancel && (
              <button
                className="bg-blackbtn hover:bg-slate-900 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
                onClick={() => handleCancelBtn()}
              >
                <AiOutlineClose className="me-2" /> Cancel
              </button>
            )}

            {save && (
              <button
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
                disabled={savedisable}
                onClick={() => handleSave()}
              >
                <img className="w-3 me-2" alt="img" src={process.env.PUBLIC_URL + "/images/check-light.svg"} /> Save
              </button>
            )}
            {saveAndContinue && (
              <button
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
                onClick={() => handleSaveAndContinue()}
              >
                <img className="w-3 me-2" alt="img" src={process.env.PUBLIC_URL + "/images/icon-savencontinue-light.svg"} /> Save & Continue
              </button>
            )}
          </div>
        )}
      </div>
      {pageSearch && showSearchBar && (
        <div className="mt-4 w-full">
          <div className="flex w-full space-x-2 text-black dark:text-textinblack">
            <div className="flex flex-col w-52">
              <label fro="search" className="text-sm font-medium">
                {searchLabel}
              </label>
              <input
                type="Search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Here..."
                className="h-9 border rounded px-2 text-sm"
              />
            </div>
            {!StatusDropdownException.includes(pageHeading) && (
              <div className="flex flex-col w-52">
                <label for="active" className="text-sm font-medium">
                  Status
                </label>
                <select
                  name="active"
                  id="statusSelect"
                  className="h-9 border rounded text-sm pl-2"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select status
                  </option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
            )}
          </div>
          <div className="flex space-x-2 mt-4">
            <button
              onClick={() => handleSearch()}
              className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
            >
              Search
            </button>
            <button
              onClick={() => {
                handleReset();
                if (pageHeading !== "Subscriptions") {
                  resetStatus();
                }
              }}
              className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
            >
              Reset
            </button>
            <button
              onClick={() => {
                handleClose();
                setShowSearchBar(!showSearchBar);
              }}
              className="bg-blackbtn hover:bg-slate-900 text-gray-200 font-bold py-2 px-4 rounded flex items-center text-xs"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageHeading;
