import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import axios from "axios";
import { API } from "../../../services/API";
import moment from "moment";
import { notify } from "../../../utility";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const Calender = () => {
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [SelectedEvent, setSelectedEvent] = useState(null);
  const [headerToolbar, setHeaderToolbar] = useState({
    left: "prev,next,today",
    center: "title",
    right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
  });

  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);

  const handleEventClick = (event) => {
    let config = {
      method: "get",
      url: API + "/events/front/" + event._def.extendedProps.eventId,
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
    .then(function (response) {
      let data = response.data;
      let EventData = data;
        EventData.eventTimeProcessed = event._def.extendedProps.time;
        setSelectedEvent(EventData);
        onOpenModal();
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const fetchEvents = (startDate, endDate) => {
    let startD = moment(startDate).format("YYYY-MM-DD");
    let endD = moment(endDate).format("YYYY-MM-DD");
    let url = `${API}/events/front/?startDate=${
      startD + " 00:00:00.000"
    }&endDate=${endD + " 00:00:00.000"}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        setEvents(data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  let width = window.innerWidth;
  const calendarRef = React.createRef();

  
  return (
    <div id="calender" className="section-pedding text-black dark:text-white px-3">
      <div className="main-content">
        <div className="overflow-hidden">
          <div className="text-center justify-center">
            <h1 className="text-xl md:text-2xl lg:text-3xl text-center mb-3">
              Calender
            </h1>
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
            initialView="dayGridMonth"
            selectable={true}
            displayEventTime={true}
            events={events}
            headerToolbar={headerToolbar}
            ref={calendarRef}
            datesSet={(dateInfo) => {
              fetchEvents(dateInfo.start, dateInfo.end);
            }}
            eventClick={function (arg) {
              handleEventClick(arg.event);
            }}
          />
          {modal && 
          <Modal open={modal} 
          onClose={onCloseModal}
           center>
            <h1>{SelectedEvent ? SelectedEvent.title : null}</h1>

            <div className="py-5">
              <p>
                Start Date:{" "}
                {SelectedEvent
                  ? moment(SelectedEvent.startDate).format("DD-MM-YYYY")
                  : null}
              </p>
              <p>
                Start time:{" "}
                {SelectedEvent ? SelectedEvent.eventTimeProcessed : null}
              </p>
              <p>
                Description: {SelectedEvent ? SelectedEvent.description : null}
              </p>
              <p className="flex">
                URL:{" "}
                {SelectedEvent ? (
                  <div
                    className="cursor-pointer text-indigo-600 break-all"
                    onClick={() => window.open(SelectedEvent.url)}
                  >
                    {SelectedEvent.url}
                  </div>
                ) : null}
              </p>
              <p>
                Address:{" "}
                {SelectedEvent
                  ? `${SelectedEvent.address1} ,${SelectedEvent.address2},${SelectedEvent.city},${SelectedEvent.state},${SelectedEvent.country},${SelectedEvent.zipcode} `
                  : null}
              </p>
            </div>

            <div>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-xs"
                onClick={onCloseModal}
              >
                Close
              </button>
            </div>
          </Modal>
          }
        </div>
      </div>
    </div>
  );
};
export default Calender;
