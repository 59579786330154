import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const UserTable = ({
  users,
  usersPerPage,
  totalUsers,
  paginate,
  currentPage,
  setNumberOfUsers,
  selectAll,
  selectSingle,
  selectAllCheckbox,
  setSelectAllCheckbox,
  sortName,
  sortByName,
  setSortByName,
  setSortByNameDesc,
  sortEmail,
  sortByEmail,
  setSortByEmail,
  setSortByEmailDesc,
  sortLastLogin,
  sortByLastLogin,
  setSortByLastLogin,
  setSortByLastLoginDesc,
  sortCreatedOn,
  sortByCreatedOn,
  setSortByCreatedOn,
  setSortByCreatedOnDesc,
  sortUserType,
  sortByUserType,
  setSortByUserTypeDesc,
  activeResetLink,
  handleActive,
  handleInActive,
  people,
  setPeople,
}) => {
  const pageNumbers = [];
  let navigate = useNavigate();
  for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortNameArrows, setSortNameArrows] = useState(false);
  let [sortEmailArrows, setSortEmailArrows] = useState(true);
  let [sortUserTypeArrows, setSortUserTypeArrows] = useState(true);
  let [sortLastLoginArrows, setSortLastLoginArrows] = useState(true);
  let [sortCreatedOnArrows, setSortCreatedOnArrows] = useState(true);

  const selectRecord = (record) => {
    let data = people.map((item) => {
      if (item.id === record.id) {
        item.select = true;
      }
      return item;
    });
    setPeople(data);
    if (record.status) {
      handleInActive();
    } else {
      handleActive();
    }
  };

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark">
      <table className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b">
        <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
          <tr>
            <th scope="col" className="border-l-6 bg-gray-200"></th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <input
                checked={selectAllCheckbox}
                onChange={(e) => {
                  setSelectAllCheckbox(!selectAllCheckbox);
                  selectAll(e);
                }}
                type="checkbox"
                className="cursor-pointer"
              />
            </th>
            <th
              onClick={() => {
                sortName(sortByName);
                setSortByNameDesc(!sortByName);
                setSortNameArrows(false);
                setSortEmailArrows(true);
                setSortUserTypeArrows(true);
                setSortLastLoginArrows(true);
                setSortCreatedOnArrows(true);
              }}
              scope="col"
              className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <span className="inline-block mr-2">Name</span>
              {sortNameArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortNameArrows && (
                <>
                  {sortByName === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortEmail(sortByEmail);
                setSortByEmailDesc(!sortByEmail);
                setSortNameArrows(true);
                setSortEmailArrows(false);
                setSortUserTypeArrows(true);
                setSortLastLoginArrows(true);
                setSortCreatedOnArrows(true);
              }}
              scope="col"
              className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <span className="inline-block mr-2 ">Email</span>
              {sortEmailArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortEmailArrows && (
                <>
                  {sortByEmail === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                sortUserType(sortByUserType);
                setSortByUserTypeDesc(!sortByUserType);
                setSortNameArrows(true);
                setSortEmailArrows(true);
                setSortUserTypeArrows(false);
                setSortLastLoginArrows(true);
                setSortCreatedOnArrows(true);
              }}
              scope="col"
              className="cursor-pointer px-4 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <span className="inline-block mr-2 ">Type</span>
              {sortUserTypeArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortUserTypeArrows && (
                <>
                  {sortByUserType === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                setSortByLastLoginDesc(!sortByLastLogin);
                sortLastLogin(sortByLastLogin);
                setSortNameArrows(true);
                setSortEmailArrows(true);
                setSortUserTypeArrows(true);
                setSortLastLoginArrows(false);
                setSortCreatedOnArrows(true);
              }}
              scope="col"
              className="cursor-pointer w-full px-0 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <span className="inline-block mr-2 ">Last Login</span>
              {sortLastLoginArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortLastLoginArrows && (
                <>
                  {sortByLastLogin === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              scope="col"
              className="cursor-pointer w-full px-0 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <span className="inline-block mr-2 ">Reset Password</span>
            </th>
            <th
              onClick={() => {
                setSortByCreatedOnDesc(!sortByCreatedOn);
                sortCreatedOn(sortByCreatedOn);
                setSortNameArrows(true);
                setSortEmailArrows(true);
                setSortUserTypeArrows(true);
                setSortLastLoginArrows(true);
                setSortCreatedOnArrows(false);
              }}
              scope="col"
              className="cursor-pointer px-0 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <span className="inline-block mr-2 ">Created On</span>
              {sortCreatedOnArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortCreatedOnArrows && (
                <>
                  {sortByCreatedOn === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              Edit
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-darkblack">
          {users &&
            users.map((user) => (
              <tr
                key={user.id}
                className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
              >
                <td
                  className={`${
                    user.status
                      ? "border-left-green-6 bg-green-500 cursor-pointer"
                      : "border-left-red-6 bg-red-500 cursor-pointer"
                  }`}
                  onClick={(e) => {
                    selectRecord(user);
                  }}
                ></td>
                <td className="px-6 py-4 text-sm dark:text-textinblack">
                  <input
                    checked={user.select}
                    onChange={(e) => selectSingle(e, user.id)}
                    type="checkbox"
                    className="cursor-pointer"
                  />
                </td>
                <td className="px-6 py-4 text-wrap w-full">
                  <div className="text-sm text-gray-900 dark:text-textinblack break-all">
                    {user.firstName} {user.lastName}
                  </div>
                </td>
                <td className="px-6 py-4 text-wrap">
                  <div className="text-sm text-gray-900 dark:text-textinblack text-wrap">
                    {user.email}
                  </div>
                </td>
                <td className="px-4 py-4 min-w-105">
                  <div className="text-sm text-gray-900 dark:text-textinblack text-wrap">
                    {user.user_type}
                  </div>
                </td>
                <td className="px-0 py-4 min-w-135">
                  <div className="text-sm text-gray-900 dark:text-textinblack  text-wrap">
                    {user.last_login_at ? user.last_login_at : null}
                  </div>
                </td>
                <td className="px-0 py-4 min-w-135">
                  <ul className="flex items-center justify-start cursor-pointer space-x-2">
                    {user.emailVerifiedDate === "-" || user.emailVerifiedDate === undefined  ? (
                      <li
                        onClick={() => {
                          activeResetLink(user.id);
                        }}
                        className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        Activate User
                      </li>
                    ) : (
                      <li
                        onClick={() => {
                          activeResetLink(user.id);
                        }}
                        className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        Reset password
                      </li>
                    )}
                  </ul>
                </td>
                <td className="px-0 py-4 min-w-135">
                  <div className="text-sm text-gray-900 dark:text-textinblack">
                    {user.created_at}
                  </div>
                </td>
                <td className="flex py-4 place-content-center">
                  <h1
                    onClick={() => {
                      navigate("/admin/users/create/" + user.id);
                    }}
                    className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                  >
                    <FiEdit2 size={17} />
                  </h1>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="overflow-hidden block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <input
            className="ml-3"
            onChange={(e) => {
              setSelectAllCheckbox(!selectAllCheckbox);
              selectAll(e);
            }}
            type="checkbox"
          />
          <label
            onClick={() => {
              sortName(sortByName);
              setSortByNameDesc(!sortByName);
              setSortNameArrows(false);
              setSortEmailArrows(true);
              setSortUserTypeArrows(true);
              setSortLastLoginArrows(true);
              setSortCreatedOnArrows(true);
            }}
            // scope="col"
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
          >
            <span className="inline-block mr-2 ">Name</span>
            {sortNameArrows && (
              <>
                <RiArrowUpFill className="inline-block ml-1" />
                <RiArrowDownFill className="inline-block ml-1" />
              </>
            )}
            {!sortNameArrows && (
              <>
                {sortByName === true ? (
                  <RiArrowUpFill className="inline-block ml-2" />
                ) : (
                  <RiArrowDownFill className="inline-block ml-2" />
                )}
              </>
            )}
          </label>
        </div>
        {users &&
          users.map((user) => (
            <div
              key={user.id}
              className={`tab w-full border-t ${
                user.status ? "border-left-green-8 " : "border-left-red-8"
              }`}
            >
              <input
                className="absolute opacity-0"
                id={user.id}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                htmlFor={user.id}
              >
                <input
                  checked={user.select}
                  onChange={(e) => selectSingle(e, user.id)}
                  type="checkbox"
                />
                <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">
                  {user.firstName} {user.lastName}
                </span>
              </label>
              <div className="tab-content border-t   overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Email:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {user.email}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Type:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {user.user_type}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">
                      Last Login:
                    </h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {user.last_login_at ? user.last_login_at : null}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">
                      Reset Password:
                    </h1>
                    <ul className="flex items-center justify-start cursor-pointer space-x-2">
                      {/* {console.log("user data", user)} */}
                      {user.emailVerifiedDate === "-" || user.emailVerifiedDate === undefined ? (
                        <li
                          onClick={() => {
                            activeResetLink(user.id);
                          }}
                          className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                        >
                          Activate User
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            activeResetLink(user.id);
                          }}
                          className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                        >
                          Reset password
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">
                      Created On:
                    </h1>
                    <h1 className="dark:text-white text-left pl-2">
                      {user.created_at}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Edit:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      <p
                        onClick={() => {
                          navigate("/admin/users/create/" + user.id);
                        }}
                        className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        <FiEdit2 size={17} />
                      </p>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* pagination */}
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
        <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
              } liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 dark:text-textinblack">Show</span>
          <input
            type="number"
            value={usersPerPage}
            min={1}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfUsers(e.target.value)}
          />
          <span className="pl-2 dark:text-textinblack">Entries</span>
        </div>
      </div>
    </div>
  );
};
export default UserTable;
