import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlineClose,
} from "react-icons/ai";
import { BsCheck, BsPencil } from "react-icons/bs";
import { FiCircle } from "react-icons/fi";
import { MdDone } from "react-icons/md";
import { RiRecordCircleLine } from "react-icons/ri";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ImageCrop } from "react_easy_croputil";
import {
  FETCH_SLIDER_IMAGE_REQUEST,
  FETCH_SLIDER_IMAGE_SUCCESS,
} from "../../Redux/actionTypes";
import { changeAlternativeText } from "../../Redux/actions/fetchSliderImagesAction";
import { hoverAnimation } from "../../helper/helper";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import UploadMultipleImages from "../galleries/uploadMultipleImages";
import { handleLogout } from "../login/handleLogOut";
import { Modal } from "react-responsive-modal";

const SliderView = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [searchBar, showSearch] = useState(false);
  let [search, setSearch] = useState("");
  const [activeValue, setActive] = useState("");
  const images = useSelector((state) => state.SliderImagesReducer.images);
  const [AllSliderImagesData, setAllSliderImagesData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [alternativeText, setAlternativeText] = useState("");
  const [AlternativeTextEdit, setAlternativeTextEdit] = useState(false);
  const [AlternativeTextRecord, setAlternativeTextRecord] = useState(null);
  const [showImgTextEditor, setShowImgTextEditor] = useState("");
  const [viewImg, setViewImg] = useState("");
  const [allImgChecked, setAllChecked] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [image, setImage] = useState("");
  const [ImagesArray, setImagesArray] = useState([]);
  const [links, setLinks] = useState([]);
  const [displayOrder, setDisplayOrder] = useState("");
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");
  const ShowYoutubeButton = false;
  const imageDimension = { width: 1900, height: 1080 };
  const [isImgHover, setIsImgHover] = useState(false);
  const [ImagePopup, setImagePopup] = useState(false);

  const handler = (info) => {
    if (info.error) {
      let errMsg = info.error;
      if (errMsg === "Invalid File dimension") {
        errMsg = `${errMsg}. Required dimension is 1900px x 1080px. Cannot upload file`;
      } else {
        errMsg = `${errMsg} of 10MB. Hence cannot upload file.`;
      }
      notify(errMsg, "error");
    }
    const fileInfo = [{ ...info }];
    const files = [...myFiles, ...fileInfo];
    setMyFiles([...files]);
  };

  const fetchImages = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/slider/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    dispatch({
      type: FETCH_SLIDER_IMAGE_REQUEST,
    });
    axios(config)
      .then(function (response) {
        const data = response.data.sliders;
        dispatch({
          type: FETCH_SLIDER_IMAGE_REQUEST,
          payload: data,
        });
        setAllSliderImagesData(data);
        //setBlogsPerPage(data.length < 10 ? data.length : 10);
        if (data !== undefined && data !== null && data !== "") {
          setImagesData(
            data.map((im) => {
              return {
                select: false,
                id: im.id,
                title: im.title,
                status: im.status,
                overlayText: im.overlayText,
                imageAlt: im.imageAlt,
                image: im.image,
                alternativeText: im.alternativeText,
                activeSlider: im.status,
                displayOrder: im.displayOrder,
              };
            })
          );
        } else {
          setImagesData([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
          // let userCred = await RefreshToken(
          //   access_token,
          //   setAccess_token,
          //   setUserInfo
          // );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
        console.log(error);
      });
    dispatch({
      type: FETCH_SLIDER_IMAGE_SUCCESS,
      payload: imagesData,
    });
  }, [access_token, dispatch, navigate]);

  const nextDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/slider/nextdisplayorder",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const order = response.data;
        //console.log("+++++initial response", order);
        setDisplayOrder(order);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        } else {
        }
        console.log(error);
      });
  }, [access_token]);

  useEffect(() => {
    if (access_token) {
      fetchImages();
      nextDisplayOrder();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  // const onDrop = useCallback(
  //   (acceptedFiles) => {
  //     const files = [...myFiles, ...acceptedFiles];
  //     const filesWithId = files.map((item, index) => {
  //       return {
  //         id: index + 1,
  //         lastModified: item.lastModified,
  //         lastModifiedDate: item.lastModifiedDate,
  //         name: item.name,
  //         size: item.size,
  //         type: item.type,
  //         webkitRelativePath: !item.id
  //           ? URL.createObjectURL(item)
  //           : item.webkitRelativePath,
  //       };
  //     });
  //     setMyFiles(filesWithId);
  //   },
  //   [myFiles]
  // );

  // const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
  //   useDropzone({
  //     accept: "image/*",
  //     noKeyboard: true,
  //     noClick: true,
  //     maxSize: 10485760,
  //     onDrop,
  //   });

  const handleCancel = (file, index) => {
    const remFiles = myFiles.filter((item, idx) => idx !== index);
    const newFiles = [...remFiles];
    setMyFiles([...newFiles]);
    // const newFiles = [...myFiles];
    // setMyFiles(reject(newFiles, { id: file.id }));
  };

  const uploadSingle = (file) => {
    if (!file.error) {
      let data = new FormData();
      data.append("file", file.fileObject);
      const config = {
        method: "post",
        url: API + "/slider/upload/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: data,
      };
      // console.log("config", config);
      axios(config)
        .then(function (response) {
          // console.log("single file upload", response.data);
          setImage(response.data);
          let arr = ImagesArray;
          arr.push(response.data);
          setImagesArray(arr);
        })
        .catch(function (error) {
          console.log("error is", error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    }
  };

  const multipleImagesUpload = () => {
    myFiles.map((image) => {
      if (!image.error) {
        let data = new FormData();
        data.append("file", image.fileObject);
        const config = {
          method: "post",
          url: API + "/slider/upload/",
          headers: {
            Authorization: `${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            // console.log("multiple file upload", response.data);
            let image = response.data;
            setImage(image);
            let arr = ImagesArray;
            arr.push(response.data);
            setImagesArray(arr);
          })
          .catch(function (error) {
            console.log("error is", error);
            if (
              (error.status = "401") &&
              (error.message = "JWT token is expired")
            ) {
              handleLogout(navigate);
            }
            console.log(error);
          });
        return null;
      }
    });
  };

  const onCloseModal = () => {
    setImagePopup(false);
    setViewImg(null);
  };

  const AddSlider = (image) => {
    let savedata = {
      title: "Slider",
      overlayText: "Slider",
      image: image,
      imageAlt: "Slider",
      status: true,
      displayOrder: displayOrder,
    };

    const config = {
      method: "post",
      url: API + "/slider/",
      headers: {
        Authorization: `${access_token}`,
      },
      data: savedata,
    };
    axios(config)
      .then(function (response) {
        // console.log("save slider suces", response.data);
        setImage("");
        fetchImages();
        setMyFiles([]);
      })
      .catch(function (error) {
        console.log("save slider error", error);
      });
  };

  const DeleteHandler = () => {
    let arrayId = [];
    imagesData.forEach((d) => {
      if (d.select) {
        arrayId.push(d.id);
      }
    });
    let config = {
      method: "delete",
      url: API + "/slider/",
      data: arrayId,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    // console.log("config", config);
    axios(config)
      .then((response) => {
        notify("Slider deleted succesfully");
        setAllChecked(false);
        fetchImages();
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const ActiveHandler = () => {
    let a = window.confirm("Are you sure you want to Active this");
    if (a) {
      let arrayId = [];
      imagesData.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/slider/status/" + true,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      // console.log("config", config);
      axios(config)
        .then((response) => {
          notify("Slider activated successfully");
          setAllChecked(false);
          fetchImages();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  const InactiveHandler = () => {
    let a = window.confirm("Are you sure you want to In-Active this");
    if (a) {
      let arrayId = [];
      imagesData.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/slider/status/" + false,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      // console.log("config", config);
      axios(config)
        .then((response) => {
          notify("Slider inactivated succesfully");
          setAllChecked(false);
          fetchImages();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/slider/search/?size=-1${
          search && activeValue
            ? `&status=${activeValue}&searchString=${search}`
            : !search && activeValue
            ? `&status=${activeValue}`
            : search && !activeValue
            ? `&searchString=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.sliders;
        dispatch({
          type: FETCH_SLIDER_IMAGE_REQUEST,
          payload: data,
        });
        //setBlogsPerPage(data.length < 10 ? data.length : 10);
        if (
          data !== undefined &&
          data !== null &&
          data !== "" &&
          data.length !== 0
        ) {
          setImagesData(
            data.map((im) => {
              return {
                select: false,
                id: im.id,
                title: im.title,
                status: im.status,
                overlayText: im.overlayText,
                imageAlt: im.imageAlt,
                image: im.image,
                alternativeText: im.alternativeText,
                activeSlider: im.status,
                displayOrder: im.displayOrder,
              };
            })
          );
        } else {
          setImagesData([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleTitleChange = (record) => {
    // console.log("record", record);
    let editData = {
      id: record.id,
      title: alternativeText,
      imageAlt: alternativeText,
      displayOrder: record.order,
      overlayText: record.overlayText,
      image: record.image,
      status: record.status,
      alternativeText: alternativeText,
      displayOrder: record.displayOrder,
    };
    // console.log("editData", editData);
    let config = {
      method: "put",
      url: API + "/slider/",
      data: editData,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then((response) => {
        setAlternativeText(null);
        setAlternativeTextEdit(false);
        setAlternativeTextRecord(null);
        fetchImages();
      })
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/sliders/view");
          // handleLogout(navigate);
        }
      });
  };

  // useEffect(() => {
  //   let filterData = [];
  //   // console.log("AllSliderImagesData", AllSliderImagesData);
  //   if (AllSliderImagesData) {
  //     if (activeValue === "true") {
  //       filterData = AllSliderImagesData.filter((d) => {
  //         // console.log("d.status === true", d.status === "true");
  //         if (d.status === true) {
  //           return d;
  //         }
  //         return null;
  //       });
  //       // console.log("filterData", filterData);
  //       setImagesData(
  //         filterData.map((im) => {
  //           return {
  //             select: false,
  //             id: im.id,
  //             title: im.title,
  //             status: im.status,
  //             overlayText: im.overlayText,
  //             imageAlt: im.imageAlt,
  //             image: im.image,
  //             alternativeText: im.alternativeText,
  //             activeSlider: im.status,
  //             displayOrder: im.displayOrder,
  //           };
  //         })
  //       );
  //     } else if (activeValue === "false") {
  //       filterData = AllSliderImagesData.filter((d) => {
  //         // console.log("d.status === false", d.status === "false");
  //         if (d.status === false) {
  //           return d;
  //         }
  //         return null;
  //       });
  //       // console.log("filterData", filterData);
  //       setImagesData(
  //         filterData.map((im) => {
  //           return {
  //             select: false,
  //             id: im.id,
  //             title: im.title,
  //             status: im.status,
  //             overlayText: im.overlayText,
  //             imageAlt: im.imageAlt,
  //             image: im.image,
  //             alternativeText: im.alternativeText,
  //             activeSlider: im.status,
  //             displayOrder: im.displayOrder,
  //           };
  //         })
  //       );
  //     } else {
  //       setImagesData(
  //         AllSliderImagesData.map((im) => {
  //           return {
  //             select: false,
  //             id: im.id,
  //             title: im.title,
  //             status: im.status,
  //             overlayText: im.overlayText,
  //             imageAlt: im.imageAlt,
  //             image: im.image,
  //             alternativeText: im.alternativeText,
  //             activeSlider: im.status,
  //             displayOrder: im.displayOrder,
  //           };
  //         })
  //       );
  //     }
  //   }
  // }, [activeValue]);

  useEffect(() => {
    if (image) {
      AddSlider(image);
    }
  }, [image]);

  let resetStatus = () => {
    let el = document.getElementById("statusSelection");
    el.value = "";
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-4 sm:px-5 md:px-5 lg:px-5 xl:px-5 pt-5 flex justify-between items-start flex-col">
        <div className="w-full flex justify-between items-center flex-col sm:flex-row">
          <div className="flex items-center justify-start">
            <h1 className="text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl font-bold sm:font-bold text-black dark:text-white mb-3 sm:mb-0">
              Home Page Slider
            </h1>
          </div>
          {!allImgChecked ? (
            <div className="flex items-center space-x-1">
              <button
                onClick={() => showSearch(true)}
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                <AiOutlineSearch />
                <span className="pl-1">Search</span>
              </button>
              <a
                href="#imagesAdd"
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                <AiOutlinePlus />
                <span className="pl-1">Add</span>
              </a>
            </div>
          ) : (
            <div className="flex items-center space-x-1">
              <button
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
                onClick={ActiveHandler}
              >
                <RiRecordCircleLine />
                <span className="pl-1">Active</span>
              </button>
              <button
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
                onClick={InactiveHandler}
              >
                <FiCircle size={12} />
                <span className="pl-1">Inactive</span>
              </button>
              <button
                onClick={() => {
                  let a = window.confirm(
                    "Are you sure you want to delete this"
                  );
                  if (a) {
                    // dispatch(deleteAll(imagesData));
                    DeleteHandler();
                  }
                }}
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                <AiOutlineDelete />
                <span className="pl-1">Delete</span>
              </button>
            </div>
          )}
        </div>
        {searchBar && (
          <div className="mt-4 w-full text-black dark:text-textinblack">
            <div className="flex w-full space-x-2 text-black dark:text-textinblack">
              <div className="flex flex-col w-52">
                <label fro="search" className="text-sm font-medium">
                  Search Home Sliders
                </label>
                <input
                  type="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  name="search"
                  placeholder="Search Here..."
                  className="h-9 border rounded px-2 text-sm"
                />
              </div>
              <div className="flex flex-col w-52">
                <label for="active" className="text-sm font-medium">
                  Status
                </label>
                <select
                  // value={activeValue}
                  onChange={(e) => {
                    let a = e.target.value;
                    setActive(e.target.value);
                  }}
                  id="statusSelection"
                  name="active"
                  className="h-9 border-none rounded bg text-sm px-2"
                >
                  <option value="" selected disabled>
                    Select status
                  </option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
            </div>
            <div className="flex space-x-2 mt-4">
              <button
                onClick={() => handleSearch()}
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                Search
              </button>
              <button
                onClick={() => {
                  setSearch("");
                  resetStatus();
                  fetchImages();
                  setActive("");
                  if (AllSliderImagesData) {
                    setImagesData(
                      AllSliderImagesData.map((im) => {
                        return {
                          select: false,
                          id: im.id,
                          title: im.title,
                          status: im.status,
                          overlayText: im.overlayText,
                          imageAlt: im.imageAlt,
                          image: im.image,
                          alternativeText: im.alternativeText,
                          activeSlider: im.status,
                          displayOrder: im.displayOrder,
                        };
                      })
                    );
                  }
                }}
                className="bg-bluecolor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                Reset
              </button>
              <button
                onClick={() => {
                  showSearch(false);
                  setActive("");
                  if (AllSliderImagesData) {
                    setImagesData(
                      AllSliderImagesData.map((im) => {
                        return {
                          select: false,
                          id: im.id,
                          title: im.title,
                          status: im.status,
                          overlayText: im.overlayText,
                          imageAlt: im.imageAlt,
                          image: im.image,
                          alternativeText: im.alternativeText,
                          activeSlider: im.status,
                          displayOrder: im.displayOrder,
                        };
                      })
                    );
                  }
                }}
                className="bg-blackbtn hover:bg-slate-900 text-gray-200 font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      {loading && (
        <Loader
          type="box-up"
          bgColor={"#0075fd"}
          // title={"Loading"}
          color={"#0075fd"}
          size={100}
        />
      )}
      {!loading && imagesData.length == 0 ? (
        <NoRecord />
      ) : (
        <>
          <div className="w-full px-10 mt-3 text-black dark:text-textinblack">
            <input
              type="checkbox"
              checked={allImgChecked}
              className="cursor-pointer"
              onChange={(e) => {
                let checked = e.target.checked;
                if (checked) {
                  setAllChecked(true);
                } else {
                  setAllChecked(false);
                }
                setImagesData(
                  imagesData.map((d) => {
                    d.select = checked;
                    return d;
                  })
                );
              }}
            />
            <label className="pl-2">Select/Unselect All</label>
          </div>
          <div className="w-full px-10 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-2">
            {imagesData &&
              imagesData.map((imageData) => (
                <div
                  key={imageData.id}
                  className={`${
                    imageData.activeSlider
                      ? "border-green-400"
                      : "border-red-400"
                  } border-2 rounded shadow-lg hover:to-black`}
                >
                  <div
                    className="h-44 w-full relative imgHover"
                    onMouseEnter={() => setIsImgHover(imageData.id)}
                    onMouseLeave={() => setIsImgHover(null)}
                  >
                    <img
                      src={
                        API +
                        "/files/download/?fileName=" +
                        `${encodeURIComponent(imageData.image)}`
                      }
                      alt={imageData.alternativeText}
                      className="w-full h-full object-cover"
                    />
                    <div
                      className={
                        isImgHover && isImgHover === imageData.id
                          ? "visible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                          : "invisible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                      }
                    >
                      <button
                        data-tooltip-id={`EyeToolTip${imageData.id}`}
                        data-tooltip-content="Preview"
                        data-tooltip-place="top"
                        onClick={() => {
                          setViewImg(imageData);
                          setImagePopup(true);
                        }}
                        className={
                          "w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center" +
                          hoverAnimation
                        }
                      >
                        <AiOutlineEye />
                        <Tooltip id={`EyeToolTip${imageData.id}`} />
                      </button>
                      <button
                        data-tooltip-id={`PencilToolTip${imageData.id}`}
                        data-tooltip-content="Edit"
                        data-tooltip-place="top"
                        onClick={() => {
                          navigate("/admin/sliders/view/edit", {
                            state: {
                              title: imageData.title,
                              id: imageData.id || isImgHover,
                              image: imageData.image,
                              alternativeText: imageData.imageAlt,
                              activeSlider: imageData.activeSlider,
                              status: imageData.status,
                              displayOrder: imageData.displayOrder,
                              overlayText: imageData.overlayText,
                            },
                          });
                        }}
                        className={
                          "w-8 h-8 bg-green-500 rounded-full flex items-center justify-center" +
                          hoverAnimation
                        }
                      >
                        <BsPencil />
                        <Tooltip id={`PencilToolTip${imageData.id}`} />
                      </button>
                      <button
                        data-tooltip-id={`DeleteToolTip${imageData.id}`}
                        data-tooltip-content="Delete"
                        data-tooltip-place="top"
                        onClick={() => {
                          let a = window.confirm(
                            "Are you sure you want to delete this"
                          );

                          if (a) {
                            // dispatch(deleteSliderImage(imageData));
                            setImagesData(
                              imagesData.map((d) => {
                                if (imageData.id === d.id) {
                                  d.select = true;
                                }
                                return d;
                              })
                            );
                            DeleteHandler();
                          }
                        }}
                        className={
                          "w-8 h-8 bg-black rounded-full flex items-center justify-center text-lg" +
                          hoverAnimation
                        }
                      >
                        x
                        <Tooltip id={`DeleteToolTip${imageData.id}`} />
                      </button>
                    </div>
                  </div>
                  {viewImg === imageData.id && (
                    <div className="absolute inset-0 bg-black bg-opacity-30 z-20 flex items-center justify-center">
                      <div className="flex w-1/2 items-center justify-center flex-col">
                        <div className="w-full flex justify-end">
                          <div className="mb-2">
                            <label
                              onClick={() => {
                                setViewImg("");
                              }}
                              className="text-white  text-xl cursor-pointer bg-black w-6 h-6 flex items-center justify-center rounded-full"
                            >
                              x
                            </label>
                          </div>
                        </div>
                        <img
                          className="object-contain"
                          src={
                            API +
                            "/files/download/?fileName=" +
                            `${encodeURIComponent(imageData.image)}`
                          }
                          //src={imageData.image}
                          alt={imageData.alternativeText}
                        />
                      </div>
                    </div>
                  )}
                  <div className="w-full flex justify-between items-center px-2 py-3 h-14">
                    <div className="w-full flex">
                      <input
                        type="checkbox"
                        className="cursor-pointer me-2"
                        name="checkImg"
                        checked={imageData.select}
                        onChange={(e) => {
                          let checked = e.target.checked;
                          setImagesData(
                            imagesData.map((data) => {
                              if (imageData.id === data.id) {
                                data.select = checked;
                              }
                              return data;
                            })
                          );

                          // console.log(imagesData);
                          const result = imagesData.some(function (data) {
                            return data.select === true;
                          });
                          setAllChecked(result);
                        }}
                      />
                      {AlternativeTextEdit ? (
                        <input
                          type="text"
                          value={
                            AlternativeTextEdit &&
                            AlternativeTextRecord === imageData.id
                              ? alternativeText
                              : imageData.title
                          }
                          alternativeText
                          onChange={(e) => setAlternativeText(e.target.value)}
                          className="border-b border-dashed border-blue-400 text-black dark:text-textinblack w-full"
                        />
                      ) : (
                        <label
                          onClick={() => {
                            setAlternativeTextRecord(imageData.id);
                            setAlternativeTextEdit(!AlternativeTextEdit);
                          }}
                          className="border-b border-dashed border-blue-400 text-black dark:text-textinblack tracking-wide cursor-pointer overflow-hidden"
                        >
                          {imageData.title}
                        </label>
                      )}
                      {AlternativeTextEdit &&
                      AlternativeTextRecord === imageData.id ? (
                        <>
                          <button
                            onClick={() => {
                              handleTitleChange(imageData);
                              setAlternativeTextEdit(!AlternativeTextEdit);
                            }}
                          >
                            <BsCheck />
                          </button>
                          <button
                            className="ml-3"
                            onClick={() => {
                              setAlternativeText(null);
                              setAlternativeTextRecord(null);
                              setAlternativeTextEdit(false);
                            }}
                          >
                            X
                          </button>
                        </>
                      ) : null}
                    </div>
                    <div className="w-auto flex justify-end">
                      {showImgTextEditor === imageData.id ? (
                        <div className="flex space-x-2">
                          <input
                            type="text"
                            alternativeText
                            onChange={(e) => setAlternativeText(e.target.value)}
                            className="border border-blue-400 px-2 text-sm"
                          />
                          <button
                            onClick={() => {
                              dispatch(
                                changeAlternativeText(
                                  imageData.id,
                                  alternativeText
                                )
                              );

                              setShowImgTextEditor("");
                            }}
                            className="btn border bg-blue-400"
                          >
                            <MdDone />
                          </button>
                          <button
                            onClick={() => {
                              setShowImgTextEditor("");
                            }}
                            className="btn border bg-black text-white"
                          >
                            x
                          </button>
                        </div>
                      ) : (
                        <label
                          onClick={() => {
                            setShowImgTextEditor(imageData.id);
                          }}
                          className="border-b border-dashed inline-block border-blue-400 text-black dark:text-textinblack tracking-wide cursor-pointer  overflow-hidden"
                        >
                          {imageData.alternativeText}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div
            className="w-full px-10 my-4 text-black dark:text-textinblack"
            id="imagesAdd"
          >
            <UploadMultipleImages
              acceptedFiles={myFiles}
              // getRootProps={getRootProps}
              // getInputProps={getInputProps}
              // open={open}
              // fileRejections={fileRejections}
              handleCancel={handleCancel}
              uploadSingle={uploadSingle}
              multipleImagesUpload={multipleImagesUpload}
              links={links}
              setLinks={setLinks}
              ShowYoutubeButton={ShowYoutubeButton}
            />
            <ImageCrop
              onChangeCallback={handler}
              dimension={imageDimension}
              // imgSize={1048576} // 2MB
              imgSize={10485760} // 10MB
              accept="image/png, image/jpeg, image/gif"
            />
            <Modal open={ImagePopup} onClose={onCloseModal} center>
              <div>
                <img
                  className="w-full"
                  src={
                    viewImg !== null &&
                    API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(viewImg.image)}`
                  }
                  alt={viewImg !== null && viewImg.imageAlt}
                />
                <div className="px-6 py-4">
                  <div className="font-bold text-black text-xl mb-2">
                    {viewImg !== null && viewImg?.title}
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </div>
    // </div>
  );
};

export default SliderView;
