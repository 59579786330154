import React from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../../services/API";

const GalleriesCard = ({ gallery }) => {
  let navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate({
          pathname: `/galleries/view/${gallery.id}`,
        });
      }}
      className="border p-4 flex flex-col bg-blue-50 rounded cursor-pointer"
    >
      <div className="w-full h-40">
        <img
          alt={gallery.imageAlt}
          src={
            API +
            "/files/download/?fileName=" +
            `${encodeURIComponent(gallery.image)}`
          }
          className="object-cover object-center w-full h-full min-w-full min-h-full"
        />
      </div>
      <h1 className="w-full line-clamp-2 font-semibold my-2 text-textcolor">
        {gallery.title}
      </h1>
      <p className="w-full line-clamp-4 text-sm font-medium text-footertext">
        <div dangerouslySetInnerHTML={{ __html: gallery.description }} />
      </p>
    </div>
  );
};

export default GalleriesCard;
