import {
    FETCH_EVENT_REQUEST,
    FETCH_EVENT_SUCCESS,
    FETCH_EVENT_FAIL,
    DELETE_EVENTS,
    ADD_NEW_EVENT,
    ACTIVE_EVENT,
    INACTIVE_EVENT,
} from "../actionTypes";

const initialState = {
    events: [],
    loading: false,
    error: null,
};
export const EventsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_EVENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_EVENT_SUCCESS:
            return {
                ...state,
                events: payload,
                loading: false,
            };
        case FETCH_EVENT_FAIL:
            return {
                ...state,
                events: [],
                error: payload,
            };
        case ADD_NEW_EVENT:
            return {
                ...state,
                events: payload,
            };
        case DELETE_EVENTS:
            return {
                ...state,
                events: payload,
            };
        case ACTIVE_EVENT:
            return {
                ...state,
                events: payload,
            };
        case INACTIVE_EVENT:
            return {
                ...state,
                events: payload,
            };
        default:
            return state;
    }
};
