import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../components/login/Login";

const AdminRoute = ({ setLogin, children, path, setSidebar }) => {
  if (!localStorage.getItem("user") && path === "/admin") {
    setLogin(false);
    return <Login setLogin={setLogin} />;
  }

  if (!localStorage.getItem("user") && path !== "/admin") {
    setLogin(false);
    return <Login setLogin={setLogin} />;
  }

  if (localStorage.getItem("user") && path === "/admin") {
    setLogin(true);
    return <Navigate to="/admin/dashboard" />;
  }

  if (localStorage.getItem("user") && path !== "/admin") {
    setLogin(true);
    return children;
  }
};

export default AdminRoute;
