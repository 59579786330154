import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import PageHeading from "./PageHeading";
import { ImageCrop } from "react_easy_croputil";
import { handleLogout } from "../login/handleLogOut";


const AddPage = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo?.accessToken;
  const [showBanner, hideBanner] = useState(false);

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [banner, setBanner] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [imageErrors, setImageErrors] = useState("");
  const [titleError, setTitleError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [bannerError, setBannerError] = useState("");
  const [pageContentError, setPageContentError] = useState("");
  const [metaTitleError, setMetaTitleError] = useState("");
  const [metaDescriptionError, setMetaDescriptionError] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageError, setImageError] = useState("");
  const imageDimension = { width: 1900, height: 1080 };
  
  const handleCancelBtn = () => {
    navigate(-1);
  };
  const SaveEdit = (clicksave) => {
    let titleError = "";
    let bannerError = "";
    let pageContentError = "";
    let metaTitleError = "";
    let metaDescriptionError = "";
    if (title?.trim() === "") {
      titleError = "Enter Title";
    }
    if (
      showBanner &&
      (typeof bannerUrl === "undefined" || bannerUrl === false)
    ) {
      bannerError = "Upload Banner";
    }
    if (pageContent?.trim() === "") {
      pageContentError = "Enter Page Content";
    }
    if (metaTitle?.trim() === "") {
      metaTitleError = "Enter Meta Title";
    }
    if (metaDescription?.trim() === "") {
      metaDescriptionError = "Enter Meta Description ";
    }
    if (
      titleError !== "" ||
      bannerError !== "" ||
      pageContentError !== "" ||
      metaTitleError !== "" ||
      metaDescriptionError !== ""
    ) {
      setTitleError(titleError);
      setBannerError(bannerError);
      setPageContentError(pageContentError);
      setMetaTitleError(metaTitleError);
      setMetaDescriptionError(metaDescriptionError);
      return [
        titleError,
        bannerError,
        pageContentError,
        metaTitleError,
        metaDescriptionError,
      ];
    }
    let page = {
      title: title,
      status: status,
      banner: showBanner,
      bannerContent: pageContent,
      metaTitle: metaTitle,
      metaDesc: metaDescription,
      description: bannerUrl,
      slug: title.toLowerCase().split(" ").join("-"),
    };

    if (id) {
      page.id = id;

      let config = {
        method: "put",
        url: API + "/pages/",
        data: page,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          if (clicksave) {
            notify("Page updated successfully");
            navigate("/admin/pages");
          } else {
            notify("Page updated successfully");
            setId(response.data.id);
            setTitle(response.data.title);
            setStatus(response.data.status);
            hideBanner(response.data.banner);
            setBannerUrl(response.data.description);
            setBanner(response.data.banner);
            setPageContent(response.data.pageContent);
            setMetaTitle(response.data.metaTitle);
            setMetaDescription(response.data.metaDescription);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/pages");
          }
          console.log(error);
        });
    } else {
      let config = {
        method: "post",
        url: API + "/pages/",
        data: page,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          let responsData = response.data;
          if (clicksave) {
            notify("Page added successfully");
            navigate("/admin/pages");
          } else {
            notify("Page added successfully");
            setId(responsData.id);
            setTitle(responsData.title);
            setStatus(responsData.status);
            hideBanner(responsData.banner);
            setBanner(responsData.description);
            setBannerUrl(responsData.description);
            setPageContent(responsData.pageContent);
            setMetaTitle(responsData.metaTitle);
            setMetaDescription(responsData.metaDescription);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/pages");
          }
          console.log(error);
        });
    }
  };
  const handleSave = () => {
    SaveEdit(true);
  };
  const handleSaveAndContinue = () => {
    SaveEdit(false);
  };

  const onImageDrop = (acceptedFiles) => {
    if (acceptedFiles.error) {
      let errMsg = acceptedFiles.error;
      if (errMsg === "Invalid File dimension") {
        errMsg = `${errMsg}. Required dimension is ${imageDimension.width}px x ${imageDimension.height}px. Cannot upload file`;
      } else {
        errMsg = `${errMsg} of 5MB. Hence cannot upload file.`;
      }
      notify(errMsg, "error");
    } else {
      setImageError("");
      if (acceptedFiles) {
        let data = new FormData();
        data.append("image", acceptedFiles.fileObject);
        const config = {
          method: "post",
          url: API + "/teams/image/upload",
          headers: {
            Authorization: `${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            setImage(response.data);
          })
          .catch((error) => {
            console.log(error);
            if (
              error.status === "401" &&
              error.message === "JWT token is expired"
            ) {
              handleLogout(navigate);
            }
          });
        return null;
      }
    }
  };

  const fetchPage = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: API + "/pages/" + id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data;
          setId(data.id);
          setTitle(data.title);
          setStatus(data.status);
          setPageContent(data.bannerContent);
          hideBanner(data.banner);
          setBanner(data.description);
          setBannerUrl(data.description);
          setMetaTitle(data.metaTitle);
          setMetaDescription(data.metaDesc);
          setImage(data.description);
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/pages");
          }
          console.log("error", error);
        });
    },
    [access_token, navigate]
  );
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setImageErrors(fileRejections[0].errors[0].message);
      }
      if (acceptedFiles.length > 0) {
        acceptedFiles.map((image) => {
          let data = new FormData();
          data.append("file", image);
          const config = {
            method: "post",
            url: API + "/pages/banner/upload",
            headers: {
              Authorization: `${access_token}`,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setBanner(response.data);
              setBannerUrl(response.data);
              setImageErrors("");
            })
            .catch((error) => {
              console.log(error);
              if (
                error.status === "401" &&
                error.message === "JWT token is expired"
              ) {
                navigate("/admin/pages");
              }
            });
          return null;
        });
      }
    },
    [access_token]
  );
  const removeImg = () => {
    // hideBanner(false);
    setBannerUrl("");
    setBanner("");
  };

  useEffect(() => {
    if (id) {
      fetchPage(id);
    }
  }, [id]);

  useEffect(() => {
    if (params.id) {
      setId(params.id);
    }
  }, [params.id]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 10000000,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  return (
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading={params.id ? "Edit Page" : "Add Page"}
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        saveAndContinue={true}
        handleSave={handleSave}
        handleSaveAndContinue={handleSaveAndContinue}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5"></div>
        <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Title
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={title}
              onChange={(e) => {
                setTitleError("");
                setTitle(e.target.value);
              }}
              type="text"
              className={`${
                titleError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter title"
            />
            {titleError && (
              <span className="text-red-500 text-xs">{titleError}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="status"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Status
              <CgAsterisk className="inline text-red-500" />
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatusError("");
                setStatus(e.target.value);
              }}
              className={`${
                statusError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
            {statusError && (
              <span className="text-red-500 text-xs">{statusError}</span>
            )}
          </div>
        </div>
        <div className="w-full mt-5">
          <div className="w-full flex items-center mb-4">
            <div>
              <input
                type="checkbox"
                name=""
                id="toggle"
                className="hidden"
                value={showBanner}
                onChange={() => hideBanner(!showBanner)}
              />
              <label htmlFor="toggle" className="flex items-center space-x-2">
                <label htmlFor="1">Banner</label>
                <div className={`w-16 h-7 flex items-center rounded-full cursor-pointer ${ showBanner ? "bg-bluecolor" : "bg-gray-300" }`}>
                  <div className={`w-6 h-6 bg-white rounded-full shadow-md cursor-pointer transform duration-300 ease-in-out ml-1 ${ showBanner ? "translate-x-8 bg-blue-400" : "" }`}></div>
                </div>
              </label>
            </div>
          </div>
          {showBanner && (
            <div>
              Max size 10(MB) and Recommended Size: 1900 PX x 1080PX (Allowed only jpg, jpeg, png and gif images)
              <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
                {image && (
                  <img
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(image)}`
                    }
                    alt="no img"
                    className="w-full h-4/5  object-contain"
                  />
                )}
                {image && (
                  <button
                    onClick={() => {
                      setImage("");
                    }}
                    className="absolute z-20 top-1 right-1 btn bg-gray-900 text-white"
                  >
                    Remove
                  </button>
                )}
                {/* {!banner && (
                  <div>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                      <em>(Only *.jpeg and *.png images will be accepted)</em>
                    </div>
                  </div>
                )} */}
              </div>
                {!image && (
                <div className="py-2">
                <ImageCrop
                  onChangeCallback={onImageDrop}
                  dimension={imageDimension}
                  imgSize={10485760} // 10MB
                  accept="image/png, image/jpeg, image/gif"
                />
                </div>
                )}
                {imageError && (
                <span className="text-red-500 text-xs">{imageError}</span>
                )}
            </div>
          )}
          {bannerError && (
            <span className="text-red-500 text-xs">{bannerError}</span>
          )}
          {imageErrors && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {imageErrors}
            </span>
          )}
        </div>
        <div className="w-full my-5">
          <label
            htmlFor="status"
            className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
          >
            Page Content
            <CgAsterisk className="inline text-red-500" />
          </label>

          <div className="w-full py-5">
            {id ? (
              <>
                {pageContent ? (
                  <Editor
                    value={pageContent}
                    init={{
                      height: 300,
                      forced_root_block: "",
                      statusbar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newValue, editor) => {
                      setPageContentError("");
                      if (editor.getContent() === "") {
                        setPageContent(" ");
                      } else {
                        setPageContent(editor.getContent());
                      }
                    }}
                  />
                ) : null}
              </>
            ) : (
              <Editor
                value={pageContent}
                init={{
                  height: 300,
                  forced_root_block: "",
                  statusbar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newValue, editor) => {
                  setPageContentError("");
                  setPageContent(editor.getContent());
                }}
              />
            )}
            {pageContentError && (
              <span className="text-red-500 text-xs">{pageContentError}</span>
            )}
          </div>
        </div>
        <div className="w-full mb-5 flex flex-col">
          <div className="w-full">
            <h1 className="text-2xl font-medium">SEO</h1>
          </div>
          <div className="w-full flex flex-col lg:flex-row lg:space-x-2 mt-2">
            <div className="w-full flex flex-col">
              <label className="font-medium mb-1">
                Meta Title
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={metaTitle}
                type="text"
                onChange={(e) => {
                  setMetaTitleError("");
                  setMetaTitle(e.target.value);
                }}
                className="h-10 rounded px-2 text-sm font-medium border"
                placeholder="Enter Meta Title"
              />
              {metaTitleError && (
                <span className="text-red-500 text-xs">{metaTitleError}</span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <label className="font-medium mb-1">
                Meta Description
                <CgAsterisk className="inline text-red-500" />
              </label>
              <textarea
                value={metaDescription}
                onChange={(e) => {
                  setMetaDescriptionError("");
                  setMetaDescription(e.target.value);
                }}
                className="h-10 rounded px-2 text-sm font-medium border"
                placeholder="Enter Meta Description"
              ></textarea>
              {metaDescriptionError && (
                <span className="text-red-500 text-xs">
                  {metaDescriptionError}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPage;
