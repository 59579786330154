import React from "react";
import { AiFillDashboard, AiOutlineSetting } from "react-icons/ai";
import { FaBlogger } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { NavLink } from "react-router-dom";

const MobileMenuDashboard = (props) => {
    return (
        <div className="w-full flex items-center justify-evenly sm:hidden md:hidden lg:hidden xl:hidden h-16 dark:bg-gray-800">
            <NavLink
                to="/admin/dashboard"
                onClick={() => props.hideSidebarFromMenu(false)}
                activeclassname="text-blue-400 dark:text-blue-400"
                className="dark:text-white"
            >
                <AiFillDashboard size="22" />
            </NavLink>
            <NavLink
                to="/admin/pages"
                onClick={() => props.hideSidebarFromMenu(false)}
                activeclassname="text-blue-400 dark:text-blue-400"
                className="dark:text-white"
            >
                <IoIosPaper size="22" />
            </NavLink>
            <NavLink
                to="/admin/blog"
                onClick={() => props.hideSidebarFromMenu(false)}
                activeclassname="text-blue-400 dark:text-blue-400"
                className="dark:text-white"
            >
                <FaBlogger size="22" />
            </NavLink>
            <NavLink
                to="/admin/setting"
                onClick={() => props.hideSidebarFromMenu(false)}
                activeclassname="text-blue-400 dark:text-blue-400"
                className="dark:text-white"
            >
                <AiOutlineSetting size="22" />
            </NavLink>
        </div>
    );
};

export default MobileMenuDashboard;
