import React, { useState, useEffect, useCallback } from "react";
import PageHeading from "../pages/PageHeading";
// import { CategoriesData } from "./CategoriesData";
import { useDispatch } from "react-redux";
import {
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
} from "../../Redux/actionTypes";
import CategoriesTable from "./CategoriesTable";
import { handleLogout } from "../login/handleLogOut";
import { notify, NoRecord } from "../../utility";
import { API } from "../../services/API";
import { RefreshToken } from "./../../services/RefreshToken";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

const Categories = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  const [search, setSearch] = useState("");
  const [pageSearch, setPageSearch] = useState(true);
  const [activeInactive, setActiveInactive] = useState(false);
  const [status, setStatus] = useState("");

  let [currentPage, setCurrentPage] = useState(1);
  let [categoryPerPage, setCategoryPerPage] = useState(5);

  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [sortByTitle, setSortByTitle] = useState(true);
  let [sortBySlug, setSortBySlug] = useState(false);

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");
  const [loading, setLoading] = useState(true);

  const getCategories = useCallback(() => {
    dispatch({
      type: FETCH_CATEGORY_REQUEST,
    });
    let config = {
      method: "get",
      url: API + "/blogs/categories/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    // console.log("config",config)
    axios(config)
      .then(function (response) {
        setLoading(!loading);
        let CategoriesData = response.data.categorys;
        dispatch({
          type: FETCH_CATEGORY_SUCCESS,
          payload: CategoriesData,
        });
        if (
          CategoriesData !== undefined &&
          CategoriesData !== null &&
          CategoriesData !== ""
        ) {
          getperPageData(CategoriesData);
          setCategories(
            CategoriesData.map((category) => {
              return {
                select: false,
                categoryId: category.categoryId,
                title: category.title,
                slug: category.slug,
                active: category.active,
                createdBy: category.createdBy,
                createdDate: category.createdDate,
                deleted: category.deleted,
                description: category.description,
                image: category.image,
                imageAlt: category.imageAlt,
                metaDescription: category.metaDescription,
                metaTitle: category.metaTitle,
                updatedBy: category.updatedBy,
                updatedDate: category.updatedDate,
              };
            })
          );
        } else {
          setCategories([]);
        }
      })
      .catch(async (error) => {
        console.log("error in categories", error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
      });
  }, [dispatch, access_token, navigate]);

  useEffect(() => {
    if (access_token) {
      getCategories();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastEvent = currentPage * categoryPerPage;
  const indexOfFirstPost = indexOfLastEvent - categoryPerPage;
  const currentCategories = categories.slice(
    indexOfFirstPost,
    indexOfLastEvent
  );

  const getperPageData = (categoryData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setCategoryPerPage(
          categoryData.length < parseInt(data.val)
            ? categoryData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const paginate = (number) => {
    setCurrentPage(number);
  };
  const setNumberOfEvent = (number) => {
    if (number > 0) {
      if (number > categories.length) {
        setCategoryPerPage(parseInt(categories.length));
      }
      setCategoryPerPage(parseInt(number));
    }
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setCategories(
      categories.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, categoryId) => {
    let checked = e.target.checked;
    setCategories(
      categories.map((category) => {
        if (categoryId === category.categoryId) {
          category.select = checked;
        }

        return category;
      })
    );

    const result = categories.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };

  const sortTitle = (sortByTitle) => {
    const sorted = categories.sort((a, b) => {
      const isReverse = sortByTitle === true ? 1 : -1;
      return isReverse * a.title.localeCompare(b.title);
    });
    setCategories(sorted);
  };
  const sortSlug = (sortBySlug) => {
    const sorted = categories.sort((a, b) => {
      const isReverse = sortBySlug === true ? 1 : -1;
      return isReverse * a.slug.localeCompare(b.slug);
    });
    setCategories(sorted);
  };
  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/blogs/categories/search/?size=-1${
          search && status
            ? `&status=${status}&searchString=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&searchString=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let CategoriesData = response.data.categorys;
        dispatch({
          type: FETCH_CATEGORY_SUCCESS,
          payload: CategoriesData,
        });
        if (
          CategoriesData !== undefined &&
          CategoriesData !== null &&
          CategoriesData !== "" &&
          CategoriesData.length !== 0
        ) {
          setCategoryPerPage(
            CategoriesData.length < 10 ? CategoriesData.length : 10
          );
          setCategories(
            CategoriesData.map((category) => {
              return {
                select: false,
                categoryId: category.categoryId,
                title: category.title,
                slug: category.slug,
                active: category.active,
                createdBy: category.createdBy,
                createdDate: category.createdDate,
                deleted: category.deleted,
                description: category.description,
                image: category.image,
                imageAlt: category.imageAlt,
                metaDescription: category.metaDescription,
                metaTitle: category.metaTitle,
                updatedBy: category.updatedBy,
                updatedDate: category.updatedDate,
              };
            })
          );
        } else {
          setCategories([]);
        }
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };
  const handleReset = () => {
    setSearch("");
    getCategories();
  };
  const handleClose = () => {
    setSearch("");
    getCategories();
  };
  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let activeID = [];
      categories.forEach((active) => {
        if (active.select) {
          activeID.push(active.categoryId);
        }
      });
      let config = {
        method: "delete",
        url: API + "/blogs/categories/",
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          notify("Categories's deleted successfully");
          getCategories();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    }
  };
  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let activeID = [];
      categories.forEach((active) => {
        if (active.select) {
          activeID.push(active.categoryId);
        }
      });
      let config = {
        method: "put",
        url: API + "/blogs/categories/status/true",
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          notify("Categories's activated successfully");
          getCategories();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    }
  };
  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let activeID = [];
      categories.forEach((active) => {
        if (active.select) {
          activeID.push(active.categoryId);
        }
      });
      let config = {
        method: "put",
        url: API + "/blogs/categories/status/false",
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          notify("Categories's deactivated successfully");
          getCategories();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    }
  };

  if (categories) {
    return (
      <div
        className={`content-container relative overflow-y-auto scrollbar-hide ${
          props.Sidebar ? "" : ""
        }`}
      >
        <div className="flex-col items-center w-full">
          <PageHeading
            pageHeading={"Categories"}
            searchLabel={"Title"}
            pageSearch={pageSearch}
            activeInactive={activeInactive}
            handleActive={handleActive}
            handleInActive={handleInActive}
            deleteBtn={true}
            handleDelete={handleDelete}
            search={search}
            setSearch={setSearch}
            setStatus={setStatus}
            handleSearch={handleSearch}
            handleReset={handleReset}
            handleClose={handleClose}
            addBtn={true}
            path="/admin/categories/addCategory"
          />
          {loading && (
            <Loader
              type="box-up"
              bgColor={"#0075fd"}
              // title={"Loading"}
              color={"#0075fd"}
              size={100}
            />
          )}
          {!loading && currentCategories.length === 0 ? (
            <NoRecord />
          ) : (
            <CategoriesTable
              categories={currentCategories}
              categoryPerPage={categoryPerPage}
              totalCategories={categories.length}
              paginate={paginate}
              currentPage={currentPage}
              setNumberOfEvent={setNumberOfEvent}
              selectAll={selectAll}
              selectSingle={selectSingle}
              sortTitle={sortTitle}
              sortByTitle={sortByTitle}
              setSortByTitle={setSortByTitle}
              sortSlug={sortSlug}
              sortBySlug={sortBySlug}
              setSortBySlug={setSortBySlug}
              selectAllCheckbox={selectAllCheckbox}
              setSelectAllCheckbox={setSelectAllCheckbox}
              handleActive={handleActive}
              handleInActive={handleInActive}
              allCategories={categories}
              setCategories={setCategories}
            />
          )}
        </div>
      </div>
    );
  }
};
export default Categories;
