import React, { useState } from "react";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import moment from "moment";

const IpTrackerTable = ({
  ips,
  ipPerPage,
  totalIps,
  paginate,
  currentPage,
  setNumberOfIp,
  sortUserId,
  sortByUserId,
  setSortByUserId,
  sortType,
  sortByType,
  setSortByType,
  sortIpAdress,
  sortByIpAdress,
  setSortByIpAdress,
  sortLogIn,
  sortByLogIn,
  setSortByLogIn,
  sortLastActivity,
  sortByLastActivity,
  setSortByLastActivity,
  sortLogOut,
  sortByLogOut,
  setSortByLogOut,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalIps / ipPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortUserIdArrow, setSortUserIdArrow] = useState(true);
  let [sortTypeArrow, setSortTypeArrow] = useState(true);
  let [sortIpAdressArrow, setSortIpAdressArrow] = useState(true);
  let [sortLogInArrow, setSortLogInArrow] = useState(true);
  let [sortLastActivityArrow, setSortLastActivityArrow] = useState(true);
  let [sortLogOutArrow, setSortLogOutArrow] = useState(true);

  return (
    <div className="overflow-x-auto scrollbar-hide py-2 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5">
      <table
        className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b"
        id="table-to-xls"
      >
        <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
          <tr>
            <th
              onClick={() => {
                setSortByUserId(!sortByUserId);
                sortUserId(sortByUserId);
                setSortUserIdArrow(false);
                setSortTypeArrow(true);
                setSortIpAdressArrow(true);
                setSortLogInArrow(true);
                setSortLastActivityArrow(true);
                setSortLogOutArrow(true);
              }}
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">User</span>
              {sortUserIdArrow && (
                <>
                  <RiArrowUpFill className="inline-block ml-1 cursor-pointer" />
                  <RiArrowDownFill className="inline-block ml-1 cursor-pointer" />
                </>
              )}
              {!sortUserIdArrow && (
                <>
                  {sortByUserId === true ? (
                    <RiArrowUpFill className="inline-block ml-2 cursor-pointer" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2 cursor-pointer" />
                  )}
                </>
              )}
            </th>
            {/* <th
              onClick={() => {
                sortType(sortByType);
                setSortByType(!sortByType);
                
              }}
              scope="col"
              className="cursor-pointer  px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">Type</span>
              {sortTypeArrow && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortTypeArrow && (
                <>
                  {sortByType === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th> */}
            <th
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">Ip Adress</span>
            </th>
            <th
              onClick={() => {
                setSortByLogIn(!sortByLogIn);
                sortLogIn(sortByLogIn);
                setSortUserIdArrow(true);
                setSortTypeArrow(true);
                setSortIpAdressArrow(true);
                setSortLogInArrow(false);
                setSortLastActivityArrow(true);
                setSortLogOutArrow(true);
              }}
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">LogIn</span>
              {sortLogInArrow && (
                <>
                  <RiArrowUpFill className="inline-block ml-1 cursor-pointer" />
                  <RiArrowDownFill className="inline-block ml-1 cursor-pointer" />
                </>
              )}
              {!sortLogInArrow && (
                <>
                  {sortByLogIn === true ? (
                    <RiArrowUpFill className="inline-block ml-2 cursor-pointer" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2 cursor-pointer" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                setSortByLastActivity(!sortByLogIn);
                sortLastActivity(sortByLogIn);
                setSortUserIdArrow(true);
                setSortTypeArrow(true);
                setSortIpAdressArrow(true);
                setSortLogInArrow(true);
                setSortLastActivityArrow(false);
                setSortLogOutArrow(true);
              }}
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">Last Activity</span>
              {sortLastActivityArrow && (
                <>
                  <RiArrowUpFill className="inline-block ml-1 cursor-pointer" />
                  <RiArrowDownFill className="inline-block ml-1 cursor-pointer" />
                </>
              )}
              {!sortLastActivityArrow && (
                <>
                  {sortByLastActivity === true ? (
                    <RiArrowUpFill className="inline-block ml-2 cursor-pointer" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2 cursor-pointer" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                setSortByLogOut(!sortByLogOut);
                sortLogOut(sortByLogOut);
                setSortUserIdArrow(true);
                setSortTypeArrow(true);
                setSortIpAdressArrow(true);
                setSortLogInArrow(true);
                setSortLastActivityArrow(true);
                setSortLogOutArrow(false);
              }}
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">Submitted On</span>
              {sortLogOutArrow && (
                <>
                  <RiArrowUpFill className="inline-block ml-1 cursor-pointer" />
                  <RiArrowDownFill className="inline-block ml-1 cursor-pointer" />
                </>
              )}
              {!sortLogOutArrow && (
                <>
                  {sortByLogOut === true ? (
                    <RiArrowUpFill className="inline-block ml-2 cursor-pointer" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2 cursor-pointer" />
                  )}
                </>
              )}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-darkblack">
          {ips &&
            ips.map((ip) => (
              <tr
                key={ip.id}
                className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
              >
                <td className="px-6 py-4  text-sm text-gray-600 dark:text-white">
                  <span className=" pb-2 inline-block">
                    {ip.userId ? ip.userId : null}
                  </span>
                </td>
                {/* <td className="px-6 py-4  text-sm capitalize text-gray-600 dark:text-white">
                  <span className=" pb-2 inline-block">
                    {ip.type ? ip.type : null}
                  </span>
                </td> */}
                <td className="px-6 py-4  text-sm capitalize text-gray-600 dark:text-white">
                  <span className=" pb-2 inline-block">
                    {ip.ipAddress ? ip.ipAddress : null}
                  </span>
                </td>
                <td className="px-6 py-4  text-sm capitalize text-gray-600 dark:text-white">
                  <span className=" pb-2 inline-block">
                    {moment(ip.createdDate).format("MM/DD/YYYY h:mm A")}
                  </span>
                </td>
                <td className="px-6 py-4  text-sm capitalize text-gray-600 dark:text-white">
                  <span className=" pb-2 inline-block">
                    {ip.activity ? ip.activity : null}
                  </span>
                </td>
                <td className="px-6 py-4  text-sm capitalize text-gray-600 dark:text-white">
                  <span className=" pb-2 inline-block">
                    {moment(ip.createdDate).format("MM/DD/YYYY h:mm A")}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <label
            onClick={() => {
              sortUserId(sortByUserId);
              setSortByUserId(!sortByUserId);
              setSortUserIdArrow(false);
              setSortTypeArrow(true);
              setSortIpAdressArrow(true);
              setSortLogInArrow(true);
              setSortLastActivityArrow(true);
              setSortLogOutArrow(true);
            }}
            className="px-5 py-3 text-sm font-medium leading-normal"
          >
            Names
            {!sortUserIdArrow && (
              <>
                {sortByUserId === true ? (
                  <RiArrowUpFill className="inline-block ml-2" />
                ) : (
                  <RiArrowDownFill className="inline-block ml-2" />
                )}
              </>
            )}
          </label>
        </div>
        {ips &&
          ips.map((ip) => (
            <div className="tab w-full border" key={ip.id}>
              <input
                className="absolute opacity-0"
                id={ip.id}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                for={ip.id}
              >
                <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">{ip.userId}</span>
              </label>
              <div className="tab-content border-t overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">User:</h1>
                    <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                      {ip.userId ? ip.userId : null}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Type:</h1>
                    <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                      {ip.type ? ip.type : null}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">
                      Ip Address:
                    </h1>
                    <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                      {ip.ipAddress}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">
                      Last Login Time:
                    </h1>
                    <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                      {ip.createdDate
                        ? moment(ip.createdDate).format("MM/DD/YYYY h:mm A")
                        : null}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Activity:</h1>
                    <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                      {ip.activity ? ip.activity : null}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Log Out:</h1>
                    <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                      {ip.createdDate
                        ? moment(ip.createdDate).format("MM/DD/YYYY h:mm A")
                        : null}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* pagination */}
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
        <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
              } liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="items-center justify-center mt-3 sm:mt-1 md:mt-1 lg:mt-1 xl:mt-1">
          <span className="pr-2 dark:text-white">Show</span>
          <input
            type="number"
            min={1}
            value={ipPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfIp(e.target.value)}
          />
          <span className="pl-2 dark:text-white">Entries</span>
        </div>
      </div>
    </div>
  );
};
export default IpTrackerTable;
