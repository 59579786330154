import React, { Component } from "react";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import {
  removeFromCart,
  increaseProductCount,
  decreaseProductCount,
} from "../../../Redux/actions/cartActions";

class Cart extends Component {
  componentDidMount() {
    // console.log("this.props.cartItems", this.props.cartItems);
  }
  componentDidUpdate() {
    // console.log("this.props.cartItems update ui", this.props.cartItems);
  }

  render() {
    return (
      <div className="w-full mt-10 min-h-screen bg-gray-100  dark:bg-gray-700 pt-16 pb-16  shadow-md flex items-center flex-col">
        {this.props.cartItems.length > 0 &&
          this.props.cartItems.map((p) => (
            <div className="flex w-full flex-wrap justify-between items-center  border-b-2 px-2 sm:px-10 md:px-10 lg:px-10 xl:px-10 my-2">
              <div className=" h-14  w-10 sm:w-20 md:w-20 lg:w-20 xl:w-20  flex items-center justify-center p-1">
                <img
                  src={p.image}
                  alt=""
                  className="w-full h-full object-contain rounded cursor-pointer"
                  onClick={() => this.props.history.push("/product/" + p.id)}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-3/4 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2  ">
                <p className="font-semibold text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm text-yellow-500 line-clamp-1">
                  {p.title}
                </p>
                <p className="font-semibold text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm  text-yellow-500 mt-2">
                  Price: $ {p.price}
                </p>
                <div className="flex items-center  mt-1">
                  <button
                    onClick={() => {
                      if (p.count === 1) {
                        this.props.removeFromCart(p);
                      } else {
                        this.props.decreaseProductCount(p);
                      }
                    }}
                    className="w-5 h-5 outline-none flex items-center justify-center  border-2 border-gray-500 m-1 bg-white"
                  >
                    -
                  </button>
                  <p className="font-semibold m-1 dark:text-white text-sm ">
                    {p.count}
                  </p>
                  <button
                    onClick={() => {
                      this.props.increaseProductCount(p);
                    }}
                    className="w-5 h-5 flex items-center justify-center  border-2 border-gray-500 m-1 bg-white"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="mb-2 flex flex-row justify-end items-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto  sm:flex-row md:flex-row lg:flex-row xl:flex-row sm:items-center md:items-center lg:items-center xl:items-center">
                <h1 className="font-semibold text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm  dark:text-gray-400">
                  Subtotal:{" "}
                  <span className="font-semibold text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm  text-yellow-500">
                    $ {(p.count * p.price).toFixed(2)}
                  </span>
                </h1>

                <button
                  className=" ml-2 p-2 h-6 sm:h-8 md:h-8 lg:h-8 xl:h-8 bg-black flex items-center justify-center hover:bg-gray-800 text-white text-xs rounded"
                  onClick={() => {
                    this.props.removeFromCart(p);
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        {this.props.cartItems.length > 0 && (
          <div className="flex w-full justify-end items-center dark:text-white px-2 sm:px-10 md:px-10 lg:px-10 xl:px-10 my-2">
            <h1 className="text-sm sm:text-sm md:text-sm lg:text-xl xl:text-xl font-semibold">
              Total:
              <span className="text-yellow-500 pl-2">
                ${" "}
                {this.props.cartItems
                  .reduce((a, c) => a + c.price * c.count, 0)
                  .toFixed(2)}
              </span>
            </h1>
            <button
              onClick={() => {
                this.props.history.push("/checkout");
              }}
              className="py-2 px-2 sm:px-4 bg-yellow-500 hover:bg-yellow-400 text-white rounded ml-2 text-sm font-semibold"
            >
              Checkout
            </button>
          </div>
        )}

        {this.props.cartItems.length === 0 && (
          <h1 className="text-center text-2xl dark:text-white mt-10">
            No items in cart
          </h1>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cartItems,
});
const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCart: (p) => dispatch(removeFromCart(p)),
    increaseProductCount: (p) => dispatch(increaseProductCount(p)),
    decreaseProductCount: (p) => dispatch(decreaseProductCount(p)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
