import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

const BlogsSearch = (props) => {
    return (
        <div className="w-full flex flex-col border mb-4 rounded-md dark:bg-gray-800 bg-white">
            <div className="w-full border-b h-10 flex items-center pl-3 bg-gray-50">
                <label>Search</label>
            </div>
            <div className="w-full flex h-20 px-3 items-center">
                <input
                    type="text"
                    value={props.search}
                    onChange={(e) => {
                        props.setSearch(e.target.value);
                    }}
                    className="h-10 border w-full rounded-tl-md rounded-bl-md px-3"
                />
                {props.searchIcon && (
                    <button
                        onClick={() => {
                            props.handleSearch();
                        }}
                        className="h-10 w-10 cursor-pointer flex items-center justify-center border-r border-t border-b bg-gray-200 rounded-tr-md rounded-br-md"
                    >
                        <AiOutlineSearch />
                    </button>
                )}

                {!props.searchIcon && (
                    <button
                        onClick={() => {
                            props.handleClose();
                        }}
                        className="h-10 w-10 cursor-pointer flex items-center justify-center border-r border-t border-b bg-gray-200 rounded-tr-md rounded-br-md"
                    >
                        x
                    </button>
                )}
            </div>
        </div>
    );
};

export default BlogsSearch;
