import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";
import SubscriptionTable from "./SubscriptionTable";

const SubscriptionView = (props) => {
  let navigate = useNavigate();
  let state = useSelector((state) => state.UsersReducer);
  let [currentPage, setCurrentPage] = useState(1);
  let [postPerPage, setPostPerPage] = useState(5);
  let [search, setSearch] = useState("");
  let [status, setStatus] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  let [SortByPlanName, setSortByPlanName] = useState(false);
  let [SortBySubscriptionCharge, setSortBySubscriptionCharge] = useState(false);
  let [SortByDisplayOrder, setSortByDisplayOrder] = useState(false);
  let [sortByCreated, setSortByCreated] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [SubscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  useEffect(() => {
    if (access_token) {
      getSubscriptionData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = SubscriptionData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(SubscriptionData.length / postPerPage); i++) {
    pageNumbers.push(i);
  }

  const setSubscriptionsPerPage = (number) => {
    if(number>0){
      if(number>SubscriptionData.length){
        setPostPerPage(parseInt(SubscriptionData.length));
      }
      setPostPerPage(parseInt(number))
    }
  }

  const paginate = (number) => {
    if(number>0){
      setCurrentPage(number);
    }
  };

  const getperPageData = (Subscription) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setPostPerPage(
          Subscription.length < parseInt(data.val)
            ? Subscription.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SortPlanName = (SortByPlanName) => {
    const sorted = SubscriptionData.sort((a, b) => {
      const isReverse = SortByPlanName === true ? 1 : -1;
      return isReverse * a.planName.trim().localeCompare(b.planName.trim());
    });
    setSubscriptionData(sorted);
  };

  const sortCreated = (sortByCreated) => {
    const sorted = SubscriptionData.sort((a, b) => {
      let dateA = new Date(a.updatedAt);
      let dateB = new Date(b.updatedAt);
      return sortByCreated ? dateA - dateB : dateB - dateA;
    });
    setSubscriptionData(sorted);
  };

  const SortDisplayOrder = (sortByDisplayOrder) => {
    const sorted = SubscriptionData.sort((a, b) => {
      const isReverse = sortByDisplayOrder === true ? 1 : -1;
      return isReverse * a.displayOrder - b.displayOrder;
    });
    setSubscriptionData(sorted);
  };

  const SortSubscriptionCharge = (sortBySubscriptionCharge) => {
    const sorted = SubscriptionData.sort((a, b) => {
      const isReverse = sortBySubscriptionCharge === true ? 1 : -1;
      return isReverse * a.charge - b.charge;
    });
    setSubscriptionData(sorted);
  };

  const getSubscriptionData = () => {
    let config = {
      method: "get",
      url: API + "/users/subscriptionplan/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let subscriptions = response.data.subscriptionPlans;
        // console.log("subscriptions", subscriptions);
        if (
          subscriptions !== undefined &&
          subscriptions !== "" &&
          subscriptions !== null
        ) {
          getperPageData(subscriptions);
          setSubscriptionData(
            subscriptions.map((data) => {
              return {
                select: false,
                planName: data.planName,
                updatedAt: data.updatedAt,
                createdAt: data.createdAt,
                description: data.description,
                displayOrder: data.displayOrder,
                id: data.id,
                period: data.period,
                type: data.type,
                status: data.status,
                charge: data.charge,
                planFeaturesDTO: data.planFeaturesDTO,
              };
            })
          );
        } else {
          setSubscriptionData([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        console.log("error", error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          handleLogout(navigate);
        }
      });
  };

  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let id = [];
      SubscriptionData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });
      // console.log("id array", id);

      let updateSubscription = {
        method: "put",
        url: API + "/users/subscriptionplan/status/true",
        data: id,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updateSubscription", updateSubscription);
      axios(updateSubscription)
        .then((response) => {
          notify("Subscription activated successfully");
          getSubscriptionData();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch((error) => {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
        });
    }
  };

  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let id = [];
      SubscriptionData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });
      // console.log("id array", id);

      let updateSubscription = {
        method: "put",
        url: API + "/users/subscriptionplan/status/false",
        data: id,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updateSubscription", updateSubscription);
      axios(updateSubscription)
        .then(function (response) {
          notify("Subscription deactivated successfully");
          getSubscriptionData();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
        });
    }
  };

  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        "/users/subscriptionplan/search/?status=true&searchString=" +
        search +
        "&size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let subscriptions = response.data.subscriptionPlans;
        if (
          subscriptions !== undefined &&
          subscriptions !== "" &&
          subscriptions !== null &&
          subscriptions.length !== 0
        ) {
          setSubscriptionData(
            subscriptions.map((data) => {
              return {
                select: false,
                planName: data.planName,
                updatedAt: data.updatedAt,
                createdAt: data.createdAt,
                description: data.description,
                displayOrder: data.displayOrder,
                id: data.id,
                period: data.period,
                type: data.type,
                status: data.status,
                charge: data.charge,
                planFeaturesDTO: data.planFeaturesDTO,
              };
            })
          );
        } else {
          setSubscriptionData([]);
        }
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setSubscriptionData(
      SubscriptionData.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setSubscriptionData(
      SubscriptionData.map((p) => {
        if (id === p.id) {
          p.select = checked;
        }

        return p;
      })
    );

    const result = SubscriptionData.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };

  const handleReset = () => {
    setSearch("");
    getSubscriptionData();
  };

  const handleClose = () => {
    setSearch("");
    getSubscriptionData();
  };

  return (
    <div
    className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar
          ? ""
          : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Subscriptions"}
          searchLabel={"Plan name"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          search={search}
          setSearch={setSearch}
          setStatus={setStatus}
          handleActive={handleActive}
          handleInActive={handleInActive}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentPosts.length === 0 ? (
          <NoRecord />
        ) : (
          <SubscriptionTable
            state={state}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            selectAll={selectAll}
            SubscriptionData={currentPosts}
            selectSingle={selectSingle}
            pageNumbers={pageNumbers}
            currentPage={currentPage}
            paginate={paginate}
            postPerPage={postPerPage}
            setPostPerPage={setPostPerPage}
            sortCreated={sortCreated}
            sortByCreated={sortByCreated}
            setSortByCreated={setSortByCreated}
            SortPlanName={SortPlanName}
            SortByPlanName={SortByPlanName}
            setSortByPlanName={setSortByPlanName}
            SortDisplayOrder={SortDisplayOrder}
            SortByDisplayOrder={SortByDisplayOrder}
            setSortByDisplayOrder={setSortByDisplayOrder}
            SortSubscriptionCharge={SortSubscriptionCharge}
            SortBySubscriptionCharge={SortBySubscriptionCharge}
            setSortBySubscriptionCharge={setSortBySubscriptionCharge}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allSubscriptionsData={SubscriptionData}
            setSubscriptionData={setSubscriptionData}
            setSubscriptionsPerPage={setSubscriptionsPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionView;
