import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import { RefreshToken } from "./../../services/RefreshToken";
import SiteCofigHeading from "./SiteConfigHeading";
import SiteConfigTable from "./SiteConfigTable";

const SiteConfig = (props) => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  let [siteconfigs, setSiteConfigs] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [siteConfigPerPage, setSiteConfigPerPage] = useState(5);

  let [sortByName, setSortByNameDesc] = useState(false);
  let [sortByVal, setSortByValDesc] = useState(true);
  let [sortByStatus, setSortByStatus] = useState(true);

  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  let [totalSiteConfigs, setTotalSiteConfigs] = useState(0);

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  const fetchSiteConfigs = useCallback(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.siteConfig;
        if (data !== null && data !== undefined && data !== "") {
          const sorted = data.sort((a, b) => {
            const isReverse = true ? 1 : -1;
            return isReverse * a.name.localeCompare(b.name);
          });
          setSiteConfigPerPage(data.length < 5 ? data.length : 5);
          setSiteConfigs(
            sorted.map((siteconfig) => {
              return {
                select: false,
                id: siteconfig.id,
                name: siteconfig.name,
                val: siteconfig.val,
                status: siteconfig.status,
                type: siteconfig.type,
                createdDate: siteconfig.createdDate
                  ? moment(siteconfig.createdDate).format("YYYY-MM-DD")
                  : null,
              };
            })
          );
          setTotalSiteConfigs(response.data.totalItems);
        } else {
          setSiteConfigs([]);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token, navigate]);

  useEffect(() => {
    if (access_token) {
      fetchSiteConfigs();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastSiteConfig = currentPage * siteConfigPerPage;
  const indexOfFirstSiteConfig = indexOfLastSiteConfig - siteConfigPerPage;
  const currentSiteConfigs = siteconfigs.slice(
    indexOfFirstSiteConfig,
    indexOfLastSiteConfig
  );

  const paginate = (number) => {
    if(number>0){
      setCurrentPage(number);
    }
  };
  const setNumberOfSiteConfig = (number) => {
    if(number>0){
      if (number > siteconfigs.length) {
        setSiteConfigPerPage(parseInt(siteconfigs.length));
      }
      setSiteConfigPerPage(parseInt(number));
    }
  };

  const sortName = (sortByName) => {
    const sorted = siteconfigs.sort((a, b) => {
      const isReverse = sortByName === true ? 1 : -1;
      return isReverse * a.name.trim().localeCompare(b.name.trim());
    });
    setSiteConfigs(sorted);
  };
  const sortVal = (sortByVal) => {
    const sorted = siteconfigs.sort((a, b) => {
      const isReverse = sortByVal === true ? 1 : -1;
      return isReverse * a.val.trim().localeCompare(b.val.trim());
    });
    setSiteConfigs(sorted);
  };

  const sortStatus = (sortByStatus) => {
    const sorted = siteconfigs.sort((a, b) => {
      let dateA = new Date(a.status);
      let dateB = new Date(b.ststus);
      return sortStatus ? dateA - dateB : dateB - dateA;
    });
    setSiteConfigs(sorted);
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setSiteConfigs(
      siteconfigs.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setSiteConfigs(
      siteconfigs.map((data) => {
        if (id === data.id) {
          data.select = checked;
        }

        return data;
      })
    );
    const result = siteconfigs.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };

  const handleSearch = () => {
    let searchData = siteconfigs.filter((d) => {
      if (d.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
        return d;
      }
      return null;
    });
    if (searchData.length === 0) {
      setSiteConfigs(siteconfigs);
    } else {
      setSiteConfigs(searchData);
      setCurrentPage(1);
    }
  };
  const handleReset = () => {
    setSearch("");
    fetchSiteConfigs();
  };
  const handleClose = () => {
    setSearch("");
    fetchSiteConfigs();
  };

  const handleActive = () => {
    let arrayId = [];
    siteconfigs.forEach((d) => {
      if (d.select) {
        arrayId.push(d.id);
      }
    });
    let config = {
      method: "put",
      url: API + "/siteConfigs/" + true,
      data: arrayId,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then((response) => {
        notify("siteconfigs activated successfully");
        setSelectAllCheckbox(false);
        setActiveInactive(false);
        setPageSearch(true);
        fetchSiteConfigs();
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };
  const handleInActive = () => {
    let arrayId = [];
    siteconfigs.forEach((d) => {
      if (d.select) {
        arrayId.push(d.id);
      }
    });
    let config = {
      method: "put",
      url: API + "/siteConfigs/" + false,
      data: arrayId,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then((response) => {
        notify("Pages deactivated successfully");
        setSelectAllCheckbox(false);
        setActiveInactive(false);
        setPageSearch(true);
        fetchSiteConfigs();
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      siteconfigs.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "delete",
        url: API + "/siteConfigs/",
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Records deleted successfully");
          setActiveInactive(!activeInactive);
          setPageSearch(!pageSearch);
          setSelectAllCheckbox(false);
          fetchSiteConfigs();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  return (
    <div
      className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide relative ${
        props.Sidebar
          ? "w-full sm:content md:content lg:content xl:content"
          : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
      }`}
    >
      <div className="flex-col items-center w-full">
        {loading && (
          <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
            <table className="w-full hidden sm:table md:table lg:table xl:table   border-gray-200 border-2">
              <thead className="bg-gray-50 dark:bg-gray-400 dark:text-textinblack w-full">
                <tr className="border-l-8 border-gray-400 h-10"></tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-700 animate-pulse">
                {Array(15)
                  .fill()
                  .map(() => (
                    <tr
                      className={`
                                    border-l-8 border-gray-400 border-b bg-gray-700 h-12 `}
                    >
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-textinblack"></td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div>
                            <div className="text-sm font-medium capitalize text-gray-900 dark:text-textinblack"></div>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 dark:text-textinblack"></div>
                      </td>

                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-textinblack"></td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-textinblack"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-textinblack"></td>
                      <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="overflow-hidden block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
              <div className="bg-gray-200 px-5 py-3 w-full border-t">
                <label className="px-5 py-3 text-sm font-medium leading-normal"></label>
              </div>
              {Array(15)
                .fill()
                .map((i) => (
                  <div
                    className={`tab w-full border-t border-left-green-8 animate-pulse `}
                  >
                    <label className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700">
                      <span className="px-5 block w-11/12 h-6"></span>
                    </label>
                    <div className="tab-content border-t   overflow-hidden w-full">
                      <div className="p-4">
                        <div className="flex py-1">
                          <p className="dark:text-textinblack font-semibold"></p>
                          <p className="dark:text-textinblack text-left pl-2"></p>
                        </div>
                        <div className="flex py-1">
                          <p className="dark:text-textinblack font-semibold"></p>
                          <p className="dark:text-textinblack text-left pl-2"></p>
                        </div>
                        <div className="flex py-1">
                          <p className="dark:text-textinblack font-semibold"></p>
                          <p className="dark:text-textinblack text-left pl-2"></p>
                        </div>
                        <div className="flex py-1">
                          <p className="dark:text-textinblack font-semibold"></p>
                          <p className="dark:text-textinblack text-left pl-2"></p>
                        </div>
                        <div className="flex py-1">
                          <p className="dark:text-textinblack text-left pl-2"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {!loading && (
          <SiteCofigHeading
            pageHeading={"SiteConfigs"}
            searchLabel={"Name/Val"}
            pageSearch={pageSearch}
            // activeInactive={activeInactive}
            // handleActive={handleActive}
            // handleInActive={handleInActive}
            // deleteBtn={true}
            // handleDelete={handleDelete}
            search={search}
            setSearch={setSearch}
            handleSearch={handleSearch}
            handleReset={handleReset}
            handleClose={handleClose}
            path="/admin/siteconfigs/addSiteConfig"
          />
        )}
        {!loading && (
          <SiteConfigTable
            siteconfigs={currentSiteConfigs}
            siteConfigPerPage={siteConfigPerPage}
            totalSiteConfigs={totalSiteConfigs}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfSiteConfig={setNumberOfSiteConfig}
            sortName={sortName}
            sortByName={sortByName}
            setSortByNameDesc={setSortByNameDesc}
            sortVal={sortVal}
            sortByVal={sortByVal}
            setSortByValDesc={setSortByValDesc}
            sortStatus={sortStatus}
            sortByStatus={sortByStatus}
            setSortByStatus={setSortByStatus}
            selectAll={selectAll}
            selectSingle={selectSingle}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
          />
        )}
      </div>
    </div>
  );
};

export default SiteConfig;
