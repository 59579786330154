import React, { useCallback, useEffect, useState } from "react";
import PageHeading from "../pages/PageHeading";
import FaqsTable from "./faqsTable";
import axios from "axios";
import { notify, NoRecord } from "../../utility";
import { API } from "../../services/API";
import { handleLogout } from "../login/handleLogOut";
import { useNavigate } from "react-router-dom";
import { RefreshToken } from "./../../services/RefreshToken";
import Loader from "react-js-loader";

const Faqs = (props) => {
  let navigate = useNavigate();
  const [faqs, setFaqs] = useState([]);

  let [currentPage, setCurrentPage] = useState(1);
  let [faqsPerPage, setFaqsPerPage] = useState(5);

  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [status, setStatus] = useState("");

  let [sortByQuestion, setSortByQuestion] = useState(true);
  let [sortByDisplayOrder, setSortByDisplayOrder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  const indexOfLastQuestion = currentPage * faqsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - faqsPerPage;
  const currentFaqs = faqs.slice(indexOfFirstQuestion, indexOfLastQuestion);

  const fetchFaqs = () => {
    let config = {
      method: "get",
      url: API + "/faqs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.faqs;
        if (data !== undefined && data !== null && data !== "") {
          const sorted = data.sort((a, b) => {
            const isReverse = sortByQuestion === true ? 1 : -1;
            return isReverse * a.question.localeCompare(b.question);
          });
          getperPageData(data);
          setFaqs(
            sorted.map((faq) => {
              return {
                select: false,
                id: faq.id,
                question: faq.question,
                displayOrder: faq.displayOrder,
                answer: faq.answer,
                status: faq.status,
              };
            })
          );
        } else {
          setFaqs([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
        console.log(error);
      });
  };

  const getperPageData = (faqsData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setFaqsPerPage(
          faqsData.length < parseInt(data.val)
            ? faqsData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (access_token) {
      fetchFaqs();
    }
  }, [access_token, sortByQuestion, navigate]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const paginate = (number) => {
    if(number>0){
      setCurrentPage(number);
    }
  };

  const setNumberOfFaqs = (number) => {
    if(number>0){
      if(number>faqs.length){
      setFaqsPerPage(parseInt(faqs.length));
      }
      setFaqsPerPage(parseInt(number));
    }
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setFaqs(
      faqs.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setFaqs(
      faqs.map((faq) => {
        if (id === faq.id) {
          faq.select = checked;
        }
        return faq;
      })
    );

    const result = faqs.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:  API +
      `/faqs/?size=-1${
        search && status
          ? `&status=${status}&keywords=${search}`
          : !search && status
          ? `&status=${status}`
          : search && !status
          ? `&keywords=${search}`
          : ""
      } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.faqs;
        if (
          data !== undefined &&
          data !== null &&
          data !== "" &&
          data.length !== 0
        ) {
          const sorted = data.sort((a, b) => {
            const isReverse = sortByQuestion === true ? 1 : -1;
            return isReverse * a.question.localeCompare(b.question);
          });
          setFaqsPerPage(data.length < 10 ? data.length : 10);
          setFaqs(
            sorted.map((faq) => {
              return {
                select: false,
                id: faq.id,
                question: faq.question,
                displayOrder: faq.displayOrder,
                answer: faq.answer,
                status: faq.status,
              };
            })
          );
        } else {
          setFaqs([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    fetchFaqs();
  };

  const handleClose = () => {
    setSearch("");
    fetchFaqs();
  };

  const sortFaqs = (sortByQuestion) => {
    const sorted = faqs.sort((a, b) => {
      const isReverse = sortByQuestion === true ? 1 : -1;
      return isReverse * a.question.trim().localeCompare(b.question.trim());
    });
    setFaqs(sorted);
  };

  const sortDisplayOrder = (sortByDisplayOrder) => {
    const sorted = faqs.sort((a, b) => {
      const isReverse = sortByDisplayOrder === true ? 1 : -1;
      return isReverse * a.displayOrder - b.displayOrder;
    });
    setFaqs(sorted);
  };

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      faqs.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "delete",
        url: API + "/faqs/",
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
        data: arrayId,
      };
      axios(config)
        .then((response) => {
          notify("Faqs deleted successfully");
          setActiveInactive(!activeInactive);
          setPageSearch(!pageSearch);
          setSelectAllCheckbox(false);
          fetchFaqs();
        })
        .catch((error) => {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let arrayId = [];
      faqs.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/faqs/" + true,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchFaqs();
          notify("Faqs activated successfully");
        })
        .catch((error) => {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let arrayId = [];
      faqs.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/faqs/" + false,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify(response.data.message);
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchFaqs();
          notify("Faqs inactivated successfully");
        })
        .catch((error) => {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };

  return (
    <div
    className={`content-container relative overflow-y-auto scrollbar-hide ${props.Sidebar
      ? ""
      : ""
    }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"FAQs"}
          searchLabel={"Title"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          search={search}
          setSearch={setSearch}
          setStatus={setStatus}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/faqs/addFaq"
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentFaqs.length === 0 ? (
          <NoRecord />
        ) : (
          <FaqsTable
            faqs={currentFaqs}
            faqsPerPage={faqsPerPage}
            totalFaqs={faqs.length}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfFaqs={setNumberOfFaqs}
            selectAll={selectAll}
            selectSingle={selectSingle}
            sortFaqs={sortFaqs}
            sortByQuestion={sortByQuestion}
            setSortByQuestion={setSortByQuestion}
            sortDisplayOrder={sortDisplayOrder}
            sortByDisplayOrder={sortByDisplayOrder}
            setSortByDisplayOrder={setSortByDisplayOrder}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allFaqs={faqs}
            setFaqs={setFaqs}
          />
        )}
      </div>
    </div>
  );
};

export default Faqs;
