import React, { useState, useEffect, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { API } from "../../../services/API";
import { handleLogout } from "../../login/handleLogOut";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-responsive-modal";

const Team = () => {
  const [teams, setTeams] = useState([]);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [viewImg, setViewImg] = useState(null);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [ImagePopup, setImagePopup] = useState(false);

  const fetchTeams = useCallback(async () => {
    setLoading(true);
    let pageCount = 0;
    let dataArray = [];
    let index = 0;
    do {
      let config = {
        method: "get",
        url: API + "/teams/front/?size=5&page=" + index,
      };
      await axios(config)
        .then(function (response) {
          setLoading(false);
          const data = response.data.teams;
          // console.log("index data", index);
          // console.log("pageCount",pageCount);
          pageCount = response.data.totalPages;
          if (data) {
            let filteredData = data.filter((item) => item.status === true);
            let dataProcessed = filteredData.map((team) => {
              return {
                select: false,
                id: team.id,
                firstName: team.firstName,
                lastName: team.lastName,
                image: team.image,
                imageAlt: team.imageAlt,
                position: team.position,
                status: team.status,
                displayOrder: team.displayOrder,
              };
            });
            dataArray.push(...dataProcessed);
            // console.log("dataArray", dataArray);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // console.log("dataArray", dataArray);
      index++;
    } while (index < pageCount);
      
    setTeams(dataArray);
  }, []);

  const showTitle = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/our_team_title",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("title Data", response);
        const data = response.data;
        const title_show = data.val;
        setTitle(title_show);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/");
          // handleLogout(history);
        }
        console.log(error);
      });
  };

  const onCloseModal = () => {
    setImagePopup(false);
    setViewImg(null);
  };

  const showSubTitle = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/our_team_sub_title",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("our_team_sub_title Data", response);
        const data = response.data;
        const subtitle_show = data.val;
        setSubTitle(subtitle_show);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/");
          // handleLogout(history);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchTeams();
    showTitle();
    showSubTitle();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    className: "multiSlides",
    slidesToShow: 5,
    slidesToScroll: 1,
    // centerPadding: "50px", // set center padding
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        id="team"
        className="flex items-center flex-col pb-10 w-11/12 md:w-4/5 mx-auto text-black dark:text-tabletitledark"
      >
        <div className="text-center justify-center">
          <h1 className="text-xl md:text-2xl lg:text-3xl text-center mb-3">
            {title}
          </h1>
          <p className="tracking-wide mb-4">{subTitle}</p>
        </div>
        {/* <div className="flex items-center justify-center mb-8">
          <h1 className="text-xl md:text-2xl lg:text-3xl uppercase text-center dark:text-yellow-500">
            {title}
          </h1>
          <p>{subTitle}</p>
        </div> */}
        {/* <Slider {...settings}> */}
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
          {teams.map((x, i) => {
            return (
              <div
                key={i}
                className="rounded h-54 overflow-hidden border bg-white mx-1 md:mx-0 lg:mx-0 mb-5 team-box-members"
              >
                <div className="w-full h-98">
                  <img
                    className="object-cover object-center w-full h-full min-w-full min-h-full"
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(x.image)}`
                    }
                    alt=""
                  />
                </div>
                <div className="team-box-detail">
                  <div
                    onClick={() => {
                      setViewImg(x);
                      setImagePopup(true);
                    }}
                    data-modal-toggle="popup-modal"
                    className="cursor-pointer "
                  >
                    <span className="text-sm text-blue-600 font-medium capitalize">
                      {x.firstName + " " + x.lastName}
                    </span>
                  </div>
                  <div className="pt-1">
                    <div className="mb-2 text-white text-sm">{x.position}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* </Slider> */}

        <Modal open={ImagePopup} onClose={onCloseModal} center>
          <div>
            <img
              className="w-full"
              src={
                viewImg !== null &&
                API +
                  "/files/download/?fileName=" +
                  `${encodeURIComponent(viewImg.image)}`
              }
              alt={viewImg !== null && viewImg.imageAlt}
            />
            <div className="px-6 py-4">
              <div className="font-bold text-black text-xl mb-2">
                {viewImg !== null && viewImg.firstName + " " + viewImg.lastName}
              </div>
              <p className="text-gray-700 text-base">
                {viewImg !== null && viewImg.position}
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Team;
