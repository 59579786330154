import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import { CgAsterisk } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  cleanseData
} from "utility-reactlibrary";
import { API } from "../../../services/API";
import { notify } from "../../../utility";
import { StoreSignupDetails } from "./../../../Redux/actions/UserSignupActions";

const FrontEndSignUp = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [fName, setFname] = useState("");
  const [fNameError, setFnameError] = useState("");

  const [lName, setLname] = useState("");
  const [lNameError, setLnameError] = useState("");

  const [MobileNumber, setMobileNumber] = useState({ phone: "" });
  const [MobileNumberError, setMobileNumberError] = useState("");

  const [DateOfBirth, setDateOfBirth] = useState("");
  const [DateOfBirthError, setDateOfBirthError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [verified, setVerified] = useState(false);
  const [verifiedError, setVerifiedError] = useState("");

  const [APIError, setAPIError] = useState("");
  const [ChargesEnabled, setChargesEnabled] = useState(true);

  const [EmailAvailable, setEmailAvailable] = useState(false);

  React.useEffect(() => {
    if (MobileNumber.phone && MobileNumber.phone.length === 13) {
      setMobileNumberError("");
    }
  }, [MobileNumber.phone]);

  useEffect(() => {
    getChargesEnabledData();
  }, []);

  let captcha;
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };

  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };

  const handleRecaptcha = (value) => {
    // console.log("captcha value", value);
    setVerified(true);
    setVerifiedError("");
  };

  const getChargesEnabledData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/signup_charges",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response getChargesEnabledData", response);
        const data = response.data;
        setChargesEnabled(data.val === "Enabled" ? true : false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const verifyEmail = () => {
    let config = {
      method: "get",
      url: API + "/users/front/email/" + email.trim(),
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response of verify email", response.data);
        // const data = response.data;
        setEmailAvailable(response.data === true ? true : false);
        if (response.data === true) {
          setEmailError("User already exists!");
        } else {
          setEmailError("");
        }
        // return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.statusText === "Not Found") {
          setEmailAvailable(false);
          setEmailError("");
        }
      });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    let retVal = true;
    let emailError = "";
    let fNameError = "";
    let LNameError = "";
    let MobileNumberError = "";
    let DateOfBirthError = "";
    let newPasswordError = "";
    let confirmPasswordError = "";
    let verifiedError = "";

    await verifyEmail();
    // console.log("EmailAvailable", EmailAvailable);

    if (EmailAvailable) {
      emailError = "The user already exists.";
      setEmailError(emailError);
      retVal = false;
    }

    // var validPhoneRegex = /^\d{10}$/;
    let strongPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    // let validEmail = new RegExp("(w+([.-]?w+)*@w+([.-]?w+)*(.w {2, 3})+)");

    if (fName.trim() === "") {
      fNameError = "The first name is required.";
      setFnameError(fNameError);
      retVal = false;
    }
    if (lName.trim() === "") {
      LNameError = "The last name is required.";
      setLnameError(LNameError);
      retVal = false;
    }

    if (MobileNumber.phone.trim() === "") {
      MobileNumberError = "The mobile number is required.";
      setMobileNumberError(MobileNumberError);
      retVal = false;
    }

    if (
      MobileNumber.phone.trim() !== "" &&
      MobileNumber?.phone?.trim().length < 13
    ) {
      MobileNumberError = "Enter a valid mobile number.";
      setMobileNumberError(MobileNumberError);
      retVal = false;
    }

    // if (!MobileNumber.phone(/\W/g, "").match(validPhoneRegex)) {
    //   MobileNumberError = "The mobile number is invalid";
    //   setMobileNumberError(MobileNumberError);
    //   return false;
    // }

    if (DateOfBirth === "") {
      DateOfBirthError = "The date of birth is required";
      setDateOfBirthError(DateOfBirthError);
      retVal = false;
    }
    if (moment(DateOfBirth) >= moment()) {
      DateOfBirthError = "The date of birth is invalid";
      setDateOfBirthError(DateOfBirthError);
      retVal = false;
    }

    // if (!email.match(validEmail)) {
    //   emailError = "Invalid email";
    //   setEmailError(emailError);
    //   return false;
    // }
    if (email.trim() === "") {
      emailError = "The email field is required.";
      setEmailError(emailError);
      retVal = false;
    }

    if (newPassword.trim() === "") {
      newPasswordError = "Enter new password before save";
      setNewPasswordError(newPasswordError);
      retVal = false;
    }

    if (confirmPassword.trim() === "") {
      confirmPasswordError = "Enter Confirm password before save";
      setConfirmPasswordError(confirmPasswordError);
      retVal = false;
    }

    if (!newPassword.match(strongPassword)) {
      newPasswordError = "Please check following condition to set new password";
      setNewPasswordError(newPasswordError);
      retVal = false;
    }
    if (newPassword !== confirmPassword) {
      confirmPasswordError = "Password and confirm password is not same";
      setConfirmPasswordError(confirmPasswordError);
      retVal = false;
    }

    if (!verified) {
      verifiedError = "Please Enter Captcha";
      setVerifiedError(verifiedError);
      retVal = false;
    }

    if (retVal) {
      let data = {
        firstName: fName,
        lastName: lName,
        mobileNumber: cleanseData(MobileNumber.phone),
        email: email,
        password: newPassword,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        dateOfBirth: moment(DateOfBirth).format("yyyy-MM-DD 00:00:00.000"),
        sessionID: "",
        subscriptionPlanId: "",
      };

      // console.log("user register data", data);

      if (ChargesEnabled) {
        await dispatch(StoreSignupDetails(data));
        navigate("/subscriptionPlans", { state: { UserDetails: data } });
      } else {
        const path = API + "/users/register";

        let config = {
          credentials: "include",
          method: "post",
          url: path,
          headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
          },
          data: data,
          crossDomain: true,
        };

        await axios(config)
          .then(function (response) {
            // console.log("response", response);
            notify("You are registered successfully");
            navigate("/frontendLogin");
            setAPIError("");
            setVerified(false);
            setChargesEnabled(true);
            resetCaptcha();
          })
          .catch(function (error) {
            // console.log(error.response.data);
            setAPIError(error.response.data.messages[0]);
          });
      }
    } else {
      return retVal;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen mb-8 sm:mb-0">
      <div className="lg:w-1/3 sm:w-3/5 w-11/12 shadow-2xl p-5 rounded-lg bg-darkblack mt-24 mb-8">
        <div className="w--full flex items-center justify-center my-4">
          <h1 className="text-3xl font-normal text-white">Sign Up</h1>
        </div>
        <div className="w-full flex flex-col my-4">
          <label className="font-normal text-sm flex item-center tracking-wider text-white">
            First Name
            <CgAsterisk className="inline-block text-red-500" />
          </label>
          <input
            type="text"
            value={fName}
            placeholder="Enter your first name"
            onChange={(e) => {
              setFname(e.target.value);
              setFnameError("");
            }}
            onBlur={() => {
              if (fName.trim() === "") {
                setFnameError("The email field is required.");
              }
            }}
            className={`${
              fNameError && "border-red-500 border"
            } py-2 border-b border-gray-300  px-2 dark:bg-gray-200 rounded focus:outline-none focus:border-blue-500`}
          />
          {fNameError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {fNameError}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col my-4">
          <label className="font-normal text-sm flex item-center tracking-wider text-white">
            Last Name
            <CgAsterisk className="inline-block text-red-500" />
          </label>
          <input
            type="text"
            value={lName}
            placeholder="Enter your Last name"
            onChange={(e) => {
              setLname(e.target.value);
              setLnameError("");
            }}
            onBlur={() => {
              if (fName.trim() === "") {
                setLnameError("The last name field is required.");
              }
            }}
            className="py-2 rounded px-2 dark:bg-gray-200 border-b border-gray-300 focus:outline-none focus:border-blue-500"
          />
          {lNameError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {lNameError}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col my-4">
          <label className="font-normal text-sm flex item-center tracking-wider text-white">
            Mobile Number
            <CgAsterisk className="inline-block text-red-500" />
          </label>
          {/* <input
            type="number"
            value={MobileNumber}
            placeholder="Enter your mobile number"
            onChange={(e) => {
              setMobileNumber(e.target.value);
              setMobileNumberError("");
            }}
            onBlur={() => {
              if (fName.trim() === "") {
                setMobileNumberError("The mobile number field is required.");
              }
            }}
            className="py-2 rounded px-2 dark:bg-gray-200 border-b border-gray-300 focus:outline-none focus:border-blue-500"
          /> */}
          <TextField
            placeholder="(999)999-9999"
            defaultValue={MobileNumber.phone}
            fieldKey="phone"
            type="tel"
            data={MobileNumber}
            setData={setMobileNumber}
            onBlur={() => {
              if (MobileNumber.phone.trim() === "") {
                setMobileNumberError("The mobile number field is required.");
              }
              if (
                MobileNumber.phone.trim() !== "" &&
                MobileNumber.phone.trim().length < 13
              ) {
                setMobileNumberError("Enter a valid mobile number.");
              }
            }}
            className="py-2 rounded px-2 dark:bg-gray-200 border-b border-gray-300 focus:outline-none focus:border-blue-500"
            // error={
            //   error &&
            //   (personalInfo?.phone === "" || personalInfo?.phone?.length < 13)
            // }
          />
          {MobileNumberError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {MobileNumberError}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col my-4">
          <label className="font-normal text-sm flex item-center tracking-wider text-white">
            Date of Birth
            <CgAsterisk className="inline-block text-red-500" />
          </label>
          <DatePicker
            selected={DateOfBirth}
            // dateFormat="yyyy-MM-dd"
            placeholderText="Enter your date of birth"
            isClearable
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={150}
            onChange={(date) => {
              setDateOfBirth(date);
              setDateOfBirthError("");
            }}
            className={`${
              DateOfBirthError ? "border-red-500 border" : "border"
            } border h-10 rounded-lg px-2 text-sm font-medium w-full `}
          />
          {DateOfBirthError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {DateOfBirthError}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col my-4">
          <label className="font-normal text-sm flex item-center tracking-wider text-white">
            Email <CgAsterisk className="inline-block text-red-500 " />
          </label>
          <input
            type="email"
            value={email}
            placeholder="Enter your email address"
            onChange={(e) => {
              setEmailError("");
              setEmail(e.target.value);
            }}
            onBlur={() => {
              if (email.trim() === "") {
                setEmailError("The email field is required.");
              } else {
                verifyEmail();
              }
            }}
            className={`${
              emailError && "border-red-500 border"
            } py-2 border-b rounded  px-2 dark:bg-gray-200 border-gray-300 focus:outline-none focus:border-blue-500`}
          />
          {emailError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {emailError}
            </span>
          )}
        </div>

        <div className="w-full flex flex-col my-4">
          <label className="font-normal text-sm flex item-center tracking-wider text-white">
            Password
            <CgAsterisk className="inline-block text-red-500 " />
          </label>
          <input
            type="password"
            value={newPassword}
            placeholder="Enter your password"
            onChange={(e) => {
              setNewPasswordError("");
              setNewPassword(e.target.value);
            }}
            onBlur={() => {
              if (newPassword.trim() === "") {
                setNewPasswordError("New  password is required");
              }
            }}
            className={`${
              newPasswordError && "border-red-500 border"
            } py-2 border-b rounded  px-2 dark:bg-gray-200 border-gray-300 focus:outline-none focus:border-blue-500`}
          />
          {newPasswordError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {newPasswordError}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col my-4 text-white">
          <p className="text-xs">
            Your password must be at least 5 characters long and your password
            should contain.
          </p>
          <ol className="list-decimal text-xs pl-3">
            <li>At least one upper case letter</li>
            <li>At least one lower case letter</li>
            <li>At least one digit.</li>
            <li>
              At least one special character. Only (! \" # . @ _ ` ~ $ % ^ * : ,
              ; | -) special characters are allowed in the password.
            </li>
          </ol>
        </div>
        <div className="w-full flex flex-col my-4">
          <label className="font-normal text-sm flex item-center tracking-wider text-white">
            Confirm Password
            <CgAsterisk className="inline-block text-red-500 " />
          </label>
          <input
            type="password"
            value={confirmPassword}
            placeholder="Enter your confirm password"
            onChange={(e) => {
              setConfirmPasswordError("");
              setConfirmPassword(e.target.value);
            }}
            onBlur={() => {
              if (confirmPassword.trim() === "") {
                setConfirmPasswordError(
                  "The Confirm-password field is required."
                );
              }
            }}
            className={`${
              confirmPasswordError && "border-red-500 border"
            } py-2 border-b rounded  px-2 dark:bg-gray-200 border-gray-300 focus:outline-none focus:border-blue-500`}
          />
          {confirmPasswordError && (
            <span className="text-xs text-red-500 my-1 tracking-wider">
              {confirmPasswordError}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col my-4">
          <ReCAPTCHA
            ref={(r) => setCaptchaRef(r)}
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={handleRecaptcha}
          />
          {verifiedError && (
            <span className="text-xs tracking-wider text-red-500">
              {verifiedError}
            </span>
          )}
        </div>
        <div className="text-sm tracking-wider text-red-500">{APIError}</div>
        <div className="w-full flex items-center justify-center my-4">
          <button
            onClick={(e) => handleSignup(e)}
            type="submit"
            className="w-full h-10 bg-blue-500 hover:bg-blue-600 text-sm text-white rounded shadow-lg tracking-wider dark:hover:bg-gray-700"
          >
            Sign Up
          </button>
          {/* <stripe-buy-button
            buy-button-id="'{{BUY_BUTTON_ID}}'"
            publishable-key="pk_test_51MEnlwSEU6bXvMjgk5rhBA3KmKdrJLlUyTQ8CAd4t7cvIyAvGFJXU4jgYP1JpATiWY5BCqJkPtsvGCRu94yepzeb00rsPEQstq"
          ></stripe-buy-button> */}
        </div>
        <div className="w-full flex items-center justify-center">
          <button
            onClick={() => {
              navigate("/frontendLogin");
            }}
            className=" text-sm font-semibold hover:underline tracking-wider outline-none text-blue-400 "
          >
            Back to the Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default FrontEndSignUp;
