import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { hoverAnimation } from "../../helper/helper";

const ProductCard = ({
  products,
  setSelectAllCheckbox,
  selectAllCheckbox,
  selectSingle,
  selectAll,
  category,
  electronicsDevice,
}) => {
  let navigate = useNavigate();
  const [viewImg, setViewImg] = useState("");
  useEffect(() => {}, []);
  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
      {products.length > 0 && (
        <div className="mb-5">
          <input
            type="checkbox"
            checked={selectAllCheckbox}
            onChange={(e) => {
              setSelectAllCheckbox(!selectAllCheckbox);
              selectAll(e);
            }}
            id="selectAll"
          />
          <label for="selectAll" className="text-black dark:text-textinblack ml-2 text-lg">
            Select/Unselect All
          </label>
        </div>
      )}
      <div className="grid grid-cols-1 gap-6  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
        {products.map((product) => (
          <div
            className={`border-2 w-full overflow-hidden flex flex-col  rounded bg-white  ${
              product.status === 1 ? "border-green-500" : "border-red-500"
            }`}
          >
            <div className="w-full imgHover relative p-2 h-40">
              <div className="w-full h-full">
                <img
                  className="rounded w-full h-full object-contain"
                  src={product.image}
                  alt=""
                />
              </div>
              <div className="imgHoverDisplay absolute inset-0 space-x-2  text-white bg-black bg-opacity-70 w-full h-full hidden items-center justify-center">
                <button
                  onClick={() => {
                    setViewImg(product.image);
                  }}
                  className={
                    "w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center" +
                    hoverAnimation
                  }
                >
                  <AiOutlineEye />
                </button>
                <button
                  onClick={() => {
                    if (
                      category === "electronics" &&
                      electronicsDevice === "mobiles"
                    ) {
                      navigate(
                        "/admin/products/editProduct/" +
                          product.id +
                          "/" +
                          category +
                          "/" +
                          electronicsDevice
                      );
                    } else if (
                      category === "electronics" &&
                      electronicsDevice === "laptop"
                    ) {
                      navigate(
                        "/admin/products/editProduct/" +
                          product.id +
                          "/" +
                          category +
                          "/" +
                          electronicsDevice
                      );
                    } else {
                      navigate(
                        "/admin/products/editProduct/" +
                          product.id +
                          "/" +
                          category
                      );
                    }
                  }}
                  className={
                    "w-8 h-8 bg-green-500 rounded-full flex items-center justify-center" +
                    hoverAnimation
                  }
                >
                  <BsPencil />
                </button>
                <button
                  onClick={() => {
                    let a = window.confirm(
                      "Are you sure you want to delete this"
                    );
                    if (a) {
                    }
                  }}
                  className={
                    "w-8 h-8 bg-black rounded-full flex items-center justify-center text-lg" +
                    hoverAnimation
                  }
                >
                  x
                </button>
              </div>
            </div>

            <div className="flex justify-between items-center mt-2 p-2">
              <div className="w-1/5 ">
                <input
                  type="checkbox"
                  checked={product.select}
                  onChange={(e) => selectSingle(e, product.id)}
                />
              </div>
              <h1 className=" w-4/5 flex justify-end">
                <span className="overflow-ellipsis ">{product.title}</span>
                <span className="">[{product.images.length}]</span>
              </h1>
            </div>
          </div>
        ))}
      </div>
      {viewImg && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
          <div className="w-1/2 h-1/2  bg-white rounded  flex flex-col mt-10">
            <div className="w-full h-10 flex item-center justify-end">
              <h1
                onClick={() => {
                  setViewImg("");
                }}
                className="w-10 h-10 flex items-center justify-center cursor-pointer bg-black border-2 text-white"
              >
                x
              </h1>
            </div>
            <img src={viewImg} alt="" className="border-2 w-full h-full" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
