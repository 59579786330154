import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Product from "./Product";

const Products = () => {
    const [clothes, setClothes] = useState([]);
    const [mobiles, setMobiles] = useState([]);
    const [laptops, setLaptops] = useState([]);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");

    const fetchClothes = useCallback(() => {
        axios
            .get("http://localhost:5000/products")
            .then(function (response) {
                if (response.status === 200) {
                    if (category === "clothes") {
                        setProducts(response.data.products);
                        setLoading(false);
                    } else {
                        setClothes(response.data.products);
                        setLoading(false);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [category]);
    const fetchMobiles = useCallback(() => {
        axios
            .get("http://localhost:5000/products/getMobiles")
            .then(function (response) {
                if (response.status === 200) {
                    if (subCategory === "mobiles") {
                        setProducts(response.data.mobiles);
                        setLoading(false);
                    } else {
                        setMobiles(response.data.mobiles);
                        setLoading(false);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [subCategory]);
    const fetchLaptops = useCallback(() => {
        axios
            .get("http://localhost:5000/products/getLaptops")
            .then(function (response) {
                if (response.status === 200) {
                    if (subCategory === "laptop") {
                        setProducts(response.data.laptops);
                        setLoading(false);
                    } else {
                        setLaptops(response.data.laptops);
                        setLoading(false);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [subCategory]);

    const fetchCategory = () => {
        if (category === "clothes") {
            fetchClothes();
        } else if (subCategory === "mobiles") {
            fetchMobiles();
        } else if (subCategory === "laptop") {
            fetchLaptops();
        }
    };
    useEffect(() => {
        window.scroll(0, 0);
        fetchClothes();
        fetchMobiles();
        fetchLaptops();
    }, [subCategory, category, fetchClothes, fetchMobiles, fetchLaptops]);
    return (
        <>
            <div className="w-full mt-0 sm:mt-6 h-full pt-16 pb-6  px-2 sm:px-10 md:px-10  lg:px-10  xl:px-10">
                {loading ? (
                    <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-6 lg:gap-6 xl:gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 my-6">
                        {Array(15)
                            .fill()
                            .map(() => (
                                <div className="bg-gray-300 rounded w-full overflow-hidden animate-pulse ">
                                    <div className="w-full h-24 sm:h-48 md:h-48 lg:h-48 xl:h-48 bg-white p-2">
                                        <img
                                            alt="f"
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/image.png"
                                            }
                                            className="w-full h-full object-contain"
                                        />
                                    </div>
                                    <div className="w-full flex items-center justify-center p-2 sm:p-4 lg:p-4 xl:p-4">
                                        <p className="w-full line-clamp-2 sm:line-clamp-1 md:line-clamp-1 lg:line-clamp-1 xl:line-clamp-1 font-medium text-xs sm:text-sm  md:text-sm  lg:text-sm  xl:text-sm overflow-hidden"></p>
                                        <p className="font-medium text-yellow-500"></p>
                                    </div>
                                    <div className="w-full flex items-center justify-evenly sm:justify-end md:justify-end  lg:justify-end xl:justify-end space-x-2  px-0 py-2 sm:p-4 lg:p-4 xl:p-4">
                                        <button className="px-1 sm:px-2 md:px-2 lg:px-2 xl:px-2 py-1  bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 border-2 border-yellow-500 text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm  font-normal sm:font-medium rounded">
                                            Add To Cart
                                        </button>
                                        <button className="px-1 sm:px-2 md:px-2 lg:px-2 xl:px-2  py-1 bg-yellow-500 hover:bg-white hover:text-yellow-500  text-white border-2 border-transparent hover:border-yellow-500 text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm font-normal sm:font-medium rounded">
                                            View more
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="flex w-full flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
                        <div className="w-full lg:w-1/4 mt-4 mb-2 lg:mr-2 lg:my-6 p-3 shadow rounded bg-white dark:bg-gray-600 overflow-hidden">
                            <div className="w-full border rounded bg-gray-300 p-4">
                                <h1 className="w-full text-center my-4  text-xl capitalize dark:text-black font-medium">
                                    Categories
                                </h1>
                                <div className="w-full flex items-center justify-between my-1">
                                    <label className="mr-2 font-medium text-sm w-1/2">
                                        Clothes
                                    </label>
                                    <select
                                        onChange={(e) => {
                                            setCategory("clothes");
                                            setSubCategory(e.target.value);
                                            fetchCategory();
                                        }}
                                        className="border w-1/2 h-8 rounded"
                                    >
                                        <option>select</option>
                                        <option values="mens">Mens</option>
                                        <option values="women">Women</option>
                                        <option values="Kids">Kids</option>
                                    </select>
                                </div>
                                <div className="w-full flex items-center justify-between my-1">
                                    <label className="w-1/2 font-medium text-sm">
                                        Electronics
                                    </label>
                                    <select
                                        onChange={(e) => {
                                            setCategory("electronics");
                                            setSubCategory(e.target.value);
                                            fetchCategory();
                                        }}
                                        className="border w-1/2 h-8 rounded"
                                    >
                                        <option>select</option>
                                        <option values="mobiles">
                                            mobiles
                                        </option>
                                        <option values="laptop">laptop</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-10/12 ">
                            {category === "" && clothes && clothes.length > 0 && (
                                <div className="w-full p-3 my-6 rounded bg-gray-100 dark:bg-gray-600">
                                    <h1 className="w-full text-center my-4 text-2xl capitalize dark:text-white font-medium">
                                        Clothes
                                    </h1>
                                    <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-6 lg:gap-6 xl:gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                                        {clothes.map((p) => (
                                            <Product
                                                product={p}
                                                category="clothes"
                                                subCategory={p.category}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {category === "" && mobiles && mobiles.length > 0 && (
                                <div className="w-full rounded p-3 my-6 bg-gray-100 dark:bg-gray-600">
                                    <h1 className="w-full text-center my-4 text-2xl capitalize dark:text-white font-medium">
                                        Mobiles
                                    </h1>
                                    <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-6 lg:gap-6 xl:gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                                        {mobiles.map((p) => (
                                            <Product
                                                product={p}
                                                category="electronics"
                                                subCategory="mobiles"
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {category === "" && laptops && laptops.length > 0 && (
                                <div className="w-full rounded p-3 my-6 bg-gray-100 dark:bg-gray-600">
                                    <h1 className="w-full text-center my-4 text-2xl capitalize dark:text-white font-medium">
                                        Laptops
                                    </h1>
                                    <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-6 lg:gap-6 xl:gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                                        {laptops.map((p) => (
                                            <Product
                                                product={p}
                                                category="electronics"
                                                subCategory="laptop"
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {category !== "" &&
                                products &&
                                products.length > 0 && (
                                    <div className="w-full rounded p-3 my-6 bg-gray-100 dark:bg-gray-600">
                                        <h1 className="w-full text-center my-4 text-2xl capitalize dark:text-white font-medium">
                                            {category}
                                        </h1>
                                        <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-6 lg:gap-6 xl:gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                                            {products.map((p) => (
                                                <Product
                                                    product={p}
                                                    category={category}
                                                    subCategory={subCategory}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Products;
