import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDropzone } from "react-dropzone";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import PageHeading from "../pages/PageHeading";

const AddBlog = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [blogCategory, setBlogCategory] = useState("");
  const [authorFname, setAuthorFname] = useState("");
  const [authorLname, setAuthorLname] = useState("");
  const [status, setStatus] = useState(true);
  const [imageAltText, setImageAltText] = useState("");
  const [publishDate, setPublishDate] = useState();
  const [publishTime, setPublishTime] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const [imageAltError, setImageAltError] = useState("");
  const [publishDateError, setPublishDateError] = useState("");
  const [publishTimeError, setPublishTimeError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [metaTitleError, setMetaTitleError] = useState("");
  const [metaDescriptionError, setMetaDescriptionError] = useState("");

  let userInfo = JSON.parse(localStorage.getItem("user"));
  let access_token = userInfo?.accessToken;

  const getCategories = () => {
    let config = {
      method: "get",
      url: API + "/blogs/categories/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        let CategoriesData = response.data.categorys;
        setCategories(CategoriesData);
        setBlogCategory(CategoriesData[0].categoryId);
      })
      .catch((error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin/blog/view");
        }
      });
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setImageError(fileRejections[0].errors[0].message);
      }
      if (acceptedFiles.length > 0) {
        acceptedFiles.map((image) => {
          let data = new FormData();
          data.append("file", image);
          const config = {
            method: "post",
            url: API + "/blogs/upload",
            headers: {
              Authorization: `${access_token}`,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setImageUrl(response.data);
              setImageError("");
            })
            .catch((error) => {
              if (
                error.status === "401" &&
                error.message === "JWT token is expired"
              ) {
                navigate("/admin/blog/view");
              }
            });
          return null;
        });
      }
    },
    [access_token]
  );

  const removeImg = () => {
    setImageUrl("");
    setImageError("");
  };

  const SaveEdit = (saveclick) => {
    let titleError = "";
    let imageError = "";
    let imageAltError = "";
    let publishDateError = "";
    let publishTimeError = "";
    let descriptionError = "";
    let metaTitleError = "";
    let metaDescriptionError = "";

    if (title.trim() === "") {
      titleError = "Enter title";
    }

    if (imageAltText.trim() === "") {
      imageAltError = "Enter image alt text";
    }
    if (
      publishDate === null ||
      publishDate === "" ||
      publishDate === undefined
    ) {
      publishDateError = "Select publish date";
    }
    if (publishTime === null || publishTime === "") {
      publishTimeError = "Select publish time";
    }
    if (description.trim() === "") {
      descriptionError = "Enter Blog Description";
    }
    if (metaTitle.trim() === "") {
      metaTitleError = "Enter meta title";
    }
    if (metaDescription.trim() === "") {
      metaDescriptionError = "Enter meta description";
    }
    if (
      titleError !== "" ||
      imageError !== "" ||
      imageAltError !== "" ||
      publishDateError !== "" ||
      publishTimeError !== "" ||
      descriptionError !== "" ||
      metaTitleError !== "" ||
      metaDescriptionError !== ""
    ) {
      setTitleError(titleError);
      setImageError(imageError);
      setImageAltError(imageAltError);
      setPublishDateError(publishDateError);
      setPublishTimeError(publishTimeError);
      setDescriptionError(descriptionError);
      setMetaTitleError(metaTitleError);
      setMetaDescriptionError(metaDescriptionError);
      return;
    }

    let blog = {
      blogTitle: title,
      blogCategory: blogCategory,
      authorFirstName: authorFname,
      authorLastName: authorLname,
      publishDate: moment(publishDate).format("y-MM-DD HH:mm:ss.sss"),
      blogImageAlt: imageAltText,
      blogImage: imageUrl,
      blogDescription: description,
      blogMetaTitle: metaTitle,
      blogMetaDescription: metaDescription,
      active: status,
    };
    if (id) {
      blog.blogId = id;
      let config = {
        method: "put",
        url: API + "/blogs/",
        data: blog,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Blog updated successfully");
            navigate("/admin/blog/view");
          } else {
            notify("Blog updated successfully");
            setId(response.data.blogId);
            setTitle(response.data.blogTitle);
            setAuthorFname(response.data.authorFirstName);
            setAuthorLname(response.data.authorLastName);
            setBlogCategory(response.data.blogCategory);
            setImageUrl(response.data.blogImage);
            setImageAltText(response.data.blogImageAlt);
            let date = new Date(response.data.publishDate);
            setPublishDate(date);
            setDescription(response.data.blogDescription);
            setMetaTitle(response.data.blogMetaTitle);
            setMetaDescription(response.data.blogMetaDescription);
            setStatus(response.data.active);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/blog/view");
          }
        });
    } else {
      let config = {
        method: "post",
        url: API + "/blogs/",
        data: blog,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Blog added successfully");
            navigate("/admin/blog/view");
          } else {
            notify("Blog added successfully");
            let responsData = response.data;
            setId(responsData.blogId);
            setTitle(responsData.blogTitle);
            setBlogCategory(responsData.data.blogCategory);
            setAuthorFname(responsData.authorFirstName);
            setAuthorLname(responsData.authorLastName);
            setImageUrl(responsData.blogImage);
            setImageAltText(responsData.blogImageAlt);
            let date = new Date(responsData.publishDate);
            setPublishDate(date);
            setDescription(responsData.blogDescription);
            setMetaTitle(responsData.blogMetaTitle);
            setMetaDescription(responsData.blogMetaDescription);
            setStatus(responsData.active);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/blog/view");
          }
        });
    }
  };
  const addEditHandle = () => {
    SaveEdit(true);
  };
  const saveAndContinueHandle = () => {
    SaveEdit(false);
  };
  const getBlog = (id) => {
    let config = {
      method: "get",
      url: API + "/blogs/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        setId(data.blogId);
        setTitle(data.blogTitle);
        setBlogCategory(data.blogCategory);
        setAuthorFname(data.authorFirstName);
        setAuthorLname(data.authorLastName);
        setStatus(data.active);
        setImageAltText(data.blogImageAlt);
        let date = new Date(data.publishDate);
        setPublishDate(date);
        setImageUrl(data.blogImage);
        setDescription(data.blogDescription);
        setMetaTitle(data.blogMetaTitle);
        setMetaDescription(data.blogMetaDescription);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/blog/view");
        }
      });
  };

  const handleCancelBtn = () => {
    navigate(-1);
  };

  useEffect(() => {
    getCategories();
    if (params.id) {
      getBlog(params.id);
    }
  }, [params.id]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 10000000,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  return (
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading={id ? "Edit Blog" : "Add Blog"}
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        handleSave={addEditHandle}
        saveAndContinue={true}
        handleSaveAndContinue={saveAndContinueHandle}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Title
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={title}
              onChange={(e) => {
                setTitleError("");
                setTitle(e.target.value);
              }}
              type="text"
              className={`${
                titleError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter title"
            />
            {titleError && (
              <span className="text-red-500 text-xs">{titleError}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              Blog Category
            </label>

            <select
              value={blogCategory}
              onChange={(e) => {
                setBlogCategory(e.target.value);
              }}
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              {categories.map((category) => (
                <option key={category.categoryId} value={category.categoryId}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
          <label htmlFor="1" className="font-medium mb-1">Author First Name</label>
            <input
              value={authorFname}
              onChange={(e) => {
                setAuthorFname(e.target.value);
              }}
              type="text"
              className="border h-10 rounded-lg px-2 text-sm font-medium "
              placeholder="Enter Author First Name"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Author Last Name</label>
            <input
              value={authorLname}
              onChange={(e) => {
                setAuthorLname(e.target.value);
              }}
              type="text"
              className="border h-10 rounded-lg px-2 text-sm font-medium "
              placeholder="Enter Author Last Name"
            />
          </div>
        </div>
        <div className="w-full mt-5">
          <div>
            <label className="font-medium mb-1">
              Image <CgAsterisk className="inline text-red-500" />
            </label>
            <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
              {imageUrl && (
                <img
                  src={
                    API +
                    "/files/download/?fileName=" +
                    `${encodeURIComponent(imageUrl)}`
                  }
                  alt="no img"
                  className="w-full h-4/5  object-contain"
                />
              )}
              {imageUrl && (
                <button
                  onClick={() => {
                    removeImg();
                  }}
                  className="absolute z-20 top-1 right-1 bg-slate-700 hover:bg-slate-900 text-gray-200 font-bold py-2 px-4 rounded"
                >
                  Remove
                </button>
              )}
              {!imageUrl && (
                <div>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                    <em>(Only *.jpeg and *.png images will be accepted)</em>
                  </div>
                </div>
              )}
            </div>
            {imageError && (
              <span className="text-red-500 text-xs">{imageError}</span>
            )}
          </div>
          <div className="text-xs mt-1">
            Max size 5(MB) and Recommended Size: 1900PX x 1080PX (Allowed only
            jpg, jpeg, png and gif images)
          </div>
        </div>
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Image Alt <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={imageAltText}
              onChange={(e) => {
                setImageAltText(e.target.value);
                setImageAltError("");
              }}
              type="text"
              className={`${
                imageAltError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter Image Alt"
            />
            {imageAltError && (
              <span className="text-red-500 text-xs">{imageAltError}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              Publish Date
              <CgAsterisk className="inline text-red-500" />
            </label>
            <DatePicker
              selected={publishDate}
              dateFormat="MM/dd/yyyy hh:mm aa"
              placeholderText="Publish Date"
              showTimeInput
              isClearable
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={150}
              onChange={(date) => {
                setPublishDate(date);
                setPublishDateError("");
              }}
              className={`${
                publishDateError ? "border-red-500 border" : "border"
              } border z-50 h-10 rounded px-2 text-sm font-medium w-full`}
            />
            {publishDateError && (
              <span className="text-red-500 text-xs">{publishDateError}</span>
            )}
          </div>
        </div>
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label
              htmlFor="status"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Status
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option>Select status</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </div>
        <div className="w-full my-5">
          <label
            htmlFor="status"
            className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
          >
            Description <CgAsterisk className="inline text-red-500" />
          </label>
          <div className="w-full pb-5">
            {id ? (
              <>
                {description ? (
                  <Editor
                    value={description}
                    init={{
                      height: 300,
                      forced_root_block: "",
                      statusbar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newValue, editor) => {
                      setDescriptionError("");
                      if (editor.getContent() === "") {
                        setDescription(" ");
                      } else {
                        setDescription(editor.getContent());
                      }
                    }}
                  />
                ) : null}
              </>
            ) : (
              <Editor
                value={description}
                init={{
                  height: 300,
                  forced_root_block: "",
                  statusbar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newValue, editor) => {
                  setDescriptionError("");
                  setDescription(editor.getContent());
                }}
              />
            )}
            {descriptionError && (
              <span className="text-red-500 text-xs">{descriptionError}</span>
            )}
          </div>
        </div>
        <div className="w-full mb-5 flex flex-col">
          <div className="w-full">
            <h1 className="text-2xl font-medium">SEO</h1>
          </div>
          <div className="w-full flex flex-col lg:flex-row lg:space-x-2 mt-2">
            <div className="w-full flex flex-col">
              <label className="font-medium mb-1">
                Meta Title
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={metaTitle}
                type="text"
                onChange={(e) => {
                  setMetaTitle(e.target.value);
                  setMetaTitleError("");
                }}
                className={`${
                  metaTitleError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter Meta Title"
              />
              {metaTitleError && (
                <span className="text-red-500 text-xs">{metaTitleError}</span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <label className="font-medium mb-1">
                Meta Description
                <CgAsterisk className="inline text-red-500" />
              </label>
              <textarea
                value={metaDescription}
                onChange={(e) => {
                  setMetaDescription(e.target.value);
                  setMetaDescriptionError("");
                }}
                className={`${
                  metaDescriptionError ? "border border-red-500" : "border"
                } h-10 rounded px-2 text-sm font-medium border`}
                placeholder="Enter Meta Description"
              ></textarea>
              {metaDescriptionError && (
                <span className="text-red-500 text-xs">
                  {metaDescriptionError}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
