import React, { useEffect, useState } from "react";
import ImageSlider from "../slider/Slider";
import About from "../about/about";
import Calender from "../calender/Calender";
import Contact from "../contact/Contact";
import Testimonials from "../Testimonials/Testimonials";
import axios from "axios";
import Team from "../Team/Team";
import { API } from "../../../services/API";

const Home = () => {
  const [siteTitle, setSiteTitle] = useState("");
  const [TageLine, setTageLine] = useState("");
  const [MetaContent, setMetaContent] = useState("");

  useEffect(() => {
    getTitleData();
    getTaglineData();
    getMetaContentData();

   const elem = document.getElementById('chatbot-home');
   elem.classList.add("bottom-14-home")

   return () => {
    elem.classList.remove("bottom-14-home")
   }
  }, []);

  useEffect(() => {
    document.title = siteTitle + " - " + TageLine;
    document.getElementsByTagName("META")["description"].content = MetaContent;
  }, [siteTitle, TageLine, MetaContent]);

  const getTitleData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/site_title",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setSiteTitle(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getTaglineData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/tagline",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setTageLine(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getMetaContentData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/meta_description_for_front",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response of meta content", response);
        const data = response.data;
        setMetaContent(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <ImageSlider />
      <About />
      <Calender />
      <Team />
      <Testimonials />
      <Contact />
    </>
  );
};

export default Home;
