export const eventsData = [
    {
        id: 1,
        title: "2018 Purpose Projects",
        startDate: "6/25/2021",
        endDate: "6/30/2021",
        eventTime: "12:00 am",
        recurrence: "daily",
        modifiedOn: "1/5/2021 6:49 am",
        status: true,
    },
    {
        id: 2,
        title: "Event Title data add1",
        startDate: "1/3/2020",
        endDate: "1/6/2020",
        eventTime: "8:30 am",
        recurrence: "daily",
        modifiedOn: "2/12/2020 11:38 pm",
        status: true,
    },
    {
        id: 3,
        title: "1998 Updated Event 24",
        startDate: "1/23/2020",
        endDate: "1/31/2020",
        eventTime: "3:50 pm",
        recurrence: "daily",
        modifiedOn: "2/12/2020 11:38 pm",
        status: false,
    },
    {
        id: 4,
        title: "Trizone Events",
        startDate: "2/3/2020",
        endDate: "	2/28/2020",
        eventTime: "6:00 pm",
        recurrence: "monthly",
        modifiedOn: "2/18/2020 1:13 am",
        status: true,
    },
    {
        id: 5,
        title: "NEW EVENT",
        startDate: "2/17/2020",
        endDate: "2/21/2020",
        eventTime: "12:00 am",
        recurrence: "daily",
        modifiedOn: "2/13/2020 4:31 am",
        status: true,
    },
    {
        id: 6,
        title: "QA EVENT 17",
        startDate: "2/17/2020",
        endDate: "2/21/2020",
        eventTime: "12:00 am",
        recurrence: "daily",
        modifiedOn: "2/12/2020 11:54 pm",
        status: false,
    },
    {
        id: 7,
        title: "Google Event",
        startDate: "2/25/2020",
        endDate: "	2/27/2020",
        eventTime: "12:05 am",
        recurrence: "weekly",
        modifiedOn: "3/14/2020 12:09 am",
        status: false,
    },
    {
        id: 8,
        title: "Leap Event Title that will be repeated every four year.",
        startDate: "2/29/2020",
        endDate: "2/29/2052",
        eventTime: "9:40 am",
        recurrence: "yearly",
        modifiedOn: "	9/8/2020 11:52 pm",
        status: true,
    },
    {
        id: 9,
        title: "Leap event",
        startDate: "2/29/2020",
        endDate: "3/1/2025",
        eventTime: "9:30 am",
        recurrence: "monthly",
        modifiedOn: "2/12/2020 11:38 pm",
        status: true,
    },
    {
        id: 10,
        title: "A Winning Formula: Digital Ecosystems in the USA and Germany",
        startDate: "3/14/2020",
        endDate: "3/14/2020",
        eventTime: "12:00 am",
        recurrence: "daily",
        modifiedOn: "2/12/2020 11:38 pm",
        status: true,
    },
    {
        id: 11,
        title: "Demo EVENT 2020",
        startDate: "3/16/2020",
        endDate: "3/20/2020",
        eventTime: "12:00 am",
        recurrence: "daily",
        modifiedOn: "2/12/2020 11:38 pm",
        status: true,
    },
    {
        id: 12,
        title: "John O'Connor stage show",
        startDate: "9/9/2020",
        endDate: "	9/11/2020",
        eventTime: "12:00 am",
        recurrence: "weekly",
        modifiedOn: "2/12/2020 11:38 pm",
        status: true,
    },
    {
        id: 13,
        title: "Today",
        startDate: "9/9/2020",
        endDate: "4/16/2021",
        eventTime: "2:00 am",
        recurrence: "daily",
        modifiedOn: "4/16/2021 3:43 am",
        status: true,
    },
    {
        id: 13,
        title: "Today strated",
        startDate: "6/25/2021",
        endDate: "12/1/2021",
        eventTime: "2:00 am",
        recurrence: "monthly",
        modifiedOn: "4/16/2021 3:43 am",
        status: true,
    },
];
