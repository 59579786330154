import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CgAsterisk } from "react-icons/cg";
import TeamHeading from "../Teams/TeamHeading";
// import TinyMCE from "react-tinymce";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ImageCrop } from "react_easy_croputil";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";

const AddEditeTeams = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [position, setPositions] = useState("");
  const [positionError, setPositionError] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [displayOrderError, setDisplayOrderError] = useState("");
  const [image, setImage] = useState("");
  const [imageError, setImageError] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageAltText, setImageAltText] = useState("");
  const [imageAltError, setImageAltError] = useState("");
  const [status, setStatus] = useState(true);
  const [allDisplayOrder, setAllDisplayOrder] = useState([]);
  const imageDimension = { width: 1900, height: 1080 };

  let userInfo = JSON.parse(localStorage.getItem("user"));
  let access_token = userInfo.accessToken;

  const handleCancelBtn = () => {
    navigate(-1);
  };

  const SaveEdit = (saveclick) => {
    let firstNameError = "";
    let lastNameError = "";
    let positionError = "";
    let displayOrderError = "";
    let imageError = "";
    let imageAltError = "";

    if (firstName.trim() === "") {
      firstNameError = "Enter First Name";
    }
    if (lastName.trim() === "") {
      lastNameError = "Enter Last Name";
    }
    if (position.trim() === "") {
      positionError = "Enter Position";
    }
    // if (displayOrder.trim() === "") {
    //     displayOrderError = "Enter Display Order";
    // }
    //remove this commented code after upload image functionality completed
    if (image.trim() === "") {
      imageError = "Upload image";
    }
    if (imageAltText.trim() === "") {
      imageAltError = "Enter image alt text";
    }

    if (
      firstNameError !== "" ||
      lastNameError !== "" ||
      positionError !== "" ||
      displayOrderError !== "" ||
      imageError !== "" ||
      imageAltError !== ""
    ) {
      setFirstNameError(firstNameError);
      setLastNameError(lastNameError);
      setPositionError(positionError);
      setDisplayOrderError(displayOrderError);
      setImageError(imageError);
      setImageAltError(imageAltError);
      return;
    }
    let team = {
      firstName: firstName,
      lastName: lastName,
      position: position,
      displayOrder: displayOrder,
      image: image,
      imageAlt: imageAltText,
      status: status,
    };
    if (id) {
      team.id = id;
      let config = {
        method: "put",
        url: API + "/teams/",
        data: team,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Team updated successfully");
            navigate("/admin/teams/view");
          } else {
            notify("Team updated successfully");
            let responseData = response.data;
            // console.log("response", response);
            setId(responseData.id);
            setFirstName(responseData.firstName);
            setLastName(responseData.lastName);
            setPositions(responseData.position);
            setDisplayOrder(responseData.displayOrder);
            setImage(responseData.image);
            setImageUrl(responseData.image);
            setImageAltText(responseData.imageAlt);
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/teams/view");
            //handleLogout(history);
          }
        });
    } else {
      let config = {
        method: "post",
        url: API + "/teams/",
        data: team,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Team added successfully");
            navigate("/admin/teams/view");
          } else {
            notify("Team added successfully");
            let responseData = response.data;
            // console.log("response", response);
            setId(responseData.id);
            setFirstName(responseData.firstName);
            setLastName(responseData.lastName);
            setPositions(responseData.position);
            setDisplayOrder(responseData.displayOrder);
            setImage(responseData.image);
            setImageUrl(responseData.image);
            setImageAltText(responseData.imageAlt);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/teams/view");
            // handleLogout(history);
          }
          console.log(error);
        });
    }
  };
  const addEditHandle = () => {
    SaveEdit(true);
    // navigate("/admin/teams");
  };
  const saveAndContinueHandle = () => {
    SaveEdit(false);
    // setId(id);
    // setFirstName(firstName);
    // setLastName(lastName);
    // setPositions(position);
    // setDisplayOrder(displayOrder);
    // setImage(image);
    // setImageUrl(image);
    // imageAltText(imageAltText);
  };
  const onImageDrop = (acceptedFiles) => {
    if (acceptedFiles.error) {
      let errMsg = acceptedFiles.error;
      if (errMsg === "Invalid File dimension") {
        errMsg = `${errMsg}. Required dimension is ${imageDimension.width}px x ${imageDimension.height}px. Cannot upload file`;
      } else {
        errMsg = `${errMsg} of 5MB. Hence cannot upload file.`;
      }
      notify(errMsg, "error");
    } else {
      setImageError("");
      if (acceptedFiles) {
        let data = new FormData();
        data.append("image", acceptedFiles.fileObject);
        const config = {
          method: "post",
          url: API + "/teams/image/upload",
          headers: {
            Authorization: `${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            // console.log(response);
            setImage(response.data);
            setImageUrl(response.data);
          })
          .catch((error) => {
            console.log(error);
            if (
              error.status === "401" &&
              error.message === "JWT token is expired"
            ) {
              // navigate("/admin/teams");
              // handleLogout(this.props.history);
              handleLogout(navigate);
            }
          });
        return null;
      }
    }
  };
  const removeImg = () => {
    let arr = imageUrl.split("/");
    let image = arr[arr.length - 1];
    const config = {
      method: "delete",
      url: API + "/teams/image/upload",
      headers: {
        Authorization: `${access_token}`,
      },
      data: {
        folder: "teamsImages",
        image: image,
      },
    };
    axios(config)
      .then(function (response) {
        setImageUrl(null);
        setImage(null);
        // console.log("response", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getTeam = useCallback(
    //console.log("id", id),
    (id) => {
      let config = {
        method: "get",
        url: API + "/teams/" + id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data;
          console.log(data);
          setId(id);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setPositions(data.position);
          setDisplayOrder(data.displayOrder);
          setImageAltText(data.imageAlt);
          setImage(data.image);
          setImageUrl(data.Image);
          setStatus(data.status);
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/teams/view");
            // handleLogout(history);
          }
          console.log("error", error);
        });
    },
    [access_token, navigate]
  );

  const nextDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/teams/nextdisplayorder",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const order = response.data;
        // console.log("+++++initial response", order);
        setDisplayOrder(order);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
        } else {
        }
        console.log(error);
      });
  }, [access_token]);

  const fetchAllDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/teams/alldisplayorders",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const orders = response.data;
        // console.log("+++++initial all display order response", orders);
        setAllDisplayOrder(orders);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
        } else {
        }
        console.log(error);
      });
  }, [access_token]);

  useEffect(() => {
    fetchData();
  }, [params.id]);

  const fetchData = async () => {
    let id = params.id;
    if (id) {
      await getTeam(id);
      await fetchAllDisplayOrder();
    } else {
      await nextDisplayOrder();
    }
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
    <div className="flex flex-col items-center w-full">
      <TeamHeading
        pageHeading="Add Team Member"
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        handleSave={addEditHandle}
        saveAndContinue={true}
        handleSaveAndContinue={saveAndContinueHandle}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              First Name
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={firstName}
              onChange={(e) => {
                setFirstNameError("");
                setFirstName(e.target.value);
              }}
              type="text"
              className={`${
                firstNameError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter First Name"
            />
            {firstNameError && (
              <span className="text-red-500 text-xs">{firstNameError}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Last Name
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={lastName}
              onChange={(e) => {
                setLastNameError("");
                setLastName(e.target.value);
              }}
              type="text"
              className={`${
                lastNameError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter Last Name"
            />
            {lastNameError && (
              <span className="text-red-500 text-xs">{lastNameError}</span>
            )}
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Position
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={position}
              onChange={(e) => {
                setPositionError("");
                setPositions(e.target.value);
              }}
              type="text"
              className={`${
                positionError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter Position"
            />
            {positionError && (
              <span className="text-red-500 text-xs">{positionError}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="font-medium mb-1">Display Order</label>
            <select
              value={displayOrder}
              onChange={(e) => {
                setDisplayOrder(e.target.value);
              }}
              disabled={id ? false : true}
              name="displayOrder"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              {allDisplayOrder.map((order) => (
                <option key={order} value={order}>
                  {order}
                </option>
              ))}
              {!id && (
                <option key={displayOrder} value={displayOrder}>
                  {displayOrder}
                </option>
              )}
            </select>

            {/* <input
                value={displayOrder}
                onChange={(e) => {
                  setDisplayOrderError("");
                  setDisplayOrder(e.target.value);
                }}
                type="number"
                className={`${
                  displayOrderError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="0"
              /> */}
            {displayOrderError && (
              <span className="text-red-500 text-xs">{displayOrderError}</span>
            )}
          </div>
          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                for="status"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Status
              </label>
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                className="border h-10 rounded px-2 text-sm font-medium"
              >
                <option>Select status</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
          </div>
          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Image Alt Text
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={imageAltText}
                placeholder="Enter Image Alt Text"
                onChange={(e) => {
                  setImageAltText(e.target.value);
                  setImageAltError("");
                }}
                type="text"
                className={`${
                  imageAltError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
              />
              {imageAltError && (
                <span className="text-red-500 text-xs">{imageAltError}</span>
              )}
            </div>
          </div>
          <div className="w-full mt-5">
            <div>
              <label className="font-medium mb-1">
                Image <CgAsterisk className="inline text-red-500" />
              </label>
              <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
                {image && (
                  <img
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(image)}`
                    }
                    alt="no img"
                    className="w-full h-4/5  object-contain"
                  />
                )}
                {image && (
                  <button
                    onClick={() => {
                      setImage("");
                    }}
                    className="absolute z-20 top-1 right-1 btn bg-gray-900 text-white"
                  >
                    Remove
                  </button>
                )}
                {/* {!image && (
                    <Dropzone
                      accept="image/*"
                      onDrop={(acceptedFiles) => {
                        onImageDrop(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="w-full h-full  flex items-center justify-center"
                        >
                          <input {...getInputProps()} />
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  )} */}
              </div>
              {!image && (
                <div className="py-2">
                  <ImageCrop
                    onChangeCallback={onImageDrop}
                    dimension={imageDimension}
                    // imgSize={1048576} // 2MB
                    imgSize={5242880} // 5MB
                    accept="image/png, image/jpeg, image/gif"
                  />
                </div>
              )}
              {imageError && (
                <span className="text-red-500 text-xs">{imageError}</span>
              )}
            </div>
            <div className="text-xs mt-1">
              Max size 5(MB) and Recommended Size: 1900PX x 1080PX (Allowed only
              jpg, jpeg, png and gif images)
            </div>
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col"></div>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default AddEditeTeams;
