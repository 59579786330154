import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
} from "../../Redux/actionTypes";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import UserHeading from "./UserHeading";
import { RefreshToken } from "./../../services/RefreshToken";
import UserTable from "./userTable";

const Users = (props) => {
  let dispatch = useDispatch();
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  let [sortByName, setSortByNameDesc] = useState(false);
  let [sortByEmail, setSortByEmailDesc] = useState(false);
  let [sortByUserType, setSortByUserTypeDesc] = useState(false);
  let [sortByLastLogin, setSortByLastLoginDesc] = useState(false);
  let [sortByCreatedOn, setSortByCreatedOnDesc] = useState(false);
  let [search, setSearch] = useState("");
  let [UserType, setUserType] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [status, setStatus] = useState("");
  let [people, setPeople] = useState([]);
  let navigate = useNavigate();
  // let state = useSelector((state) => state.UsersReducer);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");
  let [currentPage, setCurrentPage] = useState(1);
  let [usersPerPage, setUsersPerPage] = useState(5);
  const [loading, setLoading] = useState(true);

  const getUsers = useCallback(() => {
    dispatch({
      type: FETCH_USERS_REQUEST,
    });
    let config = {
      method: "get",
      url: API + "/users/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        let AllUsers = response.data.users;
        // console.log("AllUsers",AllUsers);
        dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: AllUsers,
        });
        setLoading(!loading);
        getperPageData(AllUsers);
        let noUserLogin = AllUsers.filter(
          (option) => option.lastLoginAt === "-"
        );
        let UsersLoggedIn = AllUsers.filter(
          (option) => option.lastLoginAt !== "-"
        );
        let users = [...UsersLoggedIn, ...noUserLogin];
        setPeople(
          users.map((user) => {
            return {
              select: false,
              id: user.userId,
              created_at: moment(user.createdAt).format("MM/DD/YYYY h:mm A"),
              created_by: user.createdBy,
              deleted_at: user.deletedAt,
              email: user.email,
              emailVerifiedDate: user.emailVerifiedDate,
              firstName: user.firstName,
              lastName: user.lastName,
              mobileNumber: user.mobileNumber,
              last_login_at:
                user.lastLoginAt !== "-"
                  ? moment(user.lastLoginAt).format("MM/DD/YYYY h:mm A")
                  : "-",
              last_login_ip: user.last_login_ip,
              last_name: user.lastName,
              profile_image: user.profilePic,
              status: user.active,
              updated_at: user.updatedAt,
              user_type_id: user.userTypeId,
              user_type: user.userType,
              password: user.password,
            };
          })
        );
      })
      .catch(async (error) => {
        // if (
        //   (error.status = "401") &&
        //   (error.message = "JWT token is expired")
        // ) {
        //   let userCred = await RefreshToken(
        //     access_token,
        //     setAccess_token,
        //     setUserInfo
        //   );
        //   dispatch({
        //     type: FETCH_USERS_FAIL,
        //     payload: error,
        //   });
        // } else {
        //   notify("Could not process your request!");
        //   // handleLogout(navigate);
        // }
        console.log("error in getUsers", error);
      });
  }, [access_token, navigate, dispatch]);

  const getperPageData = (userData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setUsersPerPage(
          userData.length < parseInt(data.val)
            ? userData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (access_token) {
      getUsers();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, [navigate]);

  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  var currentUsers = people.slice(indexOfFirstUsers, indexOfLastUsers);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(people.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  const sortName = (sortByName) => {
    const sorted = people.sort((a, b) => {
      const isReverse = sortByName === true ? 1 : -1;
      return isReverse * a.firstName.trim().localeCompare(b.firstName.trim());
    });
    setPeople(sorted);
  };

  const sortEmail = (sortByEmail) => {
    const sorted = people.sort((a, b) => {
      const isReverse = sortByEmail === true ? 1 : -1;
      return isReverse * a.email.trim().localeCompare(b.email.trim());
    });
    setPeople(sorted);
  };

  const sortUserType = (sortByUserType) => {
    const sorted = people.sort((a, b) => {
      const isReverse = sortByUserType === true ? 1 : -1;
      return isReverse * a.user_type.trim().localeCompare(b.user_type.trim());
    });
    setPeople(sorted);
  };
  const sortLastLogin = (sortByLastLogin) => {
    const sorted = people.sort((a, b) => {
      let dateA = new Date(a.last_login_at);
      let dateB = new Date(b.last_login_at);
      return sortByLastLogin ? dateA - dateB : dateB - dateA;
    });
    let noUserLogin = sorted.filter((option) => option.last_login_at === "-");
    let UsersLoggedIn = sorted.filter((option) => option.last_login_at !== "-");
    let users = [...UsersLoggedIn, ...noUserLogin];
    setPeople(users);
  };

  const sortCreatedOn = (sortByCreatedOn) => {
    const sorted = people.sort((a, b) => {
      let dateA = new Date(a.created_at);
      let dateB = new Date(b.created_at);
      return sortByCreatedOn ? dateA - dateB : dateB - dateA;
    });
    setPeople(sorted);
  };

  const handleSearch = () => {
    // console.log("search.trim()",search.replace(/\s/g, ""))
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/users/search/?size=-1${
          search && status && UserType
            ? `&status=${status}&keywords=${search}&userType=${UserType}`
            : search && status && !UserType
            ? `&status=${status}&keywords=${search}`
            : search && !status && UserType
            ? `&userType=${UserType}&keywords=${search}`
            : !search && status && UserType
            ? `&status=${status}&userType=${UserType}`
            : search && !status && !UserType
            ? `&keywords=${search}`
            : !search && status && !UserType
            ? `&status=${status}`
            : !search && !status && UserType
            ? `&userType=${UserType}`
            : ""
        }`,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        let users = response.data.users;
        dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: users,
        });
        if (
          users !== undefined &&
          users !== "" &&
          users !== null &&
          users.length !== 0
        ) {
          let data = users.map((user) => {
            return {
              select: false,
              created_at: moment(user.createdAt).format("MM/DD/YYYY h:mm A"),
              created_by: user.createdBy,
              deleted_at: user.deletedAt,
              email: user.email,
              emailVerifiedDate: user.emailVerifiedDate,
              firstName: user.firstName,
              id: user.userId,
              last_login_at:
                user.lastLoginAt !== "-"
                  ? moment(user.lastLoginAt).format("MM/DD/YYYY h:mm A")
                  : "-",
              last_login_ip: user.last_login_ip,
              lastName: user.lastName,
              profile_image: user.profilePic,
              status: user.active,
              updated_at: user.updatedAt,
              user_type_id: user.userTypeId,
              user_type: user.userType,
              mobileNumber: user.mobileNumber,
              password: user.password,
            };
          });
          currentUsers = data;
          setPeople(data);
        } else {
          setPeople([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request! Please try again.");
        }
        setLoading(false);

        console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    getUsers();
  };

  const handleClose = () => {
    setSearch("");
    getUsers();
  };

  const handleActive = () => {
    let a = window.confirm("Are you sure you want to Active this");
    if (a) {
      let id = [];
      people.map((p) => {
        if (p.select === true) {
          id.push(p.id);
          return null;
        } else {
          return null;
        }
      });
      let updateUser = {
        method: "put",
        url: API + "/users/status/true",
        data: id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(updateUser)
        .then(function (response) {
          notify("User's activated successfully");
          getUsers();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    }
  };

  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to In-Active this");
    if (a) {
      let id = [];
      let isSameUser = false;
      people.map((p) => {
        if (p.select === true) {
          isSameUser = p.email === userInfo.email ? true : false;
          id.push(p.id);
          return null;
        } else {
          return null;
        }
      });
      let updateUser = {
        method: "put",
        url: API + "/users/status/false",
        data: id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(updateUser)
        .then(function (response) {
          notify("User's inactivated successfully ");
          getUsers();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          if (isSameUser) {
            handleLogout(navigate);
          }
        })
        .catch(function (error) {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    }
  };

  const handleDelete = () => {
    let id = [];
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      people.map((p) => {
        if (p.select === true) {
          id.push(p.id);
          return null;
        } else {
          return null;
        }
      });
      let config = {
        method: "delete",
        url: API + "/users/",
        data: id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          notify("User's Deleted successfully ");
          getUsers();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
          console.log(error);
        });
    }
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setPeople(
      people.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setPeople(
      people.map((p) => {
        if (id === p.id) {
          p.select = checked;
        }

        return p;
      })
    );

    const result = people.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };

  const paginate = (number) => {
    if (number) {
      setCurrentPage(number);
    }
  };

  const setNumberOfUsers = (number) => {
    if (number && number > 0) {
      if (number > people.length) {
        setUsersPerPage(parseInt(people.length));
      }
      setUsersPerPage(parseInt(number));
    }
  };

  const activeResetLink = (id) => {
    let config = {
      method: "put",
      url: API + "/users/activation/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        // let active_status = response;
        // console.log(response);
        notify("Link sent successfully to user's email.");
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request! Please try again.");
        }
      });
  };

  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <UserHeading
          pageHeading={"Users"}
          searchLabel={"Name/Email"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          search={search}
          setSearch={setSearch}
          handleActive={handleActive}
          handleInActive={handleInActive}
          setStatus={setStatus}
          setUserType={setUserType}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/users/create"
          deleteBtn={true}
          handleDelete={handleDelete}
          saveAndContinue={true}
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            className="absolute"
            size={100}
          />
        )}
        {/* {console.log("people", people)} */}
        {!loading && currentUsers.length === 0 ? (
          <NoRecord />
        ) : (
          <UserTable
            users={currentUsers}
            usersPerPage={usersPerPage}
            totalUsers={people.length}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfUsers={setNumberOfUsers}
            sortName={sortName}
            sortByName={sortByName}
            setSortByNameDesc={setSortByNameDesc}
            sortEmail={sortEmail}
            sortByEmail={sortByEmail}
            setSortByEmailDesc={setSortByEmailDesc}
            sortLastLogin={sortLastLogin}
            sortByLastLogin={sortByLastLogin}
            setSortByLastLoginDesc={setSortByLastLoginDesc}
            sortCreatedOn={sortCreatedOn}
            sortByCreatedOn={sortByCreatedOn}
            setSortByCreatedOnDesc={setSortByCreatedOnDesc}
            selectAll={selectAll}
            selectSingle={selectSingle}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            sortUserType={sortUserType}
            sortByUserType={sortByUserType}
            setSortByUserTypeDesc={setSortByUserTypeDesc}
            activeResetLink={activeResetLink}
            handleActive={handleActive}
            handleInActive={handleInActive}
            people={people}
            setPeople={setPeople}
          />
        )}
      </div>
    </div>
  );
};
export default Users;
