class MessageParser {
    constructor(actionProvider) {
        this.actionProvider = actionProvider;
    }

    parse(message) {
        const lowercase = message.toLowerCase();

        if (lowercase.includes("hello")) {
            this.actionProvider.greet();
        }

        if (lowercase.includes("javascript") || lowercase.includes("js")) {
            this.actionProvider.handleQuiz("javascript");
        }
        if (lowercase.includes("python")) {
            this.actionProvider.handleQuiz("python");
        }
        if (lowercase.includes("java")) {
            this.actionProvider.handleQuiz("java");
        }
    }
}

export default MessageParser;
