import {
    FETCH_EVENT_REQUEST,
    FETCH_EVENT_SUCCESS,
    FETCH_EVENT_FAIL,
    DELETE_EVENTS,
    ADD_NEW_EVENT,
    ACTIVE_EVENT,
    INACTIVE_EVENT,
} from "../actionTypes";
import { eventsData } from "../../components/events/eventsData";

export const fetchEvents = () => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_EVENT_REQUEST,
        });
        const events = eventsData;
        dispatch({
            type: FETCH_EVENT_SUCCESS,
            payload: events,
        });
    } catch (error) {
        dispatch({
            type: FETCH_EVENT_FAIL,
            payload: error,
        });
    }
};

export const addNewEvent = (event) => (dispatch, getState) => {
    const events = getState().EventsReducer.events;
    // console.log(events);
    if (event.id === null) {
        events.push(event);
    } else {
        let objIndex = events.findIndex((obj) => obj.id === event.id);
        events[objIndex] = event;
    }

    // console.log("newEvents", events);
    dispatch({
        type: ADD_NEW_EVENT,
        payload: events,
    });
};

export const deleteEvent = (data) => (dispatch, getState) => {
    // console.log("data", data);
    const events = getState().EventsReducer.events;
    let arrayId = [];
    data.forEach((d) => {
        if (d.select) {
            arrayId.push(d.id);
        }
    });
    let arr = events.filter((val) => !arrayId.includes(val.id));
    // console.log(arr);

    dispatch({
        type: DELETE_EVENTS,
        payload: arr,
    });
};

export const setActiveEvent = (activeEvents) => (dispatch, getState) => {
    const events = getState().EventsReducer.events;
    // console.log(activeEvents);
    let activeID = [];
    activeEvents.forEach((active) => {
        if (active.select) {
            activeID.push(active.id);
        }
    });
    let arr = events.filter((val) => {
        if (activeID.includes(val.id)) {
            val.status = true;
        }
        return val;
    });
    // console.log("active elements", arr);
    dispatch({
        type: ACTIVE_EVENT,
        payload: arr,
    });
};
export const setInActiveEvent = (inActiveEvents) => (dispatch, getState) => {
    const events = getState().EventsReducer.events;
    // console.log(inActiveEvents);
    let activeID = [];
    inActiveEvents.forEach((active) => {
        if (active.select) {
            activeID.push(active.id);
        }
    });
    let arr = events.filter((val) => {
        if (activeID.includes(val.id)) {
            val.status = false;
        }
        return val;
    });
    // console.log("Inactive elements", arr);
    dispatch({
        type: INACTIVE_EVENT,
        payload: arr,
    });
};
