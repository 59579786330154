import React from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../../services/API";
import moment from "moment";

const BlogsPage = ({
  blogs,
  blogPerPage,
  totalBlogs,
  paginate,
  currentPage,
}) => {
  let navigate = useNavigate();
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalBlogs / blogPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      {blogs &&
        blogs.map((blog) => (
          <div className="w-full border mb-10 rounded-md bg-white dark:bg-darkblack text-black dark:text-white" key={blog.blogId}>
            <div className="w-full">
              <img alt={blog.blogImageAlt} src={ API + "/files/download/?fileName=" + `${encodeURIComponent(blog.blogImage)}`} className="w-full  object-contain rounded"/>
            </div>
            <h1 className="w-full mt-5 px-6 line-clamp-1 text-2xl capitalize text-blue-400"> {blog.blogTitle} </h1>
            <p className="w-full mt-5 px-6 text-xs font-medium text-gray-400">
              by{" "}
              <span className="px-1 font-bold text-gray-600 dark:text-white">
                {blog.authorFirstName} {blog.authorLastName}
              </span>
              Posted On {moment(blog.publishDate).format("y-MM-DD HH:mm a")}
            </p>
            <p className="mt-5 px-6 line-clamp-4 dark:text-white">
              <div dangerouslySetInnerHTML={{ __html: blog.blogDescription }} />
            </p>
            <button
              className="my-5 mx-6 border bg-darkblack px-5 py-2 rounded text-sm text-white"
              onClick={() => {
                navigate("/blog/" + blog.blogId);
              }}
            >
              Read More...
            </button>
          </div>
        ))}
      <ul className="flex items-center justify-start cursor-pointer space-x-2 my-10">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`${
              currentPage === number ? "bg-yellow-500" : "bg-gray-900"
            } p-3 text-sm   text-white`}
            onClick={() => {
              paginate(number);
              window.scroll(0, 0);
            }}
          >
            {number}
          </li>
        ))}
      </ul>
    </>
  );
};

export default BlogsPage;
