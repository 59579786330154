import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notify = (msg, msgType) => {
  if (!msgType) {
    toast.success(msg, {
      position: "top-center",
    });
  } else {
    toast[msgType](msg, {
      position: "top-center",
    });
  }
};

export const NoRecord = () => {
  return (
    <div className="text-center justify-center">
      No records found!
    </div>
  );
};
