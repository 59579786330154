import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import TeamHeading from "../Teams/TeamHeading";
import { handleLogout } from "../login/handleLogOut";
import { RefreshToken } from "./../../services/RefreshToken";
import TeamsTable from "./teamsTable";

const Teams = (props) => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  let [teams, setTeams] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [teamsPerPage, setTeamsPerPage] = useState(5);

  let [sortByName, setSortByNameDesc] = useState(false);
  let [sortByPosition, setSortByPositionDesc] = useState(true);
  let [sortByDisplayOrder, setSortByDisplayOrderDesc] = useState(true);
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [status, setStatus] = useState("");

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  const fetchTeams = useCallback(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: API + "/teams/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.teams;
        if (data !== undefined && data !== null && data !== "") {
          const sorted = data.sort((a, b) => {
            const isReverse = true ? 1 : -1;
            return isReverse * a.firstName.localeCompare(b.question);
          });
          getperPageData(data);
          setTeams(
            sorted.map((team) => {
              return {
                select: false,
                id: team.id,
                firstName: team.firstName,
                lastName: team.lastName,
                image: team.image,
                imageAlt: team.imageAlt,
                position: team.position,
                createdDate: team.createdDate,
                createdBy: team.createdBy,
                displayOrder: team.displayOrder,
                status: team.status,
              };
            })
          );
        } else {
          setTeams([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token, navigate]);

  const getperPageData = (teamData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setTeamsPerPage(
          teamData.length < parseInt(data.val)
            ? teamData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (access_token) {
      fetchTeams();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastTeams = currentPage * teamsPerPage;
  const indexOfFirstTeams = indexOfLastTeams - teamsPerPage;
  const currentTeams = teams.slice(indexOfFirstTeams, indexOfLastTeams);

  const paginate = (number) => {
    if (number > 0) {
      setCurrentPage(number);
    }
  };
  const setNumberOfTeams = (number) => {
    if (number > 0) {
      if (number > currentTeams.length) {
        setTeamsPerPage(parseInt(currentTeams.length));
      }
      setTeamsPerPage(parseInt(number));
    }
  };
  const sortName = (sortByName) => {
    const sorted = teams.sort((a, b) => {
      const isReverse = sortByName === true ? 1 : -1;
      return isReverse * a.firstName.trim().localeCompare(b.firstName.trim());
    });
    setTeams(sorted);
  };
  const sortPosition = (sortByPosition) => {
    const sorted = teams.sort((a, b) => {
      const isReverse = sortByPosition === true ? 1 : -1;
      return isReverse * a.position.trim().localeCompare(b.position.trim());
    });
    setTeams(sorted);
  };
  const sortDisplayOrder = (sortByDisplayOrder) => {
    const sorted = teams.sort((a, b) => {
      const isReverse = sortByDisplayOrder === true ? 1 : -1;
      return isReverse * a.displayOrder - b.displayOrder;
    });
    setTeams(sorted);
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setTeams(
      teams.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;

    setTeams(
      teams.map((team) => {
        if (id === team.id) {
          team.select = checked;
        }

        return team;
      })
    );
    const result = teams.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/teams/?size=-1${
          search && status
            ? `&status=${status}&keywords=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&keywords=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.teams;
        if (
          data !== undefined &&
          data !== null &&
          data !== "" &&
          data.length !== 0
        ) {
          const sorted = data.sort((a, b) => {
            const isReverse = true ? 1 : -1;
            return isReverse * a.firstName.localeCompare(b.question);
          });
          setTeamsPerPage(data.length < 10 ? data.length : 10);
          setTeams(
            sorted.map((team) => {
              return {
                select: false,
                id: team.id,
                firstName: team.firstName,
                lastName: team.lastName,
                image: team.image,
                imageAlt: team.imageAlt,
                position: team.position,
                createdDate: team.createdDate,
                createdBy: team.createdBy,
                displayOrder: team.displayOrder,
                status: team.status,
              };
            })
          );
        } else {
          setTeams([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    fetchTeams();
  };
  const handleClose = () => {
    setSearch("");
    fetchTeams();
  };
  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      teams.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "delete",
        url: API + "/teams/",
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Teams deleted successfully");
          setActiveInactive(!activeInactive);
          setPageSearch(!pageSearch);
          setSelectAllCheckbox(false);
          fetchTeams();
        })
        .catch((error) => {
          if (
            error.active === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };
  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let activeID = [];
      teams.forEach((active) => {
        if (active.select) {
          activeID.push(active.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/teams/" + true,
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchTeams();
          notify("Teams activated successfully");
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };
  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let activeID = [];
      teams.forEach((active) => {
        if (active.select) {
          activeID.push(active.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/teams/" + false,
        data: activeID,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchTeams();
          notify("Teams inactivated successfully");
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
        });
    }
  };

  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <TeamHeading
          pageHeading={"Our Teams"}
          searchLabel={"Name"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          setStatus={setStatus}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/teams/view/addTeam"
          saveAndContinue={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentTeams.length === 0 ? (
          <NoRecord />
        ) : (
          <TeamsTable
            teams={currentTeams}
            teamsPerPage={teamsPerPage}
            totalTeams={teams.length}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfTeams={setNumberOfTeams}
            sortName={sortName}
            sortByName={sortByName}
            setSortByNameDesc={setSortByNameDesc}
            sortPosition={sortPosition}
            sortByPosition={sortByPosition}
            setSortByPositionDesc={setSortByPositionDesc}
            sortDisplayOrder={sortDisplayOrder}
            sortByDisplayOrder={sortByDisplayOrder}
            setSortByDisplayOrderDesc={setSortByDisplayOrderDesc}
            selectAll={selectAll}
            selectSingle={selectSingle}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allTeams={teams}
            setTeams={setTeams}
          />
        )}
      </div>
    </div>
  );
};

export default Teams;
