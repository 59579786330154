import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";
import TestimonialsTable from "./TestimonialsTable";

const TestimonialsView = (props) => {
  let navigate = useNavigate();
  let state = useSelector((state) => state.UsersReducer);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  // let [pages, setPages] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [postPerPage, setPostPerPage] = useState(5);

  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [SortByAuthorName, setSortByAuthorName] = useState(false);

  let [SortByDisplayOrder, setSortByDisplayOrder] = useState(false);

  let [sortByCreated, setSortByCreated] = useState(true);

  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [status, setStatus] = useState("");
  let [TestimonialsData, setTestimonialsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (access_token) {
      getTestimonialsData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred?.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = TestimonialsData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(TestimonialsData.length / postPerPage); i++) {
    pageNumbers.push(i);
  }

  const getTestimonialsData = () => {
    let config = {
      method: "get",
      url: API + "/testimonials/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let testimonials = response.data.testimonials;
        // console.log("testimonials", testimonials);
        if (
          testimonials !== undefined &&
          testimonials !== "" &&
          testimonials !== null
        ) {
          getperPageData(testimonials);
          setTestimonialsData(
            testimonials.map((data) => {
              return {
                select: false,
                clientName: data.clientName,
                createdBy: data.createdBy,
                createdDate: moment(data.createdDate).format(
                  "MM/DD/YYYY hh:mm a"
                ),
                description: data.description,
                displayOrder: data.displayOrder,
                id: data.id,
                status: data.status,
                url: data.url,
                imageAlt: data.imageAlt,
              };
            })
          );
        } else {
          setTestimonialsData([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        console.log("error", error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
      });
  };

  const getperPageData = (testimonialData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setPostPerPage(
          testimonialData.length < parseInt(data.val)
            ? testimonialData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SortAuthorName = (SortByAuthorName) => {
    const sorted = TestimonialsData.sort((a, b) => {
      const isReverse = SortByAuthorName === true ? 1 : -1;
      return isReverse * a.clientName.trim().localeCompare(b.clientName.trim());
    });
    setTestimonialsData(sorted);
  };

  const sortCreated = (sortByCreated) => {
    const sorted = TestimonialsData.sort((a, b) => {
      let dateA = new Date(a.createdDate);
      let dateB = new Date(b.createdDate);
      return sortByCreated ? dateA - dateB : dateB - dateA;
    });
    setTestimonialsData(sorted);
  };

  const SortDisplayOrder = (sortByDisplayOrder) => {
    const sorted = TestimonialsData.sort((a, b) => {
      const isReverse = sortByDisplayOrder === true ? 1 : -1;
      return isReverse * a.displayOrder - b.displayOrder;
    });
    setTestimonialsData(sorted);
  };

  const setNumberOfTestimonialsPost = (number) => {
    if (number > 0) {
      if (number > TestimonialsData.length) {
        setPostPerPage(parseInt(TestimonialsData.length));
      }
      setPostPerPage(parseInt(number));
    }
  };

  const paginate = (number) => {
    if (number > 0) {
      setCurrentPage(number);
    }
  };

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let id = [];
      TestimonialsData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });

      id.forEach((option) => {
        let config = {
          method: "delete",
          url: API + "/testimonials/" + option,
          // data: id,
          headers: {
            "Content-type": "application/json",
            Authorization: `${access_token}`,
          },
        };
        // console.log("config", config);
        axios(config)
          .then(function (response) {
            getTestimonialsData();
            setSelectAllCheckbox(false);
            setActiveInactive(false);
            setPageSearch(true);
          })
          .catch(function (error) {
            console.log(error);
            if (
              (error.status = "401") &&
              (error.message = "JWT token is expired")
            ) {
              handleLogout(navigate);
            }
          });
      });
      notify("Testimonials deleted successfully ");
    }
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setTestimonialsData(
      TestimonialsData.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setTestimonialsData(
      TestimonialsData.map((p) => {
        if (id === p.id) {
          p.select = checked;
        }

        return p;
      })
    );

    const result = TestimonialsData.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };

  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let id = [];
      TestimonialsData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });
      // console.log("id array", id);

      let updateTestimonial = {
        method: "put",
        url: API + "/testimonials/true",
        data: id,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updateTestimonial", updateTestimonial);
      axios(updateTestimonial)
        .then((response) => {
          notify("Testimonial activated successfully");
          getTestimonialsData();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch((error) => {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
        });
    }
  };

  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let id = [];
      TestimonialsData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });
      // console.log("id array", id);

      let updateTestimonial = {
        method: "put",
        url: API + "/testimonials/false",
        data: id,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updateTestimonial", updateTestimonial);
      axios(updateTestimonial)
        .then(function (response) {
          notify("Testimonial deactivated successfully");
          getTestimonialsData();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
        });
    }
  };

  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/testimonials/?size=-1${
          search && status
            ? `&status=${status}&keywords=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&keywords=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let testimonials = response.data.testimonials;
        if (
          testimonials !== undefined &&
          testimonials !== "" &&
          testimonials !== null &&
          testimonials.length !== 0
        ) {
          setTestimonialsData(
            testimonials.map((data) => {
              return {
                select: false,
                clientName: data.clientName,
                createdBy: data.createdBy,
                createdDate: moment(data.createdDate).format(
                  "MM/DD/YYYY hh:mm a"
                ),
                description: data.description,
                displayOrder: data.displayOrder,
                id: data.id,
                status: data.status,
                url: data.url,
                imageAlt: data.imageAlt,
              };
            })
          );
        } else {
          setTestimonialsData([]);
        }
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    getTestimonialsData();
  };

  const handleClose = () => {
    setSearch("");
    getTestimonialsData();
  };

  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Testimonials"}
          searchLabel={"Author/Client"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          search={search}
          setSearch={setSearch}
          setStatus={setStatus}
          handleActive={handleActive}
          handleInActive={handleInActive}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/testimonials/view/add"
          deleteBtn={true}
          handleDelete={handleDelete}
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentPosts.length === 0 ? (
          <NoRecord />
        ) : (
          <TestimonialsTable
            state={state}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            selectAll={selectAll}
            TestimonialsData={currentPosts}
            selectSingle={selectSingle}
            pageNumbers={pageNumbers}
            currentPage={currentPage}
            paginate={paginate}
            postPerPage={postPerPage}
            setPostPerPage={setPostPerPage}
            sortCreated={sortCreated}
            sortByCreated={sortByCreated}
            setSortByCreated={setSortByCreated}
            SortAuthorName={SortAuthorName}
            SortByAuthorName={SortByAuthorName}
            setSortByAuthorName={setSortByAuthorName}
            SortDisplayOrder={SortDisplayOrder}
            SortByDisplayOrder={SortByDisplayOrder}
            setSortByDisplayOrder={setSortByDisplayOrder}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allTestimonialsData={TestimonialsData}
            setTestimonialsData={setTestimonialsData}
            setNumberOfTestimonialsPost={setNumberOfTestimonialsPost}
          />
        )}
      </div>
    </div>
  );
};

export default TestimonialsView;
