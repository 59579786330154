import React, { useEffect, useState, useCallback } from "react";
import PageHeading from "../pages/PageHeading";
import EventsTable from "./eventsTable";
import axios from "axios";
import { notify, NoRecord } from "../../utility";
import moment from "moment";
import { API } from "../../services/API";
import { handleLogout } from "../login/handleLogOut";
import { useNavigate } from "react-router-dom";
import { RefreshToken } from "./../../services/RefreshToken";
import Loader from "react-js-loader";

const Event = (props) => {
  let navigate = useNavigate();
  const [events, setEvents] = useState([]);

  let [currentPage, setCurrentPage] = useState(1);
  let [eventsPerPage, setEventsPerPage] = useState(5);

  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let [search, setSearch] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [status, setStatus] = useState("");

  let [sortByTitle, setSortByTitle] = useState(true);
  let [sortStartDate, setSortStartDate] = useState(true);
  let [sortEndDate, setSortEndDate] = useState(true);
  let [sortByTime, setSortByTime] = useState(true);
  let [sortByRecurrence, setSortByRecurrence] = useState(true);
  let [sortByModified, setSortByModified] = useState(true);
  const [loading, setLoading] = useState(true);

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  const fetchEvents = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/events/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.events;
        if (data !== undefined && data !== null && data !== "") {
          const sorted = data.sort((a, b) => {
            const isReverse = sortByTitle === true ? 1 : -1;
            return isReverse * a.title.localeCompare(b.title);
          });
          getperPageData(data);
          setEvents(
            sorted.map((event) => {
              return {
                select: false,
                id: event.id,
                title: event.title,
                startDate: moment(event.startDate).format("MM/DD/YYYY"),
                endDate: moment(event.endDate).format("MM/DD/YYYY"),
                eventTime: moment(event.eventTime, "HH:mm").format("hh:mm A"),
                recurrence: event.recurrence,
                modifiedOn: event.updatedDate
                  ? moment(event.updatedDate).format("MM/DD/YYYY h:mm A")
                  : null,
                status: event.status,
              };
            })
          );
        } else {
          setEvents([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token, sortByTitle, navigate]);

  const getperPageData = (eventsData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setEventsPerPage(
          eventsData.length < parseInt(data.val)
            ? eventsData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (access_token) {
      fetchEvents();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstPost = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstPost, indexOfLastEvent);

  const paginate = (number) => {
    if (number > 0) {
      setCurrentPage(number);
    }
  };
  const setNumberOfEvent = (number) => {
    if (number > 0) {
      if (number > events.length) {
        setEventsPerPage(parseInt(events.length));
      }
      setEventsPerPage(parseInt(number));
    }
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setEvents(
      events.map((d) => {
        d.select = checked;
        return d;
      })
    );

    // console.log(events);
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setEvents(
      events.map((event) => {
        if (id === event.id) {
          event.select = checked;
          // console.log(event);
        }

        return event;
      })
    );

    const result = events.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };
  const handleSearch = () => {
    // console.log("search && status",search, status)
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/events/?size=-1${
          search && status
            ? `&status=${status}&keywords=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&keywords=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.events;
        if (
          data !== undefined &&
          data !== null &&
          data !== "" &&
          data.length !== 0
        ) {
          const sorted = data.sort((a, b) => {
            const isReverse = sortByTitle === true ? 1 : -1;
            return isReverse * a.title.localeCompare(b.title);
          });
          setEventsPerPage(data.length < 10 ? data.length : 10);
          setEvents(
            sorted.map((event) => {
              return {
                select: false,
                id: event.id,
                title: event.title,
                startDate: moment(event.startDate).format("YYYY-MM-DD"),
                endDate: moment(event.endDate).format("YYYY-MM-DD"),
                eventTime: moment(event.eventTime, "HH:mm").format("hh:mm A"),
                recurrence: event.recurrence,
                modifiedOn: event.updatedDate
                  ? moment(event.updatedDate).format("YYYY-MM-DD")
                  : null,
                status: event.status,
              };
            })
          );
        } else {
          setEvents([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };
  const handleReset = () => {
    setSearch("");
    fetchEvents();
  };
  const handleClose = () => {
    setSearch("");
    fetchEvents();
  };

  const sortTitle = (sortByTitle) => {
    const sorted = events.sort((a, b) => {
      const isReverse = sortByTitle === true ? 1 : -1;
      return isReverse * a.title.trim().localeCompare(b.title.trim());
    });
    setEvents(sorted);
  };

  const StartDateSort = (sortStartDate) => {
    const sorted = events.sort((a, b) => {
      let dateA = new Date(a.startDate);
      let dateB = new Date(b.startDate);
      return sortStartDate ? dateA - dateB : dateB - dateA;
    });
    setEvents(sorted);
  };

  const EndDateSort = (sortEndDate) => {
    const sorted = events.sort((a, b) => {
      let dateA = new Date(a.endDate);
      let dateB = new Date(b.endDate);
      return sortEndDate ? dateA - dateB : dateB - dateA;
    });
    setEvents(sorted);
  };

  const SortTime = (sortByTime) => {
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    const sorted = events.sort((a, b) => {
      let aA = a.eventTime.toString().replace(reA, "");
      let bA = b.eventTime.toString().replace(reA, "");
      if (sortByTime) {
        if (aA === bA) {
          let aN = parseInt(a.eventTime.toString().replace(reN, ""), 10);
          let bN = parseInt(b.eventTime.toString().replace(reN, ""), 10);
          // console.log("AN, BN", aN, bN);
          return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          return aA > bA ? 1 : -1;
        }
      } else {
        if (aA === bA) {
          let aN = parseInt(a.eventTime.toString().replace(reN, ""), 10);
          let bN = parseInt(b.eventTime.toString().replace(reN, ""), 10);
          return aN === bN ? 0 : aN < bN ? 1 : -1;
        } else {
          return aA < bA ? 1 : -1;
        }
      }
    });
    // console.log("sorted time", sorted);
    setEvents(sorted);
  };

  const sortRecurrence = (sortByRecurrence) => {
    const sorted = events.sort((a, b) => {
      const isReverse = sortByRecurrence === true ? 1 : -1;
      return isReverse * a.recurrence.localeCompare(b.recurrence);
    });
    // console.log("sorted recurrence", sorted);
    setEvents(sorted);
  };

  const sortModified = (sortByModified) => {
    const sorted = events.sort((a, b) => {
      let dateA = new Date(a.modifiedOn);
      let dateB = new Date(b.modifiedOn);
      return sortByModified ? dateA - dateB : dateB - dateA;
    });
    // console.log("sorted date", sorted);
    setEvents(sorted);
  };

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let arrayId = [];
      events.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "delete",
        url: API + "/events/",
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      // console.log("config", config);
      axios(config)
        .then((response) => {})
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
      notify("Events deleted successfully");
      setActiveInactive(!activeInactive);
      setPageSearch(!pageSearch);
      setSelectAllCheckbox(false);
      fetchEvents();
    }
  };
  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let arrayId = [];
      events.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/events/" + true,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Event activated successfully");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchEvents();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };
  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let arrayId = [];
      events.forEach((d) => {
        if (d.select) {
          arrayId.push(d.id);
        }
      });
      let config = {
        method: "put",
        url: API + "/events/" + false,
        data: arrayId,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          notify("Event deactivated successfully");
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
          fetchEvents();
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            handleLogout(navigate);
          }
          console.log("error", error);
        });
    }
  };
  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Events"}
          searchLabel={"Title"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          handleActive={handleActive}
          handleInActive={handleInActive}
          deleteBtn={true}
          handleDelete={handleDelete}
          setStatus={setStatus}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/events/addEvent"
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentEvents.length === 0 ? (
          <NoRecord />
        ) : (
          <EventsTable
            events={currentEvents}
            eventsPerPage={eventsPerPage}
            totalEvent={events.length}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfEvent={setNumberOfEvent}
            selectAll={selectAll}
            selectSingle={selectSingle}
            sortTitle={sortTitle}
            sortByTitle={sortByTitle}
            setSortByTitle={setSortByTitle}
            StartDateSort={StartDateSort}
            sortStartDate={sortStartDate}
            setSortStartDate={setSortStartDate}
            EndDateSort={EndDateSort}
            sortEndDate={sortEndDate}
            setSortEndDate={setSortEndDate}
            SortTime={SortTime}
            sortByTime={sortByTime}
            setSortByTime={setSortByTime}
            sortRecurrence={sortRecurrence}
            sortByRecurrence={sortByRecurrence}
            setSortByRecurrence={setSortByRecurrence}
            sortModified={sortModified}
            sortByModified={sortByModified}
            setSortByModified={setSortByModified}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allEvents={events}
            setEvents={setEvents}
          />
        )}
      </div>
    </div>
  );
};

export default Event;
