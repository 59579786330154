import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import { notify } from "../../../utility";

const PartnerDashboard = () => {
  let navigate = useNavigate();
  const [requestForDocs, setRequestForDocs] = useState([]);
  const [listOfClients, setListOfClients] = useState([]);
  const [currentSortingValue, setCurrentSortingValue] = useState("name");
  let userData = JSON.parse(localStorage.getItem("userData"));

  const fetchClients = () => {
    axios
      .get("http://localhost:5000/doc/getClientList")
      .then((response) => {
        setListOfClients(response.data.clients);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchDocRequests = useCallback(() => {
    const id = userData.id;
    const bodyParameters = {
      id: id,
    };
    axios
      .post("http://localhost:5000/doc/getAgentsClients", bodyParameters)
      .then((res) => {
        // console.log(res);
        const newData = res.data.doc.map((d) => {
          let requestStatus = "";
          if (d.currentprogress !== d.totalprogress) {
            requestStatus = moment(d.dueDate).isBefore(moment())
              ? "OVERDUE"
              : moment(d.requestDueDate).isAfter(moment())
              ? "INPROGRESS"
              : "UNOPENED";
          } else {
            requestStatus = "READYFORREVIEW";
          }
          return {
            client_name: d.client_name,
            clientid: d.clientid,
            currentprogress: d.currentprogress,
            date: d.date,
            id: d.id,
            partnerid: d.partnerid,
            totalprogress: d.totalprogress,
            requestStatus: requestStatus,
          };
        });
        const sorted = newData.sort((a, b) => {
          return a.client_name.localeCompare(b.client_name);
        });
        setRequestForDocs(sorted);
      })
      .catch((err) => console.log(err));
  }, [userData.id]);

  const setSortingValue = (event) => {
    setCurrentSortingValue(event.target.value);
    if (event.target.value === "name") {
      sortByName();
    }
    if (event.target.value === "date") {
      sortByDate();
    }
    if (event.target.value === "status") {
      sortByStatus();
    }
  };

  const sortByName = () => {
    const sorted = requestForDocs.sort((a, b) => {
      return a.client_name.localeCompare(b.client_name);
    });
    setRequestForDocs(sorted);
  };
  const sortByDate = () => {
    const sorted = requestForDocs.sort((a, b) => {
      let dateA = new Date(a.date);
      let dateB = new Date(b.date);
      return new Date(dateB) - new Date(dateA);
    });
    setRequestForDocs(sorted);
  };
  const sortByStatus = () => {
    const sorted = requestForDocs.sort((a, b) => {
      return a.requestStatus.localeCompare(b.requestStatus);
    });
    setRequestForDocs(sorted);
  };
  const handleDocDelete = (id) => {
    const bodyParameters = {
      id: id,
    };
    axios
      .post("http://localhost:5000/doc/getSelectAgentDocReq", bodyParameters)
      .then((res) => {
        let imagesArr = [];
        res.data.docList.forEach((d) => {
          if (d.status === 1) {
            let a = d.documentname.split("/");
            imagesArr.push({
              folder: "document",
              image: a[a.length - 1],
            });
          }
        });
        axios
          .delete("http://localhost:5000/doc/deleteDoc", {
            data: {
              id: id,
            },
          })
          .then((response) => {
            notify(response.data.message);
            fetchClients();
            fetchDocRequests();
          })
          .catch((error) => {
            console.log("error", error);
          });
        if (imagesArr.length > 0) {
          axios
            .delete("http://localhost:5000/uploads/multipleDelete", {
              data: {
                imagesArr: imagesArr,
              },
            })
            .then((response) => {
              // console.log("response delete images", response);
              fetchClients();
              fetchDocRequests();
            })
            .catch((error) => {
              // console.log("response error", error);
            });
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchClients();
    fetchDocRequests();
  }, []);
  return (
    <div className="w-full mt-14 px-2 sm:px-4 md:px-6 lg:px-10 min-h-screen xl:px-10  pt-8 mb-14 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 pb-10 gap-4 dark:bg-gray-700 grid xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 grid-cols-1">
      <div className="flex w-full flex-col col-span-2 mt-2">
        <div className="col-span-2 flex items-center justify-between p-2">
          <h1 className="text-md sm:text-xl md:text-2xl lg:text-4xl xl:text-4xl font-semibold my-2 lg:my-4 xl:my-4 mx-2 dark:text-white">
            Active Requests
          </h1>
          <div className="flex items-center">
            <label className="text-md lg:text-lg dark:text-white">
              Sort By
            </label>
            <select
              value={currentSortingValue}
              onChange={(event) => {
                setSortingValue(event);
              }}
              className="text-xs lg:text-md border font-semibold px-4 py-2 ml-2"
            >
              <option value="name">Name</option>
              <option value="date">Date</option>
              <option value="status">Status</option>
            </select>
          </div>
        </div>
        {requestForDocs &&
          requestForDocs.map((doc) => (
            <div
              key={doc.id}
              className="border rounded px-2 py-4 m-2 shadow-sm cursor-pointer grid grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-1 text-sm items-center dark:bg-gray-200"
            >
              <h1
                onClick={() => {
                  navigate("/documentView/" + doc.id + "/" + doc.client_name);
                }}
                className="font-semibold hover:border-b capitalize text-xs lg:text-sm"
              >
                {doc.client_name}
              </h1>
              <div className=" flex flex-col w-full">
                <h1 className="font-semibold text-xs lg:text-sm">
                  Requested documents:
                </h1>
                <div className="flex items-center grid-cols-3 gap-2 ">
                  <h1 className="font-semibold text-xs lg:text-sm">
                    {doc.currentprogress}/{doc.totalprogress}
                  </h1>
                  <h1
                    className=" border rounded-full h-3 overflow-hidden bg-gray-300 flex items-center"
                    style={{ width: "250px" }}
                  >
                    <span
                      className="bg-blue-400 justify-start rounded-full h-4 inline-block"
                      style={{
                        width:
                          (250 / doc.totalprogress) * doc.currentprogress +
                          "px",
                      }}
                    ></span>
                  </h1>
                  {doc.totalprogress === doc.currentprogress && (
                    <AiFillCheckCircle className="text-yellow-500 text-2xl" />
                  )}
                </div>
              </div>
              <div className="lg:text-center">
                <h1 className="text-xs lg:text-sm font-semibold">Date</h1>
                <h1 className="text-xs lg:text-sm font-semibold">
                  {moment(doc.date).format("DD/MM/YYYY")}
                </h1>
              </div>
              <div className="">
                <h1 className="text-xs lg:text-sm font-semibold text-center my-1">
                  Status
                </h1>
                <h1
                  className={`text-xs lg:text-sm font-semibold border-2 text-center p-1   ${
                    doc.requestStatus === "OVERDUE"
                      ? "border-red-500 text-red-500"
                      : "" || doc.requestStatus === "READYFORREVIEW"
                      ? "border-green-500 text-green-500"
                      : "" || doc.requestStatus === "INPROGRESS"
                      ? "border-yellow-500 text-yellow-500"
                      : "" || doc.requestStatus === "UNOPENED"
                      ? "border-black text-black"
                      : ""
                  }`}
                >
                  {doc.requestStatus}
                </h1>
              </div>
              <div className="lg:text-center">
                <button
                  onClick={() => handleDocDelete(doc.id)}
                  className="text-xs lg:text-sm font-semibold bg-black text-white p-2 "
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
      </div>
      <div className="w-full flex flex-col border mt-4 rounded px-2 py-4 text-sm dark:bg-gray-200">
        <h1 className="m-4  text-xl font-bold">List of Clients</h1>
        <ul>
          {listOfClients &&
            listOfClients.map((client) => (
              <li
                key={client.id}
                className="flex border rounded dark:border-gray-700 dark:text-white dark:bg-gray-600 justify-between items-center m-1 sm:m-2 md:m-2 lg:m-4 xl:m-4  px-2 py-4"
              >
                <span className="font-semibold capitalize  text-xs lg:text-sm">
                  Name: {client.name}
                </span>
                <button
                  onClick={() => {
                    navigate({
                      pathname: "/requestForDocs",
                      state: {
                        client: client,
                        partnerid: userData.id,
                      },
                    });
                  }}
                  className="border bg-yellow-500 hover:bg-yellow-400 text-white p-2 text-xs lg:text-sm font-medium"
                >
                  Send doc Req
                </button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default PartnerDashboard;
