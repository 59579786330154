import { useEffect, useState } from "react";

function UploadMultipleImages({
  acceptedFiles,
  // getRootProps,
  // getInputProps,
  // open,
  // fileRejections,
  imageSize,
  dimension,
  handleCancel,
  uploadSingle,
  multipleImagesUpload,
  links,
  setLinks,
  ShowYoutubeButton,
}) {
  const [showPop, setPopUp] = useState(false);
  const [ytLink, setYTLink] = useState("");
  const [ytError, setYtError] = useState(false);
  // fileRejections.map((file) => alert("file size must be less  than 10 mb"));
  const acceptedFileItems = acceptedFiles.map((file, index) => (
    <li
      key={file.path}
      className={
        file.error
          ? "border-b pb-2 border-gray-800 flex w-full items-center justify-between my-4 px-1"
          : "border-b pb-2 border-gray-800 flex w-full items-center justify-between my-4 px-1"
      }
    >
      <img
        className="w-16 h-9"
        alt={file.path}
        // src={URL.createObjectURL(file)}
        src={file.webkitRelativePath}
        style={file.error ? { filter: "grayscale(100%)" } : {}}
      />
      <h1 className="text-sm px-2">{file.path}</h1>
      <h1>{file.size} bytes</h1>
      <div className="flex space-x-1">
        <button
          className={
            file.error
              ? "bg-slate-200 hover:bg-slate-500 text-gray-400 font-bold py-2 px-4 rounded"
              : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          }
          onClick={() => uploadSingle(file)}
          disabled={file.error}
        >
          Upload
        </button>
        <button
          className="bg-slate-700 hover:bg-slate-900 text-gray-200 font-bold py-2 px-4 rounded"
          onClick={() => handleCancel(file, index)}
        >
          Cancel
        </button>
      </div>
    </li>
    // <>{file.displaySection}</>
  ));
  useEffect(() => {}, []);
  return (
    <section className="container">
      <div className="w-full flex space-x-3">
        {/* <button
          type="button"
          className="btn bg-green-600 text-xs"
          onClick={() => {
            open();
          }}
        >
          Add Files
        </button> */}
        {ShowYoutubeButton && (
          <button
            type="button"
            className=" hover:bg-yellow-600 bg-yellow-500 text-gray-200 font-bold py-2 px-4 rounded"
            onClick={() => {
              setPopUp(true);
              window.scrollTo({ top: -800, behavior: "smooth" });
              document?.getElementById("youtubeInput")?.focus();
            }}
          >
            Add youtube link
          </button>
        )}
        <button
          type="button"
          className={
            acceptedFiles.length > 0
              ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              : "bg-slate-300 hover:bg-slate-300 text-white font-bold py-2 px-4 rounded"
          }
          disabled={acceptedFiles.length === 0}
          onClick={() => multipleImagesUpload()}
        >
          Start Upload
        </button>
      </div>
      <div className="w-full mt-2">
        <span className="text-sm text-black dark:text-textinblack">
          {dimension ? (
            <>
              Max size {imageSize || 10}(MB) and Recommended Size:{" "}
              {dimension?.width || 1900}
              PX x {dimension?.height || 1080}PX (Allowed only jpg, jpeg, png
              and gif images)
            </>
          ) : (
            <>
              Max size {imageSize || 10}(MB) and Recommended Size: 1900 PX x
              1080PX (Allowed only jpg, jpeg, png and gif images)
            </>
          )}
        </span>
      </div>
      {/* <div
        {...getRootProps({ className: "dropzone" })}
        className="w-full border-dashed border-2 border-black bg-gray-200 h-40 flex items-center justify-center cursor-pointer my-4 rounded"
         >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here</p>
      </div> */}

      <ul className="w-full">{acceptedFileItems}</ul>
      {/* {acceptedFiles.map((files) => ( */}
      {/* <CropImage imgUrl={acceptedFiles[0]?.image?.src} /> */}
      {/* ))} */}
      {/* <CropImage /> */}

      {showPop && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex items-start justify-center">
          <div className="w-11/12 sm:w-2/5 md:w-2/5 lg:w-1/3 xl:w-1/3 bg-white rounded  flex flex-col mt-10">
            <div className="p-5 md:p-10 w-full">
              <h1 className="text-xl mb-4">Add youtube video link</h1>
              <label className="">Youtube video link</label>
              <input
                type="text"
                id="youtubeInput"
                onChange={(e) => {
                  setYTLink(e.target.value);
                  setYtError(false);
                }}
                className={`w-full  rounded p-2 mt-2 ${
                  ytError ? "border-2 border-red-500" : "border"
                }`}
              />
              <span className="text-xs">
                Please add youtube share URL [e.g. https://youtu.be/WVZ2PhmDa2M]
              </span>
            </div>
            <div className="w-full flex p-4 justify-end space-x-2">
              <button
                onClick={() => {
                  if (ytLink === "") {
                    setYtError(true);
                  } else {
                    let regEX =
                      /^((?:https?:)?\/\/)?((?:www|m).)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/gm;
                    if (ytLink.match(regEX)) {
                      const a = [...links];
                      // console.log("links", links);
                      a.push(ytLink);
                      setLinks(a);
                      setPopUp(false);
                    } else {
                      setYtError(true);
                    }
                  }
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded text-sm"
              >
                Add link
              </button>
              <button
                onClick={() => {
                  setPopUp(false);
                  setYTLink("");
                }}                
                className="bg-blackbtn hover:bg-slate-900 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default UploadMultipleImages;
