import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { CgAsterisk } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";

const SliderEdit = (props) => {
  const location = useLocation();
  let navigate = useNavigate();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("");
  const [allDisplayOrder, setAllDisplayOrder] = useState([]);
  const [overlayText, setOverlayText] = useState("");
  const [image, setImage] = useState("");
  const [ImageAltText, setImageAltText] = useState("");
  const [status, setStatus] = useState(true);

  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const [imageAltError, setImageAltError] = useState("");

  let userInfo = JSON.parse(localStorage.getItem("user"));
  let access_token = userInfo.accessToken;
  const [savebuttonclick, setSaveButtonClick] = useState(false);
  // const imageDimension = { width: 1900, height: 1080 };

  const validationCheck = () => {
    let retVal = true;
    if (title.trim() === "") {
      setTitleError("Enter title");
      retVal = false;
    }
    if (!image) {
      setImageError("Upload image");
      retVal = false;
    }
    if (ImageAltText.trim() === "") {
      setImageAltError("Enter alternative text");
      retVal = false;
    }
    return retVal;
  };
  const SaveEdit = async () => {
    // if (title.trim() === "") {
    //   setTitleError("Enter title");
    //   return;
    // }
    // if (image.length === 0) {
    //   setImageError("Upload image");
    //   return;
    // }
    // if (ImageAltText.trim() === "") {
    //   setImageAltError("Enter alternative text");
    //   return;
    // }

    let editData = {
      id: id,
      title: title,
      displayOrder: order,
      overlayText: overlayText,
      image: image,
      imageAlt: ImageAltText,
      status: status,
    };
    // console.log("------slider edited data--------", editData);
    if (id) {
      let config = {
        method: "put",
        url: API + "/slider/",
        data: editData,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      await axios(config)
        .then((response) => {
          // console.log("response put call data =====", response.data);
          if (savebuttonclick) {
            return navigate("/admin/sliders/view");
          } else {
            let responseput = response.data;
            setId(responseput.userId);
            setTitle(responseput.title);
            setImage(responseput.image);
            setStatus(responseput.status);
            setImageAltText(responseput.imageAlt);
            setOrder(responseput.order);
            setOverlayText(responseput.overlayText);
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/sliders/view");
            // handleLogout(navigate);
          }
        });
    } else {
      console.log("Error id not set");
    }
  };
  const handleSave = async () => {
    setSaveButtonClick(!savebuttonclick);
    if (validationCheck()) {
      await SaveEdit();
      return navigate("/admin/sliders/view");
    }
  };
  const handleSaveAndContinue = async () => {
    if (validationCheck()) {
      await SaveEdit()
        .then((res) => {
          // alert('Changes Updated')
          notify("Changes Updated");
          // console.log('data Saved')
        })
        .catch((error) => {
          console.log(error);
        });
      // setId(id);
      // setTitle(title);
      // setImage(image);
      // setStatus(status);
      // setImageAltText(ImageAltText);
      // setOrder(order);
      // setOverlayText(overlayText);
    }
  };

  const handleCancelBtn = () => {
    navigate(-1);
  };
  useEffect(() => {
    setId(location.state.id);
    setTitle(location.state.title);
    setImage(location.state.image);
    setStatus(location.state.status);
    setImageAltText(location.state.alternativeText);
    setOrder(location.state.displayOrder);
    setOverlayText(location.state.overlayText);
  }, [location.state]);

  //   const getBanner = (banner) => {
  //     setImage(banner);
  //   };
  const removeImg = () => {
    setImage("");
  };

  const onImageDrop = (acceptedFiles, fileRejections) => {
    const acceptedFileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/gif",
    ];
    let error;
    if (fileRejections.length > 0) {
      // console.log("---fileRejections", fileRejections);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setImageError(`Error:  File is larger than  5(MB)`);
            error = `File is larger than  5(MB).\n Cannot upload file`;
          }

          if (err.code === "file-invalid-type") {
            setImageError(`Error: Invalid File type.\n Cannot upload File`);
            error = `Invalid File type.\n Cannot upload File`;
          }
        });
      });
    }
    if (acceptedFiles) {
      acceptedFiles.map((image) => {
        if (!acceptedFileTypes.includes(image.type.toString())) {
          error = `Invalid File type.\n Cannot upload File`;
          setImageError(`Error: File type is invalid`);
        }
      });
    }
    if (error) {
      notify(error, "error");
      return;
    }
    setImageError("");
    if (acceptedFiles) {
      acceptedFiles.map((image) => {
        let data = new FormData();
        data.append("file", image);
        const config = {
          method: "post",
          url: API + "/slider/upload",
          headers: {
            Authorization: `${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            setImage(response.data);
          })
          .catch((error) => {
            console.log(error);
            if (
              error.status === "401" &&
              error.message === "JWT token is expired"
            ) {
              // navigate("/admin/sliders");
              handleLogout(navigate);
            }
          });
        return null;
      });
    }
  };
  const fetchSlider = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: API + "/slider/" + id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data;
          // console.log("GET call response", response.data);
          setId(data.id);
          setTitle(data.title);
          setImage(data.image);
          setStatus(data.status);
          setImageAltText(data.imageAlt);
          setOrder(data.displayOrder);
          setOverlayText(data.overlayText);
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/sliders/view");
            // handleLogout(navigate);
          }
          console.log("error", error);
        });
    },
    [access_token, navigate]
  );

  const fetchAllDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/slider/alldisplayorders",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const orders = response.data;
        // console.log("+++++initial all display order response", orders);
        setAllDisplayOrder(orders);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        } else {
        }
        console.log(error);
      });
  }, [access_token]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    await fetchSlider(id);
    await fetchAllDisplayOrder();
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
    <div className="flex-col items-center w-full">
      <PageHeading
        pageHeading={"Edit Slider"}
        showSaveOptionsBtn={true}
        save={true}
        handleCancelBtn={handleCancelBtn}
        cancel={true}
        handleSave={handleSave}
        saveAndContinue={true}
        handleSaveAndContinue={handleSaveAndContinue}
      />
      <div className="w-full px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 flex items-center my-4">
        <div className="grid lg:grid-cols-2  gap-5 w-full">
          <div className="flex flex-col w-full">
            <label
              for="fname"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Title
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => {
                setTitleError("");
                setTitle(e.target.value);
              }}
              className=" 
                                border h-10 rounded-lg px-2 text-sm font-medium "
              name="title"
            />
            {titleError && (
              <span className="text-red-500 text-xs">{titleError}</span>
            )}
          </div>
          <div className="flex flex-col w-full">
            <label
              for="fname"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Display Order
            </label>
            <select
              value={order}
              onChange={(e) => {
                setOrder(e.target.value);
              }}
              name="order"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              {allDisplayOrder.map((order) => (
                <option key={order} value={order}>
                  {order}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-full col-span-2">
            <label
              for="fname"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Overlay Text
            </label>
            <input
              type="text"
              value={overlayText}
              onChange={(e) => {
                setOverlayText(e.target.value);
              }}
              className=" 
                                border h-10 rounded-lg px-2 text-sm font-medium "
              name="overlayText"
            />
          </div>
          <div className="flex flex-col w-full col-span-2">
            <div>
              <label className="font-medium mb-1">
                Image <CgAsterisk className="inline text-red-500" />
              </label>
              {/* <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
                  {image && (
                    <img
                      src={
                        API +
                        "/files/download/?fileName=" +
                        `${encodeURIComponent(image)}`
                      }
                      alt="no img"
                      className="w-full h-4/5  object-contain"
                    />
                  )}
                  {image && (
                    <button
                      onClick={() => {
                        removeImg();
                      }}
                      className="absolute z-20 top-1 right-1 bg-slate-700 hover:bg-slate-900 text-gray-200 font-bold py-2 px-4 rounded"
                    >
                      Remove
                    </button>
                  )}
                </div> */}
              <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
                {image && (
                  <img
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(image)}`
                    }
                    alt="no img"
                    className="w-full h-4/5  object-contain"
                  />
                )}
                {image && (
                  <button
                    className="absolute z-20 top-1 right-1 bg-slate-700 hover:bg-slate-900 text-gray-200 font-bold py-2 px-4 rounded"
                    onClick={() => {
                      removeImg();
                    }}
                  >
                    Remove
                  </button>
                )}
                {!image && (
                  <Dropzone
                    maxSize={5242880} //5Mb
                    accept={"image/png,image/jpg,image/gif"}
                    onDrop={(acceptedFiles, fileRejections) => {
                      setImageError("");
                      onImageDrop(acceptedFiles, fileRejections);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="w-full h-full  flex items-center justify-center"
                      >
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
              {/* {!image && (
                   <Dropzone
                     accept="image/*"
                     onDrop={(acceptedFiles) => {
                       onImageDrop(acceptedFiles);
                     }}
                   >
                     {({ getRootProps, getInputProps }) => (
                       <div
                         {...getRootProps()}
                         className="w-full h-full  flex items-center justify-center"
                       >
                         <input {...getInputProps()} />
                         <p>
                           Drag 'n' drop some files here, or click to select
                           files
                         </p>
                       </div>
                     )}
                   </Dropzone>
                  // <ImageCrop
                  //   onChangeCallback={onImageDrop}
                  //   dimension={imageDimension}
                  //   // imgSize={1048576} // 2MB
                  //   imgSize={10485760} // 10MB
                  //   accept="image/png, image/jpeg, image/gif"
                  // />
                )} */}
              {imageError && (
                <span className="text-red-500 text-xs">{imageError}</span>
              )}
            </div>
            <div className="text-xs mt-1">
              Max size 5(MB) and Recommended Size: 1900PX x 1080PX (Allowed only
              jpg, jpeg, png and gif images)
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label
              for="fname"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Image Alt
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              type="text"
              value={ImageAltText}
              className="border h-10 rounded-lg px-2 text-sm font-medium "
              name="altText"
              onChange={(e) => {
                setImageAltError("");
                setImageAltText(e.target.value);
              }}
            />
            {imageAltError && (
              <span className="text-red-500 text-xs">{imageAltError}</span>
            )}
          </div>
          <div className="flex flex-col w-full">
            <label
              for="fname"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Status
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              name="order"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option>Select Status</option>
              <option value={false}>Inactive</option>
              <option value={true}>Active</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default SliderEdit;
