import React, { useCallback, useEffect, useState } from "react";
import IpTrackerHeading from "./IpTrackerHeading";
import IpTrackerTable from "./IpTrackerTable";
import axios from "axios";
import { API } from "../../services/API";
import { RefreshToken } from "./../../services/RefreshToken";
import { handleLogout } from "../login/handleLogOut";
import { useNavigate } from "react-router-dom";
import { notify, NoRecord } from "../../utility";
import Loader from "react-js-loader";

const IpTracker = (props) => {
  const [Ips, setIps] = useState([]);
  const [IpsData, setIpsData] = useState([]);
  let navigate = useNavigate();
  let [currentPage, setCurrentPage] = useState(1);
  let [ipPerPage, setIpPerPage] = useState(5);
  let [totalIps, setTotalIps] = useState(0);

  let [search, setSearch] = useState("");
  let [pageSearch] = useState(true);

  let [sortByUserId, setSortByUserId] = useState(true);
  let [sortByType, setSortByType] = useState(true);
  let [sortByIpAdress, setSortByIpAdress] = useState(true);
  let [sortByLogIn, setSortByLogIn] = useState(true);
  let [sortByLastActivity, setSortByLastActivity] = useState(true);
  let [sortByLogOut, setSortByLogOut] = useState(true);
  const [loading, setLoading] = useState(true);

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  const fetchIps = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/iptracker/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.ips;
        if (data !== undefined && data !== null && data !== "") {
          const sorted = data.sort((a, b) => {
            const isReverse = sortByUserId === true ? 1 : -1;
            return isReverse * a.userId.localeCompare(b.userId);
          });
          getperPageData(data);
          setIps(sorted);
          setIpsData(sorted);
          setTotalIps(response.data.totalItems);
        } else {
          setIps([]);
          setIpsData([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        console.log(error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
      });
  }, [access_token]);

  const getperPageData = (ipData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setIpPerPage(
          ipData.length < parseInt(data.val)
            ? ipData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (access_token) {
      fetchIps();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastIp = currentPage * ipPerPage;
  const indexOfFirstIp = indexOfLastIp - ipPerPage;
  const currentIps = Ips.slice(indexOfFirstIp, indexOfLastIp);

  const paginate = (number) => {
    setCurrentPage(number);
  };

  const setNumberOfIp = (number) => {
    if(number>0){
      if(number>Ips.length){
        setIpPerPage(parseInt(Ips.length));
      }
      setIpPerPage(parseInt(number));
    }
  };

  const handleSearch = () => {
    let searchData = Ips.filter((d) => {
      if (d.userId.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
        return d;
      }
    });
    if (searchData.length === 0) {
      setIps([]);
    } else {
      setIps(searchData);
      setCurrentPage(1);
    }
  };

  const handleReset = () => {
    setSearch("");
    fetchIps();
  };

  const handleClose = () => {
    setSearch("");
    fetchIps();
  };

  const sortUserId = (sortByUserId) => {
    const sorted = Ips.sort((a, b) => {
      const isReverse = sortByUserId === true ? 1 : -1;
      return isReverse * a.userId.trim().localeCompare(b.userId.trim());
    });
    setIps(sorted);
  };

  const sortType = (sortByType) => {
    const sorted = Ips.sort((a, b) => {
      const isReverse = sortByType === true ? 1 : -1;
      return isReverse * a.type.trim().localeCompare(b.type.trim());
    });
    setIps(sorted);
  };

  const sortIpAdress = (sortByIpAdress) => {
    const sorted = Ips.sort((a, b) => {
      return sortByIpAdress ? a.ipAdress - b.ipAdress : b.ipAdress - a.ipAdress;
    });
    setIps(sorted);
  };

  const sortLogIn = (sortByLogIn) => {
    const sorted = Ips.sort((a, b) => {
      let dateA = new Date(a.createdDate);
      let dateB = new Date(b.createdDate);
      return sortByLogIn ? dateA - dateB : dateB - dateA;
    });
    setIps(sorted);
  };

  const sortLastActivity = (sortByLastActivity) => {
    const sorted = Ips.sort((a, b) => {
      const isReverse = sortByLastActivity === true ? 1 : -1;
      return isReverse * a.activity.trim().localeCompare(b.activity.trim());
    });
    setIps(sorted);
  };

  const sortLogOut = (sortByLogOut) => {
    const sorted = Ips.sort((a, b) => {
      let dateA = new Date(a.createdDate);
      let dateB = new Date(b.createdDate);
      return sortByLogOut ? dateA - dateB : dateB - dateA;
    });
    setIps(sorted);
  };

  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${props.Sidebar
          ? ""
          : ""
        }`}
    >
      <div className="flex-col items-center w-full">
        <IpTrackerHeading
          pageHeading={"IP Tracker"}
          searchLabel={"Name"}
          pageSearch={pageSearch}
          deleteBtn={false}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentIps.length === 0 ? (
          <NoRecord />
        ) : (
          <IpTrackerTable
            ips={currentIps}
            ipPerPage={ipPerPage}
            totalIps={totalIps}
            paginate={paginate}
            currentPage={currentPage}
            setNumberOfIp={setNumberOfIp}
            sortUserId={sortUserId}
            sortByUserId={sortByUserId}
            setSortByUserId={setSortByUserId}
            sortType={sortType}
            sortByType={sortByType}
            setSortByType={setSortByType}
            sortIpAdress={sortIpAdress}
            sortByIpAdress={sortByIpAdress}
            setSortByIpAdress={setSortByIpAdress}
            sortLogIn={sortLogIn}
            sortByLogIn={sortByLogIn}
            setSortByLogIn={setSortByLogIn}
            sortLastActivity={sortLastActivity}
            sortByLastActivity={sortByLastActivity}
            setSortByLastActivity={setSortByLastActivity}
            sortLogOut={sortLogOut}
            sortByLogOut={sortByLogOut}
            setSortByLogOut={setSortByLogOut}
          />
        )}
      </div>
    </div>
  );
};

export default IpTracker;
