import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
} from "../actionTypes";

export const initialState = {
    Users: [],
    loading: false,
    error: null,
};

export const UsersReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_USERS_REQUEST:
            return {
                loading: true,
            };
        case FETCH_USERS_SUCCESS:
            return {
                // ...state,
                Users: payload,
                loading: false,
            };
        case FETCH_USERS_FAIL:
            return {
                // ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};
