import React, { useCallback, useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import SiteConfigHeading from "./SiteConfigHeading";
// import TinyMCE from "react-tinymce";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";

const AddSiteConfig = (props) => {
  let navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [val, setVal] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState(true);

  const [nameError, setNameError] = useState("");
  const [valError, setValError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [savebuttonclick, setSaveButtonClick] = useState(false);

  const handleCancelBtn = () => {
    navigate(-1);
  };
  const validationCheck = () => {
    let nameerror = "";
    let valerror = "";
    let typeerror = "";
    let statuserror = "";

    if (name.trim() === "") {
      nameerror = "Enter Name";
    }
    if (val.trim() === "") {
      valerror = "Enter Value";
    }
    if (type.trim() === "") {
      typeerror = "Enter Type";
    }
    if (
      nameerror !== "" ||
      valerror !== "" ||
      typeerror !== "" ||
      statuserror !== ""
    ) {
      setNameError(nameerror);
      setValError(valerror);
      setTypeError(typeerror);
      setStatusError(statuserror);
      // console.log("NAmeErr1",nameerror);
      // console.log("valError1",valerror);
      // console.log("typeError1",typeerror);
      // console.log("statusError1",statuserror);
      return [nameError, valError, typeError, statusError];
    }
  };

  const SaveEdit = () => {
    let siteconfig = {
      name: name,
      val: val,
      type: type,
      status: status,
    };

    if (id) {
      siteconfig.id = id;
      let config = {
        method: "put",
        url: API + "/siteConfigs/",
        data: siteconfig,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          if (savebuttonclick) {
            return navigate("/admin/siteconfigs");
          } else {
            notify("SiteConfig updated successfully");
            //navigate("/admin/pages");
            setId(response.id);
            setName(response.name);
            setVal(response.val);
            setType(response.type);
            setStatus(response.status);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/siteconfigs");
            // handleLogout(history);
          }
          console.log(error);
        });
    } else {
      let config = {
        method: "post",
        url: API + "/siteConfigs/",
        data: siteconfig,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          if (savebuttonclick) {
            return navigate("/admin/siteconfigs");
          } else {
            notify("SiteConfig added successfully");
            let responsData = response.data;
            setId(responsData.id);
            setName(responsData.name);
            setVal(responsData.val);
            setType(responsData.type);
            setStatus(responsData.status);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/siteconfigs");
            // handleLogout(history);
          }
          console.log(error);
        });
    }
  };
  const handleSave = () => {
    setSaveButtonClick(!savebuttonclick);
    validationCheck();
    // console.log("valError2",valError);
    // console.log("typeError2",typeError);
    // console.log("statusError2",statusError);

    if (
      nameError !== "" ||
      valError !== "" ||
      typeError !== "" ||
      statusError !== ""
    ) {
      return;
    } else {
      SaveEdit();
      notify("SiteConfig added successfully");
      navigate("/admin/siteconfigs");
    }
  };
  const handleSaveAndContinue = () => {
    validationCheck();

    if (
      nameError !== "" ||
      valError !== "" ||
      typeError !== "" ||
      statusError !== ""
    ) {
      return;
    } else {
      SaveEdit();
      notify("SiteConfig updated successfully");
      setId(id);
      setName(name);
      setVal(val);
      setType(type);
      setStatus(status);
    }
  };

  const fetchSiteConfig = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: API + "/siteConfigs/" + id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          const data = response.data;
          setName(data.name);
          setType(data.type);
          setVal(data.val);
          setStatus(response.status);
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/siteconfigs");
            // handleLogout(history);
          }
          console.log("error", error);
        });
    },
    [access_token, navigate]
  );

  useEffect(() => {
    if (id) {
      fetchSiteConfig(id);
    }
  }, [id]);
  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <SiteConfigHeading
          pageHeading="Add SiteConfig"
          showSaveOptionsBtn={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          save={true}
          handleSave={handleSave}
          saveAndContinue={true}
          handleSaveAndContinue={handleSaveAndContinue}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5">
            <h1 className="text-2xl">Site Config</h1>
          </div>
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Name
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={name}
                onChange={(e) => {
                  setNameError("");
                  setName(e.target.value);
                }}
                type="text"
                className={`${
                  nameError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter Name"
              />
              {nameError && (
                <span className="text-red-500 text-xs">{nameError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label
                for="status"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Value
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={val}
                onChange={(e) => {
                  setValError("");
                  setVal(e.target.value);
                }}
                type="text"
                className={`${
                  valError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter Value"
              />
              {valError && (
                <span className="text-red-500 text-xs">{valError}</span>
              )}
            </div>
          </div>
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Type
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={type}
                onChange={(e) => {
                  setTypeError("");
                  setType(e.target.value);
                }}
                type="text"
                className={`${
                  nameError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter Type"
              />
              {typeError && (
                <span className="text-red-500 text-xs">{typeError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label
                for="status"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Status
                <CgAsterisk className="inline text-red-500" />
              </label>
              <select
                value={status}
                onChange={(e) => {
                  setStatusError("");
                  setStatus(e.target.value);
                }}
                className={`${
                  statusError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
              {statusError && (
                <span className="text-red-500 text-xs">{statusError}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default AddSiteConfig;
