import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../services/API";
import Loader from "react-js-loader";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {StoreSubscriptionPlan} from "./../../../Redux/actions/UserSignupActions";

const PlanDetails = (props) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [PaymentLink, setPaymentLink] = useState("");
  // console.log("props in subscription plan page", props, state);
  const [Plans, setPlans] = useState([]);
  const [UserDetails, setUserDetails] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    setUserDetails(state);
  }, [state]);

  const getPlans = () => {
    let config = {
      method: "get",
      url: API + "/users/subscriptionplan/front/",
    };
    axios(config)
      .then(function (response) {
        const data = response.data.subscriptionPlans;
        setLoading(false);
        // console.log("setPlans data", data);
        setPlans(
          data.map((item) => {
            return {
              select: false,
              id: item.id,
              planName: item.planName,
              period: item.period,
              description: item.description,
              stripePriceId: item.stripePriceId,
              type: item.type,
              status: item.status,
              displayOrder: item.displayOrder,
              charge: item.charge,
              planFeaturesDTO: item.planFeaturesDTO,
            };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const subscribeStripe = (item) => {
    let config = {
      method: "post",
      url:
        API +
        "/users/stripe/subscription/process-create-checkout-session/front?priceId=" +
        item.stripePriceId,
    };
    axios(config)
      .then(async(response)=> {
        const data = response.data;
        setUserDetails({ ...UserDetails,subscriptionPlanId: item.id  });
        await dispatch(StoreSubscriptionPlan(item.id));
        // console.log("payment page link", data);
        setPaymentLink(data);
        window.open(data,"_self");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="w-full items-center justify-center pt-20">
      Please select your plan
      {loading && (
        <Loader
          type="box-up"
          bgColor={"#0075fd"}
          // title={"Loading"}
          color={"#0075fd"}
          size={100}
        />
      )}
      {Plans.length && (
        <div>
          {Plans.map((item) => {
            return (
              <div className="m-4">
                <div>{item.planName}</div>
                <div>{item.charge}</div>
                <div>{item.description}</div>
                <div>{item.type}</div>
                <div>
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center text-xs"
                    onClick={(e) => {
                      subscribeStripe(item);
                    }}
                    id={item.stripePriceId}
                  >
                    Select
                  </button>
                  ________________________________________________________________________
                </div>
              </div>
            );
          })}
        </div>
      )}
       
    </div>
  );
};

export default PlanDetails;
