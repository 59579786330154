import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import PageHeading from "../pages/PageHeading";

const EditComment = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [fname, setFame] = useState("");
  const [lname, setLName] = useState("");
  const [status, setStatus] = useState(true);
  const [comment, setComment] = useState("");
  const [blogId, SetBlogId] = useState("");
  let userInfo = JSON.parse(localStorage.getItem("user"));
  let access_token = userInfo.accessToken;

  const [commentError, setCommentError] = useState("");
  let id = params.id;

  const handleEdit = () => {
    let commentError = "";
    if (comment.trim() === "") {
      commentError = "Please Enter comment";
    }
    if (commentError !== "") {
      setCommentError(commentError);
      return;
    }
    const data = {
      comments: comment,
      status: status,
      blogCommentId: id,
      firstName: fname,
      lastName: lname,
      blogId: blogId,
    };
    let config = {
      method: "put",
      url: API + "/blogs/comments/",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        notify("Comment updated successfully");
        navigate("/admin/blog/view/comment/" + blogId);
      })
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/blog/view");
          // handleLogout(history);
        }
      });
  };
  const fetchComment = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/blogs/comments/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then((response) => {
        setFame(response.data.firstName);
        setLName(response.data.lastName);
        setStatus(response.data.status);
        setComment(response.data.comments);
        SetBlogId(response.data.blogId);
      })
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/blog/view");
          // handleLogout(history);
        }
      });
  }, [access_token, id, navigate]);
  const handleCancelBtn = () => {
    navigate(-1);
  };
  useEffect(() => {
    fetchComment();
  }, []);
  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading="Edit Comment"
          showSaveOptionsBtn={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          save={true}
          saveAndContinue={true}
          handleSave={handleEdit}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">First Name</label>
              <input
                value={fname}
                disabled
                type="text"
                className="border h-10 rounded-lg px-2 text-sm font-medium "
              />
            </div>
            <div className="flex flex-col">
              <label className="font-medium mb-1">Last Name</label>
              <input
                value={lname}
                disabled
                type="text"
                className="border h-10 rounded-lg px-2 text-sm font-medium "
              />
            </div>
          </div>
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="w-full flex flex-col">
              <label className="font-medium mb-1">Comment</label>
              <textarea
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                  setCommentError("");
                }}
                className={`${
                  commentError ? "border border-red-500" : "border"
                } h-10 rounded px-2 text-sm font-medium border`}
              ></textarea>
              {commentError && (
                <span className="text-red-500 text-xs">{commentError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label
                for="status"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Status
              </label>
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                className="border h-10 rounded px-2 text-sm font-medium"
              >
                <option>Select status</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default EditComment;
