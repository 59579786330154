import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { NoRecord, notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";
import PlanFeatureTable from "./PlanFeatureTable";

const PlanFeatureView = (props) => {
  let navigate = useNavigate();
  let state = useSelector((state) => state.UsersReducer);
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  let [currentPage, setCurrentPage] = useState(1);
  let [postPerPage, setPostPerPage] = useState(5);
  let [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  let [SortByName, setSortByName] = useState(false);
  let [SortByDisplayOrder, setSortByDisplayOrder] = useState(false);
  let [sortByCreated, setSortByCreated] = useState(true);
  let [search, setSearch] = useState("");
  let [status, setStatus] = useState("");
  let [pageSearch, setPageSearch] = useState(true);
  let [activeInactive, setActiveInactive] = useState(false);
  let [PlanFeaturesData, setPlanFeaturesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (access_token) {
      getPlanFeaturesData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = PlanFeaturesData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const getPlanFeaturesData = () => {
    let config = {
      method: "get",
      url: API + "/users/planfeature/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let plans = response.data.planFeatures;
        console.log("plans", plans);
        if (plans !== undefined && plans !== "" && plans !== null) {
          getperPageData(plans);
          setPlanFeaturesData(
            plans.map((data) => {
              return {
                select: false,
                title: data.title,
                createdBy: data.createdBy,
                updatedAt: data.updatedAt,
                createdDate: moment(data.createdAt).format(
                  "MM/DD/YYYY hh:mm a"
                ),
                displayOrder: data.displayOrder,
                id: data.id,
                status: data.status,
              };
            })
          );
        } else {
          setPlanFeaturesData([]);
        }
        setLoading(false);
      })
      .catch(async (error) => {
        console.log("error", error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(navigate);
        }
      });
  };

  const getperPageData = (PlanFeaturesData) => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/records_per_page",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response", response);
        const data = response.data;
        setPostPerPage(
          PlanFeaturesData.length < parseInt(data.val)
            ? PlanFeaturesData.length
            : parseInt(data.val)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const paginate = (number) => {
    if (number > 0) {
      setCurrentPage(number);
    }
  };

  const setPostsPerPage = (number) => {
    if (number > 0) {
      if (number > PlanFeaturesData.length) {
        setPostPerPage(parseInt(PlanFeaturesData.length));
      }
      setPostPerPage(parseInt(number));
    }
  };

  const SortName = (SortByName) => {
    const sorted = PlanFeaturesData.sort((a, b) => {
      const isReverse = SortByName === true ? 1 : -1;
      return isReverse * a.title.trim().localeCompare(b.title.trim());
    });
    setPlanFeaturesData(sorted);
  };

  const SortDisplayOrder = (sortByDisplayOrder) => {
    const sorted = PlanFeaturesData.sort((a, b) => {
      const isReverse = sortByDisplayOrder === true ? 1 : -1;
      return isReverse * a.displayOrder - b.displayOrder;
    });
    setPlanFeaturesData(sorted);
  };

  const sortCreated = (sortByCreated) => {
    const sorted = PlanFeaturesData.sort((a, b) => {
      let dateA = new Date(a.updatedAt);
      let dateB = new Date(b.updatedAt);
      return sortByCreated ? dateA - dateB : dateB - dateA;
    });
    setPlanFeaturesData(sorted);
  };

  const selectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setPageSearch(false);
      setActiveInactive(true);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
    setPlanFeaturesData(
      PlanFeaturesData.map((d) => {
        d.select = checked;
        return d;
      })
    );
  };

  const selectSingle = (e, id) => {
    let checked = e.target.checked;
    setPlanFeaturesData(
      PlanFeaturesData.map((p) => {
        if (id === p.id) {
          p.select = checked;
        }

        return p;
      })
    );

    const result = PlanFeaturesData.some(function (data) {
      return data.select === true;
    });
    if (result) {
      setActiveInactive(true);
      setPageSearch(false);
    } else {
      setPageSearch(true);
      setActiveInactive(false);
    }
  };

  const handleDelete = () => {
    let a = window.confirm("Are you sure you want to delete this");
    if (a) {
      let id = [];
      PlanFeaturesData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });

      // id.forEach((option) => {
      let config = {
        method: "delete",
        url: API + "/users/planfeature/",
        // data: id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
        data: id,
      };
      // console.log("config", config);
      axios(config)
        .then(function (response) {
          getPlanFeaturesData();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          console.log(error);
          // if (
          //   (error.status = "401") &&
          //   (error.message = "JWT token is expired")
          // ) {
          //   handleLogout(navigate);
          // }
        });
      // });
      notify("Plan feature deleted successfully ");
    }
  };

  const handleActive = () => {
    let a = window.confirm("Are you sure you want to activate this");
    if (a) {
      let id = [];
      PlanFeaturesData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });
      // console.log("id array", id);

      let updatePlanFeature = {
        method: "put",
        url: API + "/users/planfeature/status/true",
        data: id,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updatePlanFeature", updatePlanFeature);
      axios(updatePlanFeature)
        .then((response) => {
          notify("Plan feature activated successfully");
          getPlanFeaturesData();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch((error) => {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
        });
    }
  };

  const handleInActive = () => {
    let a = window.confirm("Are you sure you want to inactivate this");
    if (a) {
      let id = [];
      PlanFeaturesData.map((p) => {
        if (p.select === true) {
          id.push(p.id);
        }
      });
      // console.log("id array", id);

      let updatePlanFeature = {
        method: "put",
        url: API + "/users/planfeature/status/false",
        data: id,
        headers: {
          Authorization: `${access_token}`,
        },
      };
      // console.log("updatePlanFeature", updatePlanFeature);
      axios(updatePlanFeature)
        .then(function (response) {
          notify("Plan feature deactivated successfully");
          getPlanFeaturesData();
          setSelectAllCheckbox(false);
          setActiveInactive(false);
          setPageSearch(true);
        })
        .catch(function (error) {
          console.log(error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            handleLogout(navigate);
          }
        });
    }
  };

  const handleSearch = () => {
    setLoading(true);
    let config = {
      method: "get",
      url:
        API +
        `/users/planfeature/search/?size=-1${
          search && status
            ? `&status=${status}&searchString=${search}`
            : !search && status
            ? `&status=${status}`
            : search && !status
            ? `&searchString=${search}`
            : ""
        } `,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let plans = response.data.planFeatures;

        if (
          plans !== undefined &&
          plans !== "" &&
          plans !== null &&
          plans.length !== 0
        ) {
          setPlanFeaturesData(
            plans.map((data) => {
              return {
                select: false,
                title: data.title,
                createdBy: data.createdBy,
                updatedAt: data.updatedAt,
                createdDate: moment(data.createdAt).format(
                  "MM/DD/YYYY hh:mm a"
                ),
                displayOrder: data.displayOrder,
                id: data.id,
                status: data.status,
              };
            })
          );
        } else {
          setPlanFeaturesData([]);
        }
      })
      .catch(function (error) {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        }
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setSearch("");
    getPlanFeaturesData();
  };

  const handleClose = () => {
    setSearch("");
    getPlanFeaturesData();
  };

  return (
    <div
      className={`content-container relative overflow-y-auto scrollbar-hide ${
        props.Sidebar ? "" : ""
      }`}
    >
      <div className="flex-col items-center w-full">
        <PageHeading
          pageHeading={"Plan features"}
          searchLabel={"Name"}
          pageSearch={pageSearch}
          activeInactive={activeInactive}
          search={search}
          setSearch={setSearch}
          setStatus={setStatus}
          handleActive={handleActive}
          handleInActive={handleInActive}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleClose={handleClose}
          path="/admin/planFeatures/add"
          deleteBtn={true}
          handleDelete={handleDelete}
          addBtn={true}
        />
        {loading && (
          <Loader
            type="box-up"
            bgColor={"#0075fd"}
            // title={"Loading"}
            color={"#0075fd"}
            size={100}
          />
        )}
        {!loading && currentPosts.length === 0 ? (
          <NoRecord />
        ) : (
          <PlanFeatureTable
            state={state}
            selectAllCheckbox={selectAllCheckbox}
            setSelectAllCheckbox={setSelectAllCheckbox}
            selectAll={selectAll}
            PlanFeatureData={currentPosts}
            selectSingle={selectSingle}
            totalPlanFeatures={PlanFeaturesData.length}
            // pageNumbers={pageNumbers}
            currentPage={currentPage}
            paginate={paginate}
            postPerPage={postPerPage}
            setPostsPerPage={setPostsPerPage}
            SortName={SortName}
            SortByName={SortByName}
            setSortByName={setSortByName}
            SortDisplayOrder={SortDisplayOrder}
            SortByDisplayOrder={SortByDisplayOrder}
            setSortByDisplayOrder={setSortByDisplayOrder}
            sortCreated={sortCreated}
            sortByCreated={sortByCreated}
            setSortByCreated={setSortByCreated}
            handleActive={handleActive}
            handleInActive={handleInActive}
            allPlanFeaturesData={PlanFeaturesData}
            setPlanFeaturesData={setPlanFeaturesData}
            // setPlanFeatureViewPerPage={setPlanFeatureViewPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default PlanFeatureView;
