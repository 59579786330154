import axios from "axios";
import React, { useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";

const BlogsSetting = (props) => {
  const [recordNo, setRecordNo] = useState("5");
  const [BlogDisplayPerPAgeRecordID, setBlogDisplayPerPAgeRecordID] =
    useState("");
  const [recordError, setRecordNoError] = useState("");
  const [CommentModeration, setCommentModeration] = useState("");
  const [CommentModerationRecordID, setCommentModerationRecordID] =
    useState("");
  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  useEffect(() => {
    if (access_token) {
      getData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const getData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.siteConfig;
        // console.log(data);
        let filteredData = data.filter(
          (option) => option.name === "field_blog_per_page_display"
        );
        setRecordNo(filteredData[0].val);
        setBlogDisplayPerPAgeRecordID(filteredData[0]);
        let filteredCommentmoderation = data.filter(
          (option) => option.name === "comment_moderation"
        );
        setCommentModeration(filteredCommentmoderation[0].val);
        setCommentModerationRecordID(filteredCommentmoderation[0]);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
        console.log(error);
      });
  };

  const handleSave = () => {
    let recordError = "";
    if (recordNo.trim() === "") {
      recordError = "Enter number of records";
    }
    if (recordError !== "") {
      setRecordNoError(recordError);

      return;
    }
    let dataBody = {
      id: BlogDisplayPerPAgeRecordID.id,
      val: recordNo,
      status: true,
      type: "string",
      name: "field_blog_per_page_display",
    };

    let config = {
      method: "put",
      url: API + "/siteConfigs/",
      data: dataBody,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    // console.log("config", config);

    axios(config)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/blog/setting");
          // handleLogout(history);
        }
      });

    let data = {
      id: CommentModerationRecordID.id,
      val: CommentModeration,
      status: true,
      type: "string",
      name: "comment_moderation",
    };

    let configData = {
      method: "put",
      url: API + "/siteConfigs/",
      data: data,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    // console.log("config", config);

    axios(configData)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin/blog/setting");
          // handleLogout(history);
        }
      });
    notify("Blog settings updated successfully");
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading="Settings"
          pageSearch={false}
          showSaveOptionsBtn={true}
          save={true}
          handleSave={handleSave}
        />
        <div className="w-full lg:w-8/12 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 my-4 relative ">
          <div className="shadow-md w-full">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0"
                id="tab-multi-one"
                type="checkbox"
                name="tabs"
              />
              <label
                className="block px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                for="tab-multi-one"
              >
                Blog Settings
              </label>
              <div className="tab-content overflow-hidden transition-all border-l-4 border-blue-400 dark:border-red-500  leading-normal lg:grid lg:grid-cols-3 w-full text-black dark:text-textinblack">
                <div className="p-3 flex flex-col">
                  <label className="text-sm mb-2 flex items-center">
                    Record per page on blog listing page of front side
                    <CgAsterisk className="inline text-red-500" />
                  </label>
                  <input
                    type="text"
                    value={recordNo}
                    onChange={(e) => {
                      setRecordNo(e.target.value);
                      setRecordNoError("");
                    }}
                    className={`${
                      recordError ? "border-red-500 border" : "border"
                    } border h-10 rounded-lg px-2 text-sm font-medium `}
                  />
                  {recordError && (
                    <span className="text-red-500 text-xs">{recordError}</span>
                  )}
                </div>
                <div className="p-3 flex flex-col">
                  <label className="text-sm mb-2 flex items-center">
                    Comment Moderation
                  </label>
                  <select
                    value={CommentModeration}
                    className="border h-10 rounded px-2 text-sm font-medium"
                    onChange={(e) => setCommentModeration(e.target.value)}
                  >
                    <option value="yes with mail notification">
                      Yes with mail notification
                    </option>
                    <option value="yes without mail notification">
                      Yes without mail notification
                    </option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default BlogsSetting;
