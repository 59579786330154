import React, { useCallback, useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import PageHeading from "../pages/PageHeading";
// import TinyMCE from "react-tinymce";
import axios from "axios";
import { addOrUpdateNewInfoToIDBStore } from "indexeddb-util";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateLoginData } from "../../Redux/actions/LoginActions";
import { API } from "../../services/API";
import { notify } from "../../utility";

const CreateUser = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;
  let dispatch = useDispatch();

  const [id, setId] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [type_id, setTypeId] = useState("");

  const [first_nameError, setFirstNameError] = useState("");
  const [last_nameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState([]);
  const [typeError, setTypeError] = useState("");

  const handleCancelBtn = () => {
    navigate(-1);
  };

  const SaveEdit = (savebuttonclick) => {
    // var regex = /^[a-zA-Z ]{2,30}$/;
    let first_nameError = "";
    let last_nameError = "";
    let emailError = "";

    if (!first_name) {
      first_nameError = "Enter First Name";
    }
    if (!last_name) {
      last_nameError = "Enter Last Name";
    }
    if (!email) {
      emailError = "Enter Email";
    }
    // if (first_name.match(regex) === null) {
    //   first_nameError = "Enter valid First Name";
    // }
    // if (last_name.match(regex) === null) {
    //   last_nameError = "Enter valid Last Name";
    // }
    if (first_nameError !== "" || last_nameError !== "" || emailError !== "") {
      setFirstNameError(first_nameError);
      setLastNameError(last_nameError);
      setEmailError(emailError);
      setTypeError(typeError);
      return;
    }
    let user = {
      firstName: first_name.trim(),
      lastName: last_name.trim(),
      email: email,
      userType: type,
      userTypeId: type_id,
    };
    if (id) {
      user.userId = id;
      let config = {
        method: "put",
        url: API + "/users/admin/",
        data: user,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          notify("User Updated successfully");

          const newItem = {
            ...response.data,
            accessToken: userInfo.accessToken,
            profileImage: response.data.profilePic,
          };
          let isSameUser = email === userInfo.email;
          if (isSameUser) {
            dispatch(UpdateLoginData(newItem));
            addOrUpdateNewInfoToIDBStore({
            dbName: "loginStore",
            storeName: "loginStore",
            keyPath: "accessToken",
            version: 2,
            newItem,
            key: userInfo.userType === "FRONT" ? "userData" : "user",
            });
          }
          // console.log("PUT call - setSaveButtonClick", savebuttonclick);
          if (savebuttonclick) {
            return navigate("/admin/users");
          } else {
            let responseput = response.data;
            setId(responseput.userId);
            setFirstName(responseput.firstName);
            setLastName(responseput.lastName);
            setEmail(responseput.email);
            setType(responseput.userType);
            setTypeId(responseput.userTypeId);
          }
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/users");
            notify("User not updated, please try Again");
            // handleLogout(history);
          }
          let respo_error = error.response.data;
          if (respo_error.status === 400) {
            let error_messages = Object.values(respo_error.messagesMap);
            notify(error_messages[0], "warn");
          }
          console.log(error);
        });
    } else {
      let config = {
        method: "post",
        url: API + "/users/admin/",
        data: {
          firstName: first_name.trim(),
          lastName: last_name.trim(),
          email: email,
          userType: "Admin",
        },
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then(function (response) {
          if (savebuttonclick) {
            notify("User Saved successfully");
            return navigate("/admin/users");
          } else {
            let responseData = response.data;
            setId(responseData.userId);
            setFirstName(responseData.firstName);
            setLastName(responseData.lastName);
            setEmail(responseData.email);
            setType(responseData.userType);
            setTypeId(responseData.userTypeId);
          }
          notify("User added successfully");
        })
        .catch((error) => {
          let respo_error = error.response.data;
          // if (respo_error.status === "401") {
          //   handleLogout(navigate);
          //   navigate("/admin");
          // }
          if (respo_error.status === 409) {
            navigate("/admin/users");
            let error_message = respo_error.messages[0];
            notify(error_message, "error");
          }
          // if (respo_error.status === "400") {
          //   //navigate("/admin/users");
          //   let error_messages = respo_error.messagesMap.Email;
          //   notify(error_messages);
          // }
          if (respo_error.status === 400) {
            let error_messages = Object.values(respo_error.messagesMap);
            notify(error_messages[0], "warn");
          }
        });
    }
  };

  const handleSave = () => {
    // let savebuttonclick = true;
    // setSaveButtonClick(savebuttonclick);
    SaveEdit(true);
  };

  const handleSaveAndContinue = () => {
    SaveEdit(false);
    setId(id);
    setFirstName(first_name);
    setLastName(last_name);
    setEmail(email);
    setType(type);
    setTypeId(type_id);
  };

  const fetchPage = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: API + "/users/" + id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };
      axios(config)
        .then((response) => {
          document.getElementById("adminUserFirstName").focus();
          const data = response.data;
          setId(data.userId);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmail(data.email);
          setType(data.userType);
          setTypeId(data.userTypeId);
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/users");
            // handleLogout(history);
          }
        });
    },
    [access_token, navigate]
  );

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo({ top: -800, behavior: "smooth" });
    }, 2);
  }, []);

  useEffect(() => {
    if (params.id) {
      // setId(params.id);
      fetchPage(params.id);
    }
  }, [params.id]);

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading={params.id ? "Edit User" : "Add User"}
          showSaveOptionsBtn={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          save={true}
          handleSave={handleSave}
          saveAndContinue={true}
          handleSaveAndContinue={handleSaveAndContinue}
          savedisable={false}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5">
            {/* // <h1 className="text-2xl">Page Content</h1> */}
          </div>
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                First Name
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={first_name}
                id="adminUserFirstName"
                onChange={(e) => {
                  setFirstNameError("");
                  setFirstName(e.target.value);
                }}
                type="text"
                className={`${
                  first_nameError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter First Name"
              />
              {first_nameError && (
                <span className="text-red-500 text-xs">{first_nameError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Last Name
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={last_name}
                onChange={(e) => {
                  setLastNameError("");
                  setLastName(e.target.value);
                }}
                type="text"
                className={`${
                  last_nameError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter Last Name"
              />
              {last_nameError && (
                <span className="text-red-500 text-xs">{last_nameError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Email
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={email}
                onChange={(e) => {
                  setEmailError("");
                  setEmail(e.target.value);
                }}
                type="text"
                disabled={params.id ? true : false}
                className={`${
                  emailError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter Email"
              />
              {emailError && (
                <span className="text-red-500 text-xs">{emailError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="font-medium mb-1">Type</label>
              <input
                value={type}
                type="text"
                className={`border h-10 rounded-lg px-2 text-sm font-medium `}
                placeholder="Admin"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default CreateUser;
