import axios from "axios";
import { deleteDB } from "indexeddb-util";
import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineLogout
} from "react-icons/ai";
import { FaChevronDown, FaUserAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RemoveLoginData } from "../../../Redux/actions/LoginActions";
import { API } from "../../../services/API";
import useDarkMode from "../../../useDarkMode";
import OutsideClick from "./OutsideClick";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentBreakpoint } from "utility-reactlibrary";


const FrontSpaNavbar = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();
  const {pathname} = location;
  const [colorTheme, setTheme] = useDarkMode();
  const [windowSize, setWindowSize] = useState("");
  const [ActiveTab, setActiveTab] = useState("");
  const [PagesData, setPagesData] = useState([]);
  const [PagesMenu, setPagesMenu] = useState(false);
  const [menu, setMenu] = useState(false);

  const [cartPop, setCartPop] = useState(false);
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [LinkedIn, setLinkedIn] = useState("");
  const [isOpen, toggleIsOpen] = useState(false);

  const {theme, isSmDown, ...info} = getCurrentBreakpoint();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const cart = useSelector((state) => state.CartReducer.cartItems);
  const userinformation = localStorage?.getItem("userData");

  const boxRef = useRef(null);
  const boxOutsideClick = OutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      setPagesMenu(false);
    }
  }, [boxOutsideClick]);

  const resetFunc = React.useCallback(()=>{
   toggleIsOpen(false);
  },[]);

  useEffect(()=>{
    resetFunc();
  },[pathname, resetFunc])

  const MenuRef = useRef(null);
  const MenusideClick = OutsideClick(MenuRef);

  useEffect(() => {
    if (MenusideClick) {
      setMenu(false);
    }
  }, [MenusideClick]);

  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (windowSize > 1024) {
      let navLink = document.getElementById("navLink");
      navLink.classList.remove("hidden");
    }
  }, [windowSize]);

  let userData =
    userinformation && JSON.parse(localStorage?.getItem("userData"));
  
  const logOut = async () => {
    const config = {
      method: "put",
      url: API + "/users/auth/logout",
      headers: {
        Authorization: `${userData?.accessToken}`,
      },
    };
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });

    await deleteDB({
    dbName: "loginStore",
    }).then(async () => {
        localStorage.clear();
    await dispatch(RemoveLoginData());
});
    setMenu(false);
    navigate("/");
    window.location.reload();
  };

  const getActiveTabData = () => {
    let pathname = window.location.pathname;
    if (pathname === "/blogs" || pathname.includes("/blog/")) {
      setActiveTab("Blogs");
    } else if (pathname === "/pages" || pathname.includes("/pages/")) {
      setActiveTab("Pages");
    } else if (pathname === "/galleries" || pathname.includes("/galleries/")) {
      setActiveTab("Galleries");
    } else if (pathname === "/faqs") {
      setActiveTab("Faqs");
    }
  };

  const getPagesData = () => {
    let config = {
      method: "get",
      url: API + "/pages/front/",
      headers: {
        "Content-type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const data = response.data;
        let keys = Object.keys(data);
        let processedData = [];
        for (let index = 0; index < keys.length; index++) {
          const element = keys[index];
          let obj = {
            id: element,
            value: data[element],
          };
          processedData.push(obj);
        }
        setPagesData(processedData);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const getSocialLinksData = () => {
    let facebookconfig = {
      method: "get",
      url: API + "/siteConfigs/front/name/facebook_link",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(facebookconfig)
      .then((response) => {
        const data = response.data;
        setFacebook(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
    let twitterconfig = {
      method: "get",
      url: API + "/siteConfigs/front/name/twitter_link",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(twitterconfig)
      .then((response) => {
        const data = response.data;
        setTwitter(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
    let linkedinconfig = {
      method: "get",
      url: API + "/siteConfigs/front/name/linkedin_link",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(linkedinconfig)
      .then((response) => {
        const data = response.data;
        setLinkedIn(data.val);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getPagesData();
    getActiveTabData();
    getSocialLinksData();
  }, [windowSize, cart]);

  return (
    <div className="w-full flex items-center fixed z-20 xl:h-20 lg:h-20 sm:h-20 justify-between py-2 dark:bg-bgtabletitle bg-white px-3 border-box shadow-lg flex-col xl:flex-row lg:flex-row">
      <Link
        to="/"
        onClick={() => {
          let navLink = document.getElementById("navLink");
          if (window.innerWidth < 1024) {
            navLink.classList.add("hidden");
          } 
        }}
        className="xl:w-auto lg:w-auto me-auto"
      >
        <img
          onClick={() => window.scroll(0, 0)}
          className="object-contain w-56 my-4"
          src={process.env.PUBLIC_URL + "/images/header-logo.png"}
          alt=""
        />
      </Link>
      <div className="flex items-center justify-center w-full flex-col xl:w-auto xl:flex-row xl:mt-0 lg:flex-row lg:w-auto lg:mt-0">
        <ul
          id="navLink"
          className={!isOpen ? `lg:flex items-center justify-center w-full bg-white lg:bg-transparent text-black capitalize dark:text-white mt-4 flex-col order-2 xl:order-1 xl:mt-0 xl:flex-row lg:order-1 lg:mt-0 lg:flex-row px-3 xl:px-0 hidden`
          : isMd ? `flex items-center justify-center w-full bg-white lg:bg-transparent text-black capitalize dark:text-black mt-4 flex-col order-2 xl:order-1 xl:mt-0 xl:flex-row lg:order-1 lg:mt-0 lg:flex-row px-3 xl:px-0` 
          :
          `flex items-center justify-center w-full bg-white lg:bg-transparent text-black capitalize dark:text-white mt-4 flex-col order-2 xl:order-1 xl:mt-0 xl:flex-row lg:order-1 lg:mt-0 lg:flex-row px-3 xl:px-0`}
        >
          {userData && userData?.userType === "FRONT" ? (
            <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
              <a
                href="#"
                className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
              ${ActiveTab === "survey" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                  `}
                to="/survey"
                onClick={() => {
                  navigate("/survey");
                  setActiveTab("survey");
                  let navLink = document.getElementById("navLink");
                  if (window.innerWidth < 768) {
                    navLink.classList.add("hidden");
                  }
                }}
              >
                Survey
              </a>
            </li>
          ) : (
            <></>
          )}

          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
            <a
              href="#about"
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
              ${ActiveTab === "About" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                  `}
              to="/frontend-spa/about"
              onClick={() => {
                navigate("/");
                setActiveTab("About");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
            >
              About
            </a>
          </li>
          {PagesData.length > 0 && (
            <li onClick={(e)=> {
              e.preventDefault();
              setPagesMenu(!PagesMenu);
              }} className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
              <a href="#" className={`px-3 py-1 inline-flex items-center rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor ${ActiveTab === "Pages" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}`}>
                 Pages
                 <FaChevronDown className="cursor-pointer group ml-1" />
              </a>
                {PagesMenu && (<ul ref={boxRef} className={isMd ? 
                `lg:absolute lg:flex-col lg:top-14 xl:absolute xl:flex-col xl:top-14 bg-white flex flex-col items-center rounded w-full lg:w-max` :
                `lg:absolute lg:flex-col lg:top-14 xl:absolute xl:flex-col xl:top-14 dark:bg-gray-700 bg-white flex flex-col items-center rounded w-full lg:w-max` }>
                 {PagesData.map((item)=>{
                  return(
                    <li id={item.id}  onClick={(e) => {
                      setActiveTab("Pages");
                      window.scroll(0, 0);
                      navigate("/pages/" + item.id);
                    }} className={isMd ? `w-full border-b border-black` : `w-full border-b border-black dark:border-white` 
                    }>
                    <Link to="" className="block py-2 px-3 font-normal text-sm text-start lg:text-left xl:text-center hover:bg-darkblack hover:text-white">
                      {item.value}
                    </Link>
                  </li>
                 )
                 })}
                </ul>)}
            </li>
          )}
          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
            <a
              href="#calender"
              onClick={() => {
                setActiveTab("Calender");
                navigate("/");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
              ${ActiveTab === "Calender" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                 `}
            >
              Calender
            </a>
          </li>
          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
            <a
              href="#testimonials"
              onClick={() => {
                navigate("/");
                setActiveTab("Testimonials");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
                            ${
                              ActiveTab === "Testimonials" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"
                            } `}
            >
              Testimonials
            </a>
          </li>
          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
            <a
              onClick={() => {
                navigate("/");
                setActiveTab("Teams");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
              href="#team"
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
                            ${ActiveTab === "Teams" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"} `} >
              Teams
            </a>
          </li>
          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
            <a
              onClick={() => {
                navigate("/");
                setActiveTab("Contact");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
              href="#contact"
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
                ${ActiveTab === "Contact" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                   `}
            >
              Contact
            </a>
          </li>
          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1 cursor-pointer">
            <a
              onClick={() => {
                setActiveTab("Blogs");
                navigate("/blogs");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
              ${ActiveTab === "Blogs" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                 `}
            >
              Blogs
            </a>
          </li>
          
          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
            <a
              onClick={() => {
                navigate("/galleries");
                setActiveTab("Galleries");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
              ${ActiveTab === "Galleries" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                 `}
            >
              Galleries
            </a>
          </li>
          <li className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1 cursor-pointer">
            <a
              onClick={() => {
                navigate("/faqs");
                setActiveTab("Faqs");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
              className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
              ${ActiveTab === "Faqs" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                 `}
            >
              FAQs
            </a>
          </li>
          {userData && userData?.user_type_id === 0 && (
            <li className="w-full my-4 xl:w-auto xl:my-0 lg:w-auto lg:my-0 border-b lg:border-none xl:border-none">
              <p
                onClick={() => {
                  navigate("/clientDashboard");
                  setActiveTab("Dashboard");
                  let navLink = document.getElementById("navLink");
                  if (window.innerWidth < 768) {
                    navLink.classList.add("hidden");
                  }
                }}
                className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
                ${ActiveTab === "Dashboard" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                   `}
              >
                Dashboard
              </p>
            </li>
          )}
          {userData && userData?.user_type_id === 1 && (
            <li className="w-full my-4 xl:w-auto xl:my-0 lg:w-auto lg:my-0 border-b lg:border-none xl:border-none">
              <p
                onClick={() => {
                  navigate("/PartnerDashboard");
                  setActiveTab("Dashboard");
                  let navLink = document.getElementById("navLink");
                  if (window.innerWidth < 768) {
                    navLink.classList.add("hidden");
                  }
                }}
                className={`cursor-pointer px-3 py-1 rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor
                ${ActiveTab === "Dashboard" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}
                   `}
              >
                Dashboard
              </p>
            </li>
          )}
          
          {!userData && (
            <li ref={MenuRef}
              onClick={() => {
                navigate("/frontendLogin");
               toggleIsOpen(!isOpen);
              
              }}
              className="w-full cursor-pointer mt-4 flex flex-col hidden"
            >
              <p className="w-full bg-bluecolor py-2 text-center font-semibold text-gray-900">
                Login
              </p>
            </li>
          )}

          {userData ? (
            <>
              <li
              
                onClick={() => setMenu(!menu)}
                className="w-full cursor-pointer py-4 lg:py-0 my-0 xl:my-4 border-b hidden flex-col lg:flex items-start lg:items-center xl:items-center xl:border-none xl:w-auto lg:border-none lg:w-auto lg:my-0 relative"
              >
                <div
                  onClick={() => setMenu(!menu)}
                  className="flex items-center px-1"
                >
                  <p className=" pr-1 py-1 rounded-full text-sm tracking-wide font-normal">
                    {userData.firstName} {userData.lastName}
                  </p>
                  <div className="w-8 h-8 rounded-full bg-white shadow flex items-center justify-center overflow-hidden mr-1">
                    <div className="w-8 h-8 rounded-full bg-white shadow flex items-center justify-center overflow-hidden mr-1">
                      {userData && userData.profileImage ? (
                        <img
                          className="w-full  object-contain"
                          src={
                            API +
                            "/files/download/?fileName=" +
                            `${encodeURIComponent(userData.profileImage)}`
                          }
                          alt=""
                        />
                      ) : (
                        <FaUserAlt className="dark:text-gray-700" />
                      )}
                    </div>
                  </div>
                  <FaChevronDown />
                </div>
                {menu && (
                  <ul ref={boxRef} className="lg:absolute lg:flex-col lg:top-14 xl:absolute xl:flex-col xl:top-14 dark:bg-gray-700 bg-white flex flex-col items-center rounded w-full lg:w-max">
                    <li
                      onClick={() => {
                        setMenu(!menu);
                      }}
                      className="w-full flex items-center justify-center border-b py-2 border-black dark:border-white"
                    >
                      <Link
                        to="/profile"
                        className="w-full px-3 font-normal text-sm text-start lg:text-center xl:text-center xl:my-0 lg:my-0"
                      >
                        My Profile
                      </Link>
                    </li>
                    
                    <li
                      onClick={() => {
                        logOut();
                      }}
                      className="w-full flex items-center justify-center border-b py-2 border-black dark:border-white"
                    >
                      <Link
                        className="flex items-center h-10 w-full dark:bg-gray-400 dark:text-black bg-black text-white dark:font-bold p-3 cursor-pointer "
                      >
                        <AiOutlineLogout /> <span className="pl-3">Logout</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li className="w-full cursor-pointer mt-4 flex flex-col lg:hidden xl:hidden">
                <p
                  onClick={() => {
                    navigate("/profile");
                    setMenu(!menu);
                  }}
                  className="h-10 w-full flex items-center p-3 cursor-pointer border-b-2 border-black dark:border-white"
                >
                  My Profile
                </p>
              </li>
              
              <li
                onClick={() => {
                  logOut();
                }}
                className="w-full cursor-pointer mt-4 flex flex-col lg:hidden xl:hidden"
              >
                <p className="w-full bg-bluecolor py-2 text-center font-semibold text-gray-900">
                  Logout
                </p>
              </li>
            </>
          ) : (            
            <li ref={MenuRef}
            className="py-3 my-0 xl:my-4 border-b xl:border-none w-full xl:w-auto lg:border-none mx-1">
              <p
                onClick={() => { setActiveTab("Login"); setMenu(!menu); }}
                className={`cursor-pointer px-3 py-1 inline-flex items-center rounded-full tracking-wide font-normal text-sm xl:hover:bg-bluecolor xl:hover:text-white lg:hover:bg-bluecolor ${ActiveTab === "Login" ? "bg-bluecolor text-white hover:text-white" : "hover:text-yellow-500"}`}>
                Login
                <FaChevronDown className="cursor-pointer group ml-1" />
              </p>
              {menu && (
                <ul className={isMd ? "lg:absolute lg:flex-col lg:top-14 xl:absolute xl:flex-col xl:top-14 bg-white flex flex-col items-center rounded w-full lg:w-max" 
                : 
                "lg:absolute lg:flex-col lg:top-14 xl:absolute xl:flex-col xl:top-14 dark:bg-gray-700 bg-white flex flex-col items-center rounded w-full lg:w-max"}>
                  <li
                    onClick={() => { setActiveTab("Login"); setMenu(!menu); }}
                    className="w-full flex items-center justify-center border-b border-black dark:border-white py-2 lg:hover:bg-bluecolor  xl:hover:bg-bluecolor xl:hover:text-white my-4 xl:my-0 lg:my-0"
                  >
                    <Link
                      to="/frontendLogin"
                      className={isMd ? 
                        "w-full px-3 font-normal text-sm text-start lg:text-center xl:text-center xl:my-0 lg:my-0 dark:text-black"
                        :
                        "w-full px-3 font-normal text-sm text-start lg:text-center xl:text-center xl:my-0 lg:my-0 dark:text-white"}
                    >
                      Login
                    </Link>
                  </li>
                  <li
                    onClick={() => { setActiveTab("Login"); setMenu(!menu); }}
                    className={isMd ? 
                      "w-full flex  items-center justify-center py-2 lg:hover:bg-bluecolor  xl:hover:bg-bluecolor xl:hover:text-white dark:text-black"
                      :
                      "w-full flex  items-center justify-center py-2 lg:hover:bg-bluecolor  xl:hover:bg-bluecolor xl:hover:text-white dark:text-white"}
                  >
                    <Link
                      to="/frontEndSignUp"
                      className="w-full px-3 text-sm font-normal text-start lg:text-center xl:text-center"
                    >
                      Sign up
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}
        </ul>
        { !isSmDown && <div
          onClick={() => setTheme(colorTheme)}
          className={`border-1 xl:flex lg:flex md:flex sm:hidden border-black p-2 ml-2 order-1 cursor-pointer rounded-full absolute right-2 top-4 xl:order-2 xl:relative xl:top-0 xl:right-0 lg:order-2 lg:relative lg:top-0 lg:right-0 ${
            colorTheme === "light" ? "bg-white" : "bg-gray-800"
          }`}
        >
          <HiOutlineLightBulb
            className={`${
              colorTheme === "light" ? "text-black" : "text-white"
            } `}
          />
        </div>}
        {
          userData && (
            <div className="dark:text-white text-black flex absolute right-20 top-4 xl:hidden lg:hidden">
              <div className="flex items-center px-1">
                <div className="w-8 h-8  rounded-full bg-white shadow flex items-center justify-center overflow-hidden mr-1">
                  {userData?.profileImage ? (
                    <img
                      className="w-full  object-contain"
                      alt=""
                      src={
                        API +
                        "/files/download/?fileName=" +
                        `${encodeURIComponent(userData?.profileImage)}`
                      }
                    />
                  ) : (
                    <FaUserAlt className="dark:text-gray-700" />
                  )}
                </div>
              </div>
            </div>
          )
        }
        <div className={isSmDown ? "dark:text-white text-black flex sm:flex absolute right-2 top-5 lg:hidden" : "dark:text-white text-black flex sm:flex absolute right-12 top-5 lg:hidden"}>
          {" "}
          <GiHamburgerMenu
            className="cursor-pointer"
            size={26}
            onClick={() => toggleIsOpen(!isOpen)}
          />
        </div>
      </div>
      {cartPop && (
        <div className="fixed inset-0 bg-black bg-opacity-70 w-full h-full flex items-center justify-center ">
          <div className="bg-white px-10 py-8 rounded dark:bg-black">
            <h1 className="font-semibold dark:text-white">
              No items in cart Go to the product page
            </h1>
            <div className="w-full flex justify-end h-auto  mt-10">
              <button
                onClick={() => {
                  setCartPop(!cartPop);
                  navigate("./products");
                }}
                className="h-8 w-20 rounded bg-bluecolor text-white hover:text-white texthover:text-yellow-500-sm hover:bg-yellow-400"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FrontSpaNavbar;
