import { FOOTER_MESSAGE } from "../actionTypes";

export const initialState = {
  message: "",
};

export const CompanyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FOOTER_MESSAGE:
      return {
        message: payload.message,
      };
    default:
      return state;
  }
};
