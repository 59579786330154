import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../services/API";
import { RemoveSignupDetails } from "./../../../Redux/actions/UserSignupActions";

const PaymentSuccess = () => {
  const params = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [APIError, setAPIError] = useState(false);
  const userInfo = useSelector((state) => state.UserSignupReducer.userDetails);

  useEffect(() => {
    async function registerUser() {
      const path = API + "/users/register";
      // console.log("userInfo", userInfo);
      const data = userInfo;
      data.sessionID = params.id;
      // console.log("updated data", data);
      let config = {
        credentials: "include",
        method: "post",
        url: path,
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Methods": "*",
        },
        data: data,
        crossDomain: true,
      };
      await axios(config)
        .then((response) => {
          // console.log("response of save user", response);
          // notify("You are registered successfully");
          setLoading(false);
          navigate("/frontendLogin");
          dispatch(RemoveSignupDetails());
        })
        .catch(function (error) {
          // console.log(error.response.data);
          setLoading(false);
          setAPIError(true);
          //   setAPIError(error.response.data.messages[0]);
        });
    }
    registerUser();
  }, [params.id]);

  return (
    <div className="min-h-screen justify-center flex items-center w-full pt-20 text-xl">
      {/* {console.log("loading, APIError", loading, APIError)} */}
      {loading === true && APIError === false ? (
        <Loader
          type="box-up"
          bgColor={"#0075fd"}
          // title={"Loading"}
          color={"#0075fd"}
          size={100}
        />
      ) : (
        "Payment done successfully and your registration completed..!"
      )}
      {APIError && "Something went wrong!"}
    </div>
  );
};

export default PaymentSuccess;
