import axios from "axios";
import React, { useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";

const ChangePassword = (props) => {
  let navigate = useNavigate();
  // let [userPassword, setUserPassword] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  let userInfo = JSON.parse(localStorage.getItem("user"));

  let regEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,10}$/;

  const handleCancelBtn = () => {
    navigate(-1);
  };
  let ChangePassword = () => {
    let currentPasswordError = "";
    let newPasswordError = "";
    let confirmPasswordError = "";

    if (currentPassword.trim() === "") {
      currentPasswordError = "Enter current password";
    }
    if (newPassword.trim() === "") {
      newPasswordError = "Enter new password before save";
    }
    if (confirmPassword.trim() === "") {
      confirmPasswordError = "Enter Confirm password before save";
    }
    if (newPassword.length < 5) {
      newPasswordError = "Password length must be between 5 and 10";
    }

    if (
      currentPasswordError !== "" ||
      newPasswordError !== "" ||
      confirmPasswordError !== ""
    ) {
      setCurrentPasswordError(currentPasswordError);
      setNewPasswordError(newPasswordError);
      setConfirmPasswordError(confirmPasswordError);
      return;
    }

    if (newPassword !== "" && newPassword.match(regEX)) {
      setNewPassword(newPassword);
    } else {
      setNewPasswordError(
        "Please check following condition to set new password"
      );
      return;
    }
    if (newPassword === confirmPassword) {
      setConfirmPassword(confirmPassword);
    } else {
      setConfirmPasswordError("new password and confirm password is not same");
      return;
    }
    let config = {
      method: "put",
      url: API + "/users/changePassword",
      data: {
        userId: userInfo.userId,
        oldPassword: currentPassword,
        newPassword: confirmPassword,
      },
      headers: {
        "Content-type": "application/json",
        Authorization: `${userInfo.accessToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        notify("Password changed successfully");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setCurrentPasswordError("");
        setNewPasswordError("");
        setConfirmPasswordError("");
        if (userInfo.userType.toLowerCase() === "admin") {
          navigate("/admin/dashboard");
        } else {
          // navigate("/front/dashboard");
          navigate("/");
        }
        // navigate("/admin/dashboard");
      })
      .catch(function (error) {
        let eorror_data = error.response.data;
        if (eorror_data.status === "400") {
          Object.entries(eorror_data.messagesMap).forEach(([key, value]) => {
            if (key === "OldPassword") {
              setCurrentPasswordError(value);
            }
          });
        }
        if (eorror_data.status === "401") {
          // Object.entries(eorror_data.messagesMap).forEach(([key, value]) => {
          //   console.log(typeof key);
          //   if (key === "OldPassword") {
          //     setCurrentPasswordError(value);
          //   }
          // });
          handleLogout(navigate);
        }
      });
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading={"Change Password"}
          showSaveOptionsBtn={true}
          save={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          handleSave={ChangePassword}
        />
        <div className="w-full lg:w-8/12 mt-5 grid lg:grid-cols-3 gap-4 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 overflow-hidden text-black dark:text-textinblack">
          <div className="flex flex-col xl:col-span-3 ">
            <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              <span className="font-normal pr-1">Username:</span>
              <span>
                {userInfo.first_name} {userInfo.last_name}
              </span>
            </label>
          </div>
          <div className="flex flex-col">
            <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              Current Password
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              type="password"
              value={currentPassword}
              className={`${
                currentPasswordError ? "border-red-500 border" : "border"
              } border h-10 rounded-lg px-2 text-sm font-medium `}
              onChange={(e) => {
                setCurrentPasswordError("");
                setCurrentPassword(e.target.value);
              }}
              onBlur={() => {
                if (currentPassword.trim() === "") {
                  setCurrentPasswordError(
                    "The current-password field is required."
                  );
                }
              }}
            />
            {currentPasswordError && (
              <span className="text-red-500 text-xs">
                {currentPasswordError}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label for="fname" className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              New Password
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              type="password"
              value={newPassword}
              className={`${
                newPasswordError ? "border-red-500 border" : "border"
              } border h-10 rounded-lg px-2 text-sm font-medium `}
              onChange={(e) => {
                setNewPasswordError("");
                setNewPassword(e.target.value);
              }}
              onBlur={() => {
                if (newPassword.trim() === "") {
                  setNewPasswordError("New  password is required");
                }
              }}
            />
            {newPasswordError && (
              <span className="text-red-500 text-xs">{newPasswordError}</span>
            )}
            <p className="text-xs">
              Your password must be at least 5 characters long and your password
              should contain.
            </p>
            <ol className="list-decimal text-xs pl-3">
              <li>At least one upper case letter</li>
              <li>At least one lower case letter</li>
              <li>
                At least one digit. Only (! \" # . @ _ ` ~ $ % ^ * : , ; | -)
                special characters are allowed in the password.
              </li>
            </ol>
          </div>
          <div className="flex flex-col">
            <label className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              Confirm Password
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              type="password"
              value={confirmPassword}
              className={`${
                confirmPasswordError ? "border-red-500 border" : "border"
              } border h-10 rounded-lg px-2 text-sm font-medium `}
              onChange={(e) => {
                setConfirmPasswordError("");
                setConfirmPassword(e.target.value);
              }}
              onBlur={() => {
                if (confirmPassword.trim() === "") {
                  setConfirmPasswordError(
                    "The Confirm-password field is required."
                  );
                }
              }}
            />
            {confirmPasswordError && (
              <span className="text-red-500 text-xs">
                {confirmPasswordError}
              </span>
            )}
          </div>
        </div>
      </div>
    // </div>
  );
};

export default ChangePassword;
