import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import PageHeading from "../pages/PageHeading";

const AddCategories = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [imageAltText, setImageAltText] = useState("");
  const [image, setImage] = useState("");
  const [imageError, setImageError] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;
  let [savedisable, setSavedisable] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setImageError(fileRejections[0].errors[0].message);
      }
      if (acceptedFiles.length > 0) {
        acceptedFiles.map((image) => {
          let data = new FormData();
          data.append("image", image);
          const config = {
            method: "post",
            url: API + "/blogs/categories/image/upload/",
            headers: {
              Authorization: `${access_token}`,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setImage(response.data);
              setImageError("");
            })
            .catch((error) => {
              console.log(error);
              if (
                error.status === "401" &&
                error.message === "JWT token is expired"
              ) {
                navigate("/admin/categories");
              }
            });
          return null;
        });
      }
    },
    [access_token]
  );

  const fetchCategory = useCallback(
    (id) => {
      let config = {
        method: "get",
        url: API + "/blogs/categories/" + id,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };

      axios(config)
        .then((response) => {
          const data = response.data;
          setId(data.categoryId);
          setTitle(data.title);
          setStatus(data.active);
          setImageAltText(data.imageAlt);
          setImage(data.image);
          setMetaTitle(data.metaTitle);
          setMetaDescription(data.metaDescription);
          setPageContent(data.description);
        })
        .catch((error) => {
          if (
            error.status === "401" &&
            error.message === "JWT token is expired"
          ) {
            navigate("/admin/categories");
          }
          console.log("error", error);
        });
    },
    [access_token, navigate]
  );

  const SaveEdit = (saveclick) => {
    let titleError = "";

    if (title.trim() === "") {
      titleError = "Enter title";
    }
    if (titleError !== "") {
      setTitleError(titleError);
      return;
    }
    let newCategory = {
      title: title,
      active: status,
      imageAlt: imageAltText,
      image: image,
      description: pageContent,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      displayOrder: 0,
      slug: title.toLowerCase().split(" ").join("-"),
    };
    if (id) {
      newCategory.categoryId = id;
      let config = {
        method: "put",
        url: API + "/blogs/categories/",
        data: newCategory,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };

      axios(config)
        .then(function (response) {
          notify("Category updated successfully");
          if (saveclick) {
            navigate("/admin/categories");
          } else {
            setId(response.data.categoryId);
            setTitle(response.data.title);
            setStatus(response.data.active);
            setImageAltText(response.data.imageAlt);
            setImage(response.data.image);
            setMetaTitle(response.data.metaTitle);
            setMetaDescription(response.data.metaDescription);
            setPageContent(response.data.pageContent);
          }
        })
        .catch((error) => {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            navigate("/admin/categories");
          }
          console.log(error);
        });
    } else {
      let config = {
        method: "post",
        url: API + "/blogs/categories/",
        data: newCategory,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };

      axios(config)
        .then(function (response) {
          notify("Category added successfully");
          if (saveclick) {
            navigate("/admin/categories");
          } else {
            let responsData = response.data;
            setId(responsData.categoryId);
            setTitle(responsData.title);
            setStatus(responsData.active);
            setImageAltText(responsData.imageAlt);
            setImage(responsData.image);
            setMetaTitle(responsData.metaTitle);
            setMetaDescription(responsData.metaDescription);
            setPageContent(responsData.pageContent);
          }
        })
        .catch((error) => {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            navigate("/admin/categories");
          }
          console.log(error);
        });
    }
  };

  const addEditHandle = () => {
    setSavedisable(true);
    SaveEdit(true);
  };

  const saveAndContinueHandle = () => {
    SaveEdit(false);
  };

  const handleCancelBtn = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (params.id) {
      fetchCategory(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    setId(params.id);
  }, [params.id]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 10000000,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  return (
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading={params.id ? "Edit Category" : "Add Category"}
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        handleSave={addEditHandle}
        saveAndContinue={true}
        handleSaveAndContinue={saveAndContinueHandle}
        savedisable={savedisable}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5"></div>
        <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Title
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={title}
              onChange={(e) => {
                setTitleError("");
                setTitle(e.target.value);
              }}
              type="text"
              className={`${
                titleError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter title"
            />
            {titleError && (
              <span className="text-red-500 text-xs">{titleError}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="status"
              className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
            >
              Status
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatus(JSON.parse(e.target.value));
              }}
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option>Select status</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </div>
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="1" className="font-medium mb-1">Image Alt</label>
            <input
              value={imageAltText}
              onChange={(e) => {
                setImageAltText(e.target.value);
              }}
              type="text"
              className="border h-10 rounded-lg px-2 text-sm font-medium "
              placeholder="Enter Image Alt"
            />
          </div>
        </div>
        <div className="w-full mt-5">
          <div>
            <label htmlFor="1" className="font-medium mb-1">Image</label>
            <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
              {image && (
                <img
                  src={
                    API +
                    "/files/download/?fileName=" +
                    `${encodeURIComponent(image)}`
                  }
                  alt="img"
                  className="w-full h-4/5  object-contain"
                />
              )}
              {image && (
                <button
                  onClick={() => {
                    setImage("");
                  }}
                  className="absolute z-20 top-1 right-1 btn bg-gray-900 text-white"
                >
                  Remove
                </button>
              )}
              {!image && (
                <div>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                    <em>(Only *.jpeg and *.png images will be accepted)</em>
                  </div>
                </div>
              )}
            </div>
            {imageError && (
              <span className="text-red-500 text-xs">{imageError}</span>
            )}
          </div>
          <div className="text-xs mt-1">
            Max size 5(MB) and Recommended Size: 1900PX x 1080PX (Allowed only
            jpg, jpeg, png and gif images)
          </div>
        </div>
        <div className="w-full my-5">
          <label
            htmlFor="status"
            className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
          >
            Description
          </label>
          <div className="w-full py-5">
            {id ? (
              <>
                {pageContent ? (
                  <Editor
                    value={pageContent}
                    init={{
                      height: 300,
                      forced_root_block: "",
                      statusbar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newValue, editor) => {
                      if (editor.getContent() === "") {
                        setPageContent(" ");
                      } else {
                        setPageContent(editor.getContent());
                      }
                    }}
                  />
                ) : null}
              </>
            ) : (
              <Editor
                value={pageContent}
                init={{
                  height: 300,
                  forced_root_block: "",
                  statusbar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newValue, editor) => {
                  setPageContent(editor.getContent());
                }}
              />
            )}
          </div>
        </div>
        <div className="w-full mb-5 flex flex-col">
          <div className="w-full">
            <h1 className="text-2xl font-medium">SEO</h1>
          </div>
          <div className="w-full flex flex-col lg:flex-row lg:space-x-2 mt-2">
            <div className="w-full flex flex-col">
              <label htmlFor="1" className="font-medium mb-1">Meta Title</label>
              <input
                value={metaTitle}
                type="text"
                onChange={(e) => {
                  setMetaTitle(e.target.value);
                }}
                className="h-10 rounded px-2 text-sm font-medium border"
                placeholder="Enter Meta Title"
              />
            </div>
            <div className="w-full flex flex-col">
              <label htmlFor="1" className="font-medium mb-1">Meta Description</label>
              <textarea
                value={metaDescription}
                onChange={(e) => {
                  setMetaDescription(e.target.value);
                }}
                className="h-10 rounded px-2 text-sm font-medium border"
                placeholder="Enter Meta Description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategories;
