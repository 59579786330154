import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { API } from "../../../services/API";
import Loader from "react-js-loader";
import "./emailVerify.css";

export default function VerfiyEmail() {
  const [success, setSuccess] = React.useState(null);
  const params = useParams();
  // console.log("params token is => ", params.id);

  const handleVerify = React.useCallback(() => {
    // console.log("handleVerify hit");
    let config = {
      method: "put",
      url: API + `/users/verifyEmail/${params.id}`,
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setSuccess(response.data);
      })
      .catch((error) => {
        console.log("Error is", error);
      });
  }, [params.id]);

  React.useEffect(() => {
    handleVerify();
  }, [params.id]);

  const displaySection =
    success === null ? (
      <Loader
        type="box-up"
        bgColor={"#0075fd"}
        // title={"Loading"}
        color={"#0075fd"}
        size={100}
      />
    ) : success === true ? (
      <>
        <div className="flex justify-center align-middle animation-ctn">
          <div className="icon icon--order-success svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="154px"
              height="154px"
            >
              <g fill="none" stroke="#22AE73" stroke-width="2">
                <circle
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashoffset: "960px",
                  }}
                ></circle>
                <circle
                  id="colored"
                  fill="#22AE73"
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashoffset: "960px",
                  }}
                ></circle>
                <polyline
                  class="st0"
                  stroke="#fff"
                  stroke-width="10"
                  points="43.5,77.8 63.7,97.9 112.2,49.4 "
                  style={{
                    strokeDasharray: "100px, 100px",
                    strokeDashoffset: "200px",
                  }}
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="flex justify-center align-middle text-2xl my-10">
          Your email verified successfully!!
        </div>
        <div className="flex justify-center align-middle text-blue-500">
          <a
            rel="noreferrer"
            href="https://reactjscms.devdigdev.com/frontendLogin"
            target="_blank"
          >
            Login Now?
          </a>
        </div>
      </>
    ) : (
      <>
        <div className="flex justify-center align-middle animation-ctn">
          <div className="icon icon--order-success svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="154px"
              height="154px"
            >
              <g fill="none" stroke="#F44812" stroke-width="2">
                <circle
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashoffset: "960px",
                  }}
                ></circle>
                <circle
                  id="colored"
                  fill="#F44812"
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashoffset: "960px",
                  }}
                ></circle>
                <polyline
                  class="st0"
                  stroke="#fff"
                  stroke-width="10"
                  points="43.5,77.8  112.2,77.8 "
                  style={{
                    strokeDasharray: "100px, 100px",
                    strokeDashoffset: "200px",
                  }}
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="flex justify-center align-middle text-2xl my-10">
          Your email couldn't be verified !!
        </div>
        <div className="flex justify-center align-middle text-blue-500">
          <a
            rel="noreferrer"
            href="https://reactjscms.devdigdev.com/frontendLogin"
            target="_blank"
          >
            Try Login ?
          </a>
        </div>
      </>
    );

  return (
    <div className="flex justify-center align-middle w-full">
      <div className="flex justify-center flex-col">{displaySection}</div>
    </div>
  );
}
