import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";

const PlanFeatureAdd = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [ID, setID] = useState("");
  const [Title, setTitle] = useState("");
  const [TitleError, setTitleError] = useState("");
  const [DisplayOrder, setDisplayOrder] = useState(0);
  const [Status, setStatus] = useState(true);
  const [allDisplayOrder, setAllDisplayOrder] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const access_token = userInfo.accessToken;

  const getPlanFeatureData = (id) => {
    let config = {
      method: "get",
      url: API + "/users/planfeature/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data;
        setID(data.id);
        setTitle(data.title);
        setDisplayOrder(data.displayOrder);
        setStatus(data.status);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/admin");
          handleLogout(navigate);
        }
        console.log(error);
      });
  };

  const nextDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/users/planfeature/nextdisplayorder",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const order = response.data;
        // console.log("+++++initial response", order);
        setDisplayOrder(order);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin");
          handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token]);

  const fetchAllDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/users/planfeature/alldisplayorders",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const orders = response.data;
        // console.log("+++++initial all display order response", orders);
        setAllDisplayOrder(orders);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          navigate("/admin");
          handleLogout(navigate);
        }
        console.log(error);
      });
  }, [access_token]);

  const handleCancelBtn = () => {
    setTitle("");
    setTitleError("");
    setDisplayOrder(0);
    setStatus("Active");
    navigate("/admin/planFeatures");
  };

  const SaveEdit = (saveclick) => {
    let TitleError = "";
    if (Title.trim() === "") {
      TitleError = "Title required";
      setTitleError(TitleError);
    }

    if (TitleError !== "") return;

    let id = ID;
    if (id) {
      let dataBody = {
        id: ID,
        title: Title,
        displayOrder: parseInt(DisplayOrder),
        status: Status,
      };

      let config = {
        method: "put",
        url: API + "/users/planfeature/",
        data: dataBody,
        headers: {
          "Content-type": "application/json",
          Authorization: `${access_token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Plan feature updated successfully");
            navigate("/admin/planFeatures");
          } else {
            notify("Plan feature updated successfully");
            let data = response.data;
            setID(data.id);
            setTitle(data.title);
            setDisplayOrder(data.displayOrder);
            setStatus(data.status);
            setTitleError("");
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response.status === "401" &&
            error.response.message === "JWT token is expired"
          ) {
            navigate("/admin");
            handleLogout(navigate);
          }
        });
    } else {
      let dataBody = {
        title: Title,
        displayOrder: parseInt(DisplayOrder),
        status: Status,
      };
      let config = {
        method: "post",
        url: API + "/users/planfeature/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: dataBody,
      };

      axios(config)
        .then((response) => {
          if (saveclick) {
            notify("Plan feature created successfully");
            navigate("/admin/planFeatures");
          } else {
            notify("Plan feature created successfully");
            let data = response.data;
            // console.log("data", data);
            setID(data.id);
            setTitle(data.title);
            setDisplayOrder(data.displayOrder);
            setStatus(data.status);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const handleSave = () => {
    SaveEdit(true);
  };

  const handleSaveAndContinue = () => {
    SaveEdit(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (params.id) {
      await getPlanFeatureData(params.id);
      await fetchAllDisplayOrder();
    } else {
      await nextDisplayOrder();
    }
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
    <div className="flex flex-col items-center w-full">
      <PageHeading
        pageHeading={params.id ? "Edit Plan Feature" : "Add Plan Feature"}
        showSaveOptionsBtn={true}
        cancel={true}
        handleCancelBtn={handleCancelBtn}
        save={true}
        handleSave={handleSave}
        saveAndContinue={true}
        handleSaveAndContinue={handleSaveAndContinue}
      />
      <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">
              Title
              <CgAsterisk className="inline text-red-500" />
            </label>
            <input
              value={Title}
              onChange={(e) => {
                setTitleError("");
                setTitle(e.target.value);
              }}
              type="text"
              className={`${
                TitleError ? "border-red-500 border" : "border"
              } h-10 rounded px-2 text-sm font-medium `}
              placeholder="Enter Title"
            />
            {TitleError && (
              <span className="text-xs text-red-500 my-1 tracking-wider">
                {TitleError}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="font-medium mb-1">Status</label>
            <select
              value={Status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </div>

        <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-medium mb-1">Display Order</label>
            <select
              value={DisplayOrder}
              onChange={(e) => {
                setDisplayOrder(e.target.value);
              }}
              disabled={ID ? false : true}
              name="displayOrder"
              className="border h-10 rounded px-2 text-sm font-medium"
            >
              {!ID && (
                <option key={DisplayOrder} value={DisplayOrder}>
                  {DisplayOrder}
                </option>
              )}
              {ID &&
                allDisplayOrder.map((order) => (
                  <option key={order} value={order}>
                    {order}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default PlanFeatureAdd;
