import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import _ from "lodash";
import "./surveystyle.css";
import {
  getKeyInfoFromIDBStore,
  addOrUpdateNewInfoToIDBStore,
} from "indexeddb-util";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifyWarn = (mess) => {
  toast.warn(mess, {
    position: "top-center",
  });
};

const notifySucc = (mess) => {
  toast.success(mess, {
    position: "top-center",
  });
};

const SurveyPage = () => {
  const [formDetails, setformDetails] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [submitinitiated, setSubitInitiated] = useState(0);

  const submitEntered = useRef(0);
  const submitCompleted = useRef(0);

  const token = useSelector(
    (state) => state?.LoginReducer?.userInfo?.accessToken
  );

  let navigate = useNavigate();

  const onSuccessfulFetchCallback = async (info) => {
    if (info) {
      setUserDetails(info);
    }
  };

  const fetchUserInfo = () => {
    getKeyInfoFromIDBStore({
    dbName: "loginStore",
    storeName: "loginStore",
    key: "surveyUserInfo",
    keyPath: "accessToken",
    onSuccessfulFetchCallback: onSuccessfulFetchCallback,
    });
  };

  function handleConnectionChange(event) {
    if (event.type === "online") {
      fetchUserInfo();
    }
  }
  window.addEventListener("online", handleConnectionChange);
  window.addEventListener("offline", handleConnectionChange);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userDetails && !submitCompleted.current) {
      setUserDetails(null);
      submitCompleted.current = 1;
      notifySucc("Details submitted successfully");
    }
  }, [userDetails]);

  const invokeFrontendLogin = () => {
    navigate("/frontendLogin");
  };

  const successCallback = (position) => {
    const { latitude, longitude } = position.coords;
    setformDetails({ ...formDetails, lat: latitude, long: longitude });
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (formDetails.file) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
  }, [formDetails.file]);

  const onChangeFile = (e) => {
    var image = document.getElementById("userImage");
    if (e.target.files.length) {
      image.src = URL.createObjectURL(e.target.files[0]);
      setformDetails({ ...formDetails, file: e.target.files });
    }
  };

  const onClickCancelImage = () => {
    var image = document.getElementById("userImage");
    image.src = "";

    const file = document.getElementById("file");
    file.value = "";
    const formObj = _.omit(formDetails, ["file"]);
    setformDetails(formObj);
  };

  const isValidForm = () => {
    setSubitInitiated(1);
    const hasLatLong = _.has(formDetails, "lat");
    const minReqLen = hasLatLong ? 7 : 5;
    const fulfilledLength = hasLatLong ? 8 : 6;
    if (
      Object.keys(formDetails).length >= minReqLen &&
      !_.has(formDetails, "hobby")
    ) {
      const elem = document.querySelector("#survey-form-improve1");
      elem.focus();
    }
    if (
      Object.keys(formDetails).length >= fulfilledLength &&
      _.has(formDetails, "hobby") &&
      !submitEntered.current
    ) {
      submitEntered.current = 1;
      
      const newItem = {
        ...formDetails,
      };
      if (!navigator.onLine) {
        notifyWarn("Your information will be submitted once you are online !");
        addOrUpdateNewInfoToIDBStore({
        dbName: "loginStore",
        storeName: "loginStore",
        keyPath: "accessToken",
        newItem,
        key: "surveyUserInfo",
        });
      }
    }
  };

  const onChangeChecked = (e) => {
    if (e.target.checked) {
      setformDetails({
        ...formDetails,
        hobby: {
          ...formDetails.hobby,
          [e.target.value]: e.target.value,
        },
      });
    } else {
      let { hobby } = formDetails;
      const obj = _.omit(hobby, [e.target.value]);
      if (_.isEmpty(obj)) {
        const formObj = _.omit(formDetails, ["hobby"]);
        setformDetails(formObj);
      } else {
        setformDetails({ ...formDetails, hobby: obj });
      }
    }
  };

  return (
    <>
      {token ? (
        <div
          className="flex justify-center bg-[#37404a]">
          <div
            id="surveyContainer"
            className="w-full lg:w-4/5 xl:w-4/5 md:w-4/5 flex flex-row items-center justify-center dark:bg-gray-600 mt-20 mb-12"
          >
            <main id="main" className="container">
              <div className="row">
                <div className="col-xs-12 col-lg-offset-3 col-lg-6">
                  <div className="m-b-md text-center">
                    <h1 id="title">Survey Form</h1>
                  </div>
                  <form
                    id="survey-form"
                    name="survey-form"
                  >
                    <div className="">
                      <div className="flex flex-row">
                        <img
                          id="userImage"
                          alt=""
                          hidden={!formDetails.file}                          
                          className="w-48 h-48 lg:w-72 lg:h-72 xl:w-80 xl:h-80 object-contain"
                        />
                        {formDetails.file && (
                          <div
                            id="cancelImage"
                            role="button"
                            onClick={onClickCancelImage}
                          />
                        )}
                      </div>

                      <fieldset>
                        <label htmlFor="image">
                          Upload Image * <input
                            type="file"
                            id="file"
                            onChange={(e) => {
                              onChangeFile(e);
                            }}
                            required
                          />
                        </label>
                      </fieldset>
                    </div>

                    <fieldset>
                      <label htmlFor="name" id="name-label">
                        Name * <input
                          className={
                            !formDetails.name && submitinitiated ? "error" : ""
                          }
                          type="text"
                          id="name"
                          name="name"
                          value={formDetails.name}
                          placeholder="Enter your name (required)"
                          required
                          onChange={(e) => {
                            if (e.target.value.length <= 255) {
                              setformDetails({
                                ...formDetails,
                                name: e.target.value,
                              });
                            }
                          }}
                        />
                      </label>
                    </fieldset>
                    <fieldset className="w-full lg:w-1/5 xl:w-1/5 md:w-1/4">
                      DOB *
                      <DatePicker
                        placeholder="Enter you dob (required)"
                        id="dob"
                        selected={formDetails.dob}
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={150}
                        shouldCloseOnSelect={true}
                        maxDate={new Date("2004/12/31")}
                        onChange={(date) => {
                          setformDetails({
                            ...formDetails,
                            dob: date,
                          });
                        }}
                        className={
                          !formDetails.dob && submitinitiated ? "error" : ""
                        }
                        required
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </fieldset>
                    <fieldset>
                      <div
                        className={
                          !formDetails.gender && submitinitiated
                            ? "labels error"
                            : "labels"
                        }
                      >
                        Gender *
                      </div>
                      <div className="grid grid-cols-3 gap-4">
                        <label htmlFor="1" className="m-b-xs gender">
                          <input
                            type="radio"
                            name="survey-form-gender"
                            value="M"
                            checked={formDetails.gender === "M"}
                            onChange={(e) =>
                              setformDetails({
                                ...formDetails,
                                gender: e.target.value,
                              })
                            }
                          />{" "}
                          <span>Male</span>
                        </label>
                        <label className="m-b-xs gender">
                          <input
                            type="radio"
                            name="survey-form-gender"
                            value="F"
                            required
                            checked={formDetails.gender === "F"}
                            onChange={(e) =>
                              setformDetails({
                                ...formDetails,
                                gender: e.target.value,
                              })
                            }
                          />{" "}
                          <span>Female</span>
                        </label>
                        <label className="m-b-xs gender">
                          <input
                            type="radio"
                            name="survey-form-gender"
                            value="O"
                            checked={formDetails.gender === "O"}
                            onChange={(e) =>
                              setformDetails({
                                ...formDetails,
                                gender: e.target.value,
                              })
                            }
                          />{" "}
                          <span>Others</span>
                        </label>
                      </div>
                    </fieldset>
                    <fieldset>
                      <label htmlFor="domain">
                        Which option best describes your currently? * <select id="domain" name="domain"
                          className={
                            !formDetails.domain && submitinitiated
                              ? "m-t-xs error"
                              : "m-t-xs"
                          }
                          value={formDetails.domain}
                          required="required"
                          onChange={(e) =>
                            setformDetails({
                              ...formDetails,
                              domain: e.target.value,
                            })
                          }
                        >
                          <option value="" hidden>
                            You are currently ...
                          </option>
                          <option value="student">Student</option>
                          <option value="ftLob">Full Time Job</option>
                          <option value="ftLearner">Full Time Learner</option>
                          <option value="notSaying">Prefer not to say</option>
                          <option value="other">Other</option>
                        </select>
                      </label>
                    </fieldset>

                    <fieldset>
                      <div
                        className={
                          !formDetails.hobby &&
                          _.isEmpty(formDetails.hobby) &&
                          submitinitiated
                            ? "labels error"
                            : "labels"
                        }
                      >
                        What do you like most in your everyday work? *
                      </div>
                      <label htmlFor="survey-form-improve1" className="m-b-xs">
                        <input type="checkbox" id="survey-form-improve1" value="feProjects" name="checkboxgrp1" onChange={(e) => { onChangeChecked(e); }} />
                        Front-end Projects
                      </label>
                      <label htmlFor="survey-form-improve2" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve2"
                          value="beProjects"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Back-end Projects
                      </label>
                      <label htmlFor="survey-form-improve3" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve3"
                          value="dataVisualization"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Data Visualization
                      </label>
                      <label htmlFor="survey-form-improve4" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve4"
                          value="challenges"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Challenges
                      </label>
                      <label htmlFor="survey-form-improve5" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve5"
                          value="openSources"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Open Source Community
                      </label>
                      <label htmlFor="survey-form-improve6" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve6"
                          value="gitter"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Gitter help rooms
                      </label>
                      <label htmlFor="survey-form-improve7" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve7"
                          value="videos"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Videos
                      </label>
                      <label htmlFor="survey-form-improve8" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve8"
                          value="meetup"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        City Meetups
                      </label>
                      <label htmlFor="survey-form-improve9" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve9"
                          value="wiki"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Wiki
                      </label>
                      <label htmlFor="survey-form-improve10" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve10"
                          value="forum"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Forum
                      </label>
                      <label htmlFor="survey-form-improve11" className="m-b-xs">
                        <input
                          type="checkbox"
                          id="survey-form-improve11"
                          value="additional"
                          name="checkboxgrp1"
                          onChange={(e) => {
                            onChangeChecked(e);
                          }}
                        />
                        Additional Courses
                      </label>
                    </fieldset>
                    <fieldset>
                      <label htmlFor="survey-form-suggestions">
                        Any Comments or Suggestions?
                        <textarea
                          id="survey-form-suggestions"
                          maxlength="255"
                          onChange={(e) => {
                            if (e.target.value.length <= 255) {
                              setformDetails({
                                ...formDetails,
                                comment: e.target.value,
                              });
                            }
                          }}
                        ></textarea>
                      </label>
                    </fieldset>

                    <button
                      id="submit"
                      type="submit"
                      onClick={() => {
                        isValidForm(formDetails);
                      }}
                      className="btn"
                    >
                      <span>Submit the form</span>
                    </button>
                  </form>
                </div>
              </div>
            </main>
          </div>
        </div>
      ) : (
        invokeFrontendLogin()
      )}
    </>
  );
};

export default SurveyPage;
