export const SidebarData = [
    {
        id: 1,
        image: "https://picsum.photos/id/0/3000/2000",
        alternativeText: "slider",
        activeSlider: true,
    },
    {
        id: 2,
        image: "https://picsum.photos/id/10/3000/2000",
        alternativeText: "my slider",
        activeSlider: false,
    },
    {
        id: 3,
        image: "https://picsum.photos/id/1001/3000/2000",
        alternativeText: "new slider",
        activeSlider: true,
    },
    {
        id: 4,
        image: "https://picsum.photos/id/1001/3000/2000",
        alternativeText: "new slider",
        activeSlider: true,
    },
    {
        id: 5,
        image: "https://picsum.photos/id/1011/3000/2000",
        alternativeText: "new slider",
        activeSlider: false,
    },
    {
        id: 6,
        image: "https://picsum.photos/id/1012/3000/2000",
        alternativeText: "slider",
        activeSlider: true,
    },
    {
        id: 7,
        image: "https://picsum.photos/id/1015/3000/2000",
        alternativeText: "slider",
        activeSlider: true,
    },
];
