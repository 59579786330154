import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineEye } from "react-icons/ai";
import { CgAsterisk } from "react-icons/cg";
import ReactPlayer from "react-player";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImageCrop } from "react_easy_croputil";
import { hoverAnimation, ytEmberUrl } from "../../helper/helper";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import UploadMultipleImages from "./uploadMultipleImages";

const AddEditGallery = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [displayOrder, setDisplayOrder] = useState(0);
  const [status, setStatus] = useState(true);
  const [imageAltText, setImageAltText] = useState("");
  const [image, setImage] = useState("");
  const [ImagesArray, setImagesArray] = useState([]);
  const [pageContent, setPageContent] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [links, setLinks] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [youtubeLinks, setYoutubeLinks] = useState([]);
  const [ytLink, setYtLink] = useState("");
  const [ytPopUp, setYtPopUp] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [imgPopUp, setImgPopUp] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const [allDisplayOrder, setAllDisplayOrder] = useState([]);
  const [isImgHover, setIsImgHover] = useState(false);
  const ShowYoutubeButton = true;
  const imageDimension = { width: 1900, height: 1080 };

  let userInfo = JSON.parse(localStorage.getItem("user"));
  let access_token = userInfo.accessToken;

  const handler = (info) => {
    if (info.error) {
      let errMsg = info.error;
      if (errMsg === "Invalid File dimension") {
        errMsg = `${errMsg}. Required dimension is 1900px x 1080px. Cannot upload file`;
      } else {
        errMsg = `${errMsg} of 5MB. Hence cannot upload file.`;
      }
      notify(errMsg,"error");
    }
    const fileInfo = [{ ...info }];
    const files = [...myFiles, ...fileInfo];
    setMyFiles([...files]);
  };

  const onImageDrop = (acceptedFiles) => {
    if (acceptedFiles.error) {
      let errMsg = acceptedFiles.error;
      if (errMsg === "Invalid File dimension") {
        errMsg = `${errMsg}. Required dimension is 1900px x 1080px. Cannot upload file`;
      } else {
        errMsg = `${errMsg} of 5MB. Hence cannot upload file.`;
      }
      notify(errMsg, "error");
    } else {
      setImageError("");
      if (acceptedFiles) {
        let data = new FormData();
        data.append("file", acceptedFiles.fileObject);
        const config = {
          method: "post",
          url: API + "/galleries/images/upload",
          headers: {
            Authorization: `${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            setImage(response.data);
            setImageError("");
          })
          .catch(function (error) {
            if (
              (error.status = "401") &&
              (error.message = "JWT token is expired")
            ) {
              // navigate("/admin/galleries");
              handleLogout(navigate);
            }
          });
        return null;
      }
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...myFiles, ...acceptedFiles];
      const filesWithId = files.map((item, index) => {
        return {
          id: index + 1,
          lastModified: item.lastModified,
          lastModifiedDate: item.lastModifiedDate,
          name: item.name,
          size: item.size,
          type: item.type,
          webkitRelativePath: !item.id
            ? URL.createObjectURL(item)
            : item.webkitRelativePath,
        };
      });
      setMyFiles(filesWithId);
    },
    [myFiles]
  );

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: "image/*",
      noKeyboard: true,
      noClick: true,
      maxSize: 10485760,
      onDrop,
    });

  const handleCancel = (file, index) => {
    const remFiles = myFiles.filter((item, idx) => idx !== index);
    const newFiles = [...remFiles];
    setMyFiles([...newFiles]);
    // const newFiles = [...myFiles];
    // setMyFiles(reject(newFiles, { id: file.id }));
  };

  const uploadSingle = (file) => {
    let validImgPresnt = false;
    // myFiles.map((image) => {
    if (!file.error) {
      let data = new FormData();
      data.append("file", file.fileObject);
      const config = {
        method: "post",
        url: API + "/galleries/images/upload",
        headers: {
          Authorization: `${access_token}`,
        },
        data: data,
      };
      // console.log("config", config);
      axios(config)
        .then(function (response) {
          // console.log("I am in uploadSingle");
          let arr = ImagesArray;
          arr.push(response.data);
          setImagesArray(arr);
        })
        .catch(function (error) {
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            // navigate("/admin/galleries");
            handleLogout(navigate);
          }
        });
      validImgPresnt = true;
    }
    // });
    if (validImgPresnt) {
      notify("Image uploaded to gallery!");
    }
  };

  const multipleImagesUpload = () => {
    let validImgPresnt = false;
    myFiles.map((image) => {
      if (!image.error) {
        let data = new FormData();
        data.append("file", image.fileObject);
        const config = {
          method: "post",
          url: API + "/galleries/images/upload",
          headers: {
            Authorization: `${access_token}`,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            let arr = ImagesArray;
            arr.push(response.data);
            setImagesArray(arr);
          })
          .catch(function (error) {
            if (
              (error.status = "401") &&
              (error.message = "JWT token is expired")
            ) {
              // navigate("/admin/galleries");
              handleLogout(navigate);
            }
          });
        validImgPresnt = true;
        return null;
      }
    });
    if (validImgPresnt) {
      notify("All valid images uploaded to gallery!");
    }
  };

  const remove = (imageUrl) => {
    setImage("");
  };

  const removeImg = (imageUrl) => {
    // console.log("In removeImg");
    // let arr = imageUrl.split("/");
    // let image = arr[arr.length - 1];
    // const config = {
    //   method: "delete",
    //   url: API + "/uploads",
    //   headers: {
    //     Authorization: `Bearer ${access_token}`,
    //   },
    //   data: {
    //     folder: "galleryImages",
    //     image: image,
    //   },
    // };
    // axios(config)
    //   .then(function (response) {
    //     const data = uploadedData.filter((d) => d.imageUrl !== imageUrl);
    //     setUploadedData(data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const uploadedImage = (imageLink, gallery_id) => {
    let arr = imageLink.split("/");
    let image = arr[arr.length - 1];
    let data = [gallery_id];
    const config = {
      method: "delete",
      url: API + "/galleries/images/",
      headers: {
        Authorization: `${access_token}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const data = uploadedData.filter((d) => d.id !== gallery_id);
        setUploadedData(data);
        const linkData = youtubeLinks.filter((d) => d.id !== gallery_id);
        setYoutubeLinks(linkData);
      })
      .catch(function (error) {
        console.log(error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          // navigate("/admin/galleries");
          handleLogout(navigate);
        }
      });
  };

  const SaveEdit = (saveclick) => {
    let titleError = "";
    let imageError = "";

    if (title.trim() === "") {
      titleError = "Enter title";
    }

    if (image.trim() === "") {
      imageError = "Upload image";
    }
    if (titleError !== "" || imageError !== "") {
      setTitleError(titleError);
      setImageError(imageError);
      return;
    }

    if (id) {
      let editGallery = {
        title: title,
        displayOrder: parseInt(displayOrder),
        status: status,
        imageAlt: imageAltText,
        image: image,
        description: pageContent,
        metaTitle: metaTitle,
        metaDescription: metaDescription,
        id: id,
      };
      // console.log("editGallery", editGallery);
      let config = {
        method: "put",
        url: API + "/galleries/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: editGallery,
      };
      axios(config)
        .then((response) => {
          if (ImagesArray.length) {
            UploadOtherImagesToGallery(location.state.details);
          }
          if (links.length) {
            UploadLink(location.state.details);
          }
          if (saveclick) {
            notify("Gallery updated successfully!");
            navigate("/admin/galleries");
          } else {
            notify("Gallery updated successfully!");
            let responseData = response.data;
            // console.log("response", response);
            setId(responseData.id);
            setTitle(responseData.title);
            setDisplayOrder(responseData.displayOrder);
            setStatus(responseData.status);
            setImageAltText(responseData.imageAlt);
            setImage(responseData.image);
            setPageContent(responseData.description);
            setMetaTitle(responseData.metaTitle);
            setMetaDescription(responseData.metaDescription);
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            // navigate("/admin/galleries");
            handleLogout(navigate);
          }
        });
    } else {
      let newGallery = {
        title: title,
        displayOrder: parseInt(displayOrder),
        status: status,
        imageAlt: imageAltText,
        image: image,
        description: pageContent,
        metaTitle: metaTitle,
        metaDescription: metaDescription,
        // isExternallink: links.length !== 0 ? true : false,
        // externalLink: links,
      };
      // console.log("newGallery", newGallery);
      let config = {
        method: "post",
        url: API + "/galleries/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: newGallery,
      };
      axios(config)
        .then((response) => {
          if (ImagesArray.length) {
            UploadOtherImagesToGallery(response.data);
          }
          if (links.length) {
            UploadLink(response.data);
          }
          if (saveclick) {
            notify("Gallery created successfully!");
            navigate("/admin/galleries");
          } else {
            notify("Gallery created successfully!");
            let responseData = response.data;
            setId(responseData.id);
            setTitle(responseData.title);
            setDisplayOrder(responseData.displayOrder);
            setStatus(responseData.status);
            setImageAltText(responseData.imageAlt);
            setImage(responseData.image);
            setPageContent(responseData.description);
            setMetaTitle(responseData.metaTitle);
            setMetaDescription(responseData.metaDescription);
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            // navigate("/admin/galleries");
            handleLogout(navigate);
          }
        });
    }
  };

  const handleSave = () => {
    SaveEdit(true);
  };

  const handleSaveAndContinue = () => {
    SaveEdit(false);
  };

  const UploadOtherImagesToGallery = (record) => {
    // console.log("ImagesArray", ImagesArray);
    ImagesArray.map((item) => {
      let bodyData = {
        galleryId: record.id,
        image: item,
        displayOrder: record.displayOrder,
        isExternallink: false,
        status: true,
      };
      // console.log("bodyData for image upload", bodyData);
      let config = {
        method: "post",
        url: API + "/galleries/images/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: bodyData,
      };
      axios(config)
        .then((response) => {
          // console.log("Response from UploadOtherImagesToGallery", response);
          navigate("/admin/galleries");
        })
        .catch((error) => {
          console.log("error", error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            // navigate("/admin/galleries");
            handleLogout(navigate);
          }
        });
    });
  };

  const UploadLink = (record) => {
    links.map((item) => {
      let bodyData = {
        galleryId: record.id,
        externalLink: item,
        displayOrder: record.displayOrder,
        isExternallink: true,
        status: true,
      };
      let config = {
        method: "post",
        url: API + "/galleries/images/",
        headers: {
          Authorization: `${access_token}`,
        },
        data: bodyData,
      };
      axios(config)
        .then((response) => {
          navigate("/admin/galleries");
        })
        .catch((error) => {
          console.log("error", error);
          if (
            (error.status = "401") &&
            (error.message = "JWT token is expired")
          ) {
            // navigate("/admin/galleries");
            handleLogout(navigate);
          }
        });
    });
  };

  const fetchGalleryImages = (id) => {
    setId(id);
    setTitle(location.state.details.title);
    setDisplayOrder(location.state.details.displayOrder);
    setStatus(location.state.details.status);
    setImageAltText(location.state.details.imageAlt);
    setImage(location.state.details.image);
    setPageContent(location.state.details.description);
    setMetaTitle(location.state.details.metaTitle);
    setMetaDescription(location.state.details.metaDescription);
    let config = {
      method: "get",
      url: API + "/galleries/images/gallery/" + id,
      headers: {
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then((response) => {
        const data = response.data;
        const LinkArr = [];
        const arr = [];
        data.map((record) => {
          if (record.isExternallink === true) {
            LinkArr.push({
              id: record.id,
              link: record.externalLink,
            });
          } else {
            arr.push({
              id: record.id,
              link: record.image,
            });
          }
          return null;
        });
        setYoutubeLinks(LinkArr);
        setUploadedData(arr);
      })
      .catch((error) => {
        console.log("error", error);
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          // navigate("/admin/galleries");
          handleLogout(navigate);
        }
      });
  };

  const removeLink = (index) => {
    const data = [...links];
    data.splice(index, 1);
    setLinks(data);
  };

  const handleCancelBtn = () => {
    navigate("/admin/galleries");
  };

  const nextDisplayOrder = useCallback(() => {
    let config = {
      method: "get",
      url: API + "/galleries/nextdisplayorder",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const order = response.data;
        setDisplayOrder(order);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        } else {
        }
        console.log(error);
      });
  }, [access_token]);

  const fetchAllDisplayOrder = useCallback(async () => {
    let config = {
      method: "get",
      url: API + "/galleries/alldisplayorders",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    await axios(config)
      .then(function (response) {
        const orders = response.data;
        setAllDisplayOrder(orders);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          handleLogout(navigate);
        } else {
        }
        console.log(error);
      });
  }, [access_token]);

  const onCloseModal = () => {
    setYtPopUp(false);
    setYtLink("");
  };

  const onCloseImageModal = () => {
    setImgPopUp(false);
    setImgLink("");
  };

  useEffect(() => {
    (async () => {
      await fetchAllDisplayOrder();
      const { id } = params;
      if (id) {
        fetchGalleryImages(id);
      } else {
        nextDisplayOrder();
      }
    })();
  }, [params.id]);

  useEffect(() => {
    if (displayOrder) {
      setDisplayOrder(displayOrder);
    }
  }, [displayOrder]);

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
    <>
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading={id ? "Edit Galleries" : "Add Galleries"}
          showSaveOptionsBtn={true}
          cancel={true}
          handleCancelBtn={handleCancelBtn}
          save={true}
          saveAndContinue={true}
          handleSave={handleSave}
          handleSaveAndContinue={handleSaveAndContinue}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5 grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Title
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={title}
                onChange={(e) => {
                  setTitleError("");
                  setTitle(e.target.value);
                }}
                type="text"
                className={`${
                  titleError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter title"
              />
              {titleError && (
                <span className="text-red-500 text-xs">{titleError}</span>
              )}
            </div>
            <div className="flex flex-col">
              <label
                for="status"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Display Order
              </label>
              <select
                value={displayOrder}
                onChange={(e) => {
                  setDisplayOrder(e.target.value);
                }}
                disabled={id ? false : true}
                name="displayOrder"
                className="border h-10 rounded px-2 text-sm font-medium"
              >
                {!id && (
                  <option key={displayOrder} value={displayOrder} disabled>
                    {displayOrder}
                  </option>
                )}
                {id &&
                  allDisplayOrder.map((order) => (
                    <option key={order} value={order}>
                      {order}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                for="status"
                className="font-medium mb-1 flex items-center text-black dark:text-textinblack"
              >
                Status
              </label>
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                className="border h-10 rounded px-2 text-sm font-medium"
              >
                <option>Select status</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="font-medium mb-1">Image Alt Text</label>
              <input
                value={imageAltText}
                onChange={(e) => {
                  setImageAltText(e.target.value);
                }}
                type="text"
                placeholder="Enter Image Alt Text"
                className="border h-10 rounded-lg px-2 text-sm font-medium "
              />
            </div>
          </div>
          <div className="w-full mt-5">
            <div>
              <label className="font-medium mb-1">
                Image
                <CgAsterisk className="inline text-red-500" />
              </label>
              <div className="w-full mt-2 relative border-dashed border-2 border-gray-700  h-56 flex items-center justify-center hover:bg-black hover:bg-opacity-40">
                {image && (
                  <img
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(image)}`
                    }
                    alt="no img"
                    className="w-full h-4/5  object-contain"
                  />
                )}
                {image && (
                  <button
                    onClick={() => {
                      remove(image);
                    }}
                    className="absolute z-20 top-1 right-1 bg-slate-700 hover:bg-slate-900 text-gray-200 font-bold py-2 px-4 rounded"
                  >
                    Remove
                  </button>
                )}
                {/* {!image && (
                  <Dropzone
                    accept="image/*"
                    onDrop={(acceptedFiles) => {
                      onImageDrop(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="w-full h-full  flex items-center justify-center"
                      >
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  )} */}
              </div>
              {!image && (
                <div className="py-2">
                  <ImageCrop
                    onChangeCallback={onImageDrop}
                    dimension={imageDimension}
                    // imgSize={1048576} // 2MB
                    imgSize={5242880} // 5MB
                    accept="image/png, image/jpeg, image/gif"
                  />
                </div>
              )}
            </div>
            <div className="text-xs mt-1">
              {/* Max size 20(MB) and Recommended Size: 508PX x 285PX (Allowed only
              jpg, jpeg, png and gif images) */}
              Max size 5(MB) and Recommended Size: 1900PX x 1080PX (Allowed only
              jpg, jpeg, png and gif images)
            </div>
            {imageError && (
              <span className="text-red-500 text-xs">{imageError}</span>
            )}
          </div>
          <div className="w-full my-5">
            <label for="status" className="font-medium mb-1 flex items-center text-black dark:text-textinblack">
              Description
            </label>
            <div className="w-full py-5">
              {id ? (
                <>
                  {pageContent ? (
                    <Editor
                      value={pageContent}
                      init={{
                        height: 300,
                        forced_root_block: "",
                        statusbar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(newValue, editor) => {
                        if (editor.getContent() === "") {
                          setPageContent(" ");
                        } else {
                          setPageContent(editor.getContent());
                        }
                      }}
                    />
                  ) : (
                    <Editor
                      value={pageContent}
                      init={{
                        height: 300,
                        forced_root_block: "",
                        statusbar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(newValue, editor) => {
                        if (editor.getContent() === "") {
                          setPageContent(" ");
                        } else {
                          setPageContent(editor.getContent());
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <Editor
                  value={pageContent}
                  init={{
                    height: 300,
                    forced_root_block: "",
                    statusbar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onEditorChange={(newValue, editor) => {
                    setPageContent(editor.getContent());
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-full mb-5 flex flex-col">
            <div className="w-full flex flex-col lg:flex-row lg:space-x-2 mt-2">
              <div className="w-full flex flex-col">
                <label className="font-medium mb-1">Meta Title</label>
                <input
                  value={metaTitle}
                  type="text"
                  placeholder="Enter Meta Title"
                  onChange={(e) => {
                    setMetaTitle(e.target.value);
                  }}
                  className="h-10 rounded px-2 text-sm font-medium border"
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="font-medium mb-1">Meta Description</label>
                <textarea
                  value={metaDescription}
                  placeholder="Enter Meta Description"
                  onChange={(e) => {
                    setMetaDescription(e.target.value);
                  }}
                  className="h-10 rounded px-2 text-sm font-medium border"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="w-full my-5">
            <UploadMultipleImages
              imageSize={20}
              dimension={imageDimension}
              acceptedFiles={myFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              fileRejections={fileRejections}
              handleCancel={handleCancel}
              uploadSingle={uploadSingle}
              multipleImagesUpload={multipleImagesUpload}
              links={links}
              setLinks={setLinks}
              ShowYoutubeButton={ShowYoutubeButton}
            />
            <ImageCrop
              onChangeCallback={handler}
              dimension={imageDimension}
              // imgSize={1048576} // 2MB
              imgSize={5242880} // 5MB
              accept="image/png, image/jpeg, image/gif"
            />
          </div>

          <div className="w-full my-5 flex flex-col">
            {links &&
              links.length > 0 &&
              links.map((link, index) => (
                <div className="border-2 my-2 flex justify-between items-center p-2 bg-gray-300 rounded">
                  <h1
                    className="text-sm font-medium cursor-pointer"
                    onClick={() => window.open(link)}
                  >
                    {link}
                  </h1>
                  <button
                    onClick={() => {
                      removeLink(index);
                    }}
                    className="btn bg-black "
                  >
                    x
                  </button>
                </div>
              ))}
          </div>
          <div className="w-full my-5 grid grid-cols-1 gap-4  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
            {uploadedData.length > 0 &&
              uploadedData.map((d) => (
                <div
                  className="w-full relative imgHover border border-gray-700 flex items-center justify-center"
                  onMouseEnter={() => setIsImgHover(d.link)}
                  onMouseLeave={() => setIsImgHover(null)}
                >
                  <img
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(d.link)}`
                    }
                    alt="image"
                    className="w-full"
                  />
                  <div
                    className={
                      isImgHover && isImgHover === d.link
                        ? "imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                        : "hidden"
                    }
                  >
                    <button
                      onClick={() => {
                        setImgPopUp(true);
                        setImgLink(d.link);
                      }}
                      className={
                        "w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center" +
                        hoverAnimation
                      }
                    >
                      <AiOutlineEye />
                    </button>
                    <button
                      onClick={() => {
                        let a = window.confirm(
                          "Are you sure you want to delete this"
                        );
                        if (a) {
                          if (params.id) {
                            uploadedImage(d.link, d.id);
                          } else {
                            removeImg(d.link);
                          }
                        }
                      }}
                      className={
                        "w-8 h-8 bg-black rounded-full flex items-center justify-center text-lg" +
                        hoverAnimation
                      }
                    >
                      x
                    </button>
                  </div>
                </div>
              ))}
            {youtubeLinks.length > 0 &&
              youtubeLinks.map((i) => (
                <div
                  className="w-full relative imgHover border rounded p-1 h-48 cursor-pointer shadow flex items-center bg-white justify-center"
                  onMouseEnter={() => setIsImgHover(i.link)}
                  onMouseLeave={() => setIsImgHover(null)}
                >
                  <iframe
                    src={`https://www.youtube.com/embed/${ytEmberUrl(i.link)}`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    className="border-2 w-full h-full"
                  />
                  <div
                    className={
                      isImgHover && isImgHover === i.link
                        ? "visible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                        : "invisible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                    }
                  >
                    <button
                      onClick={() => {
                        setYtPopUp(true);

                        setYtLink(ytEmberUrl(i.link));
                      }}
                      className={
                        "w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center" +
                        hoverAnimation
                      }
                    >
                      <AiOutlineEye />
                    </button>
                    <button
                      onClick={() => {
                        let a = window.confirm(
                          "Are you sure you want to delete this"
                        );
                        if (a) {
                          if (params.id) {
                            uploadedImage(i.link, i.id);
                          } else {
                            removeImg(i.link);
                          }
                        }
                      }}
                      className={
                        "w-8 h-8 bg-black rounded-full flex items-center justify-center text-lg" +
                        hoverAnimation
                      }
                    >
                      x
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal open={ytPopUp} onClose={onCloseModal} center>
        <div className="mt-5">
          <ReactPlayer
            controls
            url={`https://www.youtube.com/embed/${ytLink}`}
          />
        </div>
      </Modal>
      {/* {ytPopUp && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
         
          <div className="w-full h-10 flex item-center justify-end">
            <h1
              onClick={() => {
                setYtPopUp(false);
                setYtLink("");
              }}
              className="w-10 h-10 flex items-center justify-center cursor-pointer bg-black border-2 text-white"
            >
              x
            </h1>
          </div>
          <ReactPlayer
            controls
            url={`https://www.youtube.com/embed/${ytLink}`}
          />
        </div>
      )} */}
      <Modal open={imgPopUp} onClose={onCloseImageModal} center>
        <div className="mt-5">
          <img
            src={
              API +
              "/files/download/?fileName=" +
              `${encodeURIComponent(imgLink)}`
            }
            alt=""
            className="border-2 w-full h-full"
          />
        </div>
      </Modal>
      {/* {imgPopUp && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
          <div className="w-1/2 h-1/2  bg-white rounded  flex flex-col mt-10">
            <div className="w-full h-10 flex item-center justify-end">
              <h1
                onClick={() => {
                  setImgPopUp(false);
                  setImgLink("");
                }}
                className="w-10 h-10 flex items-center justify-center cursor-pointer bg-black border-2 text-white"
              >
                x
              </h1>
            </div>
            <img
              src={
                API +
                "/files/download/?fileName=" +
                `${encodeURIComponent(imgLink)}`
              }
              alt=""
              className="border-2 w-full h-full"
            />
          </div>
        </div>
      )} */}
      </>
    // </div>
  );
};

export default AddEditGallery;
