import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import ReactPlayer from "react-player";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useParams } from "react-router-dom";
import { hoverAnimation, ytEmberUrl } from "../../../helper/helper";
import { API } from "../../../services/API";

const GalleryDescription = (props) => {
  const params = useParams();
  const [gallery, setGallery] = useState("");
  const [images, setImages] = useState([]);
  const [links, setLinks] = useState([]);
  const [ytLink, setYtLink] = useState("");
  const [ytPopUp, setYtPopUp] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [imgPopUp, setImgPopUp] = useState(false);
  const [isImgHover, setIsImgHover] = useState(false);


  const fetchGallery = (id) => {
    let GalleryConfig = {
      method: "get",
      url: API + "/galleries/front/" + id,
    };
    axios(GalleryConfig)
      .then(function (response) {
        // console.log("response gallery", response);
        const gallery = response.data;
        setGallery(gallery);
      })
      .catch(function (error) {
        console.log("error", error);
      });

    let config = {
      method: "get",
      url: API + "/galleries/images/front/gallery/" + id,
    };
    axios(config)
      .then(function (response) {
        // console.log("response.data", response.data);
        const gallery = response.data;
        // setSelectOpt(id);
        const LinkArr = [];
        const arr = [];
        gallery.map((record) => {
          if (record.isExternallink === true) {
            LinkArr.push({
              id: record.id,
              link: record.externalLink,
            });
          } else {
            arr.push({
              id: record.id,
              link: record.image,
            });
          }
          return null;
        });
        setLinks(LinkArr);
        setImages(arr);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const onCloseModal = () => {
    setYtPopUp(false);
    setYtLink("");
  };

  const onCloseImageModal = () => {
    setImgPopUp(false);
    setImgLink("");
  };

  useEffect(() => {
    const id = params.id;
    // console.log("params", params);
    if (id) {
      fetchGallery(id);
      //   setGallery(params.details);
    }
  }, [params.id]);

  return (
    <div className="w-full pt-24 text-black dark:text-white pb-14">
      {gallery && (
        <div className="main-content">
          <h1 className="w-full text-center mb-4 text-2xl capitalize font-medium text-black dark:text-white">
            {gallery.title}
          </h1>
          <h1 className="w-full text-center font-medium capitalize mb-8">
            <div dangerouslySetInnerHTML={{ __html: gallery.description }} />
          </h1>
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 m-4">
            {images.length > 0 &&
              images.map((i) => (
                <div className="w-full border rounded h-48 p-1 cursor-pointer shadow flex items-center justify-center bg-white">
                  <img
                    alt="dd"
                    src={
                      API +
                      "/files/download/?fileName=" +
                      `${encodeURIComponent(i.link)}`
                    }
                    onClick={() => {
                      setImgPopUp(true);
                      setImgLink(i);
                    }}
                    className="w-full h-full object-contain"
                  />
                </div>
              ))}
            {links.length > 0 &&
              links.map((i) => {
                return (
                  <div
                    className="w-full relative imgHover border rounded p-1 h-48 cursor-pointer shadow flex items-center bg-white justify-center"
                    onMouseEnter={() => setIsImgHover(i.id)}
                    onMouseLeave={() => setIsImgHover(null)}
                  >
                    <iframe
                      src={`https://www.youtube.com/embed/${ytEmberUrl(
                        i.link
                      )}`}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title="video"
                      className="border-2 w-full h-full"
                    />
                    <div
                      className={
                        isImgHover && isImgHover === i.id
                          ? "visible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                          : "invisible flex imgHoverDisplay absolute inset-0 space-x-2 text-white bg-black bg-opacity-70 w-full h-full items-center justify-center"
                      }
                    >
                      <button
                        onClick={() => {
                          setYtPopUp(true);

                          setYtLink(ytEmberUrl(i.link));
                        }}
                        className={
                          "w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center" +
                          hoverAnimation
                        }
                      >
                        <AiOutlineEye />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <Modal open={ytPopUp} onClose={onCloseModal} center>
        <div className="mt-5">
          <ReactPlayer
            controls
            url={`https://www.youtube.com/embed/${ytLink}`}
          />
        </div>
      </Modal>
      {/* {ytPopUp && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
          <div className="w-full h-10 flex item-center justify-end">
            <h1
              onClick={() => {
                setYtPopUp(false);
                setYtLink("");
              }}
              className="w-10 h-10 flex items-center justify-center cursor-pointer bg-black border-2 text-white"
            >
              x
            </h1>
          </div>
          <ReactPlayer
            controls
            url={`https://www.youtube.com/embed/${ytLink}`}
          />
        </div>
      )} */}
      <Modal open={imgPopUp} onClose={onCloseImageModal} center>
        <div className="mt-5">
          <img
            alt="image"
            src={
              API +
              "/files/download/?fileName=" +
              `${encodeURIComponent(imgLink.link)}`
            }
            className="border-2 w-full h-full bg-white object-contain"
          />
        </div>
      </Modal>
      {/* {imgPopUp && (
        <div className="w-full absolute inset-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center">
          <div className="w-1/2 h-1/2  bg-white rounded  flex flex-col mt-10">
            <div className="w-full h-10 bg-white flex item-center justify-end">
              <h1
                onClick={() => {
                  setImgPopUp(false);
                  setImgLink("");
                }}
                className="w-10 h-10 flex items-center justify-center cursor-pointer bg-black border-2 text-white"
              >
                x
              </h1>
            </div>
            <img
              alt="image"
              src={
                API +
                "/files/download/?fileName=" +
                `${encodeURIComponent(imgLink.link)}`
              }
              className="border-2 w-full h-full bg-white object-contain"
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default GalleryDescription;
