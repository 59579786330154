import {
  STORE_LOGIN_DATA,
  REMOVE_LOGIN_DATA,
  UPDATE_LOGIN_DATA,
  UPDATE_LAST_ACTIVE,
} from "./../actionTypes";

export const LoginReducer = (
  state = {
    userInfo: null,
    isLoading: null,
    lastActive: null,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_LOGIN_DATA:
      return {
        userInfo: payload.userInfo,
        isLoading: false,
        lastActive: payload.lastActive,
      };
    case UPDATE_LAST_ACTIVE:
      return {
        ...state,
        lastActive: payload.lastActive,
      };
    case REMOVE_LOGIN_DATA:
      return {
        ...state,
        userInfo: null,
        isLoading: false,
        lastActive: null,
      };
    case UPDATE_LOGIN_DATA:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          firstName: payload.firstName,
          lastName: payload.lastName,
          profileImage: payload.profilePic,
          email: payload.email,
        },
      };
    default:
      return state;
  }
};
