import React, { useState } from "react";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

const FaqsTable = ({
  faqs,
  faqsPerPage,
  totalFaqs,
  paginate,
  currentPage,
  setNumberOfFaqs,
  selectAll,
  selectSingle,
  sortFaqs,
  sortByQuestion,
  setSortByQuestion,
  sortDisplayOrder,
  sortByDisplayOrder,
  setSortByDisplayOrder,
  selectAllCheckbox,
  setSelectAllCheckbox,
  handleActive,
  handleInActive,
  allFaqs,
  setFaqs,
}) => {
  const pageNumbers = [];
  let navigate = useNavigate();
  for (let i = 1; i <= Math.ceil(totalFaqs / faqsPerPage); i++) {
    pageNumbers.push(i);
  }
  let [sortQuestionArrows, setSortQuestionArrows] = useState(false);
  let [sortDisplayOrderArrows, setSortDisplayOrderArrows] = useState(true);

  const selectRecord = (record) => {
    let data = allFaqs.map((item) => {
      if (item.id === record.id) {
        item.select = true;
      }
      return item;
    });
    setFaqs(data);
    if (record.status) {
      handleInActive();
    } else {
      handleActive();
    }
  };

  return (
    <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark">
      <table className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b">
        <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
          <tr>
            <th scope="col" className="border-l-8 border-gray-200"></th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <input
                checked={selectAllCheckbox}
                className="cursor-pointer"
                onChange={(e) => {
                  setSelectAllCheckbox(!selectAllCheckbox);
                  selectAll(e);
                }}
                type="checkbox"
              />
            </th>
            <th
              onClick={() => {
                setSortByQuestion(!sortByQuestion);
                sortFaqs(sortByQuestion);
                setSortQuestionArrows(false);
                setSortDisplayOrderArrows(true);
              }}
              scope="col"
              className="cursor-pointer w-full px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">Question</span>
              {sortQuestionArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortQuestionArrows && (
                <>
                  {sortByQuestion === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => {
                setSortByDisplayOrder(!sortByDisplayOrder);
                sortDisplayOrder(sortByDisplayOrder);
                setSortQuestionArrows(true);
                setSortDisplayOrderArrows(false);
              }}
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase cursor-pointer whitespace-nowrap"
            >
              <span className="inline-block mr-2 ">Display Order</span>
              {sortDisplayOrderArrows && (
                <>
                  <RiArrowUpFill className="inline-block ml-1" />
                  <RiArrowDownFill className="inline-block ml-1" />
                </>
              )}
              {!sortDisplayOrderArrows && (
                <>
                  {sortByDisplayOrder === true ? (
                    <RiArrowUpFill className="inline-block ml-2" />
                  ) : (
                    <RiArrowDownFill className="inline-block ml-2" />
                  )}
                </>
              )}
            </th>
            <th
              scope="col"
              className=" px-6 py-3 text-left text-xs font-medium uppercase whitespace-nowrap"
            >
              Edit
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-darkblack">
          {faqs &&
            faqs.map((faq) => (
              <tr
                key={faq.id}
                className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
              >
                <td
                  className={`${
                    faq.status
                      ? "border-left-green-8 bg-green-500 cursor-pointer"
                      : "border-left-red-8 bg-red-500 cursor-pointer"
                  }`}
                  onClick={(e) => {
                    selectRecord(faq);
                  }}
                ></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm dark:text-textinblack">
                  <input
                    checked={faq.select}
                    onChange={(e) => selectSingle(e, faq.id)}
                    type="checkbox"
                    className="cursor-pointer"
                  />
                </td>
                <td className="px-6 py-4 text-sm capitalize text-gray-600 dark:text-textinblack">
                  <span className=" pb-2 inline-block">{faq.question}</span>
                </td>
                <td className="px-6 py-4 text-sm capitalize text-gray-600 dark:text-textinblack">
                  <span className=" pb-2 inline-block">{faq.displayOrder}</span>
                </td>
                <td className="flex py-4 place-content-center">
                  <h1
                    onClick={() => {
                      navigate("/admin/faqs/edit/" + faq.id);
                    }}
                    className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                  >
                    <FiEdit2 size={17} />
                  </h1>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
        <div className="bg-gray-200 px-5 py-3 w-full border-t">
          <input
            className="ml-3"
            checked={selectAllCheckbox}
            onChange={(e) => {
              setSelectAllCheckbox(!selectAllCheckbox);
              selectAll(e);
            }}
            type="checkbox"
          />
          <label
            onClick={() => {
              sortFaqs(sortByQuestion);
              setSortByQuestion(!sortByQuestion);
              setSortQuestionArrows(false);
            }}
            className="px-5 py-3 text-sm font-medium leading-normal"
          >
            Questions{" "}
            {!sortQuestionArrows && (
              <>
                {sortByQuestion === true ? (
                  <RiArrowUpFill className="inline-block ml-2" />
                ) : (
                  <RiArrowDownFill className="inline-block ml-2" />
                )}
              </>
            )}
          </label>
        </div>
        {faqs &&
          faqs.map((faq) => (
            <div
              className={`tab w-full border-t ${
                faq.status ? "border-left-green-8 " : "border-left-red-8"
              }`}
              key={faq.id}
            >
              <input
                className="absolute opacity-0"
                id={faq.id}
                type="checkbox"
                name="tabs"
              />
              <label
                className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                for={faq.id}
              >
                <input
                  checked={faq.select}
                  onChange={(e) => selectSingle(e, faq.id)}
                  type="checkbox"
                />
                <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">{faq.question}</span>
              </label>
              <div className="tab-content border-t overflow-hidden w-full">
                <div className="p-4">
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">answer:</h1>
                    <h1 className="dark:text-white text-left pl-2 line-clamp-3">
                      {faq.answer}
                    </h1>
                  </div>
                  <div className="flex py-1">
                    <h1 className="dark:text-white font-semibold">Edit:</h1>
                    <h1 className="dark:text-white text-left pl-2">
                      <h1
                        onClick={() => {
                          navigate("/admin/faqs/edit/" + faq.id);
                        }}
                        className="cursor-pointer underlinetext-bluecolor hover:text-indigo-900 dark:hover:text-blue-500"
                      >
                        <FiEdit2 size={17} />
                      </h1>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* pagination */}
      <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-wrap justify-between mt-5 mb-3">
      <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
              } liTags`}
              onClick={() => paginate(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
          <span className="pr-2 dark:text-textinblack">Show</span>
          <input
            type="number"
            min={1}
            value={faqsPerPage}
            className="px-1 py-1 w-24 border border-black"
            onChange={(e) => setNumberOfFaqs(e.target.value)}
          />
          <span className="pl-2 dark:text-textinblack">Entries</span>
        </div>
      </div>
    </div>
  );
};
export default FaqsTable;
