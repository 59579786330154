import {
    FETCH_SLIDER_IMAGE_REQUEST,
    FETCH_SLIDER_IMAGE_SUCCESS,
    FETCH_SLIDER_IMAGE_FAIL,
    CHANGE_ALTERNATIVE_TEXT,
    DELETE_SLIDER_IMAGE,
    DELETE_ALL_IMAGES,
} from "../actionTypes";

const initialState = {
    images: [],
    loading: false,
    error: null,
};

export const SliderImagesReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_SLIDER_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SLIDER_IMAGE_SUCCESS:
            return {
                ...state,
                images: payload,
                loading: false,
            };
        case FETCH_SLIDER_IMAGE_FAIL:
            return {
                ...state,
                images: [],
                error: payload,
            };
        case CHANGE_ALTERNATIVE_TEXT:
            return {
                ...state,
                images: payload,
            };
        case DELETE_SLIDER_IMAGE:
            return {
                ...state,
                images: payload,
            };
        case DELETE_ALL_IMAGES:
            return {
                ...state,
                images: payload,
            };
        default:
            return state;
    }
};
