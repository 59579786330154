import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
// import ReactImageMagnify from "react-image-magnify";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../Redux/actions/cartActions";
// import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from "react-dom/cjs/react-dom.development";

const ProductsDescription = (props) => {
  let dispatch = useDispatch();
  const [data, setData] =
    useState();
    // __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
  const [mainImg, setMainImg] = useState("");
  const [colors, setColors] = useState([]);
  const [sizes, setSize] = useState([]);
  const params = useParams();
  const id = params.id;
  const category = params.category;
  const subCategory = params.subCategory;

  const getClothDetails = useCallback(async () => {
    if (params.id) {
      await axios
        .get("http://localhost:5000/products/" + id)
        .then((res) => {
          if (res.status === 200) {
            let a = [];
            let b = [];
            res.data.product[0].attribute.map((att) => {
              if (!a.includes(att.color)) {
                a.push(att.color);
              }
              if (!b.includes(att.size)) {
                b.push(att.size);
              }
              return null;
            });
            setSize(b);
            setColors(a);
            setData(res.data.product[0]);
            setMainImg(res.data.product[0].image);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, params.id]);

  const getMobileDetails = useCallback(async () => {
    if (params.id) {
      await axios
        .get("http://localhost:5000/products/mobiles/" + id)
        .then((res) => {
          if (res.status === 200) {
            let color = res.data.mobile[0].color;
            let a = [];
            a.push(color);
            setColors(a);
            setData(res.data.mobile[0]);
            setMainImg(res.data.mobile[0].image);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, params.id]);

  const getLaptopDetails = useCallback(async () => {
    if (params.id) {
      await axios
        .get("http://localhost:5000/products/laptops/" + id)
        .then((res) => {
          if (res.status === 200) {
            let color = res.data.laptop[0].color;
            let a = [];
            a.push(color);
            setColors(a);
            setData(res.data.laptop[0]);
            setMainImg(res.data.laptop[0].image);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, params.id]);

  const imageProps = {
    smallImage: {
      alt: data.title,
      isFluidWidth: true,
      src: mainImg,
    },
    largeImage: {
      src: mainImg,
      width: 1200,
      height: 1800,
    },
    enlargedImageContainerStyle: { background: "#fff", zIndex: 9 },
  };
  useEffect(() => {
    window.scroll(0, 0);
    if (category === "clothes") {
      getClothDetails();
    }
    if (category === "electronics" && subCategory === "mobiles") {
      getMobileDetails();
    }
    if (category === "electronics" && subCategory === "laptop") {
      getLaptopDetails();
    }
  }, [
    category,
    subCategory,
    getClothDetails,
    getMobileDetails,
    getLaptopDetails,
  ]);
  return (
    <div className="w-full min-h-screen mt-14 bg-gray-100  dark:bg-gray-700 pt-16 pb-16  px-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 overflow-hidden">
      <div className="flex flex-col pb-20 px-2">
        {/* <ReactImageMagnify {...imageProps} /> */}

        <div className="w-full h-20 mt-10 grid grid-cols-4 sm:grid-cols-6 lg:grid-cols-6 gap-2 grid-flow-col">
          {data && (
            <img
              onClick={() => {
                setMainImg(mainImg);
              }}
              alt="ggg"
              src={mainImg}
              className="w-20 h-full object-contain border-2 border-gray-400 rounded bg-white cursor-pointer hover:border-gray-700"
            />
          )}
          {data &&
            data.images &&
            data.images.length > 0 &&
            data.images.map((i) => (
              <img
                onClick={() => {
                  setMainImg(i.imageUrl);
                }}
                alt="ff"
                src={i.imageUrl}
                className="w-20 h-full object-contain border-2 border-gray-400 rounded bg-white cursor-pointer hover:border-gray-700"
              />
            ))}
        </div>
      </div>
      <div className="pt-2 pl-0 sm:pl-10 lg:pl-10 xl:pl-10 mt-20 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
        <h1 className="w-full font-medium dark:text-white text-xl">
          {data.title}
        </h1>
        <p className="w-full text-sm text-gray-500 dark:text-gray-300 font-medium mt-6">
          {data.description}
        </p>
        <p className="w-full font-semibold text-lg text-yellow-500 mt-6">
          ${data.price}
        </p>
        <div className="w-full flex space-x-2 mt-2">
          {colors.length > 0 &&
            colors.map((color) => (
              <div
                style={{ background: color }}
                className="w-4 h-4 rounded-full cursor-pointer"
              ></div>
            ))}
        </div>
        <div className="w-full flex space-x-4 mt-2">
          {sizes.length > 0 &&
            sizes.map((size) => (
              <div className="w-6 h-6 flex items-center cursor-pointer justify-center text-xs rounded font-semibold border border-yellow-500 bg-gray-200">
                {size}
              </div>
            ))}
        </div>
        <div className="w-full mt-6">
          <button
            onClick={() => {
              dispatch(addToCart(data));
            }}
            className="bg-black text-white font-normal text-sm px-4 py-2 hover:text-yellow-500"
          >
            Add to cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductsDescription;
