import React, { useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../../services/API";
import { useSelector, useDispatch } from "react-redux";
import {
  StoreLoginData,
  RemoveLoginData,
} from "./../../../Redux/actions/LoginActions";
import { addOrUpdateAccessTokenToIDBStore } from "indexeddb-util";

const FrontendLogin = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(
    localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : ""
  );
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [APIError, setAPIError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    let emailError = "";
    let passwordError = "";

    if (email.trim() === "") {
      emailError = "The email field is required.";
    }
    if (password.trim() === "") {
      passwordError = "The password field is required.";
    }
    if (emailError !== "" || passwordError !== "") {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }
    if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    const path = API + "/users/auth/login";

    let data = {
      username: email,
      password: password,
    };
    let config = {
      credentials: "include",
      method: "post",
      url: path,
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Methods": "*",
      },
      data: data,
      crossDomain: true,
    };

    await axios(config)
      .then(async (response) => {
        let userInfo = await JSON.stringify(response.data);
        localStorage.setItem("userData", userInfo);
        localStorage.setItem("isAdmin", "false");
        // console.log("userData", response.data);
        let userData = response.data;
        if (userData && userData.userType.toLowerCase() === "front") {
          await dispatch(StoreLoginData(userData));
          // setFrontLogin(true);
          const newItem = {
            accessToken: userData.accessToken,
            userData: { ...userData },
          };
          addOrUpdateAccessTokenToIDBStore({
          dbName: "loginStore",
          storeName: "loginStore",
          keyPath: "accessToken",
          version: 2,
          newItem,
          });

          navigate("/");
        } else {
          let access_token = response.data.accessToken;
          const config = {
            method: "put",
            url: API + "/users/auth/logout",
            headers: {
              Authorization: `${access_token}`,
            },
          };
          axios(config)
            .then(function (response) {
              dispatch(RemoveLoginData());
              localStorage.removeItem("userData");
              setEmailError("You are not user. Please register!");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        let eorror_data = error.response?.data;
        if (eorror_data && eorror_data?.code === 400) {
          Object.entries(eorror_data.messagesMap).forEach(([key, value]) => {
            setAPIError(value);
          });
        }
      });

    if (rememberMe) {
      localStorage.setItem("userEmail", email);
    } else {
      localStorage.removeItem("userEmail");
    }
  };

  const handleKeyDown = (event) => {
    // console.log("I am here")
    if (event.key === "Enter") {
      // console.log("Enter pressed here");
      handleLogin(event);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div onKeyDown={(e) => handleKeyDown(e)} className="lg:w-1/3 sm:w-3/5 w-9/12 shadow-2xl p-5 rounded-lg bg-darkblack mt-24 mb-8">
      <div className="w--full flex items-center justify-center my-4">
        <h1 className="text-3xl font-normal text-white">Login</h1>
      </div>
      <div className="w-full flex flex-col">
        <label className="font-normal text-sm flex item-center tracking-wider text-white">
          Email <CgAsterisk className="inline-block text-red-500" />
        </label>
        <input
          type="email"
          value={email}
          placeholder="Enter your email address"
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
          }}
          onBlur={() => {
            if (email.trim() === "") {
              setEmailError("The email field is required.");
            }
          }}
          className={`${
            emailError && "border-red-500"
          } py-2 px-2 dark:bg-gray-200 border-b rounded border-gray-300 focus:outline-none focus:border-blue-500`}
        />
        {emailError && (
          <span className="text-xs text-red-500 my-1 tracking-wider">
            {emailError}
          </span>
        )}
      </div>
      <div className="w-full flex flex-col my-4">
        <label className="font-normal text-sm flex item-center tracking-wider text-white">
          Password <CgAsterisk className="inline-block text-red-500" />
        </label>
        <input
          type="password"
          placeholder="Enter your password"
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError("");
          }}
          onBlur={() => {
            if (password.trim() === "") {
              setPasswordError("The password field is required.");
            }
          }}
          className={`${
            passwordError && "border-red-500"
          } py-2 px-2 dark:bg-gray-200 rounded border-b border-gray-300  focus:outline-none focus:border-blue-500`}
        />
        {passwordError && (
          <span className="text-xs text-red-500 my-1 tracking-wider">
            {passwordError}
          </span>
        )}
      </div>
      <div className="w-full flex items-center">
        <input
          id="rememberMe"
          type="checkbox"
          checked={rememberMe}
          onChange={() => {
            setRememberMe(!rememberMe);
          }}
          className="mr-2"
        />
        <label
          for="rememberMe"
          className="text-sm tracking-wider cursor-pointer text-white"
        >
          Remember Me
        </label>
      </div>
      {APIError && (
        <span className="text-xs text-red-500 my-1 tracking-wider">
          {APIError}
        </span>
      )}
      <div className="w-full flex items-center justify-center my-4">
        <button
          onClick={(e) => handleLogin(e)}
          type="submit"
          className="w-full h-10 bg-blue-500 hover:bg-blue-600 text-sm text-white rounded shadow-lg tracking-wider dark:hover:bg-gray-700"
        >
          Login
        </button>
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          onClick={() => {
            localStorage.setItem("forgotPasswordAdmin", false);
            navigate("/bakend/forgetPassword", {
              state: {
                origin: "front",
              },
            });
            // navigate("/bakend/forgetPassword");
          }}
          className=" text-xs hover:underline tracking-wider outline-none text-white "
        >
          Forgot Your Password?
        </button>
      </div>
      <div className="w-full flex items-center justify-center mt-4">
        <button
          onClick={() => {
            navigate("/frontendSignUp");
          }}
          className=" text-sm font-semibold hover:underline tracking-wider outline-none text-blue-400 "
        >
          Sign Up
        </button>
      </div>
    </div>
    </div>
  );
};

export default FrontendLogin;
