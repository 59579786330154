import {
  STORE_SIGNUP_DETAILS,
  REMOVE_SIGNUP_DETAILS,
  STORE_SUBSCRIPTION_PLAN,
} from "./../actionTypes";

export const StoreSignupDetails = (payload) => {
  // console.log("In StoreSignupDetails", payload);
  let data = {
    userDetails: payload,
  };
  return {
    type: STORE_SIGNUP_DETAILS,
    payload: data,
  };
};

export const StoreSubscriptionPlan = (payload) => {
  // console.log("In StoreSubscriptionPlan", payload);
  let data = {
    subscriptionPlanId: payload,
  };
  return {
    type: STORE_SUBSCRIPTION_PLAN,
    payload: data,
  };
};

export const RemoveSignupDetails = () => {
  // console.log("In RemoveSignupDetails");
  let data = {
    userDetails: {},
  };
  return {
    type: REMOVE_SIGNUP_DETAILS,
    payload: data,
  };
};
