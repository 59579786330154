import React from "react";

const PageNotFound = (props) => {
    return (
        // <div
        //     className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
        //         props.Sidebar
        //             ? "w-full sm:content md:content lg:content xl:content"
        //             : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
        //     }`}
        // >
            <div className="w-full h-full flex flex-col items-center justify-center bg-gray-50">
                <h1 className="w-full text-center text-red-500 font-bold text-5xl uppercase mt-10">
                    Error
                </h1>
                <p className="w-full text-center text-gray-900 font-black text-9xl  uppercase mt-5">
                    404
                </p>
                <p className="w-full text-center text-red-500 font-bold text-3xl  capitalize mt-5">
                    page not found
                </p>
            </div>
        // </div>
    );
};

export default PageNotFound;
