import axios from "axios";
import React, { useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import TeamHeading from "../Teams/TeamHeading";
import { handleLogout } from "../login/handleLogOut";
import { RefreshToken } from "./../../services/RefreshToken";

const TeamSetting = (props) => {
  let navigate = useNavigate();

  const [titleID, setTitleID] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [subtitleID, setSubTitleID] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [subtitleError, setSubTitleError] = useState("");

  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  useEffect(() => {
    if (access_token) {
      getData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const getData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.siteConfig;

        let titleData = data.filter(
          (option) => option.name === "our_team_title"
        );
        let subtitleData = data.filter(
          (option) => option.name === "our_team_sub_title"
        );

        setTitleID(titleData[0].id);
        setTitle(titleData[0].val);
        setSubTitleID(subtitleData[0].id);
        setSubTitle(subtitleData[0].val);
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
        console.log(error);
      });
  };
  const saveTitle = () => {
    let titleBody = {
      id: titleID,
      val: title,
      status: true,
      type: "string",
      name: "our_team_title",
    };
    // console.log("dataBody", dataBody);

    let configtitle = {
      method: "put",
      url: API + "/siteConfigs/",
      data: titleBody,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    // console.log("config", config);

    axios(configtitle)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          handleLogout(navigate);
        }
      });
  };

  const saveSubTitle = () => {
    let subtitleBody = {
      id: subtitleID,
      val: subtitle,
      status: true,
      type: "string",
      name: "our_team_sub_title",
    };
    // console.log("dataBody", dataBody);

    let configsubtitle = {
      method: "put",
      url: API + "/siteConfigs/",
      data: subtitleBody,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    // console.log("config", config);

    axios(configsubtitle)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          handleLogout(navigate);
        }
      });
  };

  const changeHandler = () => {
    let titleError = "";
    let subtitleError = "";

    if (title.trim() === "") {
      titleError = "Enter Title";
    }
    if (subtitle.trim() === "") {
      subtitleError = "Enter Sub Title";
    }

    if (titleError !== "" || subtitleError !== "") {
      setTitleError(titleError);
      setSubTitleError(subtitleError);
      return;
    }
    saveTitle();
    saveSubTitle();
    notify("Data updated successfully");
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <TeamHeading
          pageHeading="Teams Settings"
          showSaveOptionsBtn={true}
          save={true}
          handleSave={changeHandler}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Title
                <CgAsterisk className="inline text-red-500" />
              </label>
              <input
                value={title}
                onChange={(e) => {
                  setTitleError("");
                  setTitle(e.target.value);
                }}
                type="text"
                className={`${
                  titleError ? "border-red-500 border" : "border"
                } h-10 rounded px-2 text-sm font-medium `}
                placeholder="Enter title"
              />
              {titleError && (
                <span className="text-red-500 text-xs">{titleError}</span>
              )}
            </div>

            <div className="flex flex-col">
              <label className="font-medium mb-1">
                Sub Title
                <CgAsterisk className="inline text-red-500" />
              </label>
              {subtitleError && (
                <span className="text-red-500 text-xs">{subtitleError}</span>
              )}
              <textarea
                className="w-full h-56 border rounded p-2"
                value={subtitle}
                onChange={(e) => {
                  setSubTitleError("");
                  setSubTitle(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default TeamSetting;
