import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import { notify } from "../../utility";
import { handleLogout } from "../login/handleLogOut";
import PageHeading from "../pages/PageHeading";
import { RefreshToken } from "./../../services/RefreshToken";

const TestimonialSetting = (props) => {
  let navigate = useNavigate();
  const [ImageAllow, setImageAllow] = useState("");
  const [RecordID, setRecordID] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [access_token, setAccess_token] = useState("");

  useEffect(() => {
    if (access_token) {
      getData();
    }
  }, [access_token]);

  useEffect(() => {
    let userCred = JSON.parse(localStorage.getItem("user"));
    if (userCred !== null) {
      setUserInfo(userCred);
      setAccess_token(userCred.accessToken);
    } else {
      localStorage.removeItem("user");
      navigate("/admin");
      window.location.reload();
    }
  }, []);

  const getData = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/?size=-1",
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data.siteConfig;
        // console.log(data);
        let filteredData = data.filter(
          (option) => option.name === "field_testimonial_title_display"
        );
        setRecordID(filteredData[0].id);
        if (filteredData.length !== 0) setImageAllow(filteredData[0].val);
        else setImageAllow("No");
      })
      .catch(async (error) => {
        if (
          (error.status = "401") &&
          (error.message = "JWT token is expired")
        ) {
          let userCred = await RefreshToken(
            access_token,
            setAccess_token,
            setUserInfo
          );
        } else {
          notify("Could not process your request!");
          // handleLogout(history);
        }
        console.log(error);
      });
  };

  const changeHandler = () => {
    let dataBody = {
      id: RecordID,
      val: ImageAllow,
      status: true,
      type: "string",
      name: "field_testimonial_title_display",
    };

    let config = {
      method: "put",
      url: API + "/siteConfigs/?size=-1",
      data: dataBody,
      headers: {
        "Content-type": "application/json",
        Authorization: `${access_token}`,
      },
    };

    axios(config)
      .then((response) => {
        notify("Updated successfully");
      })
      .catch((error) => {
        console.log(error);
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          handleLogout(navigate);
        }
      });
  };

  return (
    // <div
    //   className={`content-container bg-gray-100 overflow-y-auto scrollbar-hide ${
    //     props.Sidebar
    //       ? "w-full sm:content md:content lg:content xl:content"
    //       : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
    //   }`}
    // >
      <div className="flex flex-col items-center w-full">
        <PageHeading
          pageHeading="Testimonials Settings"
          showSaveOptionsBtn={true}
          save={true}
          handleSave={changeHandler}
        />
        <div className="overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark w-full lg:w-8/12">
          <div className="w-full mt-5  grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="font-medium mb-1">Allow Image</label>
              <select
                value={ImageAllow}
                onChange={(e) => {
                  setImageAllow(e.target.value);
                }}
                className="border h-10 rounded px-2 text-sm font-medium"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default TestimonialSetting;
