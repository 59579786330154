import React from "react";
import { useNavigate } from "react-router-dom";

const BlogsCategories = (props) => {
  let navigate = useNavigate();
  return (
    <div className="w-full flex flex-col border mb-4 rounded-md dark:bg-gray-800 bg-white">
      <div className="w-full border-b h-10 flex items-center pl-3 bg-gray-50">
        <label>Categories</label>
      </div>
      <div className="w-full flex  pl-3  items-center my-2">
        <ul className="">
          {/* <li
            onClick={() => {
              props.setAllBlog();
            }}
            className={`${
              props.activeCategory === "all"
                ? "text-yellow-500 font-semibold"
                : "text-blue-400"
            } text-sm underline  capitalize mb-2 cursor-pointer  hover:text-blue-500`}
          >
            All Blogs
          </li> */}
          {/* {console.log("props.categories", props.categories)} */}
          {props.categories &&
            props.categories.map((item, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    navigate({
                      pathname: "/blogs",
                      state: {
                        category: item,
                      },
                    });
                    props.categoriesFilter(item);
                  }}
                  className={`${
                    props.activeCategory.id === item.id
                      ? "text-yellow-500 font-semibold"
                      : "text-blue-400"
                  } text-sm underline  capitalize mb-2 cursor-pointer  hover:text-blue-500`}
                >
                  {item.val}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default BlogsCategories;
