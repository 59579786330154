import React, { useEffect, useState } from "react";
import { API } from "../../../services/API";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Testimonials = () => {
  let navigate = useNavigate();
  const [TestimonialsData, setTestimonialsData] = useState([]);
  const [AllowImage, setAllowImage] = useState("Yes");

  const showImage = () => {
    let config = {
      method: "get",
      url: API + "/siteConfigs/front/name/field_testimonial_title_display",
      headers: {
        "Content-type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("siteconfig Data", response.data);
        const data = response.data;
        const image_show = data.val;
        setAllowImage(image_show);
      })
      .catch((error) => {
        if (
          error.status === "401" &&
          error.message === "JWT token is expired"
        ) {
          navigate("/");
          // handleLogout(history);
        }
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
    showImage();
  }, []);

  const getData = async () => {
    let pageCount = 0;
    let dataArray = [];
    let index = 0;
    do {
      let config = {
        method: "get",
        url:
          API +
          `/testimonials/front/?size=5&page=${index}&sort=displayOrder, asc`,
      };
      await axios(config)
        .then(function (response) {
          let testimonials = response.data.testimonials;
          pageCount = response.data.totalPages;
          if (testimonials) {
            let filtered = testimonials.filter(
              (option) => option.status === true
            );
            dataArray.push(...filtered);
            // console.log("dataArray",index,  dataArray);
          }
        })
        .catch(function (error) {
          console.log("error", error);
        });
      // console.log("dataArray", dataArray);
      index++;
    } while (index < pageCount);
    setTestimonialsData(dataArray);
  };

  return (
    <div
      id="testimonials"
      className="flex items-center flex-col pb-10 w-11/12 md:w-4/5 mx-auto text-black dark:text-tabletitledark"
    >
      <div className="text-center justify-center">
        <h1 className="text-xl md:text-2xl lg:text-3xl text-center mb-3">
          Testimonials
        </h1>
      </div>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {/* {console.log("TestimonialsData",TestimonialsData)} */}
        {TestimonialsData.map((record, index) => (
          <div
            key={index}
            className="rounded h-54 overflow-hidden border bg-white dark:bg-slate-700 mx-1 md:mx-0 lg:mx-0 mb-5 team-box-members"
          >
            {AllowImage === "Yes" ? (
              <div className="w-full h-96">
                <img
                  className="object-cover object-center w-full h-full min-w-full min-h-full"
                  src={
                    API +
                    "/files/download/?fileName=" +
                    `${encodeURIComponent(record.image)}`
                  }
                  alt={record.imageAlt}
                />
              </div>
            ) : null}
            <div className="px-3 ">
              <div
                onClick={() => window.open(record.url)}
                className="cursor-pointer pt-2"
              >
                <span className="text-sm font-medium capitalize">
                  {record.clientName}
                </span>
              </div>
              <div className="pt-1">
                <div
                  className="mb-2 text-sm"
                  dangerouslySetInnerHTML={{ __html: record.description }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
