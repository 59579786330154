import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const PlanFeatureTable = ({
  state,
  selectAllCheckbox,
  setSelectAllCheckbox,
  selectAll,
  PlanFeatureData,
  selectSingle,
  // pageNumbers,
  currentPage,
  paginate,
  postPerPage,
  setPostsPerPage,
  SortName,
  SortByName,
  setSortByName,
  SortDisplayOrder,
  SortByDisplayOrder,
  setSortByDisplayOrder,
  sortCreated,
  sortByCreated,
  setSortByCreated,
  handleActive,
  handleInActive,
  allPlanFeaturesData,
  setPlanFeaturesData,
  totalPlanFeatures
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPlanFeatures / postPerPage); i++) {
    pageNumbers.push(i);
  }

  let navigate = useNavigate();
  let [SortNameArrow, setSortNameArrow] = useState(false);
  let [SortDisplayOrderArrow, setSortDisplayOrderArrow] = useState(true);
  let [sortCreatedArrow, setSortCreatedArrow] = useState(true);

  const selectRecord = (record) => {
    let data = allPlanFeaturesData.map((item) => {
      if (item.id === record.id) {
        item.select = true;
      }
      return item;
    });
    setPlanFeaturesData(data);
    if (record.status) {
      handleInActive();
    } else {
      handleActive();
    }
  };

  return (
    <div
      className={`overflow-x-auto scrollbar-hide py-3 px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 text-black dark:text-tabletitledark ${ state.loading ? "" : "" }`}>
      {state.loading ? (
        <>
          <table className="w-full hidden sm:table md:table lg:table xl:table border-gray-200 border-2">
            <thead className="bg-gray-50 dark:bg-gray-400 dark:text-textinblack w-full">
              <tr className="border-l-8 border-gray-400 h-10"></tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-700 animate-pulse">
              {Array(15)
                .fill()
                .map((i) => (
                  <tr
                    key={i}
                    className={`border-l-8 border-gray-400 border-b bg-gray-700 h-12 `}
                  >
                    <td className="px-3 py-4 whitespace-nowrap text-sm dark:text-textinblack"></td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium capitalize text-gray-900 dark:text-textinblack"></div>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-textinblack"></div>
                    </td>

                    <td className="px-3 py-4 whitespace-nowrap text-sm dark:text-textinblack"></td>
                    <td className="px-3 py-4 whitespace-nowrap text-sm dark:text-textinblack"></td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm dark:text-textinblack"></td>
                    <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r">
            <div className="bg-gray-200 px-5 py-3 w-full border-t">
              <label className="px-5 py-3 text-sm font-medium leading-normal"></label>
            </div>
            {Array(15)
              .fill()
              .map((i) => (
                <div
                  key={i}
                  className={`tab w-full border-t border-left-green-8 animate-pulse `}
                >
                  <label className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-blue-400 text-white dark:bg-gray-700 dark:text-textinblack">
                    <span className="px-5 block w-11/12 h-6"></span>
                  </label>
                  <div className="tab-content border-t   overflow-hidden w-full">
                    <div className="p-4">
                      <div className="flex py-1">
                        <p className="dark:text-textinblack font-semibold"></p>
                        <p className="dark:text-textinblack text-left pl-2"></p>
                      </div>
                      <div className="flex py-1">
                        <p className="dark:text-textinblack font-semibold"></p>
                        <p className="dark:text-textinblack text-left pl-2"></p>
                      </div>
                      <div className="flex py-1">
                        <p className="dark:text-textinblack font-semibold"></p>
                        <p className="dark:text-textinblack text-left pl-2"></p>
                      </div>
                      <div className="flex py-1">
                        <p className="dark:text-textinblack font-semibold"></p>
                        <p className="dark:text-textinblack text-left pl-2"></p>
                      </div>
                      <div className="flex py-1">
                        <p className="dark:text-textinblack text-left pl-2"></p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <table className="w-full hidden sm:table md:table lg:table xl:table border-r border-gray-200 dark:border-tabletitledark border-b">
            <thead className="text-black dark:text-white bg-lightblue dark:bg-bgtabletitle">
              <tr>
                <th scope="col" className="border-l-6 bg-gray-400"></th>

                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                >
                  <input
                    checked={selectAllCheckbox}
                    onChange={(e) => {
                      setSelectAllCheckbox(!selectAllCheckbox);
                      selectAll(e);
                    }}
                    type="checkbox"
                    className="cursor-pointer"
                  />
                </th>

                <th
                  onClick={() => {
                    SortName(SortByName);
                    setSortByName(!SortByName);
                    setSortNameArrow(false);
                    setSortDisplayOrderArrow(true);
                    setSortCreatedArrow(true);
                  }}
                  scope="col"
                  className="px-3 py-3 cursor-pointer text-left text-xs font-medium uppercase "
                >
                  <span className="inline-block mr-2">Name</span>
                  {SortNameArrow && (
                    <>
                      <RiArrowUpFill className="inline-block ml-1" />
                      <RiArrowDownFill className="inline-block ml-1" />
                    </>
                  )}
                  {!SortNameArrow && (
                    <>
                      {SortByName === true ? (
                        <RiArrowUpFill className="inline-block ml-2" />
                      ) : (
                        <RiArrowDownFill className="inline-block ml-2" />
                      )}
                    </>
                  )}
                </th>

                <th
                  onClick={() => {
                    SortDisplayOrder(SortByDisplayOrder);
                    setSortByDisplayOrder(!SortByDisplayOrder);
                    setSortNameArrow(true);
                    setSortDisplayOrderArrow(false);
                    setSortCreatedArrow(true);
                  }}
                  scope="col"
                  className="cursor-pointer px-3 py-3 text-left text-xs font-medium uppercase"
                >
                  Display Order
                  {SortDisplayOrderArrow && (
                    <>
                      <RiArrowUpFill className="inline-block ml-1" />
                      <RiArrowDownFill className="inline-block ml-1" />
                    </>
                  )}
                  {!SortDisplayOrderArrow && (
                    <>
                      {SortByDisplayOrder === true ? (
                        <RiArrowUpFill className="inline-block ml-2" />
                      ) : (
                        <RiArrowDownFill className="inline-block ml-2" />
                      )}
                    </>
                  )}
                </th>
                <th
                  onClick={() => {
                    sortCreated(sortByCreated);
                    setSortByCreated(!sortByCreated);
                    setSortNameArrow(true);
                    setSortDisplayOrderArrow(true);
                    setSortCreatedArrow(false);
                  }}
                  scope="col"
                  className="cursor-pointer  px-3 py-3 text-left text-xs font-medium uppercase"
                >
                  Modified On
                  {sortCreatedArrow && (
                    <>
                      <RiArrowUpFill className="inline-block ml-1" />
                      <RiArrowDownFill className="inline-block ml-1" />
                    </>
                  )}
                  {!sortCreatedArrow && (
                    <>
                      {sortByCreated === true ? (
                        <RiArrowUpFill className="inline-block ml-2" />
                      ) : (
                        <RiArrowDownFill className="inline-block ml-2" />
                      )}
                    </>
                  )}
                </th>
                <th
                  scope="col"
                  className=" px-3 py-3 text-left text-xs font-medium uppercase"
                >
                  Edit
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-darkblack">
              {PlanFeatureData.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-200 dark:hover:bg-black dark:hover:text-white border-b-gray-200 dark:border-b-gray-700 border-b"
                >
                  <td
                    className={`${
                      item.status === true
                        ? "border-left-green-6 bg-green-500 cursor-pointer"
                        : "border-left-red-6 bg-red-500 cursor-pointer"
                    }`}
                    onClick={(e) => {
                      selectRecord(item);
                    }}
                  ></td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm dark:text-textinblack">
                    <input
                      checked={item.select}
                      onChange={(e) => selectSingle(e, item.id)}
                      type="checkbox"
                      className="cursor-pointer"
                    />
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium capitalize text-gray-900 dark:text-textinblack">
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm dark:text-textinblack">
                    {item.displayOrder}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm dark:text-textinblack">
                    {item.updatedAt}
                  </td>
                  <td className="flex py-4 place-content-center">
                    <button
                      onClick={() => {
                        navigate("/admin/planFeatures/edit/" + item.id);
                      }}
                      className="cursor-pointer text-dark hover:text-indigo-900 dark:hover:text-blue-500"
                    >
                      <FiEdit2 size={17} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="overflow-hidden  block sm:hidden md:hidden lg:hidden xl:hidden border-r dark:bg-gray-600">
            <div className="bg-gray-200 px-5 py-3 w-full border-t">
              <input
                className="ml-3"
                onChange={(e) => {
                  setSelectAllCheckbox(!selectAllCheckbox);
                  selectAll(e);
                }}
                type="checkbox"
              />
              <label
                onClick={() => {
                  SortName(SortByName);
                  setSortByName(!SortByName);
                  setSortNameArrow(false);
                  setSortDisplayOrderArrow(true);
                  setSortCreatedArrow(true);
                }}
                scope="col"
                className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                <span className="inline-block mr-2 ">Name</span>
                {SortNameArrow && (
                  <>
                    <RiArrowUpFill className="inline-block ml-1" />
                    <RiArrowDownFill className="inline-block ml-1" />
                  </>
                )}
                {!SortNameArrow && (
                  <>
                    {SortByName === true ? (
                      <RiArrowUpFill className="inline-block ml-2" />
                    ) : (
                      <RiArrowDownFill className="inline-block ml-2" />
                    )}
                  </>
                )}
              </label>
            </div>

            {allPlanFeaturesData &&
              allPlanFeaturesData.map((item) => (
                <div
                  key={item.id}
                  className={`tab w-full border-t ${
                    item.status ? "border-left-green-8 " : "border-left-red-8"
                  }`}
                >
                  <input
                    className="absolute opacity-0"
                    id={item.id}
                    type="checkbox"
                    name="tabs"
                  />
                  <label
                    className="flex items-center justify-between px-5 py-3 text-sm font-medium leading-normal cursor-pointer bg-bluecolor text-white dark:bg-gray-700"
                    htmlFor={item.id}
                  >
                    <input
                      checked={item.select}
                      onChange={(e) => selectSingle(e, item.id)}
                      type="checkbox"
                    />
                    <span className="px-0 sm:px-5 block w-11/12 overflow-ellipsis overflow-hidden">{item.title}</span>
                  </label>
                  <div className="tab-content border-t   overflow-hidden w-full">
                    <div className="p-4">
                      <div className="flex py-1">
                        <h1 className="dark:text-white font-semibold">:</h1>
                        <h1 className="dark:text-white text-left pl-2">
                          {item.title}
                        </h1>
                      </div>

                      <div className="flex py-1">
                        <h1 className="dark:text-white font-semibold">
                          Display order:
                        </h1>
                        <h1 className="dark:text-white text-left pl-2">
                          {item.displayOrder}
                        </h1>
                      </div>
                      <div className="flex py-1">
                        <h1 className="dark:text-white font-semibold">
                          Modified On:
                        </h1>
                        <h1 className="dark:text-white text-left pl-2">
                          {item.updatedAt}
                        </h1>
                      </div>

                      <div className="flex py-1">
                        <h1 className="dark:text-white font-semibold">Edit:</h1>
                        <h1 className="dark:text-white text-left pl-2">
                          <h1
                            onClick={() => {
                              navigate("/admin/planFeatures/edit/" + item.id);
                            }}
                            className="cursor-pointer underlinetext-bluecolor hover:text-indigo-900 dark:hover:text-blue-500"
                          >
                            <FiEdit2 size={17} />
                          </h1>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* pagination */}
          <div className="w-full  flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-between mt-5 mb-3">
          <ul className="flex items-center justify-start cursor-pointer border-y border-e border-bluecolor rounded-md overflow-hidden">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`px-3 py-2 text-sm grow text-center border-s border-bluecolor hover:bg-bluecolor hover:text-white ${
                    currentPage === number ? "bg-bluecolor text-white" : "bg-slate-50 text-bluecolor"
                  } liTags`}
                  onClick={(e) => {
                    paginate(number);
                  }}
                >
                  {number}
                </li>
              ))}
            </ul>
            <div className="flex items-center justify center mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
              <span className="pr-2 dark:text-textinblack">Show</span>
              <input
                type="number"
                min={1}
                value={postPerPage}
                className="px-1 py-1 w-24 border border-black"
                onChange={(e) => setPostsPerPage(e.target.value)}
              />
              <span className="pl-2 dark:text-textinblack">Entries</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PlanFeatureTable;
