import Auth from "../login/Auth";
import { API } from "../../services/API";
import axios from "axios";
import { deleteDB } from "indexeddb-util";

export const handleLogout = (navigate) => {
  Auth.signout();
  let userInfo;
  const isAdmin = window.location.pathname.indexOf("/admin") === 0;
  if (
    localStorage.getItem("user") !== undefined &&
    localStorage.getItem("user") !== null
  ) {
    userInfo = JSON.parse(localStorage.getItem("user"));
  } else {
    userInfo = JSON.parse(localStorage.getItem("userData"));
  }

  let access_token = userInfo?.accessToken;
  const config = {
    method: "put",
    url: API + "/users/auth/logout",
    headers: {
      Authorization: `${access_token}`,
    },
  };
  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });

  deleteDB({
  dbName: "loginStore",
  });
  localStorage.clear();
  if (isAdmin) {
    navigate("/admin");
  } else {
    navigate("/frontendLogin");
  }
};
